import React, { useEffect, useState } from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from 'react-bootstrap'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import axios from 'axios';
import { USER_MANAGEMENT, FRONT_BASE } from '../config/config';
import { Layout } from './common/Layout';
import loader_image from '../images/loading.gif';
import jstz from 'jstz';

import { sha256, sha224 } from 'js-sha256';
//import { adminService,userService } from '../../_services';
import { adminService, userService } from '../_services';
import { Timeformatter } from '../components/common/Timeformatter'
import { DataHandling, HandlePermission } from '../components/common/DataHandling'
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}


const Account = () => {

  const [users, setUsers] = useState([]) as any;
  const [userskyc, setUsersKYC] = useState([]);
  const [isLoading, setisLoading] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isLoader, setIsLoader] = useState(false)
  const [disable, setDisable] = useState(false);
  const [permissionCount, setPermissionCount] = useState(0);
  useEffect(() => {
    getData(0, "");
  }, []);

  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(20);
  const [totalPage, setTotalPage] = useState(1);
  const user_role = localStorage.getItem("user_role");

  const total = Math.ceil(totalPage / usersPerPage);

  const getData = (page: any, txt: any) => {

    adminService.getAccounts(page, txt)
      .then(function (response: any) {

        console.log("getData-------", response.data)
        setPermissionCount(response.data.permissionsCount)
        var data1 = response.data.data
        console.log(data1)
        if (data1.count > 0) {
          setisLoading(1)
        } else {
          setisLoading(2)
        }
        setTotalPage(data1.count)
        setUsers(data1.user)

      }).catch(function (error: any) {
        console.log(error);
        setisLoading(3)
        setUsers([])
      });
  }
  const timezone = jstz.determine();
  const tz = timezone.name()

  function KYCverified(user_id: any) {
    const access_token = localStorage.getItem('access_token');
    if (access_token && user_id) {
      console.log("submit start")
      let config1 = {
        headers: {
          'Content-Type': 'application/json',
          'deviceType': 'Android',
          'appVersion': '1.0',
          'Authorization': 'Bearer ' + access_token
        }
      }
      console.log("submit start end" + config1.headers)

      axios.put(`${USER_MANAGEMENT}/admin/${user_id}/verifyKYC`, {}, config1).then(function (response) {
        var data1 = response.data.data
        console.log(response.data)
        getData(currentPage, "");
        setUsersKYC(response.data)
      }).catch(function (error) {
        console.log(error);
        getData(currentPage, "");
        setUsersKYC([])
      })
    }
  }

  function search_account(e: any) {
    setSearchText(e.target.value);
    getData(0, e.target.value)
  }


  function getDataaccount(currentPage: any, txt: any) {
    setIsLoader(true)

    adminService.get_account_detail(currentPage, txt)
      .then(function (response: any) {
        setIsLoader(false)
        if (response.data.data.count > 0) {
          setisLoading(1)
        } else {
          setisLoading(2)

        }
        console.log("-----------------------------------------response", response.data.data);
        setCurrentPage(currentPage);
        setUsers(response.data.data.user)
      }).catch(function (error: any) {
        console.log("-----------error--------", error);
        setIsLoader(false)
        // userService.handleError(error);
      });
  }

  function get_kyc_status(email: any, index: any) {
    console.log("get_kyc_status", email);
    console.log("index", index);
    let tmp = "up";
    // return "a";
    // get_kyc_status
    // return "aaaaaa"
    // email = "atul.netset@gmail.com";
    let api_key = "prod-GyNtgjCefFzWrOCMjYFCdoxpZTumokrl";
    let sha_key = sha256(email + "s2-HAwCJvRSROozaRRZpWYwrNlnqlMRbktI");
    // sha_key = "9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08";

    userService.get_kyc_status(email, api_key, sha_key).then(function (response) {
      console.log("responsee---", response);

      if (response.data.status == "user_not_found") {
        console.log("not submitted");
        tmp = "Not submitted";
        users[index].kyc = "Not submitted"
        console.log("kyccc", users);
        // setUsers(users);
        // return "not submitted"
      }


    }).catch(function (error) {
      console.log("error---", error);
      return "Not Available"
    })

    // setTimeout(function(){ 
    //  return tmp;
    //  }, 1000);


  }

  function ekyc_unverified(user_id: any, index: any) {
    if (user_id) {
      adminService.un_verify_user_eKYC(user_id).then(function (response) {
        var data1 = response.data.data
        console.log(response.data)
        // users[index].basisStatus=10
        getData(0, "");
        setUsersKYC(response.data)
      }).catch(function (error) {
        console.log(error);
        getData(0, "");
        setUsersKYC([])
      })
    }
  }



  const makeHttpRequestWithPage = async (pageNumber: any) => {
    setUsers([]);
    if (pageNumber < 0) {
      pageNumber = total - 1
    }
    if (pageNumber == total) {
      pageNumber = 0
    }
    getData(pageNumber, searchText);
    setCurrentPage(pageNumber)

  }

  const pagination = () => {
    let table = []
    table.push(<li><a href="javascript:void(0)" className={currentPage == 0 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage - 1)}>Previous</a></li>)
    for (let i = 0; i < total; i++) {
      console.log("i------------------->" + currentPage)
      // table.push(<span onClick={() => makeHttpRequestWithPage(i)} className="active">{i}</span>)
      table.push(<li className={i == currentPage ? "active block_cursor_event" : ""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}  >{i + 1}</a></li>)
    }
    table.push(<li><a href="javascript:void(0)" className={currentPage == total - 1 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage + 1)}>Next</a></li>)
    return table
  }


  const UserTable = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>PHONE NUMBER</th>
            <th>NAME</th>
            <th>EMAIL ADDRESS</th>
            <th>Country</th>
            <th>DATE & TIME</th>
            {/* <th style={{ textAlign: 'center' }}>KYC</th> */}
            <th>Action</th>

          </tr>
        </thead>
        <tbody>
          {users.length > 0 ? users.map((item: any, index: any) => {

            return (
              <tr key={index}>
                <td>
                  <a href={`/admin/AccountDetails/${item.id}`}>
                    {(currentPage * 20) + (index + 1)}
                  </a>
                </td>
                <td>{item.countryCode}-{item.phoneNumber}</td>
                <td>{(item.firstName == null || item.firstname == "") ? '-' : item.firstName} {item.lastName ? item.lastName : ''}</td>
                <td>{item.email}</td>

                <td>{(item.country == null || item.country == "") ? "-" : item.country.name}</td>
                <td><Timeformatter timeget={item.createdAt} /></td>
                {/* {HandlePermission("edit", "account") ?
                  // <td style={{textAlign: 'center'}}>{item.isKycVerified == true ? <a className="btn btn-primary" style={{borderColor: 'red'}} onClick={() => ekyc_unverified(item.id)}>Un-Verified</a> : item.ekycStatus=="NOT_APPLIED"? "Not apply for kyc"  : item.ekycStatus=="DONE"? "Verified" :<a className="btn btn-primary" onClick={() => KYCverified(item.id)}>KYC Verified</a>}</td>


                  // <td style={{textAlign: 'center'}}>{item.basisStatus == 11 ? <button className="btn btn-secondary" style={{borderColor: '#868585',backgroundColor:"#868585", color:"#fff", cursor: 'not-allowed'}} disabled={disable} onClick={() => setDisable(false)}>Verify KYC</button> :(item.basisStatus == 10 ? <a className="btn btn-primary"
                  // style={{borderColor: 'red'}} onClick={() => ekyc_unverified(item.id)}>Verify KYC</a>
                  // // style={{borderColor: 'red', cursor:'default'}}>Verify KYC</a>

                  //  :
                  //  <a className="btn btn-primary" style={{borderColor: 'green'}} onClick={() =>KYCverified(item.id) }>KYC Verified</a> )}</td>


                  ////////////

                  // <td style={{textAlign: 'center'}}>

                  //   {(item.basisStatus == 11 || item.basisStatus == 0 || item.basisStatus == null) ? 
                  //     <button className="btn btn-secondary" style={{borderColor: '#868585',backgroundColor:"#868585", color:"#fff", cursor: 'not-allowed'}} disabled={disable} onClick={() => setDisable(false)}>Verify KYC</button> 
                  //   :

                  //   (item.basisStatus == 10 && item.isKycVerified == false ? 
                  //     <a className="btn btn-primary"
                  //     style={{borderColor: 'red'}} onClick={() => KYCverified(item.id)}>Verify KYC</a>
                  // // style={{borderColor: 'red', cursor:'default'}}>Verify KYC</a>
                  //  :
                  //  (<a className="btn btn-primary" style={{borderColor: 'green'}} >KYC Verified</a> ))}
                  // </td>

                  // <td style={{textAlign: 'center'}}>

                  //   {(item.basisStatus == 10 && item.isKycVerified ==null ) ? 
                  //     <a className="btn btn-primary"
                  //     style={{borderColor: 'red'}} onClick={() => KYCverified(item.id)}>Verify KYC</a>
                  //   :

                  //   (item.basisStatus == 10 && item.isKycVerified == true ? 
                  //     <button className="btn" style={{borderColor: 'green'}} disabled={true}>KYC Verified</button> 
                  // // style={{borderColor: 'red', cursor:'default'}}>Verify KYC</a>
                  //  :
                  //  (<button className="btn btn-secondary" style={{borderColor: '#868585',backgroundColor:"#868585", color:"#fff", cursor: 'not-allowed'}} disabled={disable} onClick={() => setDisable(false)}>Verify KYC</button> ))}
                  // </td>
                  <td style={{ textAlign: 'center' }}>
                    {(item?.ekycStatus !== null && item?.ekycStatus !== "NOT_APPLIED")? (
                      ((item?.ekycStatus == "REJECTED" || item?.ekycStatus == "DONE" || (item?.ekycStatus == "APPLIED")) && !item?.isKycVerified) ?
                        ((user_role =="KYCADMIN" && permissionCount !=3)?
                        <button className="btn btn-secondary" style={{ borderColor: '#868585', backgroundColor: "#868585", color: "#fff", cursor: 'not-allowed' }} disabled={disable}>Verify KYC</button>
                        :<a className="btn btn-primary"
                          style={{ borderColor: 'red' }} onClick={() => KYCverified(item.id)}>Verify KYC</a>) :
                        <button className="btn btn-secondary" style={{ borderColor: '#868585', backgroundColor: "#868585", color: "#fff", cursor: 'not-allowed' }} disabled={disable}>KYC Verified</button>
                    ):"--"}
                  </td>

                  :
                  <td>{item.basisStatus == null ? <a>Verified</a> : <a>Not Verified</a>}</td>
                } */}

                <td>
                  <a href={`/admin/AccountDetails/${item.id}`}>
                    View Details {item.isKycVerified}
                  </a>
                </td>
              </tr>)
          }) :
            <div id="" className="loaderbox" style={{ marginTop: '80px' }}><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div>
          }
        </tbody>
      </table>
    )

  }

  console.log("usersPerPage" + usersPerPage)
  return (
    <Layout>
      <section className="inner_sction">
        <section className="table_sction accunt_table">
          <div className="container-fluid">
            <div className="table_sction_outer admin_index loader_parent">
              {getData}
              <div className="table_inner">
                <div className="table-responsive">

                  <div className="top-sctin mbtm-0">
                    <h4>Accounts</h4>
                    <div className="form-group pull-right">
                      <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                      <input type="text" className="form-control" placeholder="Search accounts by name,email,phone" value={searchText} onChange={(e) => search_account(e)} />
                    </div>
                    <div className="clearfix"></div>

                  </div>

                  {isLoading == 0 && <div id="api_loader">

                    {/* <img src={loader_image} width="50" height="50" />Loading... */}
                    <div id="" className="loaderbox">
                      <div>
                        <img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p>
                      </div>
                    </div>

                  </div>}
                  {isLoading == 1 && UserTable()}
                  {isLoading == 2 && <h1>Record Not Found</h1>}
                  {isLoading == 3 && <h1>Some Error Occur</h1>}
                </div>
                <div className="pagination_sction">
                  <ul className="pagination">
                    {total > 0 ? pagination() : ""}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {isLoader &&
          <div id="" className="loaderbox loginloader innerloader"><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div>
        }
      </section>
    </Layout>
  )
}

export default reduxForm({
  form: 'searchForm' // a unique identifier for this form
})(Account)


