import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import store from '../../store';
import { Container, Row, Col, Button } from 'react-bootstrap' 
import { Field, reduxForm, SubmissionError, change } from 'redux-form'
import { Layout } from '../common/Layout'
import { EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT} from '../../config/config';
import $ from 'jquery'; 
import loader_image from '../../images/loading.gif';
import axios from 'axios';
import { any } from 'prop-types';

interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
      touched: boolean;
      error: string;
  };
}




const Currencypairing = (props: any) => {
    const [ currencypair, setCurrencypair ] = useState([]);
    const [ isLoading, setisLoading ] = useState(0);
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ usersPerPage, setUsersPerPage ] = useState(20);
    const [ totalPage, setTotalPage ] = useState(1);
    const [ currencies, setCurrencies ] = useState([]);
    const [ errormsg, setErrormsg ] = useState();

    const total = Math.round(totalPage/usersPerPage);

    useEffect(() => {
        getData()
        getCurrencies()
    }, []);

    const getCurrencies = () => {   
      const access_token = localStorage.getItem('access_token');
      if (access_token) {
        console.log("submit start")
        let config1 = {
          headers: {
              'Content-Type': 'application/json',
              'deviceType':'Android',
              'appVersion' : '1.0',
              'Authorization' : 'Bearer '+access_token
          }
        } 
        axios.get(`${TRANSACTION_MANAGEMENT}/currencies`, config1).then(function (responsedata) {
          console.log("responsedata----------------->")
          console.log(responsedata)
          setCurrencies(responsedata.data.data.currencies)
        }).catch(function (error) {
          console.log(error)
          setCurrencies([])
        })
      }
    }



    const renderSelectField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
      <div className="form-group">

        {currencies.length > 0 && input.name == "currencyFrom"
        ?
          <label>
            {label}
              <select {...input} className="form-control"  value={ localStorage.getItem('fromcurrency')} id={input.name}>
                <option>Select Currency</option>
                {
                  currencies.map((item: any, index: any) => { 
                    return (<option key={index} value={item.id}>{item.name}</option>)
                  })
                }
              </select> 
            </label>
        :
        <label>
            {label}
              <select {...input} className="form-control"  value={ localStorage.getItem('currencyTo')} id={input.name} >
                <option>Select Currency</option>
                {
                  currencies.map((item: any, index: any) => { 
                    return (<option value={item.id}>{item.name}</option>)
                  })
                }
              </select>
            </label>
        }
      </div>
    )


    const submit = (values:any) => {
      console.log(values)
    }

    const getData = () => {                
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
          let config1 = {
            headers: {
                'Content-Type': 'application/json',
                'deviceType':'Android',
                'appVersion' : '1.0',
                'Authorization' : 'Bearer '+access_token
            }
          }
          axios.get(`${EXCHANGE_MANAGEMENT}/admin/getCurrencyPair?page=0`, config1).then(function (response) {
            var data1 = response.data.data
            if (data1.count > 0){
              setisLoading(1)
            }else{
              setisLoading(2)
            }
            setTotalPage(data1.count)
            setCurrencypair(data1.currencyPairs)
          }).catch(function (error) {
          console.log(error);
          setisLoading(3)
          setCurrencypair([])
        })
      }  
  }

  const UserTable = () => { 
      return (
              <table className="table">
                <thead>
                  <tr>
                  <th>ID</th>
                  <th>ORDER TYPE</th>
                  <th>CURRENCY FROM</th>
                  <th>CURRENCY TO</th>
                  </tr>
                </thead>
                <tbody>
               {number_of_transactions != 0 && currencypair.map((item: any, index: any) => {             
                 return(
                 <tr key={index}>
                  <td>{ item.id }</td>
                  <td>{ item.orderType }</td>
                  <td><img src={item.currencyFromFlag} style={ { width:`33px`, height: `22px` } }/>{item.currencyFromName}</td>
                  <td><img src={item.currencyToFlag} style={ { width:`33px`, height: `22px` } }/>{item.currencyToName}</td>
                  </tr> )
                })}
                </tbody>
           </table>      
        )

    }
    const number_of_transactions = currencypair.length
    const { error, handleSubmitt, pristine, reset, submitting } = props
    return (
        <Layout>  
        <section className="inner_sction">
          <section className="table_sction accunt_table">
            <div className="container">
              <div className="table_sction_outer admin_index">
                <div className="table_inner">
                  <div className="table-responsive">
                  {/* <a href="#" data-toggle="modal" data-target="#add_currency_pair" id="add_rate_button" className="btn btn-primary">ADD CURRENCY PAIR</a> */}
                    {isLoading == 0 && <div id="api_loader"><img src={loader_image} width="50" height="50" />Loading...</div> }
                    {isLoading == 1 &&  UserTable() }
                    {isLoading == 2 &&   <h1>Record Not Found</h1> }
                    <div className="pagination_sction">
                      <ul className="pagination">
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <div className="modal fade" id="add_currency_pair" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content custom_modal">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
                <h3 className="modal-title">ADD CURRENCY PAIR</h3>
              </div>
              <div className="modal-body">
                <div className="user_request">
                  <form>
                    {errormsg == "error" && <h4 className="error">Please Select Different Value</h4> }
                    {errormsg == "already" && <h4 className="error">Already Exists</h4>}
                    <Field
                      name="currencyFrom"
                      type="select"
                      component={renderSelectField}
                      label="From Currency"
                    />
                    <Field
                      name="currencyTo"
                      type="select"
                      component={renderSelectField}
                      label="To"
                    />  
                    <div className="bottom_btn"> 
                      <button id="add_rate_btn" type="submit" disabled={submitting}>Submit</button>                   
                    </div>
                  </form>
                </div>
              </div>
            </div>          
          </div>
        </div>
      </Layout>
        
    )
}


export default reduxForm({
  form: 'searchForms', // a unique identifier for this form
  enableReinitialize : true
})(Currencypairing)

//  export default connect()(Currencypairing);