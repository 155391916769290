import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from 'react-bootstrap' 
import { Field, reduxForm, SubmissionError } from 'redux-form'
import axios from 'axios';
import { USER_MANAGEMENT, FRONT_BASE, EXCHANGE_MANAGEMENT } from '../../config/config';
import { Layout } from '../common/Layout';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import loader_image from '../../images/loading.gif';
import user_image from '../../images/user_img_default.png';
import { adminService,userService } from '../../_services';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Timeformatter} from '../common/Timeformatter'
import {DataHandling , HandlePermission} from '../common/DataHandling'
import {Amountformatter} from '../../components/common/AmountFormatter'
interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
}

const SuspiciousTransactions = (props:any) => { 
  
    const [ users, setUsers ] = useState([]);
    const { error, handleSubmit, pristine, reset, submitting } = props
    useEffect(() => {
      getData(0,"");
    }, []);

    const [ currentPage, setCurrentPage ] = useState(0);
    const [ usersPerPage, setUsersPerPage ] = useState(20);
    const [ totalPage, setTotalPage ] = useState(1);
    const [ rateCount, setRateCount ] = useState(0);
    const [ isLoading, setisLoading ] = useState(0);
    const [isuserStatus, setisStatus] = useState(3);
    const [isLoader , setIsLoader] = useState(false)
    const [searchText,setSearchText]=useState("")
    
    const [ transactions, setTransactions ] = useState([]) as any;
    const [ transactionDetail , setTransactionDetail ] = useState({ "show" : false , "orderId" : "", "comment" : "" , "subStatus" : "" });
    const total = Math.ceil(totalPage/usersPerPage);
    
    const customStyles1 = {
      overlay : {
       display: "block" ,
       paddingRight: "15px",
       background: "#00000054"
      }
    }; 

    function closeModal(){
      console.log("--- closeModal");
      setTransactionDetail({...transactionDetail, "show" : false})
    }

  function submit_tr() {
    if (transactionDetail.comment) {
      setisLoading(0)
        console.log("submit start")
        adminService.suspicious_tran_action(transactionDetail).then(function (response) {
          console.log("suspicious_tran_action-----",response)
          setTransactionDetail({...transactionDetail, "show": false , "orderId" : ""    })
          // alert(response.data.message);
          setisLoading(1)
          toast.success(response.data.message);
          getData(currentPage,searchText);
      }).catch(function(error){
          console.log("error",error);
          // setLoaderstyle({display:"none"})
          userService.handleError(error);

      })
      }else{
       //alert("Please add comment");
      }     
    }

    const submit_form = (values:any) => {
      // setLoader(1)
      if ( transactionDetail.comment ) {
          console.log("submit start")


        //   adminService.claim_money(claim.id,formData).then(function (response) {
        //     console.log("send_otp-----",response)
        //     alert(response.data.message);
        //     setClaim({...claim ,  "show" : false })
        //     getData();
        // }).catch(function(error){
        //     console.log("error",error);
        //     // setLoaderstyle({display:"none"})
        //     userService.handleError(error);

        // })
  }else{
    alert("Please add comment");
  }
   
 
}
  function searchMonth(e: any) {
    setSearchText(e.target.value);
    getData(currentPage,e.target.value)
  }

  const getData = (currentPage: any, txt:any) => {
      
      let page = 0;
      adminService.get_suspicious_transactions(currentPage,txt)
      .then(function (response:any) {
          // setLoaderstyle({display:"none"})
          console.log("-----------------------------------------response",response.data.data.returnList)
          console.log("count",response.data.data.returnList.length);
          setTransactions(response.data.data.returnList)
          if (response.data.data.returnList.length > 0){
            setisLoading(1)
          }else{
            setisLoading(2)
          }

      }).catch(function(error:any){
        console.log("-----------error--------",error);
          // setLoaderstyle({display:"none"})
          // userService.handleError(error);
      }); 
      
      
      //   const access_token = localStorage.getItem('access_token');
        //   if (access_token) {
        //     console.log("submit start")
        //     let config1 = {
        //       headers: {
        //           'deviceType':'Android',
        //           'appVersion' : '1.0',
        //           'Authorization' : 'Bearer '+access_token
        //       }
        //     }
        //     axios.get(`${USER_MANAGEMENT}/admin/getMerchantsList?page=0`, config1).then(function (response) {
        //       var data1 = response.data.data
        //       console.log(data1)

        //       if (data1.count > 0){
        //         setisLoading(1)
        //         }else{
        //         setisLoading(2)
        //         }
        //       setTotalPage(data1.count)
        //       setUsers(data1.merchant)
        //     }).catch(function (error) {
        //     console.log(error);
        //     setisLoading(3)
        //     setUsers([])

        //   })
        // }  
    }


   

  

    


  const UserTable = () => {     
      return (
              <table className="table">
                <thead>
                  <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>From Amount</th>               
                  <th>To Amount</th>
                  <th>Request Date</th>
                  <th>Expiry Date</th>
                  <th>Money Changer Name</th>
                  {/* {HandlePermission("edit","suspicious_transactions") &&
                    <th>Action</th>
                  } */}
                  </tr>
                </thead>
                <tbody>
               
               {transactions.map((item: any, index: any) => {
                console.log("ratecountval")
                 return(
                <tr key={index}>
                  <td>{(currentPage*20)+ (index+1)}</td>
                  <td>{item.userObj ? item.userObj.firstName : ""}</td>
                  <td><Amountformatter amount={item.value}/> {item.currencyFrom}</td>
                  <td><Amountformatter amount={item.totalAmount}/> {item.currencyTo}</td>
                  <td><Timeformatter  timeget={item.createdOn}  /></td>
                  <td><Timeformatter  timeget={item.expiresOn}  /></td>
                  <td>{ item.merchantObj ? item.merchantObj.firstName : "-"} { item.merchantObj ? item.merchantObj.lastName : ""}</td>
                  {/* {HandlePermission("edit","suspicious_transactions") &&
                  <td>
                    <div className="dropdown slect_profile">
                        <button className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">Select
                        <span className="caret"></span></button>
                        <ul className="dropdown-menu">
                          <li><a onClick={() => setTransactionDetail({...transactionDetail, "show": true , "orderId" : item.idMoneyChangerOrders , "subStatus" : "GENUINE"   }) }>Genuine Transaction</a></li>
                          <li><a onClick={() => setTransactionDetail({...transactionDetail, "show": true , "orderId" : item.idMoneyChangerOrders , "subStatus" : "CANCELLED"   }) }>Cancel Transaction</a></li>
                        </ul>
                    </div>
                  </td>
               } */}
                </tr> )
                })}
                </tbody>
           </table>      
        )

    }

    function viewRate(user_id:any){
      console.log("view rate")
      window.sessionStorage.setItem('merchant_id', user_id)
      window.location.href = `${FRONT_BASE}/merchant/rate`; 
    }
                    
    return (      
      <Layout>  
        <section className="inner_sction">
          <section className="table_sction accunt_table">
            <div className="container-fluid">
              <div className="table_sction_outer admin_index loader_parent">

              <div className="top-sctin mbtm-0">
                  <h4>Suspicious Transactions</h4>
                  <div className="form-group pull-right">
                    <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                    <input type="text" className="form-control" value={searchText} onChange={(e)=>searchMonth(e)} placeholder="Search suspicious transaction by name"/>
                  </div>
              </div>

                {getData}
                <div className="table_inner">
                  <div className="table-responsive">
                  {isLoading == 0 && <div id="api_loader">
                    {/* <img src={loader_image} width="50" height="50" />Loading... */}
                    <div id="" className="loaderbox">
                      <div>
                        <img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p>
                      </div>
                    </div>
                    </div> }
                  {isLoading == 1 &&  UserTable() }
                  {isLoading == 2 &&   <h1>Record Not Found</h1> }
                  {isLoading == 3 &&   <h1>Some Error Occur</h1> }
                  </div>
                 
                </div>
              </div>
            </div>
          </section>
          { isLoader &&
                <div id="" className="loaderbox loginloader innerloader"><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div>
            }
        </section>
        <Modal
ariaHideApp={false}
          isOpen={transactionDetail.show}
          zIndex={1000}
 // onAfterOpen={afterOpenModal}
 onRequestClose={ closeModal }
//  style={customStyles1}
 className={
   "modal-content custom_modal thakyou_pop"}
 contentLabel="Example Modal"
 overlayClassName="Overlay"
>
<div className="modal-header">
          <button type="button" className="close" onClick={closeModal} ><i className="fa fa-times" aria-hidden="true"></i></button>
          <h3 className="modal-title">Transaction Reason</h3>
        </div>

          <div className="modal-body">
          {isLoading == 0 && <div id="api_loader">
                    {/* <img src={loader_image} width="50" height="50" />Loading... */}
                    <div id="" className="loaderbox">
                      <div>
                        <img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p>
                      </div>
                    </div>
                    </div> }
                <div className="user_request">
                  {/* <form > */}
                      <div className="form-group ">
                      <label> Reason :</label>
                        <div className="input_box">
                        <input className="form-control form-control-two" type='text' value={transactionDetail.comment} onChange={(e:any)=>setTransactionDetail({...transactionDetail, "comment": e.target.value  })}  />
                        </div>
                      </div>
                      <div className="bottom_btn"> 
                <button className="btn btn-lg btn-block" onClick={submit_tr}>

                  Okay</button>
                      </div>
                  {/* </form>                 */}
                </div>
        </div>
</Modal>


<ToastContainer />
      </Layout>
      
    )
}

export default (SuspiciousTransactions)