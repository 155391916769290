import { authHeader } from '../_helpers';
import {config} from '../config/config'
import axios from 'axios';
import { func } from 'prop-types';

export const merchantService = {
    get_exchange,
    accept_reject_exchange,
    merchant_online_offline,
    merchant_exchange_scan,
    edit_all_exchanges,
    notifications,
    get_currencies,
    get_exchange_completed,
    get_merchant_wallets,
    merchantActivities
};
    var configheaders:any = {
        headers: authHeader()
    }

    var configheaders:any = {
        headers: authHeader()
    }

    var user_id:any = localStorage.getItem('user_id');

    function merchantActivities(page: any) {
        let url = `${config.apiUrl}/EFXUserManagement/v1/api/merchantActivities?page=${page}`;
        return axios.get(url, configheaders)
    }

    function get_currencies(){
        let url =  `${config.apiUrl}/EfxCurrency/v1/api/currencies`;
        return axios.get(url,configheaders)
    }

    function merchant_exchange_scan(exchange_id:any, status:any){
       let merchant_id:any = localStorage.getItem('user_id');
        let url =  `${config.apiUrl}/EfxExchange/v1/api/sendOtpForMoneyExchange/${exchange_id}?merchantId=${merchant_id}&status=${status}`;
        return axios.get(url,configheaders)
    }

    function get_exchange_completed(user_id: any,pageNo:any, searchTxt:any, orderStatus:any, dateRange:any, exporttransaction:any){
        const params={
            page:pageNo,
            search:searchTxt,
            orderStatus:orderStatus,
            from: dateRange[0],
            to: dateRange[1],
            export:exporttransaction
        }
        if(!params.from) delete params.from
        if(!params.to) delete params.to
        if(!params.export) delete params.export

        let url =  `${config.apiUrl}/EfxExchange/v1/api/allCompletedRequestForMerchant/${user_id}`; 

        const data= {
            ...configheaders,
            params: params,
        }
        return axios.get(url,data)
    }

    function get_exchange(params:any){
        let url =  `${config.apiUrl}/EfxExchange/v1/api/allPendingRequestForMerchant`;
        return axios.post(url,params,configheaders)
    }
    
    function accept_reject_exchange(params:any) {
        let url =  `${config.apiUrl}/EfxExchange/v1/api/moneyExchangeAction`;
        return axios.post(url,params,configheaders)
    }

    function merchant_online_offline(params:any){
        let url =  `${config.apiUrl}/EFXUserManagement/v1/api/merchantServicesAction`;
        return axios.post(url,params,configheaders)
    }

    function edit_all_exchanges(params:any,merchant_id:any){
        let url =  `${config.apiUrl}/EfxExchange/v1/api/moneyChangerExchangeRates/${merchant_id}`;
        return axios.post(url,params,configheaders)
    }

    function notifications(pageNo:any){
        let merchant_id:any = localStorage.getItem('user_id');
        let url =  `${config.apiUrl}/EFXUserManagement/v1/api/notificationListForMerchant?id=${merchant_id}&page=${pageNo}`;
        return axios.get(url,configheaders)
    }

    function get_merchant_wallets(){
         let url =  `${config.apiUrl}/EfxCurrency/v1/api/getWalletByMerchant/${user_id}`;
         return axios.get(url,configheaders)
     }