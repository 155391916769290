import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError, change } from 'redux-form'
import Count from '../Count';
import Merchant from '../Merchant';
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Layout } from '../common/Layout'
import axios from 'axios';
import $ from 'jquery';
import user_image from '../../images/user_img_default.png';
import { USER_MANAGEMENT, EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT } from '../../config/config';
import loader_image from '../../images/loading.gif';
import ReactTelephoneInput from 'react-telephone-input'
import { userService, adminService } from '../../_services';
import Modal from 'react-modal';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { toast } from 'react-toastify';
import image_not_available from '../../images/dummy_image.png'
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const BranchDetails = (props: any) => {

  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      background: "#00000054"
    }
  };



  let branch_id = props.match.params.id;
  const [isLoading, setisLoading] = useState(0);
  const { error, handleSubmit, pristine, reset, submitting } = props
  const [isuserStatus, setisStatus] = useState(1);
  const [branchDetail, setBranchDetail] = useState([]) as any;
  const [editModal, setEditModal] = useState(false);
  const [branchImage, setBranchImage] = useState('');
  const [formVar, setFormVar] = useState({ email: "", password: "", firstName: "", phoneNumber: "", fullPhoneNumber: "", countryCode: "", companyName: "", address: "", latitude: "", longitude: "", emailError: "", passwordError: "", firstNameError: "", phoneNumberError: "", countryCodeError: "", companyNameError: "", addressError: "", fullPhoneNumberError: "" });
  useEffect(() => {
    getBrancheDetail();
  }, []);

  function closeModal() {
    setEditModal(false)
  }

  function getBrancheDetail() {
    //    setBranchList([])
    adminService.branches_detail(branch_id).then(function (response) {
      console.log("get_branch-----", response.data);
      console.log("get_branch 2-----", response.data.data);
      setBranchDetail(response.data.data)
      // setFormVar({...formVar , firstName: response.data.data.UserDto.firstName})
      let user = response.data.data.UserDto;
      // setFormVar({...formVar, "firstName": user.firstName , "lastName": user.lastName}) 
      setFormVar({ ...formVar, firstName: user.firstName, email: user.email, address: user.address, latitude: user.latitude, longitude: user.longitude, companyName: user.companyName, phoneNumber: user.phoneNumber, countryCode: user.countryCode, fullPhoneNumber: get_full_number(user) })
      if (response.data.data.UserDto.image != null) {
        setBranchImage("https://api.e-fx.asia/EFXUserManagement/" + response.data.data.UserDto.image)
      }

      // "email":"" , "password":"","firstName":"","phoneNumber":"", "fullPhoneNumber" : "" ,"countryCode":"","companyName":"","address":"" , "latitude": "" , "longitude": "" , "emailError":"" , "passwordError":"","firstNameError":"","phoneNumberError":"","countryCodeError":"","companyNameError":"","addressError":"" , "fullPhoneNumberError" : ""});

    }).catch(function (error) {
      // console.log("error",error);
      // setLoaderstyle({display:"none"})
      userService.handleError(error);

    })
  }


  const validateForm = (data: any) => {
    // return true;
    let fields = data;
    let errors: any = [];
    let formIsValid = true;
    console.log("validateForm");
    console.log("fields", fields);
    console.log("formVar", formVar);

    if (formVar.firstName == "" || formVar.firstName == null) {
      formIsValid = false;
      //errors["firstName"] = "*Please enter your password.";
      // setisLoading(0);
      formVar.firstNameError = "Please Enter Valid Name";
    }
    if (formVar.email == "" || formVar.email == null) {
      formIsValid = false;
      //setisLoading(0);
      formVar.emailError = "Please Enter Your Email";
    }

    if (typeof formVar.email !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(formVar.email)) {
        // setisLoading(0);
        formIsValid = false;
        formVar.emailError = "Please Enter Valid Email";
      }
    }
    if (formVar.companyName == "" || formVar.companyName == null) {
      formIsValid = false;
      // errors["password"] = "*Please enter your password.";
      //setisLoading(0);
      // throw new SubmissionError({ confirmpassword: 'Please confirm your password.', _error: 'Register failed!' })
      console.log("company", formIsValid)
      formVar.companyNameError = "Please Enter Valid Company Name";
    }

    // if (
    //   formVar.address == "" ||
    //   formVar.address == null ||
    //   formVar.latitude == "lat" ||
    //   formVar.longitude == "lng"
    // ) {
    //   formIsValid = false;
    //  // setisLoading(0);
    //   formVar.addressError = "Please Enter Valid Address";
    // }
    if (formVar.fullPhoneNumber == "" || formVar.fullPhoneNumber == null) {
      console.log("form is valid", formVar.phoneNumber);
      formIsValid = false;
      // setisLoading(0);
      formVar.fullPhoneNumberError = "Please Enter Valid phone number";
    }
    console.log("foonnnsr", formIsValid);
    return formIsValid;
  };

  const submit = (values: any) => {
    // setLoader(1)
    // return sleep(1000).then(() => {
    if (validateForm(values)) {
      console.log("submit start")
      console.log("formdata", formVar);
      console.log("validation complete");

      const mobileNumber = parsePhoneNumber(formVar.fullPhoneNumber)
      if (mobileNumber == undefined) {

      } else {
        console.log(mobileNumber.countryCallingCode);
        console.log(mobileNumber.nationalNumber);
        formVar.countryCode = mobileNumber.countryCallingCode;
        formVar.phoneNumber = mobileNumber.nationalNumber;

      }
      console.log("branchDetail", branchDetail.UserDto.id);
      console.log("formVar--------", formVar);
      adminService.edit_branch(formVar, branchDetail.UserDto.id).then(function (response) {
        console.log("create_branch-----", response)
        closeModal()
        toast.success(response.data.message);
        getBrancheDetail()
      }).catch(function (error) {
        // console.log("error",error);
        // setLoaderstyle({display:"none"})
        userService.handleError(error);

      })


    }
    // })
  }

  const handleCompanyAddress = (e: any) => {
    geocodeByAddress(e.description)
      .then((results: any[]) => getLatLng(results[0]))
      .then(({ lat, lng }: any) =>
        setFormVar({
          ...formVar,
          latitude: lat,
          longitude: lng,
          address: e.description,
          addressError: "",
        })
      );
    //   setFormVar({...formVar, "companyAddress": e.description , "companyAddressError": ""})
    console.log("e------", e);
    setFormVar({ ...formVar, address: e.description, addressError: "" });
  };

  function get_full_number(user: any) {
    return ("+" + user.countryCode + user.phoneNumber)
  }



  const renderField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className={type == "hidden" ? "" : "form-group"}>
      <label>{label}</label>
      <input {...input} placeholder={label} type={type} className={type == "hidden" ? "" : "form-control"} required />
      {touched && error && <span>{error}</span>}
    </div>
  )

  const UserTable = () => {
    return (
      <div className="table_inner">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>PHONE</th>
                <th>TRANSACTION TYPE</th>
                <th>NAME</th>
                <th>DATE & TIME</th>
                <th>AMOUNT</th>
                <th>COMMISSION</th>
                <th>CURRENCY</th>
                <th>STATUS</th>
              </tr>
            </thead>
            {branchDetail && branchDetail.UserDto &&
              <tbody>
                {branchDetail.orderList.map((item: any, index: any) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>+{item.countryCode}-{item.phoneNumber}</td>
                      <td>{item.transactionType}</td>
                      <td>{item.userObj.firstName} {item.userObj.lastName}</td>
                      <td>{item.updatedOn}</td>

                      <td>-{item.value}/+{item.totalAmount}</td>
                      <td>{item.commission}</td>
                      <td>{item.currencyFrom}/{item.currencyTo}</td>
                      <td>
                        {item.orderStatus == "PENDING" && <p className="yellow">Pending</p>}
                        {item.orderStatus == "ACCEPTED" && <p className="blue">Accepted</p>}
                        {item.orderStatus == "REJECTED" && <p className="red">Declined</p>}
                        {item.orderStatus == "EXPIRED" && <p className="red">Expired</p>}
                        {item.orderStatus == "COMPLETED" && <p className="green">Collected</p>}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            }
          </table>
        </div>
      </div>
    )
  }

  return (
    <Layout>

      <section className="inner_sction">
        <section className="table_sction">
          <div className="container-fluid">
            <a href="/admin/Branches" className="page_back_btn"><i className="fa fa-chevron-left" aria-hidden="true"></i>Back</a>
            <div className="table_sction_outer">
              <div className="top-sctin profile_inner_sction">
                <h4>Branch Details</h4>
                <a onClick={() => setEditModal(true)} id="add_rate_button" className="btn btn-primary">EDIT</a>
              </div>
              <div className="profile_filled">
                <div className="profile_upd text-center">
                  {branchDetail.UserDto &&
                    <img src={branchImage != '' ? branchImage : image_not_available} />
                  }
                  {/* <a data-toggle="modal" data-target="#editprofilepic">Update Profile Picture</a> */}
                </div>
                {branchDetail && branchDetail.UserDto &&
                  <div className="listng_user_info">
                    <ul>
                      <li>
                        <p>Name:</p>
                        <h5>{branchDetail.UserDto.firstName}</h5>
                      </li>
                      <li>
                        <p>Phone Number</p>
                        <h5>+{branchDetail.UserDto.countryCode}-{branchDetail.UserDto.phoneNumber}</h5>
                      </li>
                      <li>
                        <p>Email Address:</p>
                        <h5>{branchDetail.UserDto.email}</h5>
                      </li>

                      <hr />

                      <p className="title">Company Information</p>

                      <li>
                        <p>Company Name:</p>
                        <h5>{branchDetail.UserDto.companyName}</h5>
                      </li>
                      <li>
                        <p>Unit No, Floor::</p>
                        <h5>{branchDetail.UserDto.address}</h5>
                      </li>

                      <li>
                        <p>Company Address:</p>
                        <h5>{branchDetail.UserDto.companyAddress}</h5>
                      </li>

                      {/* <li>
                                            <p>Commission:</p>
                                            <h5>{branchDetail.UserDto.commission}</h5>
                                        </li> */}

                    </ul>
                  </div>
                }


              </div>
            </div>

          </div>
        </section>
      </section>

      <section className="inner_sction">
        <section className="table_sction">
          <div className="container-fluid">
            <div className="table_sction_outer">

              <div className="top-sctin mbtm-0">
                <h4>Transactions</h4>
              </div>
              {branchDetail && branchDetail.orderList && branchDetail.orderList.length > 0 ?
                UserTable()
                :
                <p>
                  No Transactions
                </p>
              }
            </div>
          </div>
        </section>
      </section>

      <div className="modal fade" id="add_rate" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content custom_modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
              <h3 className="modal-title">Add Branche</h3>
            </div>
            <div className="modal-body">
              <div className="user_request">

                <form>

                  <Field
                    name="Name"
                    type="text"
                    component={renderField}
                    label="Name"
                  />
                  <Field
                    name="Phone Number"
                    type="text"
                    component={renderField}
                    label="Phone Number"
                  />
                  <Field
                    name="Email"
                    type="text"
                    component={renderField}
                    label="Email"
                  />
                  <Field
                    name="Company Name"
                    type="text"
                    component={renderField}
                    label="Company Name"
                  />
                  <Field
                    name="Address"
                    type="text"
                    component={renderField}
                    label="Address"
                  />

                  {/* <Field
                      name="Password"
                      type="text"
                      component={renderField}
                      label="Password"
                    />

                    <Field
                      name="Confirm Password"
                      type="text"
                      component={renderField}
                      label="Confirm Password"
                    /> */}

                  <div className="bottom_btn">
                    <a href="#" data-toggle="modal" data-target="#pass_verified" id="add_rate_btns" >Submit</a>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={editModal}
        //  onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        // style={customStyles1}
        className={
          "modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      // className="reset_password"
      >
        <div className="modal-header">
          <button type="button" className="close" onClick={() => setEditModal(false)}><i className="fa fa-times" aria-hidden="true"></i></button>
          <h3 className="modal-title">Edit Branch</h3>
        </div>
        <div className="modal-body">
          <div className="user_request">
            <form onSubmit={handleSubmit(submit)}>
              <div className="form-group ">
                <label>Name:</label>
                <div className="input_box">
                  <input
                    onChange={(e: any) =>
                      setFormVar({
                        ...formVar,
                        firstName: e.target.value,
                        firstNameError: "",
                      })
                    }
                    maxLength={30}
                    className="form-control form-control-two"
                    value={formVar.firstName}
                    placeholder="Enter Name"
                  ></input>
                  {formVar.firstNameError.length > 0 && (
                    <span style={{ color: "red" }}>
                      {formVar.firstNameError}
                    </span>
                  )}
                </div>
              </div>

              <div className="form-group ph_field">
                <label>Phone Number:</label>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={formVar.fullPhoneNumber}
                  maxLength="20"
                  onChange={(e: any) =>
                    setFormVar({
                      ...formVar,
                      fullPhoneNumber: e,
                      fullPhoneNumberError: "",
                    })
                  }
                />
                {formVar.fullPhoneNumberError.length > 0 && (
                  <span style={{ color: "red" }}>
                    {formVar.fullPhoneNumberError}
                  </span>
                )}
              </div>

              <div className="form-group ">
                <label>Email:</label>
                <div className="input_box">
                  <input
                    onChange={(e: any) =>
                      setFormVar({
                        ...formVar,
                        email: e.target.value,
                        emailError: "",
                      })
                    }
                    maxLength={64}
                    className="form-control form-control-two"
                    value={formVar.email}
                    placeholder="Enter Email"
                  ></input>
                  {formVar.emailError.length > 0 && (
                    <span style={{ color: "red" }}>{formVar.emailError}</span>
                  )}
                </div>
              </div>

              <div className="form-group ">
                <label>Company Name:</label>
                <div className="input_box">
                  <input
                    onChange={(e: any) =>
                      setFormVar({
                        ...formVar,
                        companyName: e.target.value,
                        companyNameError: "",
                      })
                    }
                    maxLength={30}
                    className="form-control form-control-two"
                    value={formVar.companyName}
                    placeholder="Enter Company Name"
                  ></input>
                  {formVar.companyNameError.length > 0 && (
                    <span style={{ color: "red" }}>
                      {formVar.companyNameError}
                    </span>
                  )}
                </div>
              </div>

              {/* <div className="form-group ">
                <label>Address:</label>
                <GooglePlacesAutocomplete
                  onSelect={(e: any) => handleCompanyAddress(e)}
                  inputClassName="form-control"
                  initialValue={formVar.address}
                />
                {formVar.addressError.length > 0 && (
                  <span style={{ color: "red" }}>{formVar.addressError}</span>
                )}
              </div> */}


              <div className="bottom_btn">
                <button
                  className="btn btn-lg btn-primary btn-block"

                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

    </Layout>
  )
}

export default reduxForm({
  form: 'searchForm', // a unique identifier for this form
  enableReinitialize: true
})(BranchDetails)
