import React, { useEffect, useState } from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from 'react-bootstrap'
import { Field, reduxForm, SubmissionError, change } from 'redux-form'
import axios from 'axios';
import { EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT } from '../../config/config';
import { Layout } from '../common/Layout';
import $ from 'jquery'
import { Alert } from 'reactstrap';
import { any, array } from 'prop-types';
import Modal from 'react-modal';
import { setConstantValue } from 'typescript';
import { merchantService, userService } from '../../_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loader_image from "../../images/loading.gif";
import XLSX from 'xlsx';
// import { State } from 'bitgo/dist/src/v2/pendingApproval';
import { CSVLink, CSVDownload } from "react-csv";
import { Amountformatter } from '../../components/common/AmountFormatter';
import { adminService } from "../../_services";

interface fieldInterface {
  input: any;
  label: string;
  type: string;
  value: string;
  meta: {
    touched: boolean;
    error: string;
  };
}




const Exchange = (props: any) => {

  const [rates, setRates] = useState([]);
  const [userskyc, setUsersKYC] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [currenciesto, setCurrenciesto] = useState([]);
  const [checked, setChecked] = useState();
  const [tocurrency, setTocurrency] = useState();
  const [rateupdate, setRateupdate] = useState(false);
  const [rateid, setRateid] = useState() as any;
  const [currencyFromId, setCurrencyFromId] = useState('-1') as any;
  const [currencyToId, setCurrencyToId] = useState('-1') as any;
  const [errormsg, setErrormsg] = useState("") as any;
  const [currencyratevalue, setcurrencyratevalue] = useState('');
  const [currencyrateval, setcurrencyrateval] = useState();
  const [exchangeratevalue, setexchangeratevalue] = useState('') as any;
  const [currencyFromtext, setCurrencyFromtext] = useState('') as any;
  const [currencyTotext, setCurrencyTotext] = useState('') as any;
  const [currentrate, setCurrentrate] = useState([]);
  const [erroroccur, setErroroccur] = useState();

  const [passwordcase, setPasswordcase] = useState();
  const [exchangeEdit, setExchangeEdit] = useState({ "show": false, "passwordShow": false, price_ary: [] }) as any;
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordValidate, setIsPasswordValidate] = useState(false);
  const [passwordModal, setpasswordModal] = useState(false);
  const [addRateModal, setAddRateModal] = useState(false)
  const [passwordEntered, setPasswordEntered] = useState("")
  const [isLoading, setIsLoading] = useState('');
  const [editExchangeRatePasswordModal, setEditExchangeRatePasswordModal] = useState(false);
  const [deleteExchangeRatePasswordModal, setDeleteExchangeRatePasswordModal] = useState(false);
  const [deleteRateId, setDeleteRateId] = useState('')

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(0);
  const [exchangeRatesIndexCount, setExchangeRatesIndexCount] = useState();
  const [editExchangeRatesError, setEditExchangeRatesError] = useState([]);
  const [batchFileData, setBatchFileData] = useState([]);
  const [batchFileError, setBatchFileError] = useState('');
  const [batchUploadModal, setBatchUploadModal] = useState(false);
  const [inventoryCSVHeaders, setInventoryCSVHeaders] = useState([]);
  const [inventoryCSVData, setInventoryCSVData] = useState([]);
  const [batchFileName, setBatchFileName] = useState('');
  const [batchPassword, setBatchPassword] = useState("");
  const [batchPasswordError, setBatchPasswordError] = useState("");
  const [isBatchPasswordValidate, setIsBatchPasswordValidate] = useState(false);
  const [batchPasswordModal, setBatchPasswordModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(20);
  const [totalPage, setTotalPage] = useState(1);
  const [newCurrenctTo, setNewCurrenctTo] = useState([]);
  const total = Math.ceil(totalPage / usersPerPage);
  const [formEditExchangeRateVar, setFormEditExchangeRateVar] = useState({
    exchangeRateValue: "",
    password: "",
    exchangeRateValueError: "",
    passwordError: "",
    isExchangeRateValueValidate: false,
    isPasswordValidate: false
  });

  const [formDeleteExchangeRateVar, setFormDeleteExchangeRateVar] = useState({
    password: "",
    passwordError: "",
    isPasswordValidate: false

  });

  const [formAddExchangeRateVar, setFormAddExchangeRateVar] = useState({
    currencyFromId: "-1",
    currencyFromText: "",
    currencyToId: "-1",
    currencyToText: "",
    exchangeRate: "",
    currencyFromError: "",
    currencyToError: "",
    exchangeRateError: "",
    isCurrencyFromValidate: false,
    isCurrencyToValidate: false,
    isExchangeRateValidate: false,
    password: "",
    passwordError: "",
    isPasswordValidate: false

  });

  useEffect(() => {
    setIsFormLoading(0);
    setIsPageLoading(true);
    getRate(currentPage);
    getData();
    getCurrentExchangeRate();
  }, []);


  // const getData = () => { 
  //   const access_token = localStorage.getItem('access_token');
  //   if (access_token) {
  //     console.log("submit start")
  //     let config1 = {
  //       headers: {
  //           'Content-Type': 'application/json',
  //           'deviceType':'Android',
  //           'appVersion' : '1.0',
  //           'Authorization' : 'Bearer '+access_token
  //       }
  //     } 
  //     axios.get(`${TRANSACTION_MANAGEMENT}/currencies`, config1).then(function (responsedata) {
  //       localStorage.setItem('same_valugetRate();
  //       setCurrencies([])
  //     })
  //   }
  // }


  const getData = () => {


    merchantService.get_currencies()
      .then(function (response: any) {
        console.log("-----------------------------------------response get_currencies", response.data.data.currencies);
        setCurrencies(response.data.data.currencies)

      }).catch(function (error: any) {
        console.log("-----------error--------", error);
        userService.handleError(error);
        setCurrencies([])
      });




  }

  const pass_verify_case = (cases: any) => {
    console.log(cases)
    localStorage.setItem('pass_verify', cases);
    // setPasswordcase(cases)
  }


  function validateExchangeRatesPassword() {
    validateFormCase("edit_exchange_rates_password", password)
    if (!isPasswordValidate) {
      return false;
    }
    return true;
  }

  const submitallrateWithPassword = (values: any) => {
    console.log("submitallrateWithPassword");
    console.log(exchangeEdit);

    if (validateExchangeRatesPassword() == true) {

      setIsLoading('edit_all_echange_rates');
      let params = {
        "password": password,
        "exchangeRateList": exchangeEdit.price_ary
      }
      const user_id = localStorage.getItem('user_id');
      // setExchangeEdit({...exchangeEdit, passwordShow: false})
      merchantService.edit_all_exchanges(params, user_id)
        .then(function (response: any) {
          // setLoaderstyle({display:"none"})
          console.log("-----------------------------------------response", response)
          // show toast
          toast.success(response.data.message);
          closeModal()
          getRate(currentPage)
          //window.location.href = '/merchant/exchange'
          console.log("calling later");
          setIsLoading('');

          // setExchangeEdit({...exchangeEdit, "passwordShow" : false , "show" :false })
          //  closeModal()
          setTimeout(function () {
            //getRate(false);
          }, 1000);


        }).catch(function (error: any) {
          setIsLoading('');
          console.log("-----------error--------", error);
          // setLoaderstyle({display:"none"})
          userService.handleError(error);
        });
    }
  }

  const submitallrate = (values: any) => {
    console.log("submitallrate");
    console.log(exchangeEdit);
    if (editExchangeRatesValidate() == true) {
      // open password modal
      //setPassword("");
      //setPasswordError("")
      setExchangeEdit({ ...exchangeEdit, passwordShow: true })
    }

  }

  function validateAddRateForm() {
    if (!formAddExchangeRateVar.isCurrencyFromValidate) {
      validateFormCase('currency_from', formAddExchangeRateVar.currencyFromId);
      return false;
    } else {
      if (!formAddExchangeRateVar.isCurrencyToValidate) {
        validateFormCase('currency_to', formAddExchangeRateVar.currencyToId);
        return false;
      } else {
        if (!formAddExchangeRateVar.isExchangeRateValidate) {
          validateFormCase('add_exchange_rate', formAddExchangeRateVar.exchangeRate);
          return false;
        }
        setAddRateModal(false);
        setpasswordModal(true);
        return true;
      }
    }
  }

  function validateAddRateFormPassword() {
    if (!formAddExchangeRateVar.isPasswordValidate) {
      validateFormCase('add_exchange_rate_password', formAddExchangeRateVar.password)
      return false;
    }
    return true;
  }

  const submit = (values: any) => {

    const access_token = localStorage.getItem('access_token');
    const user_id = localStorage.getItem('user_id');
    console.log(values)

    // if (validateForm(values, currencyFromId, currencyToId, user_id)) {
    if (validateAddRateFormPassword()) {
      setIsLoading('add_exchange_rate_password');
      console.log("submit start")
      let config1 = {
        headers: {
          'Content-Type': 'application/json',
          'deviceType': 'Android',
          'appVersion': '1.0',
          'Authorization': 'Bearer ' + access_token
        }
      }

      console.log("submit start end")
      axios.post(`${EXCHANGE_MANAGEMENT}/exchangeRates`, {
        // currencyFrom: currencyFromId,
        // currencyFromId: currencyFromtext,
        // currencyTo: currencyToId,
        // currencyToId: currencyTotext,
        // exchangeRate: exchangeratevalue,
        // merchantId: user_id,
        // password: passwordEntered

        currencyFrom: formAddExchangeRateVar.currencyFromText,
        currencyFromId: formAddExchangeRateVar.currencyFromId,
        currencyTo: formAddExchangeRateVar.currencyToText,
        currencyToId: formAddExchangeRateVar.currencyToId,
        exchangeRate: formAddExchangeRateVar.exchangeRate,
        merchantId: user_id,
        password: formAddExchangeRateVar.password

      }, config1).then(function (response) {
        setpasswordModal(false);
        getRate(currentPage);
        getData();
        UserTable();
        localStorage.setItem('same_value', "false");
        setErrormsg("")
        setIsLoading('');
        //window.location.href = '/merchant/exchange'

      }).catch(function (error) {
        console.log(error)
        setIsLoading('');
        if (error.response.status == 400) {
          localStorage.setItem('same_value', "true");
          setErrormsg("already");
        }
        else {
          localStorage.setItem('same_value', "true");

          setErrormsg("pass_failed")
        }
        setTimeout(() => {
          setErrormsg('')
        }, 2000);
      })
    }
  }

  const validateForm = (data: any, currencyFrom: any, currencyTo: any, user_id: any) => {
    let formIsValid = true;
    return formIsValid;
    let errors: any = [];

    if (data == "" && currencyFrom == "" && currencyTo == "" && user_id == "") {
      formIsValid = false;
      localStorage.setItem('same_value', "true");
      setErrormsg("error_field")

      setTimeout(() => {
        setErrormsg('')
      }, 2000);
      throw new SubmissionError({ email: 'Please enter valid email-ID.', _error: 'Register failed!' })
    }
    else if (currencyFrom == currencyTo) {
      formIsValid = false;
      localStorage.setItem('same_value', "true");
      setErrormsg("error")

      setTimeout(() => {
        //window.location.reload();
        setErrormsg('')
      }, 2000);
      throw new SubmissionError({ email: 'Please enter valid email-ID.', _error: 'Register failed!' })
    }
    else {
      formIsValid = true;
    }

    return formIsValid;
  }

  const renderHiddenfield = ({ input, label, value, type, meta: { touched, error } }: fieldInterface) => (
    <input {...input} placeholder={label} id={input.name} value={input.name == "currencyToId" ? localStorage.getItem('currencyTo') : input.name == "currencyFromId" ? localStorage.getItem('fromcurrency') : localStorage.getItem('user_id')} type={type} className={type == "hidden" ? "" : ""} />
  )

  const renderField = ({ input, value, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className={type == "hidden" ? "" : "form-group"}>
      <label>{label}</label>
      <input {...input} placeholder={label} value={value} type={type} className={type == "hidden" ? "" : "form-control"} required />
      {touched && error && <span>{error}</span>}
    </div>
  )

  const renderHiddenField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className={type == "hidden" ? "" : "form-group"}>
      <input {...input} placeholder={label} type={type} className={type == "hidden" ? "" : "form-control"} required />
      {touched && error && <span>{error}</span>}
    </div>
  )

  const renderSelectField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className="form-group">

      <label>
        {label}

      </label>
      <select {...input} className="form-control" value={localStorage.getItem('fromcurrency')} id={input.name}>
        <option id="-1">Select Currency From</option>
        {
          currencies.length > 0 && currencies.map((item: any, index: any) => {
            return (<option key={index} id={item.id}>{item.name}</option>)
          })
        }
      </select>

    </div>
  )



  const renderSelectFieldto = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className="form-group">

      <label>
        {label}
      </label>
      <select {...input} className="form-control" value={localStorage.getItem('currencyTo')} id={input.name} >
        <option id="-1">Select Currency To</option>
        {
          currenciesto.length > 0 && currenciesto.map((item: any, index: any) => {
            return (<option id={item.currencyToId}>{item.currencyToName}</option>)
          })
        }
      </select>
    </div>
  )




  $(document).ready(function () {
    setTimeout(function () {
      $('#currencyFrom').on('change', function (this: any) {
        localStorage.setItem('fromcurrency', this.value);
        var optionsText = this.options[this.selectedIndex].text;
        localStorage.setItem('fromcurrencyvalue', optionsText);
        // $("#currencyFromId").val(this.value)
      });
    }, 1000);

    setTimeout(function () {
      $('#currencyTo').on('change', function (this: any) {
        localStorage.setItem('currencyTo', this.value);
        var optionsText = this.options[this.selectedIndex].text;
        localStorage.setItem('currencyTovalue', optionsText);
        // $("#currencyToId").val(this.value)
      });
    }, 1000);

  });

  const getRate = (pageNumber: any, modal = false) => {
    const user_id = localStorage.getItem('user_id');
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      console.log("submit start")
      let config1 = {
        headers: {
          'Content-Type': 'application/json',
          'deviceType': 'Android',
          'appVersion': '1.0',
          'Authorization': 'Bearer ' + access_token
        }
      }
      axios.get(`${EXCHANGE_MANAGEMENT}/${user_id}/getExchangeRatesByMerchant?page=${pageNumber}`, config1).then(function (response) {
        var data1 = response.data.data
        setRates(data1.exchangeRates)
        // "currencyFrom":"MYR",
        // "currencyTo":"SGD",
        // "exchangeRate":"3",

        if (data1.exchangeRates.length > 0) {
          setIsFormLoading(1);
        }
        else {
          setIsFormLoading(2);
        }

        setExchangeRatesIndexCount(data1.exchangeRates.length);
        setIsPageLoading(false);
        let i;
        let price_ary_tmp: any = []
        let tmp_exchange_rates_error: any = []
        for (i = 0; i < data1.exchangeRates.length; i++) {
          console.log("exchangeRates", data1.exchangeRates[i]);
          let tmp: any = {};
          tmp.currencyFrom = data1.exchangeRates[i].currencyFrom;
          tmp.currencyTo = data1.exchangeRates[i].currencyTO;
          tmp.exchangeRateOld = data1.exchangeRates[i].exchangeRate;
          tmp.exchangeRate = data1.exchangeRates[i].exchangeRate;
          tmp.currencyFromFlag = data1.exchangeRates[i].currencyFromFlag;
          tmp.currencyToFlag = data1.exchangeRates[i].currencyToFlag;
          price_ary_tmp.push(tmp);
          tmp_exchange_rates_error.push("");
        }
        console.log("price_ary_tmp", price_ary_tmp);
        console.log("exchangeEdit", exchangeEdit);
        setPasswordError("");
        setPassword("");
        setIsPasswordValidate(false);
        if (modal == false) {
          setExchangeEdit({ ...exchangeEdit, price_ary: price_ary_tmp, "show": false, "passwordShow": false })
        } else {
          setEditExchangeRatesError(tmp_exchange_rates_error);
          setExchangeEdit({ ...exchangeEdit, price_ary: price_ary_tmp, "show": true })
        }



      }).catch(function (error) {
        console.log(error);
        setIsFormLoading(3);
        setIsPageLoading(false);
        setRates([])
        userService.handleError(error);
      })
    }
  }


  const editExchangeRatesValidate = () => {
    for (let i: any = 0; i < editExchangeRatesError.length; i++) {
      if (editExchangeRatesError[i] != "") {
        return false;
      }
    }
    return true;
  }

  function setUpdate(id: any, exchangeRate: any) {
    setFormEditExchangeRateVar({
      ...formEditExchangeRateVar,
      exchangeRateValue: exchangeRate,
      password: "",
      exchangeRateValueError: "",
      passwordError: "",
      isExchangeRateValueValidate: true,
      isPasswordValidate: false
    });
    setRateupdate(true);
    setRateid(id);
  }


  const submitrate = (values: any) => {
    // $("#pass_verified2").modal("hide");

    const user_id = localStorage.getItem('user_id');
    const access_token = localStorage.getItem('access_token');
    if (access_token && validateEditExchangeRateForm("password")) {
      console.log("submit start")
      setIsLoading("edit_exchange_rate_password")
      let config1 = {
        headers: {
          'Content-Type': 'application/json',
          'deviceType': 'Android',
          'appVersion': '1.0',
          'Authorization': 'Bearer ' + access_token
        }
      }
      console.log(parseFloat(values.exchangeRate))
      var val = parseFloat(values.exchangeRate)

      // axios.put(`${EXCHANGE_MANAGEMENT}/${rateid}/editExchangeRate?exchangeRate=${formEditExchangeRateVar.exchangeRateValue}&password=${formEditExchangeRateVar.password}`, {
      axios.put(`${EXCHANGE_MANAGEMENT}/${rateid}/editExchangeRate?exchangeRate=${formEditExchangeRateVar.exchangeRateValue}&password=${formEditExchangeRateVar.password}`, {
        exchangeRate: parseFloat(formEditExchangeRateVar.exchangeRateValue),
        password: formEditExchangeRateVar.password

      }, config1).then(function (response) {
        var data1 = response.data.data
        setRateid("")
        //getRate()
        UserTable()
        setEditExchangeRatePasswordModal(false);
        setIsLoading("");
        window.location.reload();
        // window.$('#pass_verified2').modal('hide');
        // $("#pass_verified2").modal("hide");
      }).catch(function (error) {
        setIsLoading("");
        console.log("error", error)
        // setRateid("")
        localStorage.setItem('same_value', "true");
        setErrormsg("pass_failed")
        userService.handleError(error);
        setTimeout(() => {
          setErrormsg('')
        }, 2000);
        UserTable()
      })
    }
  }

  const getCurrentExchangeRate = async () => {
    // let config1 = {
    //   headers: {
    //       'Content-Type': 'application/json'
    //   }
    // }
    // console.log("getCurrentExchangeRate")
    // // var resp = await axios.get(`https://api.exchangeratesapi.io/latest?base=USD`, config1).then(function (response) {
    // var resp = await axios.get(`http://api.exchangeratesapi.io/v1/latest?access_key=b74d8d26a6351902641a37cef6cf0441`, config1).then(function (response) {
    //   console.log("Exchange Rate-------------------------------")
    //     var tmp_rates = response.data.rates
    //     setCurrentrate((tmp_rates ? tmp_rates : []));
    //     }).catch(function (error) {
    //       setCurrentrate([]);
    //       setIsPageLoading(false);
    //     console.log(error);
    //   })

    adminService.get_current_exchange_rate().then(function (response) {
      var tmp_rates = response.data.data
      setCurrentrate((tmp_rates ? tmp_rates : []));
    })
      .catch(function (error) {
        setCurrentrate([]);
        console.log(error);
      });


  }


  const calculateRate = (from: any, to: any) => {
    let currencyRate = 0
    if (currentrate.length === 0) {
      currencyRate = 0
    } else {
      let from_currency = currentrate[from]
      let to_currency = currentrate[to]
      // let currencyRate = 1.0
      if (from_currency && to_currency) {
        currencyRate = (to_currency / from_currency)
      }
      console.log('.......', currencyRate)
      console.log(typeof (currencyRate))
    }
    return currencyRate
  }

  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      background: "#00000054"
    },

    content: {
      position: "fixed",
      top: "24px",
      right: "588px",
      bottom: "",
      left: "617px"
    }
  };
  const [deleteStatus, setdeleteStatus] = useState(false);

  const deleteRate = async (id: any) => {
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      console.log("submit start")
      let config1 = {
        headers: {
          'Content-Type': 'application/json',
          'deviceType': 'Android',
          'appVersion': '1.0',
          'Authorization': 'Bearer ' + access_token
        }
      }
      alert("Are you sure?")
      axios.delete(`${EXCHANGE_MANAGEMENT}/${id}/deleteExchangeRate`, config1).then(function (response) {
        setdeleteStatus(true)
        setTimeout(() => {
          //window.location.reload();
          setdeleteStatus(false)

        }, 2000);
        //getRate()
        UserTable()
      }).catch(function (error) {
        UserTable()
      })
    }

  }

  function closeModal() {
    console.log("called closemodal")
    setExchangeEdit({ ...exchangeEdit, "show": false, "passwordShow": false })
    setBatchPasswordModal(false);
  }


  const validateFormCase = (type: any, value: any) => {
    switch (type) {
      case 'edit_exchange_rate': {
        if (value == "" || value == null) {
          setFormEditExchangeRateVar({ ...formEditExchangeRateVar, exchangeRateValue: "", exchangeRateValueError: "Please Enter Exchange Rate", isExchangeRateValueValidate: false });
        }
        else {
          if (!/^\d+(\.\d+)?$/.test(value) || /^[0.]+$/.test(value)) {
            setFormEditExchangeRateVar({ ...formEditExchangeRateVar, exchangeRateValue: value, exchangeRateValueError: "Please Enter Valid Exchange Rate", isExchangeRateValueValidate: false });
          }
          else {
            setFormEditExchangeRateVar({ ...formEditExchangeRateVar, exchangeRateValue: value, exchangeRateValueError: "", isExchangeRateValueValidate: true });
          }
        }
        break;
      }

      case 'password': {
        if (value == "" || value == null) {
          setFormEditExchangeRateVar({ ...formEditExchangeRateVar, password: "", passwordError: "Please Enter Password", isPasswordValidate: false });
        } else {
          setFormEditExchangeRateVar({ ...formEditExchangeRateVar, password: value, passwordError: "", isPasswordValidate: true });
        }
        break;
      }

      case 'delete_exchange_rate_password': {
        if (value == "" || value == null) {
          setFormDeleteExchangeRateVar({ ...formDeleteExchangeRateVar, password: "", passwordError: "Please Enter Password", isPasswordValidate: false });
        } else {
          setFormDeleteExchangeRateVar({ ...formDeleteExchangeRateVar, password: value, passwordError: "", isPasswordValidate: true });
        }
        break;
      }

      case 'edit_exchange_rates_password': {
        setPassword(value);
        if (value == "" || value == null) {
          setIsPasswordValidate(false);
          setPasswordError("Please Enter Password");
        } else {
          setIsPasswordValidate(true);
          setPasswordError("");
        }
        break;
      }

      case 'currency_from': {
        if (value == "-1" || value == null) {
          setFormAddExchangeRateVar({ ...formAddExchangeRateVar, currencyFromError: "Please Select Currency From", isCurrencyFromValidate: false })
        } else {
          setFormAddExchangeRateVar({ ...formAddExchangeRateVar, currencyFromError: "", isCurrencyFromValidate: true })
        }
        break;
      }

      case 'currency_to': {
        if (value == "-1" || value == null) {
          setFormAddExchangeRateVar({ ...formAddExchangeRateVar, currencyToError: "Please Select Currency To", isCurrencyToValidate: false })
        } else {
          setFormAddExchangeRateVar({ ...formAddExchangeRateVar, currencyToError: "", isCurrencyToValidate: true })
        }
        break;
      }
      case 'add_exchange_rate': {
        if (value == "" || value == null) {
          setFormAddExchangeRateVar({ ...formAddExchangeRateVar, exchangeRate: value, exchangeRateError: "Please Enter Exchange Rate", isExchangeRateValidate: false });
        }
        else {
          if (!/^\d+(\.\d+)?$/.test(value) || /^[0.]+$/.test(value)) {
            setFormAddExchangeRateVar({ ...formAddExchangeRateVar, exchangeRate: value, exchangeRateError: "Please Enter Valid Exchange Rate", isExchangeRateValidate: false });
          }
          else {
            setFormAddExchangeRateVar({ ...formAddExchangeRateVar, exchangeRate: value, exchangeRateError: "", isExchangeRateValidate: true });
          }
        }
        break;
      }

      case 'add_exchange_rate_password': {
        if (value == "" || value == null) {
          setFormAddExchangeRateVar({ ...formAddExchangeRateVar, password: "", passwordError: "Please Enter Password", isPasswordValidate: false });
        } else {
          setFormAddExchangeRateVar({ ...formAddExchangeRateVar, password: value, passwordError: "", isPasswordValidate: true });
        }
        break;
      }

      case 'batch_upload_password': {
        if (value == "" || value == null) {
          setBatchPasswordError("Please Enter Password");
          setBatchPassword('');
          setIsBatchPasswordValidate(false);
        } else {
          setBatchPasswordError("");
          setBatchPassword(value);
          setIsBatchPasswordValidate(true);
        }
        break;
      }
    }
  }

  const validateEditExchangeRateForm = (type: any) => {
    if (type == "edit_exchange_rate" && formEditExchangeRateVar.isExchangeRateValueValidate == false) {
      validateFormCase('edit_exchange_rate', formEditExchangeRateVar.exchangeRateValue)
      return false;
    }
    if (type == "password" && formEditExchangeRateVar.isPasswordValidate == false) {
      validateFormCase('password', formEditExchangeRateVar.password)
      return false;
    }
    return true;
  }

  const validateDeleteExchangeRateForm = (type: any) => {
    if (type == "delete_exchange_rate_password" && formDeleteExchangeRateVar.isPasswordValidate == false) {
      validateFormCase('delete_exchange_rate_password', formDeleteExchangeRateVar.password)
      return false;
    }
    return true;
  }

  function openEditExchangeRatePasswordVerifyModal() {
    if (validateEditExchangeRateForm("edit_exchange_rate")) {
      setEditExchangeRatePasswordModal(true);
    }
  }

  function openDeleteExchangeRatePasswordVerifyModal(id: any) {
    setDeleteRateId(id);
    setFormDeleteExchangeRateVar({ ...formDeleteExchangeRateVar, passwordError: "", password: "", isPasswordValidate })
    setDeleteExchangeRatePasswordModal(true);
  }

  function handlePriceEdit(e: any, i: any) {

    let tmp_Exchange_rates_error: any = editExchangeRatesError

    if (e.target.value == "" || e.target.value == null) {
      tmp_Exchange_rates_error[i] = "Enter Rate"
    }
    else {
      if (!/^\d+(\.\d+)?$/.test(e.target.value) || /^[0.]+$/.test(e.target.value)) {
        tmp_Exchange_rates_error[i] = "Invalid Rate"
      }
      else {
        tmp_Exchange_rates_error[i] = ""
      }
    }

    setEditExchangeRatesError(tmp_Exchange_rates_error);
    let items: any = { ...exchangeEdit };
    let item = { ...exchangeEdit.price_ary[i] };
    item.exchangeRate = e.target.value;
    items.price_ary[i] = item;
    setExchangeEdit(items);



    // console.log("e",e.target.value);
    // console.log("i---",i);
    // if (!(/^[-+]?\d*\.?\d*$/.test(e.target.value))) {
    //   toast.error("Please enter valid Exchange Rate")     

    // }else{
    //   let items:any = {...exchangeEdit};

    //   let item = {...exchangeEdit.price_ary[i]};
    //   item.exchangeRate = e.target.value;
    //   console.log("item",item)
    //   items.price_ary[i] = item;
    //   console.log("items",items);
    //   setExchangeEdit(items);
    // }

  }

  const deleteRatepass = async (values: any) => {
    const access_token = localStorage.getItem('access_token');
    const id = localStorage.getItem('delete_rate_id');
    const user_id = localStorage.getItem('user_id');
    if (access_token && deleteRateId && validateDeleteExchangeRateForm("delete_exchange_rate_password")) {
      console.log("submit start")
      setIsLoading('delete_exchange_rate_password');
      let config1 = {
        headers: {
          'Content-Type': 'application/json',
          'deviceType': 'Android',
          'appVersion': '1.0',
          'Authorization': 'Bearer ' + access_token
        }
      }
      // alert("Are you sure?")
      axios.post(`${EXCHANGE_MANAGEMENT}/${deleteRateId}/deleteExchangeRate`, {
        userId: user_id,
        password: formDeleteExchangeRateVar.password
      }, config1).then(function (response) {
        setIsLoading('');
        setdeleteStatus(true)
        setTimeout(() => {
          //window.location.reload();
          setdeleteStatus(false)

        }, 2000);
        localStorage.removeItem('delete_rate_id');
        window.location.href = `/merchant/exchange`;
        //getRate()
        UserTable()
      }).catch(function (error) {
        setIsLoading('');
        if (error.response.status == 404) {
          localStorage.setItem('same_value', "true");
          setErrormsg("already")
        }
        else {
          localStorage.setItem('same_value', "true");
          setErrormsg("pass_failed")
        }
        setTimeout(() => {
          setErrormsg('')
        }, 2000);
        UserTable()
      })
    }

  }

  const validatepasswordForm = (data: any) => {
    let formIsValid = true;
    let errors: any = [];
    if (!data.user_password) {
      formIsValid = false;
      localStorage.setItem('same_value', "true");
      setErrormsg("error_field")

      setTimeout(() => {
        setErrormsg('')
      }, 2000);
      throw new SubmissionError({ email: 'Please enter valid email-ID.', _error: 'Register failed!' })
    }
    else {
      formIsValid = true;
      setErrormsg("")
    }

    return formIsValid;
  }

  function setRateId() {
    setRateid("");
  }

  function openBatchUploadModal() {
    let tmp_data: any = [];
    let tmp_headers: any = [
      { label: "Currency From", key: "currency_from" },
      { label: "Currency To", key: "currency_to" },
      { label: "Exchange Rate", key: "exchange_rate" }
    ];
    rates.map((item: any, index: any) => {
      tmp_data.push({ currency_from: item.currencyFrom, currency_to: item.currencyTO, exchange_rate: item.exchangeRate });
    });
    setBatchFileName('');
    setBatchFileData([]);
    setBatchFileError('');
    setInventoryCSVData(tmp_data);
    setInventoryCSVHeaders(tmp_headers);
    setBatchUploadModal(true);
  }
  const handleBatchUpload = (e: any) => {
    setBatchFileError('');
    setBatchFileData([]);
    setBatchFileName(e.target.files[0].name);
    e.preventDefault();
    var isAnyError = false;
    var files = e.target.files, f = files[0];
    var reader = new FileReader();
    var temp_currency_names: any = []
    let errorMsg = "Invalid CSV Data. Please follow the sample CSV file."
    reader.onload = function (e: any) {
      var data = e.target.result;
      var data_arry: any = [];
      var file_data: any = [];
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      data_arry = XLSX.utils.sheet_to_json(ws, { header: 1 });
      if (data_arry.length == 1) {
        setBatchFileError(errorMsg);
      }
      data_arry.map((item: any, index: any) => {
        if (index == 0 || isAnyError) {
          if (item[0] != 'Currency From' || item[1] != 'Currency To' || item[2] != 'Exchange Rate') {
            setBatchFileError(errorMsg);
            isAnyError = true;
          }
          return;
        }
        if (item[0] == '' || (!/^[A-Z]+$/.test(item[0])) || item[1] == '' || (!/^[A-Z]+$/.test(item[1])) || !/^\d+(\.\d+)?$/.test(item[2]) || /^[0.+-]+$/.test(item[2]) || item[0] == item[1]) {
          setBatchFileError(errorMsg);
        }
        file_data.push({ 'currencyFrom': item[0], 'currencyTo': item[1], 'exchangeRate': item[2] });
        let status1: any = false;
        let status2: any = false;
        currencies.map((currency: any, index: any) => {
          if (item[0] == currency.name) {
            status1 = true;
          }
          if (item[1] == currency.name) {
            status2 = true;
          }
        });
        if (status1 == false || status2 == false) {
          setBatchFileError(errorMsg);
        }
        //  temp_currency_names.push(item[0]+item[1])
      });
      // if (temp_currency_names.filter((e:any, i:any, a:any) => a.indexOf(e) !== i) != []){
      // }
      setBatchFileData(file_data);
    };
    reader.readAsBinaryString(f);
  }

  function validateBatchUpload() {
    if (batchFileData.length == 0) {
      setBatchFileError('Please select a CSV File.');
      return false;
    } else if (batchFileError.length > 0) {
      return false;
    }
    else {
      setBatchPassword('');
      setBatchPasswordError('');
      setIsBatchPasswordValidate(false);
      setBatchUploadModal(false);
      setBatchPasswordModal(true);
    }
  }

  function validateBatchPassword() {
    validateFormCase("batch_upload_password", batchPassword)
    if (!isBatchPasswordValidate) {
      return false;
    }
    return true;
  }

  function submitBatchFile() {
    if (validateBatchPassword()) {
      setIsLoading('batch_uplaod');
      console.log('data-', batchFileData)
      // Write API code here...
      let params: any = { password: batchPassword, exchangeRateList: batchFileData }
      const user_id = localStorage.getItem('user_id');
      merchantService.edit_all_exchanges(params, user_id)
        .then(function (response: any) {
          getRate(currentPage);
          setBatchPasswordModal(false);
          setIsPageLoading(false);
        }).catch(function (error: any) {
          setIsLoading('');
          setIsPageLoading(false);
          console.log("-----------error--------", error);
          // setLoaderstyle({display:"none"})
          userService.handleError(error);
        });
    }
  }


  const UserTable = () => {
    return (
      <div className="table_inner">
        <div className="table-responsive">
          {deleteStatus && <h4>Item Successfully Deleted</h4>}
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>FROM</th>
                <th>TO</th>
                <th>EXCHANGE PRICE</th>
                <th>YOUR BEST RATE</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {rates.map((item: any, index: any) => {
                var val = calculateRate(item.currencyFrom, item.currencyTO)
                console.log(val)
                return (
                  <tr key={index}>
                    <td>{(currentPage * 20) + (index + 1)}</td>
                    <td><img src={item.currencyFromFlag} style={{ width: `33px`, height: `33px` }} />{item.currencyFrom}</td>
                    <td><img src={item.currencyToFlag} style={{ width: `33px`, height: `33px` }} />{item.currencyTO}</td>
                    <td>{(val !== 0) ? ("1 " + item.currencyFrom + " = " + val.toFixed(3) + " " + item.currencyTO) : "-"}</td>
                    <td>1.00 {item.currencyFrom} = <Amountformatter amount={item.exchangeRate} /><span> {item.currencyTO}</span></td>
                    <td style={{ width: '220px' }}>{(rateid != item.id) ? <button className="btn" onClick={() => setUpdate(item.id, item.exchangeRate)} >Edit Rate</button>
                      :
                      <form onSubmit={handleSubmit(submitrate)}>
                        <div className="form-group edit_exchange_rate ">
                          <label>Exchange Rate:</label>
                          <a className="small_close_button" onClick={setRateId}>X</a>
                          <div className="input_box tableinputarea">
                            <input
                              className="form-control form-control-two "
                              value={formEditExchangeRateVar.exchangeRateValue}
                              onChange={(e: any) => validateFormCase('edit_exchange_rate', e.target.value)}
                              placeholder="Enter Rate"
                              maxLength={30}
                            ></input>
                            <a onClick={openEditExchangeRatePasswordVerifyModal} className="bottom_btn small_save_btn" >Submit</a>
                            {formEditExchangeRateVar.exchangeRateValueError.length > 0 && (
                              <span style={{ color: "red" }}>{formEditExchangeRateVar.exchangeRateValueError}</span>
                            )}
                          </div>
                        </div>
                        {pass_verify_case('edit')}
                      </form>
                    }</td>
                    <td>
                      {/* <button className="btn btn-danger" onClick={() => deleteRate(item.id)} >DELETE RATE</button>  */}
                      <a onClick={() => openDeleteExchangeRatePasswordVerifyModal(item.id)} data-id={item.id} className="btn delete_rate">Delete Rate</a>
                    </td>
                  </tr>)
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  const user_id = localStorage.getItem('user_id');

  function check_valid_add_data() {
    console.log("currenctToId", currencyToId);
    console.log("currenctToId", currencyFromId);
    console.log("currenctToId", exchangeratevalue);
    if (currencyFromId == undefined) {
      toast.error("Please select from country")
      return
    }
    if (currencyToId == undefined) {
      toast.error("Please select to country")
      return
    }

    if (exchangeratevalue == undefined) {
      toast.error("Please select exchange value")
      return
    }

    if (currencyFromId == currencyToId) {
      toast.error("Currency from and to will not be same")
      return
    }
    if (!(/^[-+]?\d*\.?\d*$/.test(exchangeratevalue))) {
      toast.error("Please enter valid value in exchange rate")
      return
    }


    setAddRateModal(false);
    setpasswordModal(true);

  }

  const setCurrencyFromData = (event: any) => {
    setFormAddExchangeRateVar({ ...formAddExchangeRateVar, currencyToId: "-1", currencyToText: "", isCurrencyToValidate: false })
    let index = event.target.selectedIndex;
    let optionElement = event.target.childNodes[index];
    let optionElementId = optionElement.getAttribute('id');
    var temp_array: any = [];
    currencies.map((currency: any, index: any) => {
      if (currency.name != event.target.value) {
        temp_array.push(currency);
      }
    });
    setNewCurrenctTo(temp_array);
    setCurrenciesto([])
    // var index = event.nativeEvent.target.selectedIndex;
    // console.log(event.nativeEvent.target[index].text);
    // setCurrencyFromtext(event.nativeEvent.target.selectedIndex);
    // setCurrencyFromId(event.nativeEvent.target[index].text);
    // var id = event.nativeEvent.target[index].value;
    // var text = event.nativeEvent.target[index].text;
    // if (text != "Select Currency "){
    if (optionElementId != "-1") {
      setFormAddExchangeRateVar({ ...formAddExchangeRateVar, currencyFromId: optionElementId, currencyFromText: event.target.value, currencyFromError: "", isCurrencyFromValidate: true })
      const access_token = localStorage.getItem('access_token');
      //   if (access_token) {
      //     setIsLoading('set_currency_from_id')
      //     let config1 = {
      //       headers: {
      //           'Content-Type': 'application/json',
      //           'deviceType':'Android',
      //           'appVersion' : '1.0',
      //           'Authorization' : 'Bearer ' + access_token
      //       }
      //     }
      //     axios.get(`${EXCHANGE_MANAGEMENT}/getCurrencyPairFrom?fromCurrency=${optionElementId}`, config1).then(function (response) {
      //       localStorage.setItem('same_value',"false"); 
      //       setCurrenciesto(response.data.data.currencyPairs)
      //       setIsLoading('')
      //     }).catch(function (error) {
      //     console.log(error);
      //     setCurrencies([])
      //   })
      // }
    } else {
      setFormAddExchangeRateVar({ ...formAddExchangeRateVar, currencyFromId: "-1", currencyFromText: event.target.value, currencyFromError: "Please Select Currency From", isCurrencyFromValidate: false })
    }

  }



  const setCurrencyToData = (event: any) => {
    let index = event.target.selectedIndex;
    let optionElement = event.target.childNodes[index];
    let optionElementId = optionElement.getAttribute('id');
    setFormAddExchangeRateVar({
      ...formAddExchangeRateVar, currencyToId: optionElementId, currencyToText: event.target.value,
      currencyToError: (optionElementId != "-1" ? "" : "Please Select Currency To"), isCurrencyToValidate: (optionElementId != "-1" ? true : false)
    })
  }

  const setcurrencyToId = (event: any) => {
    var index = event.nativeEvent.target.selectedIndex;
    setCurrencyTotext(event.target.value);
    setCurrencyToId(event.nativeEvent.target[index].text);
  }

  const setExachangeRate = (event: any) => {
    console.log(event.nativeEvent.target.value)
    setexchangeratevalue(event.target.value)
    // var index = event.nativeEvent.target.selectedIndex;
    // setCurrencyToId(event.nativeEvent.target[index].text)
  }


  function openEditRatesModal() {
    setIsPageLoading(true);
    getRate(currentPage, true);
  }

  function openAddRateModal() {
    setFormAddExchangeRateVar({
      ...formAddExchangeRateVar,
      currencyFromId: "-1",
      currencyFromText: "",
      currencyToId: "-1",
      currencyToText: "",
      exchangeRate: "",
      currencyFromError: "",
      currencyToError: "",
      exchangeRateError: "",
      isCurrencyFromValidate: false,
      isCurrencyToValidate: false,
      isExchangeRateValidate: false,
      password: "",
      passwordError: "",
      isPasswordValidate: false
    })
    setAddRateModal(true);
  }

  const makeHttpRequestWithPage = async (pageNumber: any) => {
    setRates([]);
    if (pageNumber < 0) {
      pageNumber = total - 1
    }
    if (pageNumber == total) {
      pageNumber = 0
    }
    setIsFormLoading(0);
    getRate(pageNumber)
    setCurrentPage(pageNumber)
  }

  const pagination = () => {
    let table = []
    table.push(<li><a href="javascript:void(0)" className={currentPage == 0 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage - 1)}>Previous</a></li>)
    for (let i = 0; i < total; i++) {
      console.log("i------------------->" + currentPage)
      // table.push(<span onClick={() => makeHttpRequestWithPage(i)} className="active">{i}</span>)
      table.push(<li className={i == currentPage ? "active block_cursor_event" : ""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}  >{i + 1}</a></li>)
    }
    table.push(<li><a href="javascript:void(0)" className={currentPage == total - 1 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage + 1)}>Next</a></li>)
    return table
  }

  const { error, handleSubmit, pristine, reset, submitting } = props
  return (
    <Layout>
      <section className="inner_sction">
        <section className="table_sction">
          <div className="container">
            <div className="table_sction_outer">
              <div className="top-sctin">
                <h4>Rates</h4>
                <a onClick={() => openEditRatesModal()} className="btn btn-primary pull-right"> Edit Rates </a>
                <a onClick={() => openAddRateModal()} id="add_rate_button" className="btn btn-primary" >Add Rate</a>
              </div>
              {isFormLoading == 0 && <div id="api_loader"><img src={loader_image} width="50" height="50" />Loading...</div>}
              {isFormLoading == 1 && UserTable()}
              {isFormLoading == 2 && <h1>Record Not Found</h1>}
              {isFormLoading == 3 && <h1>Some Error Occur</h1>}
            </div>
            <div className="pagination_sction" style={{ marginBottom: '100px' }}>
              <ul className="pagination">
                {total > 0 ? pagination() : ""}
              </ul>
            </div>
          </div>
          <div className="bar_code" style={{ bottom: '47px' }}>
            <button className="batch_upload_button" onClick={() => openBatchUploadModal()} >Batch Upload</button>
          </div>
        </section>
      </section>

      <Modal
        ariaHideApp={false}
        isOpen={addRateModal}
        onRequestClose={closeModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      // className="reset_password"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setAddRateModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Add Rate</h3>
        </div>
        <div className="modal-body">
          <div className="user_request">

            <form onSubmit={handleSubmit(submit)}>
              {errormsg == "error" && <h4 className="error">Please Select Different Value</h4>}
              {errormsg == "already" && <h4 className="error">Already Exists</h4>}


              <div className="form-group">
                <label>From Currency:</label>
                <select className="form-control" onChange={setCurrencyFromData}>
                  <option id="-1">Select Currency From</option>
                  {
                    currencies.length > 0 && currencies.map((item: any, index: any) => {
                      return (<option key={index} id={item.id}>{item.name}</option>)
                    })
                  }
                </select>
                {formAddExchangeRateVar.currencyFromError != "" && (<span style={{ color: "red" }}>{formAddExchangeRateVar.currencyFromError}</span>)}
              </div>

              <div className="form-group">
                <label>To Currency:</label>
                {/* <select className="form-control"  onChange = {setCurrencyToData}>
                        <option id="-1">Select Currency To</option>
                        {
                        currenciesto.length > 0 && currenciesto.map((item: any, index: any) => {
                          return (<option id={item.currencyToId}>{item.currencyToName}</option>)
                        })
                      } 
                        </select> */}
                <select className="form-control" onChange={setCurrencyToData}>
                  <option id="-1">Select Currency To</option>
                  {
                    newCurrenctTo.length > 0 && newCurrenctTo.map((item: any, index: any) => {
                      return (<option key={index} id={item.id}>{item.name}</option>)
                    })
                  }
                </select>
                {formAddExchangeRateVar.currencyToError != "" && (<span style={{ color: "red" }}>{formAddExchangeRateVar.currencyToError}</span>)}
              </div>



              <div className="form-group" >
                <label>Exchange Rate:</label>
                <input placeholder="Enter Exchange Rate"
                  value={formAddExchangeRateVar.exchangeRate}
                  type="text"
                  onChange={(e: any) => validateFormCase('add_exchange_rate', e.target.value)}
                  className="form-control"
                />
                {formAddExchangeRateVar.exchangeRateError.length > 0 && (<span style={{ color: "red" }}>{formAddExchangeRateVar.exchangeRateError}</span>)}
              </div>


              {/* <Field
                      name="currencyFrom"
                      type="select"
                      component={renderSelectField}
                      label="From Currency"
                      onChange = {setcurrencyFromId}
                    /> */}

              {/* <Field
                      name="currencyTo"
                      type="select"
                      component={renderSelectFieldto}
                      label="To Currency"
                      onChange = {setcurrencyToId}
                    /> */}

              {/* <div className="form-group" >
                    <label>Exchange Rate</label>
                      <input  placeholder="Exchange Rate" 
                      value={formAddExchangeRateVar.exchangeRate} 
                      type="text" 
                      onChange = {setExachangeRate} 
                      className="form-control"
                      />
                      { error && <span>{error}</span>}
                  </div> */}

              {pass_verify_case('add')}

              <div className="bottom_btn">
                {/* <a onClick={check_valid_add_data} >Submit</a> */}
                <a className="change_cursor_to_pointer" onClick={validateAddRateForm} >Submit</a>

              </div>
            </form>
            {isLoading == 'set_currency_from_id' && (
              <div id="" className="loaderbox loginloader">
                <div>
                  <img
                    className="bounce-2"
                    src={loader_image}
                    width="50"
                    height="50"
                  />
                  <p>Loading...</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={passwordModal}
        onRequestClose={closeModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      // className="reset_password"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setpasswordModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Verify Your Password</h3>
        </div>
        <div className="modal-body">
          <div className="user_request">
            <form onSubmit={handleSubmit(submit)}>
              {passwordcase}
              <div className="form-group">
                <label>Enter Password</label>
                <input placeholder="Enter Password"
                  value={formAddExchangeRateVar.password}
                  onChange={e => validateFormCase('add_exchange_rate_password', e.target.value)}
                  type="password"
                  className="form-control form-control-two"
                />
                {formAddExchangeRateVar.passwordError != "" && (<span style={{ color: "red" }}>{formAddExchangeRateVar.passwordError}</span>)}

                {/* <input  placeholder="Enter Password" value={passwordEntered} onChange={e => setPasswordEntered(e.target.value)}  type="password" className="form-control form-control-two" required/> */}
              </div>
              {errormsg == "error" && <h4 className="error">Please Select Different Value for currencies</h4>}
              {errormsg == "already" && <h4 className="error">Already Exists</h4>}
              {errormsg == "error_field" && <h4 className="error">Please Fill all the Fields for Rate</h4>}
              {errormsg == "pass_failed" && <h4 className="error">Incorrect Password</h4>}
              <div className="bottom_btn">
                <button id="add_pass_btn" type="submit" disabled={submitting}>Submit</button>
              </div>
            </form>
          </div>
        </div>
        {isLoading == 'add_exchange_rate_password' && (
          <div id="" className="loaderbox loginloader">
            <div>
              <img
                className="bounce-2"
                src={loader_image}
                width="50"
                height="50"
              />
              <p>Loading...</p>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={editExchangeRatePasswordModal}
        onRequestClose={closeModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop edit_profile"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setEditExchangeRatePasswordModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Verify Your Password</h3>
        </div>
        <div className="modal-body">
          <div className="user_request">
            <form onSubmit={handleSubmit(submitrate)}>
              {passwordcase}
              <div className="form-group ">
                <label>Password:</label>
                <div className="input_box">
                  <input
                    type="password"
                    className="form-control form-control-two"
                    value={formEditExchangeRateVar.password}
                    onChange={(e: any) => validateFormCase('password', e.target.value)}
                    placeholder="Enter Password"

                  ></input>
                  {errormsg == "error" && <h4 className="error">Please Select Different Value for currencies</h4>}
                  {errormsg == "already" && <h4 className="error">Already Exists</h4>}
                  {errormsg == "error_field" && <h4 className="error">Please Fill all the Fields for Rate</h4>}
                  {errormsg == "pass_failed" && <h4 className="error">Incorrect Password</h4>}
                  {formEditExchangeRateVar.passwordError.length > 0 && (
                    <span style={{ color: "red" }}>
                      {formEditExchangeRateVar.passwordError}
                    </span>
                  )}
                </div>
              </div>


              <div className="bottom_btn">
                <button id="add_pass_btn" type="submit" disabled={submitting}>Submit</button>
              </div>
            </form>
          </div>
        </div>
        {isLoading == 'edit_exchange_rate_password' && (
          <div id="" className="loaderbox loginloader">
            <div>
              <img
                className="bounce-2"
                src={loader_image}
                width="50"
                height="50"
              />
              <p>Loading...</p>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={deleteExchangeRatePasswordModal}
        onRequestClose={closeModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop edit_profile"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setDeleteExchangeRatePasswordModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Confirm To Delete Rate</h3>
        </div>
        <div className="modal-body">
          <div className="user_request">
            <form onSubmit={handleSubmit(deleteRatepass)} id="delete_rate_form">
              <div className="form-group ">
                <label>Password:</label>
                <div className="input_box">
                  <input
                    type="password"
                    className="form-control form-control-two"
                    value={formDeleteExchangeRateVar.password}
                    onChange={(e: any) => validateFormCase('delete_exchange_rate_password', e.target.value)}
                    placeholder="Enter Password"
                  ></input>
                  {formDeleteExchangeRateVar.passwordError.length > 0 && (
                    <span style={{ color: "red" }}>
                      {formDeleteExchangeRateVar.passwordError}
                    </span>
                  )}
                  {errormsg == "error" && <h4 className="error">Please Select Different Value for currencies</h4>}
                  {errormsg == "already" && <h4 className="error">Already Exists</h4>}
                  {errormsg == "error_field" && <h4 className="error">Please Fill all the Fields for Rate</h4>}
                  {errormsg == "pass_failed" && <h4 className="error">Incorrect Password</h4>}
                </div>
              </div>

              <div className="bottom_btn">
                <button id="add_pass_btn" type="submit" disabled={submitting}>Submit</button>
              </div>
            </form>
          </div>
        </div>
        {isLoading == 'delete_exchange_rate_password' && (
          <div id="" className="loaderbox loginloader">
            <div>
              <img
                className="bounce-2"
                src={loader_image}
                width="50"
                height="50"
              />
              <p>Loading...</p>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={exchangeEdit.show}
        //  onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button type="button" className="close" onClick={closeModal} ><i className="fa fa-times" aria-hidden="true"></i></button>
          <h3 className="modal-title"> Edit Rates </h3>
        </div>

        <div className="modal-body">
          {exchangeEdit.price_ary.length > 0 ?
            <div>
              <div className="table_inner">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>FROM</th>
                        <th>TO</th>
                        <th>YOUR BEST RATE</th>
                        <th>EXCHANGE RATE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {exchangeEdit.price_ary.map((item: any, index: any) => {
                        var val = calculateRate(item.currencyFrom, item.currencyTO)
                        console.log(val)
                        return (
                          <tr key={index}>
                            <td><img src={item.currencyFromFlag} style={{ width: `33px`, height: `33px` }} />{item.currencyFrom}</td>
                            <td><img src={item.currencyToFlag} style={{ width: `33px`, height: `33px` }} />{item.currencyTo}</td>
                            <td style={{ width: '190px' }}>1 {item.currencyFrom} = <span>{item.exchangeRateOld} {item.currencyTo}</span></td>
                            <td>
                              <form onSubmit={handleSubmit(submitallrate)}>
                                <input className="form-control"
                                  type="text"
                                  placeholder="Enter Exchange Rate"
                                  value={item.exchangeRate}
                                  onChange={(e: any) => handlePriceEdit(e, index)} />
                                {editExchangeRatesError[index] != "" && (
                                  <span style={{ color: "red" }}>
                                    {editExchangeRatesError[index]}
                                  </span>
                                )}
                              </form>
                            </td>
                          </tr>)
                      })}
                    </tbody>
                  </table>

                </div>
              </div>
              <div className="bottom_btn">
                <button onClick={submitallrate} >Submit</button>
              </div>
            </div>
            :
            <h1>Record Not Found</h1>}
        </div>
        {/* {isLoading == 'edit_all_echange_rates' && (
        <div id="" className="loaderbox loginloader">
          <div>
            <img
              className="bounce-2"
              src={loader_image}
              width="50"
              height="50"
            />
            <p>Loading...</p>
          </div>
        </div>
      )} */}
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={exchangeEdit.passwordShow}
        //  onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >

        <div className="modal-header">
          <button type="button" className="close" onClick={closeModal} ><i className="fa fa-times" aria-hidden="true"></i></button>
          <h3 className="modal-title"> Verify Your Password </h3>
        </div>
        <div className="modal-body">
          <div className="form-group ">
            <label>Password:</label>
            <div className="input_box">
              <input
                type="password"
                className="form-control form-control-two"
                onChange={(e: any) => validateFormCase('edit_exchange_rates_password', e.target.value)}
                value={password}
                placeholder="Enter Password" />
            </div>
            {passwordError != "" && (<span style={{ color: "red" }}>{passwordError}</span>)}
          </div>
          <div className="bottom_btn">
            <button id="add_pass_btn" onClick={submitallrateWithPassword} type="submit" disabled={submitting}>Submit</button>
          </div>
        </div>
        {isLoading == 'edit_all_echange_rates' && (
          <div id="" className="loaderbox loginloader">
            <div>
              <img
                className="bounce-2"
                src={loader_image}
                width="50"
                height="50"
              />
              <p>Loading...</p>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={batchUploadModal}
        onRequestClose={closeModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop edit_profile"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setBatchUploadModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Update Rates</h3>
        </div>
        <div className="modal-body">
          <div className="user_request">
            <div className="sample_file">
              <CSVLink data={inventoryCSVData} headers={inventoryCSVHeaders} className='add_minus_button pull-left' target="_blank" filename={"Exchange-Rates.csv"}><i className="fa fa-download" aria-hidden="true"></i> Download Rates CSV</CSVLink>
              <a className="add_minus_button pull-right" href="/rates_sample.csv" download="exchange_rates_sample.csv"><i className="fa fa-download" aria-hidden="true"></i> Download Sample CSV</a>
            </div>
            <div className="input_form_box form_input_box input-upload">
              <form autoComplete={"off"} >
                <div className="input_content upload_content">
                  <input contentEditable={false} type="text" className="form-control" placeholder="Upload CSV" value={batchFileName} />
                  <div className="upload_file-btn-box">
                    <div className="upload-btn-wrapper">
                      <button className="btn" style={{ fontSize: '19px' }}>Select</button>
                      <input type="file" name="myfile" onChange={(e) => handleBatchUpload(e)} />
                    </div>
                  </div>
                </div>
                {batchFileError.length > 0 && (<span style={{ color: "red" }}>{batchFileError}</span>)}
                <br /><br />
                <div className="bottom_btn">
                  <a className="change_cursor_to_pointer" onClick={() => validateBatchUpload()}>Upload!</a>
                </div>

              </form>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={batchPasswordModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"

      >

        <div className="modal-header">
          <button type="button" className="close" onClick={() => setBatchPasswordModal(false)} ><i className="fa fa-times" aria-hidden="true"></i></button>
          <h3 className="modal-title"> Verify Your Password </h3>
        </div>
        <div className="modal-body">
          <div className="form-group ">
            <label>Password:</label>
            <div className="input_box">
              <input
                type="password"
                className="form-control form-control-two"
                onChange={(e: any) => validateFormCase('batch_upload_password', e.target.value)}
                value={batchPassword}
                placeholder="Enter Password" />
            </div>
            {batchPasswordError != "" && (<span style={{ color: "red" }}>{batchPasswordError}</span>)}
          </div>
          <div className="bottom_btn">
            <button id="add_pass_btn" onClick={() => submitBatchFile()} >Submit</button>
          </div>
        </div>
        {isLoading == 'batch_uplaod' && (
          <div id="" className="loaderbox loginloader">
            <div>
              <img
                className="bounce-2"
                src={loader_image}
                width="50"
                height="50"
              />
              <p>Loading...</p>
            </div>
          </div>
        )}
      </Modal>
      {isPageLoading && (
        <div id="" className="loaderbox loginloader">
          <div>
            <img
              className="bounce-2"
              src={loader_image}
              width="50"
              height="50"
            />
            <p>Loading...</p>
          </div>
        </div>
      )}
      <ToastContainer />


    </Layout>
  )
}

export default reduxForm({
  form: 'searchForm', // a unique identifier for this form
  enableReinitialize: true
})(Exchange)