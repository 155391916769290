import React from 'react';
import { connect } from "react-redux";
import store from '../store';
import Count from './Count'
import { Container, Row, Col, Button } from 'react-bootstrap' 
import { Layout } from './common/Layout'
import Merchanthome from '../components/merchant/Merchanthome'
import Dashboard from '../components/admin/Dashboard'

const hanldleClick = (type: string) => {
    store.dispatch({type});
    console.log(store.getState().counter)
}

const Home = () => {
    //const countt = store.getState().counter 
    var access_token = localStorage.getItem('access_token');
    const user_role = localStorage.getItem('user_role');
    return (
      <div>
        {access_token && (user_role == "MERCHANT" || user_role == "BRANCH") && <Merchanthome></Merchanthome>}
        {access_token && (user_role == "ADMIN" || user_role ==="KYCADMIN") && <Dashboard></Dashboard>}
        {!access_token &&
        <Layout>
          <section className="outer_section">
            <div className="container">
              <div className="col-md-6 col-md-offset-3">
                  <div className="inner_section">
                    <div className="logo_form text-center">
                      <h1>Coming Soon</h1>
                    </div>
                  </div>
              </div>
             </div>
          </section>
        </Layout>
        }
        </div>
    )
}


export default connect()(Home);