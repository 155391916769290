import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from 'react-bootstrap' 
import { Field, reduxForm, SubmissionError } from 'redux-form'
import axios from 'axios';
import { EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT } from '../../config/config';
import { Layout } from '../common/Layout';
import Moment from 'react-moment';
import loader_image from '../../images/loading.gif';
import * as moment from 'moment-timezone';
import jstz from 'jstz';


interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
}


const Currency = () => { 

    const [ users, setUsers ] = useState([]);
    const [ userskyc, setUsersKYC ] = useState([]);
    const [ isLoading, setisLoading ] = useState(0);

    // Default TimeZone
    const timezone = jstz.determine();
    const tz = timezone.name()

    useEffect(() => {
      getData();
    }, []);


    const getData = () => {                
          const access_token = localStorage.getItem('access_token');
          if (access_token) {
            console.log("submit start")
            let config1 = {
              headers: {
                  'Content-Type': 'application/json',
                  'deviceType':'Android',
                  'appVersion' : '1.0',
                  'Authorization' : 'Bearer '+access_token
              }
            }
            axios.get(`${TRANSACTION_MANAGEMENT}/currencies`, config1).then(function (response) {
              var data1 = response.data.data
              console.log(response.data)
              if (data1.currencies.length > 0){
                setisLoading(1)
              }else{
                setisLoading(2)
              }
              setTotalPage(data1.currencies.length)
              setUsers(data1.currencies)
            }).catch(function (error) {
            console.log(error);
            setisLoading(3)
            setUsers([])
          })
        }  
    }


  const UserTable = () => { 
    
      return (
              <table className="table">
                <thead>
                  <tr>
                  <th>ID</th>
                  <th>NAME</th>
                  <th colSpan={col}>Deposit</th>
                  <th colSpan={col}>Withdraw</th>
                  <th colSpan={col}>Exchange</th>
                  <th colSpan={col}>Investment</th>
                  </tr>
                  <tr>
                      <td></td>
                      <td></td>
                      <td><b>Min</b></td>
                      <td><b>Max</b></td>
                      <td><b>Min</b></td>
                      <td><b>Max</b></td>
                      <td><b>Min</b></td>
                      <td><b>Max</b></td>
                      <td><b>Min</b></td>
                      <td><b>Max</b></td>
                  </tr>
                </thead>
                <tbody>
               {users.map((item: any, index: any) => {   
                 return(
                 <tr key={index}>
                  <td>{ index+1 }</td>
                  <td>{item.name}</td>
                  <td>{item.depositeMin}</td>
                  <td>{item.depositeMax}</td>
                  <td>{item.withdrawMin}</td>
                  <td>{item.withdrawMax}</td>
                  <td>{item.exchangeMin}</td>
                  <td>{item.exchangeMax}</td>
                  <td>{item.invetstmentMin}</td>
                  <td>{item.investmentMax}</td>
                  </tr> 
                       )
                })}
                </tbody>
           </table>      
        )

    }

    const [ currentPage, setCurrentPage ] = useState(0);
    const [ usersPerPage, setUsersPerPage ] = useState(20);
    const [ totalPage, setTotalPage ] = useState(1);
    const [ col, setCol ] = useState(2);

    const total = Math.round(totalPage/usersPerPage);
    

    const makeHttpRequestWithPage = async (pageNumber:any) => {
      console.log("total",total)
      
      if (pageNumber < 0){
        pageNumber = total-1
      }
      if (pageNumber == total){
        pageNumber = 0
      }
      console.log("pageNumber",pageNumber)
      const access_token = localStorage.getItem('access_token');
      const response = await fetch(`${EXCHANGE_MANAGEMENT}/getOrdersListByAdmin/${pageNumber}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'deviceType':'Android',
          'appVersion' : '1.0',
          'Authorization' : 'Bearer '+access_token
      },
      });
  
      const data = await response.json();
      console.log(data)
      setUsers(data.data.queuedOrders)
      setCurrentPage(pageNumber)
   
    }
  
    const pagination = () =>{
      let table = []
      table.push(<li><a  href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(currentPage-1)}>Previous</a></li>)
      for (let i = 0; i < total; i++) {
        console.log("i------------------->"+currentPage)
        // table.push(<span onClick={() => makeHttpRequestWithPage(i)} className="active">{i}</span>)
        table.push(<li className={ i == currentPage ? "active block_cursor_event" :""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}  >{i+1}</a></li>)
      }      
      table.push(<li><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(currentPage+1)}>Next</a></li>)
      return table
    }
                    

    // const number_of_transactions = users.length
    return (      
      <Layout>  
        <section className="inner_sction">
          <section className="table_sction accunt_table">
            <div className="container">
              <div className="table_sction_outer admin_index">
                {getData}
                <div className="table_inner">
                  <div className="table-responsive">
                    {isLoading == 0 && <div id="api_loader"><img src={loader_image} width="50" height="50" />Loading...</div> }
                    {isLoading == 1 &&  UserTable() }
                    {isLoading == 2 &&   <h1>Record Not Found</h1> }
                    {/* {number_of_transactions != 0 ? UserTable() : <div className="table"><h1>NO Transactions Found</h1></div>} */}
                    <div className="pagination_sction">
                      <ul className="pagination">
                        {total > 0 ? pagination() : ""}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </Layout>
    )
}

  export default reduxForm({
    form: 'searchForm' // a unique identifier for this form
  })(Currency)