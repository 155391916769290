import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form'
import { Layout } from '../common/Layout'
import loader_image from '../../images/loading.gif';
import { adminService, userService } from '../../_services';
import Modal from 'react-modal';
import Currency from './Currency';
import {Amountformatter} from '../../components/common/AmountFormatter';

const AmountLimit = (props: any) => {

    useEffect(() => {
      setisLoading(0);
      getCurrencies();
        getExchangeLimits();
    }, []);

    const [formVar, setFormVar] = useState({
        currencyId: "-1",
        currencyName: '',
        id:'',
        min: "",
        max: "",
        currencyIdError: "",
        minError: "",
        maxError: ""
    });


    const [currencyList, setCurrencyList] = useState([]) as any;
    const [isLoading, setisLoading] = useState(0);
    const [frequencyModal, setFrequencyModal] = useState(false);
    const [frequencyModalHeader, setFrequencyModalHeader] = useState('Add');
    const [exchangeLimits, setExchangeLimits] = useState([]);
    const [itemIndex, setItemIndex] = useState(-1);
    const [exchangeLimitCurrencyName, setExchangeLimitCurrencyName] = useState("Select Currency");
    const [frequencyModalLoading, setFrequencyModalLoading] = useState(false);
    const [tempCurrencyList, setTempCurrencyList] = useState([]) as any;
    const [tempArray, setTempArray] = useState([]) as any;
    const [tempForLoadingState, setTempForLoadingState] = useState(0);

    const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    const { handleSubmit } = props;


    const [formValidateVar, setFormvalidateVar] = useState({
        //isFrequencyValidate: false,
        isCourrencyValidate: false,
        isMinValidate: false,
        isMaxValidate: false,
      })



 const getCurrencies = () => {
        adminService.get_currencies().then(function (response) {
            setCurrencyList(response.data.data.currencies)
            tempCurrencyList(response.data.data.currencies)
        }).catch(function (error) {
            console.log(error);
        })
    }


    const getExchangeLimits = () => {
      var user_id:any = localStorage.getItem('user_id')
      if (user_id != null){
        adminService.get_amount_limits().then(function (response) {
            var data:any = response.data.data.flagsForAmountLimit
            if (data.length > 0){
              setisLoading(1);
            }else{
              setisLoading(2);
            }
            setExchangeLimits(data);
          })
          .catch(function (error) {
            setisLoading(3);
            //userService.handleError(error);
          });
      }
    }

    const newOrEditExchangeLimit = () => {
      if (validateForm()){
        setFrequencyModalLoading(true);
        var user_id:any = localStorage.getItem('user_id')
        if (user_id != null){
          var params:any =  {userId : user_id , currency : {id: formVar.currencyId}, startLimit : formVar.min, endLimit : formVar.max}
          console.log("submit", formVar);
          if (frequencyModalHeader == 'Add'){
            adminService.new_amount_limit(params).then(function (response) {
              let tmp_arry:any = [response.data.data.flagsForAmountLimit];
              setExchangeLimits(tmp_arry.concat(exchangeLimits));
              setFrequencyModal(false);
              setFrequencyModalLoading(false);
            })
            .catch(function (error) {
              setFrequencyModalLoading(false);
              userService.handleError(error);
            });
          }else{
            params["id"] = formVar.id;
            adminService.edit_amount_limit(params).then(function (response) {
              let tmp_arry:any = exchangeLimits;
              tmp_arry[itemIndex] = response.data.data.flagsForAmountLimit
              setExchangeLimits(tmp_arry);
              setFrequencyModal(false);
              setFrequencyModalLoading(false);
            })
            .catch(function (error) {
              setFrequencyModalLoading(false); 
              userService.handleError(error);
            });
          }
        }
      }
    }

    const validateForm = () => {
      var isValdate: any = true;
      if (frequencyModalHeader == 'Add' && formVar.currencyId == "-1") {
        isValdate = false;
        formVar.currencyIdError = "Please Select Currency"
      }
        
      if (formVar.min == "" || formVar.min == null) {
        isValdate = false;
        formVar.minError = "Please Enter Min"
      }
      else {
        if (!/^[0-9]+$/.test(formVar.min)) {
          isValdate = false;
          formVar.minError = "Please Enter Valid Min Value"
        }
      }

      if (formVar.max == "" || formVar.max == null) {
        isValdate = false;
        formVar.maxError = "Please Enter Max"
      }
      else {
        if (!/^[0-9]+$/.test(formVar.max)) {
          isValdate = false;
          formVar.maxError = "Please Enter Valid Max Value"
        }
        else {
          if (formVar.min >= formVar.max) {
            isValdate = false;
            formVar.maxError = "Max Time is Greater than Min Time"
          }
        }
      }
      setTempForLoadingState(tempForLoadingState + 1);
      return (isValdate);
    }
    
      const customStyles1 = {
        overlay : {
         display: "block" ,
         paddingRight: "15px",
         background: "#00000054"
        },
       
        content:{
          position: "fixed",
          top: "24px",
          right: "588px",
          bottom: "",
          left: "617px"
        }
      }; 

      function openFrequencyModal(type:any, item:any, index:any){
        var temp_arry:any = [];
        var status:any = false
        if (type == 'new'){
          setFrequencyModalHeader('Add')
          setFormVar({...formVar,
            currencyName: '',
            currencyId: "-1",
            min: "",
            max: "",
            currencyIdError: "",
            minError: "",
            maxError: ""
        });
        setFormvalidateVar({...formValidateVar,
          isMinValidate: false,
          isMaxValidate: false,
        });
        currencyList.map((currency:any)=>{
          status = false
          exchangeLimits.map((item: any) => {
            if (item.currency.name == currency.name) {
              status = true;
            }
          });
          if (status == false) {
            temp_arry.push(currency);
          }
        });
        setTempCurrencyList(temp_arry)
        }else{
          setExchangeLimitCurrencyName(item.currency.name)
          setItemIndex(index);
          setFrequencyModalHeader('Update')
          setFormVar({...formVar,
            id: item.id,
            currencyId: item.currency.id,
            currencyName: item.currency.name,
            min: item.startLimit,
            max: item.endLimit,
            currencyIdError: "",
            minError: "",
            maxError: ""
          });
          setFormvalidateVar({...formValidateVar,
            isMinValidate: true,
            isMaxValidate: true,
          });
        }
        
        setFrequencyModal(true);
      }

      const UserTable = () => {
        return (
                <table className="table">
                  <thead>
                    <tr>
                    <th>ID</th>
                    <th>CURRENCY</th>
                    <th>COUNTRY</th>
                    <th>MIN AMOUNT</th>
                    <th>MAX AMOUNT</th>
                    <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exchangeLimits && exchangeLimits.map((item:any, index:any) => 
                      <tr >
                        <td>{index +1}</td>
                        <td>{item.currency && <img src={item.currency.imagePath} style={ { width:`33px`, height: `33px` } }/>}{item.currency && item.currency.name}</td>
                        <td>{item.currency && item.currency.country}</td>
                        <td><Amountformatter amount={item.startLimit}/></td>
                        <td><Amountformatter amount={item.endLimit}/></td>
                        <td>
                          <div className="dropdown slect_profile">
                            <button className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">Select
                            <span className="caret"></span></button>
                            <ul className="dropdown-menu">
                            <li><a onClick={() => openFrequencyModal('edit', item, index)}>Edit</a></li>
                            {/* <li><a>Delete</a></li> */}
                            </ul>
                          </div>
                        </td>
                      </tr> 
                    )}
                  </tbody>
             </table>      
          )
  
      }

  return (
    <Layout>
      <section className="inner_sction">
        <section className="table_sction accunt_table">
          <div className="container-fluid">
            <div className="table_sction_outer admin_index loader_parent">
              <div className="table_inner">
                <div className="table-responsive">
                  <div className="top-sctin mbtm-0">
                    <h4>Amount Limit</h4>
                    <div className="form-group pull-right">
                      <a onClick={() => openFrequencyModal('new', 'no value', '-1')} id="add_rate_button" className="btn btn-primary"> Add Amount-Limit </a>
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  {isLoading == 0 && <div id="" className="loaderbox loginloader">
                      <div>
                        <img
                          className="bounce-2"
                          src={loader_image}
                          width="50"
                          height="50"
                        />
                        <p>Loading...</p>
                      </div>
                    </div> }
                  {isLoading == 1 && UserTable()}
                  {isLoading == 2 &&   <h1>Record Not Found</h1> }
                  {isLoading == 3 && <h1>Some Error Occur</h1>}
                </div>
                {/* <div className="pagination_sction">
                    <ul className="pagination">
                      {total > 0 ? pagination() : ""}
                    </ul>
                  </div> */}
              </div>
            </div>
          </div>
        </section>
      </section>

      <Modal
        ariaHideApp={false}
        isOpen={frequencyModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop edit_profile"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button type="button" className="close" onClick={() => setFrequencyModal(false)}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">{frequencyModalHeader} Amount-Limit</h3>
        </div>
        <div className="modal-body">
          <div className="user_request">
            <form onSubmit={handleSubmit((val: any) => newOrEditExchangeLimit())}>
              <div className="col-md-12">
                <div className="form-group ">
                  <label>Currency:</label>
                  {frequencyModalHeader == 'Add' ? 
                    <select className="form-control long-select" 
                    // onChange={(e: any) => validateFormCase('currency', e.target.value)} 
                    onChange={(e:any) => setFormVar({...formVar, currencyId: e.target.value, currencyIdError: ''})}
                    style={{ height: '56px' }}>
                      <option value="-1">Select Currency</option>
                      {tempCurrencyList && tempCurrencyList.length > 0 && tempCurrencyList.map((currency: any, index: any) => {
                        return (
                          <option value={currency.id}>{currency.name}</option>
                        )
                      })}
                    </select>
                    :
                    // <select className="form-control long-select" value={exchangeLimitCurrencyName} onChange={(e: any) => validateFormCase('edit_currency', e)} style={{ height: '56px' }}>
                    //   <option  id="-1" value="Select Currency">Select Currency</option>
                    //   {tempCurrencyList && tempCurrencyList.length > 0 && tempCurrencyList.map((currency: any, index: any) => {
                    //     return (
                    //       <option id={currency.id} value={currency.name}>{currency.name}</option>
                    //     )
                    //   })}
                    // </select>
                   
                    <div className="input_box">
                      <input
                        className="form-control form-control-two"
                        value={formVar.currencyName}
                        disabled
                      ></input>
                    </div>
                  }
                  {formVar.currencyIdError.length > 0 && (
                    <span style={{ color: "red" }}>{formVar.currencyIdError}</span>
                  )}
                </div>
                <div className="form-group ">
                  <label>Min Amount:</label>
                  <div className="input_box">
                    <input
                      className="form-control form-control-two"
                      value={formVar.min}
                      // onChange={(e: any) => validateFormCase('min', e.target.value)}
                      onChange={(e:any) => setFormVar({...formVar, min: e.target.value, minError: ''})}
                      placeholder="Enter Min"
                      maxLength={30}
                    ></input>
                    {formVar.minError.length > 0 && (
                      <span style={{ color: "red" }}>{formVar.minError}</span>
                    )}
                  </div>
                </div>
                <div className="form-group ">
                  <label>Max Amount:</label>
                  <div className="input_box">
                    <input
                      className="form-control form-control-two"
                      value={formVar.max}
                      // onChange={(e: any) => validateFormCase('max', e.target.value)}
                      onChange={(e:any) => setFormVar({...formVar, max: e.target.value, maxError: ''})}
                      placeholder="Enter Max"
                      maxLength={30}
                    ></input>
                    {formVar.maxError.length > 0 && (
                      <span style={{ color: "red" }}>{formVar.maxError}</span>
                    )}
                  </div>
                </div>
                <div className="bottom_btn">
                  <button className="btn btn-lg btn-primary btn-block">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        { frequencyModalLoading &&
          <div id="" className="loaderbox loginloader innerloader"><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div>
        }
      </Modal>
    </Layout>
  )
}

export default reduxForm({
    form: 'searchForm', // a unique identifier for this form
    enableReinitialize: true
})(AmountLimit)
