import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
// import store from '../../store';
import { Field, reduxForm, SubmissionError, change } from 'redux-form'

import Count from '../Count';
import Merchant from '../Merchant';
import { Container, Row, Col, Button } from 'react-bootstrap' 
import { Layout } from '../common/Layout'
import $ from 'jquery'; 
import { INVESTMENT_MANAGEMENT,TRANSACTION_MANAGEMENT,FRONT_BASE } from '../../config/config';
import axios from 'axios';
import loader_image from '../../images/loading.gif';
import Moment from 'react-moment';
// import * as moment from 'moment';
import * as moment from 'moment-timezone';
import jstz from 'jstz';
import { adminService, userService } from "../../_services";

interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
  }
  


const Merchanttopup = (props: any) => {
    const [ errormsg, setErrormsg ] = useState();
    const [ interest, setInterest ] = useState([]);
    const [ plans, setPlans ] = useState('');
    const [ isLoading, setIsloading ] = useState(0);
    const [ checked, setChecked ] = useState();
    const [ rateupdate, setRateupdate ] = useState(false);
    const [ rateid, setRateid ] = useState();
    const [ finalamount, setFinalamount ] = useState();


    useEffect(() => {
        getInterest();
    }, []);

      const getInterest = () => { 
        var investment_id = window.sessionStorage.getItem('investment_id');
        const access_token = localStorage.getItem('access_token');
        const user_id = localStorage.getItem('user_id');
        if (access_token) {
          console.log("submit start")
          let config1 = {
            headers: {
                'Content-Type': 'application/json',
                'deviceType':'Android',
                'appVersion' : '1.0',
                'Authorization' : 'Bearer '+access_token
            }
          } 
          axios.get(`${TRANSACTION_MANAGEMENT}/admin/getAdminNotifications?page=0`, config1).then(function (responsedata) { 
            console.log(responsedata.data.data)
            setInterest(responsedata.data.data.adminNotifications)
            console.log(responsedata.data.data.adminNotifications.length)
            if (responsedata.data.data.adminNotifications.length > 0){
              setIsloading(1)
            }else{              
              setIsloading(2)
            }
          }).catch(function (error) {
            console.log(error)
            setIsloading(3)
            setInterest([])
          })
        }


        // adminService
        // .subadmin_list(pageNumber)
        // .then(function (response) {
        //   console.log("subadmin_list-----", response.data);
        //   var data1 = response.data.subAdmins;
        //   console.log(data1);
        //   if (response.data.count > 0) {
        //     setisLoading(1);
        //   } else {
        //     setisLoading(2);
        //   }
        //   console.log(data1)
        //   setTotalPage(response.data.count);    
  
        //   setUsers(data1);
        // })
        // .catch(function (error) {
        //   setisLoading(3);
        //   setUsers([]);
        //   userService.handleError(error);
        // });


      }


    const [ currentPage, setCurrentPage ] = useState(0);
    const [ usersPerPage, setUsersPerPage ] = useState(20);
    const [ totalPage, setTotalPage ] = useState(1);

    const total = Math.round(totalPage/usersPerPage);
    

    const makeHttpRequestWithPage = async (pageNumber:any) => {
      console.log("total",total)
      
      if (pageNumber < 0){
        pageNumber = total-1
      }
      if (pageNumber == total){
        pageNumber = 0
      }



      adminService.subadmin_list(pageNumber)
      .then(function (response) {
      console.log(response)
      setInterest(response.data.adminNotifications)
      setCurrentPage(pageNumber)
      })
      .catch(function (error) {
        userService.handleError(error);
      });


      // console.log("pageNumber",pageNumber)
      // const access_token = localStorage.getItem('access_token');
      // const response = await fetch(`${TRANSACTION_MANAGEMENT}/admin/getAdminNotifications?page=${pageNumber}`, {
      //   method: 'GET',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'deviceType':'Android',
      //     'appVersion' : '1.0',
      //     'Authorization' : 'Bearer '+access_token
      // },
      // });
  
      // const data = await response.json();
      // console.log(data)
      // setInterest(data.data.adminNotifications)
      // setCurrentPage(pageNumber)
   
    }
  
    const pagination = () =>{
      let table = []
      table.push(<li><a  href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(currentPage-1)}>Previous</a></li>)
      for (let i = 0; i < total; i++) {
        console.log("i------------------->"+currentPage)
        // table.push(<span onClick={() => makeHttpRequestWithPage(i)} className="active">{i}</span>)
        table.push(<li className={ i == currentPage ? "active block_cursor_event" :""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}  >{i+1}</a></li>)
      }      
      table.push(<li><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(currentPage+1)}>Next</a></li>)
      return table
    }

      const timezone = jstz.determine();
      const tz = timezone.name()

    const UserTable = () => { 
        return (	
            <table className="table">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>MERCHANT NAME</th>
                    <th>CURRENCY</th>
                    <th>AMOUNT</th>
                    <th>CREATED AT</th>
                    {/* <th></th> */}
                    {/* <th>ACTION</th> */}
                </tr>
                </thead>
                <tbody>
                {interest.map((item: any, index: any) => {
                  console.log(item)
                  var aa = moment.tz(item.createdAt, tz)
                    return(
                      <tr key={index}>
                          <td>{index+1}</td>
                          <td>{item.merchantName}</td>
                          <td>{item.currency.name}</td>
                          <td>{item.amount}</td>
                          <td><Moment format="YYYY-MM-DD HH:mm"  withTitle>{aa}</Moment></td>                          
                      </tr>	
                    )
                  })}								  
                </tbody>
            </table>      
      )

  }





  const { error, handleSubmit, pristine, reset, submitting } = props
    return (
    <Layout>
        <section className="inner_sction">
            <section className="table_sction">
                <div className="container">
                    <div className="table_sction_outer investment_sction">
                        <div className="top-sctin profile_new">
                            <h4>Merchant Top Up</h4>
                        </div>
                        <div className="table_inner">
                            <div className="table-responsive">
                            {isLoading == 0 && <div id="api_loader"><img src={loader_image} width="50" height="50" />Loading...</div> }
                            {isLoading == 1 &&  UserTable() }
                            {isLoading == 2 &&   <h1>Record Not Found</h1> }
                            {isLoading == 3 &&   <h1>Some Error Occur</h1> }
                            <div className="pagination_sction">
                            <ul className="pagination">
                                {total > 0 ? pagination() : ""}
                            </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>    
    </Layout>
    )
}

export default reduxForm({
    form: 'searchForm', // a unique identifier for this form
    enableReinitialize : true
  })(Merchanttopup)