import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import account_logo from '../../images/logo.png';
import axios from 'axios';
import $ from 'jquery';
import OtpInput from 'react-otp-input';

interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const renderField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
  <div className={type=="password" ? "form-group pswd_sign" : "form-group"}>
      <label>{label}</label>
  
        <input {...input} placeholder={label} type={type} className="form-control" id={type=="password" ? "password_input" : "email_input"}/>
        {touched && error && <span>{error}</span>}
        {type=="password" && <span className="eye hide_pass"><img src="images/eye.png"/></span>}

  </div>
)

const CodeVerify = (props: any) => {
  const [ otp, setotp ] = useState('');

  return (
    <div>
      <section className="outer_section">
          <div className="container">
            <div className="col-md-12">
              <div className="inner_section code_verify_section">
                <div className="logo_form text-center">
                  <a href="/"><img src={account_logo}></img></a>
                  <p>Welcome to eFX</p>
                </div>
               <div className="main_form text-center">
                 <h1>Verification Code</h1>
                 <h3>Please type the verification code sent to</h3>
                 <h2>+91 987-6543-210 <a href="#"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</a></h2>
               
                <form>
                <div className="grid_box">

                  <OtpInput
                    value={otp}
                    numInputs={6}
                  />

                </div>

                <a href="#" className="resend_btn">Resend OTP</a>
                               
                <div className="bottom_btn"> 
                  <button type="submit">Confirm</button>                   
                </div>
                 
                </form>
              </div>
          
            </div>
          </div>
        </div>
      </section>

      <section className="footer text-center">
        <p>© 2022, All Rights Reserved.</p>
      </section>


    </div>
  )
}

export default reduxForm({
  form: 'loginForm'
})(CodeVerify)