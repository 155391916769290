import firebase from 'firebase';

// const config = {
// 	apiKey: "AIzaSyC4AYfvhc4vxPN35juNHITGdyT422wU6nw",
// 	authDomain: "projectefx-fd9ed.firebaseapp.com",
// 	// databaseURL: "https://projectefx-fd9ed.firebaseio.com",
// 	databaseURL: "https://projectefx-fd9ed-c8ef2.firebaseio.com",
// 	projectId: "projectefx-fd9ed",
// 	storageBucket: "",
// 	messagingSenderId: "968865274494",
// 	appId: "1:968865274494:web:afb8a7b54a04774174962f"
// };

var config = {
    apiKey: "AIzaSyA0awMy74b45hJyCJfntESfVEtv2D4pzB4",
    authDomain: "efx-app-7fbc6.firebaseapp.com",
    databaseURL: "https://efx-app-7fbc6-default-rtdb.firebaseio.com",
    projectId: "efx-app-7fbc6",
    storageBucket: "efx-app-7fbc6.appspot.com",
    messagingSenderId: "234809980996",
    appId: "1:234809980996:web:11f5586d5e95face32e411",
    measurementId: "G-RYGPH3DEY3"
  };

firebase.initializeApp(config);



export default firebase;