import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Field, reduxForm, SubmissionError } from "redux-form";
// import {config} from '../config/config'
import { USER_MANAGEMENT } from "../../config/config";
import { FRONT_BASE } from "../../config/config";
// import account_logo from '../images/account_logo.png';
import account_logo from "../../images/logo.png";
import axios from "axios";
import $ from "jquery";
import loader_image from "../../images/loading.gif";
import Modal from "react-modal";
import close_image from "../../../assets/images/close.png";

import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng, } from "react-google-places-autocomplete";
import "react-google-places-autocomplete/dist/index.min.css";
import { userService } from "../../_services";
import OtpInput from "react-otp-input";
// import Loader from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import '../../alltype.d.ts';
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  placeholder: string;
  value: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const CreateProfile = (props: any) => {
  const [users, setUsers] = useState("string");
  const [api_error, setApiError] = useState("string");
  const [errormsg, setErrormsg] = useState("");
  const [fieldtype, setFieldType] = useState("password");
  const [fieldtype2, setFieldType2] = useState("password");
  const [image, setimage] = useState("");
  const [loader, setLoader] = useState(0);
  const [loaderinfo, setLoaderinfo] = useState(0);
  const [passerror, setPasserror] = useState(0);
  // const [ address, setAddress ] = useState("");
  const [userdetail, setUserdetail] = useState(false);
  const [value, setValue] = useState(null);
  const [valueone, setValueone] = useState();
  const [latlng, setLatlng] = useState() as any;
  const [otpModal, setOtpModal] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [formVar, setFormVar] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    companyAddress: "",
    link: "",
    fullPhoneNumber: "",
    countryCode: "",
    phoneNumber: "",
    annualIncome: "",
    jobIndustry: "",
    jobTitle: "",
    address: "",
    isloading: false,
    workingHoursStart: "09:30 AM",
    workingHoursEnd: "10:30 PM",
    otp: "",
    latitude: "", // company address lat and lngs
    longitude: "",
    firstNameError: "",
    lastNameError: "",
    companyNameError: "",
    companyAddressError: "",
    linkError: "",
    fullPhoneNumberError: "",
    annualIncomeError: "",
    jobIndustryError: "",
    jobTitleError: "",
    addressError: "",
    countryId: -1,
    countryError: "",
    image: "",
    bankName:"",
    bankNameError:"",
    accountNumber:"",
    accountNumberError:""
  });

  useEffect(() => {
    getData();
    get_countries()
  }, []);

  const renderField = ({
    input,
    value,
    label,
    type,
    meta: { touched, error },
  }: fieldInterface) => (
    <div className="form-group">
      <label>{label}</label>
      <input
        {...input}
        placeholder={label}
        type={type}
        className="form-control"
      />
      {touched && error && <span className="error">{error}</span>}
    </div>
  );

  const renderCheckbox = ({ input, label, type }: any) => (
    <div className="group grup_chck">
      <label className="cb-container">
        <p>{label}</p> <input {...input} type={type} className="check" />
        <span className="checkmark"></span>
      </label>
    </div>
  );
  function get_countries() {
    userService.countries_list().then(function (response) {
      console.log("countries_list-----", response.data.data.countries);
      setCountryList(response.data.data.countries)
      // toast.success(response.data.message);

    })
      .catch(function (error) {
        console.log("error get_countries-------------------");
        // userService.handleError(error);
      });
    // api to get operational countries
  }


  function Userdetailmodal() {
    setUserdetail(true);
    setLoader(1);
  }

  function closeModal() {
    setUserdetail(false);
    setOtpModal(false);
    setLoader(1);
  }
  const validateForm = (data: any) => {

    // return true;
    let fields = data;
    let errors: any = [];
    let formIsValid = true;
    console.log("validateForm");
    console.log("fields", fields);
    console.log("formVar", formVar);

    if (formVar.firstName == "" || formVar.firstName == null) {
      formIsValid = false;
      //errors["firstName"] = "*Please enter your password.";
      setLoaderinfo(0);
      formVar.firstNameError = "Please Enter Valid First Name";
    }

    if (formVar.lastName == "" || formVar.lastName == null) {
      formIsValid = false;
      // localStorage.removeItem('err');
      //errors["lastName"] = "*Please enter your password.";
      setLoaderinfo(0);
      // throw new SubmissionError({ lastname: 'Please enter your lastname.', _error: 'Register failed!' })
      formVar.lastNameError = "Please Enter Valid Last Name";
    }

    if (formVar.countryId == -1) {
      formIsValid = false;
      setLoaderinfo(0);
      formVar.countryError = "Please select country";
    }


    if (formVar.companyName == "" || formVar.companyName == null) {
      formIsValid = false;
      // errors["password"] = "*Please enter your password.";
      setLoaderinfo(0);
      // throw new SubmissionError({ confirmpassword: 'Please confirm your password.', _error: 'Register failed!' })
      formVar.companyNameError = "Please Enter Valid Company Name";
    }

    if (formVar.bankName == "" || formVar.bankName == null) {
      formIsValid = false;
      // errors["password"] = "*Please enter your password.";
      setLoaderinfo(0);
      // throw new SubmissionError({ confirmpassword: 'Please confirm your password.', _error: 'Register failed!' })
      formVar.bankNameError = "Please Enter Valid Bank Name";
    }

    if (formVar.accountNumber == "" || formVar.accountNumber == null) {
      formIsValid = false;
      // errors["password"] = "*Please enter your password.";
      setLoaderinfo(0);
      // throw new SubmissionError({ confirmpassword: 'Please confirm your password.', _error: 'Register failed!' })
      formVar.accountNumberError = "Please Enter Valid Account Number";
    }



    if (formVar.companyAddress == "" || formVar.companyAddress == null) {
      formIsValid = false;
      setLoader(0);
      formVar.companyAddressError = "Please select address from google suggestions.";

    }

    if (formVar.companyAddress == "" || formVar.companyAddress == null) {
      formIsValid = false;
      // errors["password"] = "*Please enter your password.";
      setLoaderinfo(0);
      // throw new SubmissionError({ confirmpassword: 'Please confirm your password.', _error: 'Register failed!' })
      formVar.companyAddressError = "Please select address from google suggestions.";
    }

    if (formVar.link == "" || formVar.link == null) {
      formIsValid = false;
      // errors["password"] = "*Please enter your password.";
      setLoaderinfo(0);
      // throw new SubmissionError({ confirmpassword: 'Please confirm your password.', _error: 'Register failed!' })
      formVar.linkError = "Please Enter Valid Website Link";
    }

    // if (!formVar.phoneNumber) {
    //   formIsValid = false;
    //   // errors["password"] = "*Please enter your password.";
    //   setLoader(0);
    //   // throw new SubmissionError({ confirmpassword: 'Please confirm your password.', _error: 'Register failed!' })
    //   formVar.fullPhoneNumberError = "Please Enter Valid phone number";
    // }
    if (formVar.address == "" || formVar.address == null) {
      formIsValid = false;
      setLoaderinfo(0);
      formVar.addressError = "Please Enter Valid address";
    }
    // if (image == "" || image == undefined) {
    //   formIsValid = false;
    //   setLoader(0); 
    // }

    // if(fields["password"] !== fields["confirmpassword"]){
    //   formIsValid = false;
    //   setLoader(0)
    //   throw new SubmissionError({ confirmpassword: 'Password Not matched.', _error: 'Register failed!' })
    //   // setPasserrro(1)
    // }
    // if (!formVar.annualIncome) {
    //   formIsValid = false;
    //   setLoaderinfo(0);
    //   formVar.annualIncomeError = "Please enter valid Annual Income";
    // }
    // if (formVar.jobIndustry == "" || formVar.jobIndustry == null) {
    //   formIsValid = false;
    //   setLoaderinfo(0);
    //   formVar.jobIndustryError = "Please enter valid Job Industry";
    // }
    // if (formVar.jobTitle == "" || formVar.jobTitle == null) {
    //   formIsValid = false;
    //   setLoaderinfo(0);
    //   formVar.jobTitleError = "Please enter valid Job Industry";
    // }
    return formIsValid;
  };

  const submit = (values: any) => {
    //setLoader(1);
    return sleep(1000).then(() => {

      if (validateForm(values)) {
        setLoader(0);
        console.log("submit star0t");

        console.log("validation complete");
        const mobileNumber = parsePhoneNumber((String(formVar.fullPhoneNumber)));
        if (mobileNumber == undefined) {
        } else {
          console.log(mobileNumber.countryCallingCode);
          console.log(mobileNumber.nationalNumber);
          formVar.countryCode = mobileNumber.countryCallingCode;
          formVar.phoneNumber = mobileNumber.nationalNumber;
        }

        let user_id = JSON.parse(localStorage.getItem("user") as string).id;
        userService
          .send_otp(formVar, user_id)
          .then(function (response) {
            console.log("send_otp-----", response);

            let config1 = {
              headers: {
                'Accept': 'application/json',
                'deviceType': 'Android',
                'appVersion': '1.0',
                'Authorization': 'Bearer ' + localStorage.getItem("access_token")
              }
            }
            const formData = new FormData();
            formData.append('file', image)

            axios.post(`${USER_MANAGEMENT}/users/${user_id}/updateImage`, formData, config1).then(function (response) {
              $("#wait").css("display", "none");
              var pattern = new RegExp(
                /https\:\/\/s3\-(\w+)\.amazonaws\.com\/(\w+)\/(\w+)\/(\w+)$/i
              );
            }).catch(function (error) {
              console.log(error);
            })

            // otp model open
            setOtpModal(true);
            setLoader(1);
            // setLoader(1);
            // localStorage.setItem("user", JSON.stringify(response.data.user))
            // Userdetailmodal()
            // setLoaderstyle({display:"none"}) 
            // window.location.href = "/";
            //window.location.href = "/invite_user/"+job_id;
          })
          .catch(function (error) {
            // console.log("error",error); 
            // setLoaderstyle({display:"none"})
            userService.handleError(error);
          });

        // Userdetailmodal()
      }
    });
  };

  function create_profile() {
    return sleep(1000).then(() => {
      if (validateForm(formVar)) {
        console.log("submit start");
        setLoader(1);
        // setFormVar({
        //   ...formVar,
        //   isloading: true,
        // });
        //setLoader(1);
        console.log("validation complete");

        const mobileNumber = parsePhoneNumber(formVar.fullPhoneNumber);
        if (mobileNumber == undefined) {
        } else {
          console.log(mobileNumber.countryCallingCode);
          console.log(mobileNumber.nationalNumber);
          formVar.countryCode = mobileNumber.countryCallingCode;
          formVar.phoneNumber = mobileNumber.nationalNumber;
          // formVar.otp = "0000"
        }



        let user_id = JSON.parse(localStorage.getItem("user") as string).id;
        console.log("aaaa")
        userService
          .create_profile(formVar, user_id)
          .then(function (response) {
            console.log("create_profile-----", response);

            let config1 = {
              headers: {
                'Accept': 'application/json',
                'deviceType': 'Android',
                'appVersion': '1.0',
                'Authorization': 'Bearer ' + localStorage.getItem("access_token")
              }
            }
            const formData = new FormData();
            formData.append('file', image)

            axios.post(`${USER_MANAGEMENT}/users/${user_id}/updateImage`, formData, config1).then(function (response) {
              $("#wait").css("display", "none");
              var pattern = new RegExp(
                /https\:\/\/s3\-(\w+)\.amazonaws\.com\/(\w+)\/(\w+)\/(\w+)$/i
              );
            }).catch(function (error) {
              console.log(error);
            })

            setLoader(1);
            // localStorage.setItem("user", JSON.stringify(response.data.user))
            // Userdetailmodal()
            // setLoaderstyle({display:"none"})
            alert("Profile created successfully.");
            localStorage.clear();

            window.location.href = "/login";
            //window.location.href = "/invite_user/"+job_id;
          })
          .catch(function (error) {
            // console.log("error",error); 
            // setLoaderstyle({display:"none"})
            userService.handleError(error);
          });

        // Userdetailmodal()
      }
    });
  }

  const submit_old = (values: any) => {
    // setLoader(1);
    return sleep(1000).then(() => {
      if (validateForm(values)) {
        console.log("submit start");
        setLoader(0);
        console.log("validation complete");

        const mobileNumber = parsePhoneNumber(formVar.fullPhoneNumber);
        if (mobileNumber == undefined) {
        } else {
          console.log(mobileNumber.countryCallingCode);
          console.log(mobileNumber.nationalNumber);
          formVar.countryCode = mobileNumber.countryCallingCode;
          formVar.phoneNumber = mobileNumber.nationalNumber;
        }
        let user_id = JSON.parse(localStorage.getItem("user") as string).id;
        console.log("formVar------",formVar)
        userService
          .create_profile(formVar, user_id)
          .then(function (response) {
            console.log("create_profile-----", response);

            let config1 = {
              headers: {
                'Accept': 'application/json',
                'deviceType': 'Android',
                'appVersion': '1.0',
                'Authorization': 'Bearer ' + localStorage.getItem("access_token")
              }
            }
            const formData = new FormData();
            formData.append('file', image)

            axios.post(`${USER_MANAGEMENT}/users/${user_id}/updateImage`, formData, config1).then(function (response) {
              $("#wait").css("display", "none");
              var pattern = new RegExp(
                /https\:\/\/s3\-(\w+)\.amazonaws\.com\/(\w+)\/(\w+)\/(\w+)$/i
              );
            }).catch(function (error) {
              console.log(error);
            })
            setLoader(1);
            alert("Profile created successfully.");
            localStorage.clear();
            window.location.href = "/login";
          })
          .catch(function (error) {
            userService.handleError(error);
          });

        // Userdetailmodal()
      }
    });
  };

  function _handleImageChange(e: any) {
    e.preventDefault();


    let reader = new FileReader();
    let file = e.target.files[0];
    //if (valid_file_type(file)) {
    setimage(file);

    // }
    reader.onloadend = () => {
      console.log("onloadend");
    };
    reader.readAsDataURL(file);
  }
  // function valid_file_type() {
  //   let type_of_file = file.split("/")[1];
  //   if (
  //     type_of_file == "png" ||
  //     type_of_file == "jpg" ||
  //     type_of_file == "jpeg"
  //   ) {
  //     return true;
  //   } else { 
  //   }
  //   return false;
  // }

  function hidePassword(e: any) {
    if (e.target.id == "Confirm Password") {
      if (e.target.className == "password") {
        setFieldType2("text");
      } else {
        setFieldType2("password");
      }
    } else {
      if (e.target.className == "password") {
        setFieldType("text");
      } else {
        setFieldType("password");
      }
    }
  }

  const handleCompanyAddress = (e: any) => {
    { console.log("jinga lala", handleCompanyAddress) }
    geocodeByAddress(e.description)
      .then((results: any[]) => getLatLng(results[0]))
      .then(({ lat, lng }: any) =>
        // setLatlng({ lat, lng })
        setFormVar({
          ...formVar,
          companyAddress: e.description,
          companyAddressError: "",
          latitude: lat,
          longitude: lng,
        })
      );
    // console.log("e------", e);
    // setFormVar({
    //   ...formVar,
    //   companyAddress: e.description,
    //   companyAddressError: "",
    // });
  };

  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      background: "#00000054",
    },
  };

  const getData = () => {
    localStorage.removeItem("err");
  };
  const is_error = localStorage.getItem("err");
  const { error, handleSubmit, pristine, reset, submitting } = props;
  return (
    <div>
      <section className="outer_section">
        <div className="container">
          <div className="col-md-10 col-md-offset-1">
            {false ? (
              <div style={{ position: "fixed", top: "35%", left: "45%" }}>
                {/* <Loader type="Oval" color="#1aa79e" height={100} width={100} /> */}
              </div>
            ) : (
              <div className="inner_section signup_form">
                <div className="logo_form text-center">
                  <img src={account_logo} />
                  <p>Welcome to eFX</p>
                </div>
                <div className="main_form row">
                  <form onSubmit={handleSubmit(submit)}>
                    <div className="col-lg-4 col-md-5">
                      <div className="profl_chng text-center">
                        <div className="avatar-upload">
                          <div className="avatar-edit">
                            <input
                              type="file"
                              id="imageUpload"
                              onChange={(e) => _handleImageChange(e)}
                              accept=".png, .jpg, .jpeg"
                            />
                            <label htmlFor="imageUpload"></label>
                          </div>

                          <div className="avatar-preview">
                            <div
                              id="imagePreview"
                              style={{
                                backgroundImage: "url(../images/bg_img.png)",
                              }}
                            ></div>
                          </div>
                        </div>
                        <h4>
                          Upload Profile Picture <br />
                          or
                          <br />
                          Company Logo
                        </h4>
                        <h6>
                          * File format (jpg, jpeg,png) * Max size (1MB)
                          <br />* Best view pixels (800X800)
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-1 hidden-md"></div>
                    <div className="col-md-7">
                      <div className="">
                        <div className="">
                          <form onSubmit={handleSubmit(submit)}>
                            <label>First Name:</label>
                            <div className="input_box">
                              <input
                                onChange={(e: any) =>
                                  setFormVar({
                                    ...formVar,
                                    firstName: e.target.value,
                                    firstNameError: "",
                                  })
                                }
                                maxLength={30}
                                className="form-control form-control-two"
                                value={formVar.firstName}
                                placeholder="Enter First Name"
                              ></input>
                              {formVar.firstNameError.length > 0 && (
                                <span style={{ color: "red" }}>
                                  {formVar.firstNameError}
                                </span>
                              )}
                            </div>
                            <br />
                            <label>Last Name:</label>
                            <div className="input_box">
                              <input
                                onChange={(e: any) =>
                                  setFormVar({
                                    ...formVar,
                                    lastName: e.target.value,
                                    lastNameError: "",
                                  })
                                }
                                maxLength={30}
                                className="form-control form-control-two"
                                value={formVar.lastName}
                                placeholder="Enter Last Name"
                              ></input>
                              {formVar.lastNameError.length > 0 && (
                                <span style={{ color: "red" }}>
                                  {formVar.lastNameError}
                                </span>
                              )}
                            </div>
                            <br />
                            <div className="form-group ph_field">
                              <label>Phone Number:</label>
                              <PhoneInput
                                placeholder="Enter phone number"
                                value={formVar.fullPhoneNumber}
                                maxLength="20"
                                onChange={(e: any) =>
                                  setFormVar({
                                    ...formVar,
                                    fullPhoneNumber: e,
                                    fullPhoneNumberError: "",
                                  })
                                }
                              />
                              {formVar.fullPhoneNumberError.length > 0 && (
                                <span style={{ color: "red" }}>
                                  {formVar.fullPhoneNumberError}
                                </span>
                              )}
                            </div>
                            <div className="form-group ">
                              <label>Select country:</label>
                              <select value={formVar.countryId} className="form-control" style={{ height: '55.9px' }} onChange={(e: any) =>
                                setFormVar({ ...formVar, countryId: e.target.value, countryError: "" })
                              }>
                                <option value="-1">Country</option>
                                {countryList && countryList.length > 0 && countryList.map((item: any, index: any) => {
                                  return (
                                    <option value={item.id}>{item.name}</option>
                                  )
                                })}
                              </select>
                              {formVar.countryError.length > 0 && (
                                <span style={{ color: "red" }}>
                                  {formVar.countryError}
                                </span>
                              )}
                            </div>
                            <label>Bank Name:</label>
                            <div className="input_box">
                              <input
                                onChange={(e: any) =>
                                  setFormVar({
                                    ...formVar,
                                    bankName: e.target.value,
                                    bankNameError: "",
                                  })
                                }
                                maxLength={30}
                                className="form-control form-control-two"
                                value={formVar.bankName}
                                placeholder="Enter Bank Name"
                              ></input>
                              {formVar.bankNameError.length > 0 && (
                                <span style={{ color: "red" }}>
                                  {formVar.bankNameError}
                                </span>
                              )}
                            </div>
                            <br />

                            <label>Account Number:</label>
                            <div className="input_box">
                              <input
                                onChange={(e: any) =>
                                  setFormVar({
                                    ...formVar,
                                    accountNumber: e.target.value,
                                    accountNumberError: "",
                                  })
                                }
                                maxLength={30}
                                className="form-control form-control-two"
                                value={formVar.accountNumber}
                                placeholder="Enter Account Number"
                              ></input>
                              {formVar.accountNumberError.length > 0 && (
                                <span style={{ color: "red" }}>
                                  {formVar.accountNumberError}
                                </span>
                              )}
                            </div>

                            <label>Company Name:</label>
                            <div className="input_box">
                              <input
                                onChange={(e: any) =>
                                  setFormVar({
                                    ...formVar,
                                    companyName: e.target.value,
                                    companyNameError: "",
                                  })
                                }
                                maxLength={30}
                                className="form-control form-control-two"
                                value={formVar.companyName}
                                placeholder="Enter Company Name"
                              ></input>
                              {formVar.companyNameError.length > 0 && (
                                <span style={{ color: "red" }}>
                                  {formVar.companyNameError}
                                </span>
                              )}
                            </div>

                           
                            <br />
                            <label>Unit No, Floor:</label>
                            <div className="input_box">
                              <input
                                onChange={(e: any) =>
                                  setFormVar({
                                    ...formVar,
                                    address: e.target.value,
                                    addressError: "",
                                  })
                                }
                                maxLength={200}
                                className="form-control form-control-two"
                                value={formVar.address}
                                placeholder="Enter Unit No, Floor"
                              ></input>
                              {formVar.addressError.length > 0 && (
                                <span style={{ color: "red" }}>
                                  {formVar.addressError}
                                </span>
                              )}
                            </div>
                            <br />
                            <div className="form-group ">
                              <label>Company Company  Address:</label>
                              {console.log("jatt tinka", formVar.companyAddress)}
                              <GooglePlacesAutocomplete
                                onSelect={(e: any) => handleCompanyAddress(e)}

                                renderInput={(props) => (
                                  <div >
                                    <input
                                      // Custom properties
                                      onFocus={() => setFormVar({ ...formVar, companyAddress: '' })}
                                      inputClassName="form-control" style={{ width: '100%' }}
                                      {...props}
                                    />
                                  </div>
                                )}
                                // // selectProps={{
                                // //   value,
                                // //   onChange: setValue,
                                // // }}
                                // inputClassName="form-control"
                                placeholder='Enter Location'

                                initialValue={formVar.companyAddress}
                              />
                              {formVar.companyAddressError.length > 0 && (
                                <span style={{ color: "red" }}>
                                  {formVar.companyAddressError}
                                </span>
                              )}
                            </div>
                            <label>Website Link:</label>
                            <div className="input_box">
                              <input
                                onChange={(e: any) =>
                                  setFormVar({
                                    ...formVar,
                                    link: e.target.value,
                                    linkError: "",
                                  })
                                }
                                className="form-control form-control-two"
                                value={formVar.link}
                                placeholder="Enter Link"
                              ></input>
                              {formVar.linkError.length > 0 && (
                                <span style={{ color: "red" }}>
                                  {formVar.linkError}
                                </span>
                              )}
                            </div>
                            {/* <br />
                            <label>Annual Income:</label>
                            <div className="input_box">
                              <input
                                type="number"
                                onChange={(e: any) =>
                                  setFormVar({
                                    ...formVar,
                                    annualIncome: e.target.value,
                                    annualIncomeError: "",
                                  })
                                }
                                maxLength={30}
                                className="form-control form-control-two"
                                value={formVar.annualIncome}
                                placeholder="Enter Annual Income"
                              ></input>
                              {formVar.annualIncomeError.length > 0 && (
                                <span style={{ color: "red" }}>
                                  {formVar.annualIncomeError}
                                </span>
                              )}
                            </div> */}
                            {/* <br />
                            <label>Job Industry:</label>
                            <div className="input_box">
                              <input
                                onChange={(e: any) =>
                                  setFormVar({
                                    ...formVar,
                                    jobIndustry: e.target.value,
                                    jobIndustryError: "",
                                  })
                                }
                                maxLength={30}
                                className="form-control form-control-two"
                                value={formVar.jobIndustry}
                                placeholder="Enter Job Industry"
                              ></input>
                              {formVar.jobIndustryError.length > 0 && (
                                <span style={{ color: "red" }}>
                                  {formVar.jobIndustryError}
                                </span>
                              )}
                            </div> */}
                            {/* <br />
                            <label>Job Title:</label>
                            <div className="input_box">
                              <input
                                onChange={(e: any) =>
                                  setFormVar({
                                    ...formVar,
                                    jobTitle: e.target.value,
                                    jobTitleError: "",
                                  })
                                }
                                maxLength={30}
                                className="form-control form-control-two"
                                value={formVar.jobTitle}
                                placeholder="Enter Job Title"
                              ></input>
                              {formVar.jobTitleError.length > 0 && (
                                <span style={{ color: "red" }}>
                                  {formVar.jobTitleError}
                                </span>
                              )}
                            </div> */}
                            <br />
                            <div className="bottom_btn">
                              {/* <a href="let_start.html">Continue</a> */}
                              {/* <input type="submit" value="Submit" /> */}
                              <button
                                className="btn btn-lg btn-primary btn-block"
                                onClick={() => submit_old}
                              >
                                Continue
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="footer text-center">
        <p>© 2022, All Rights Reserved.</p>
      </section>

      <Modal
        ariaHideApp={false}
        isOpen={userdetail}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      // className="reset_password"
      >
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal">
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Sign Up Confirmation</h3>
        </div>
        <div className="modal-body">
          <div className="thank_content text-center">
            <h1>Thank You</h1>
            <h3>Your sign up has been successfull.</h3>
            <div className="bottom_btn">
              <a href="create_profile.html">Continue</a>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={otpModal}
        onRequestClose={closeModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      // className="reset_password"
      >
        <div className="modal-header">
          <button
            type="button"
            onClick={closeModal}
            className="close"
            data-dismiss="modal"
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Verification Code</h3>
        </div>
        <div className="modal-body">
          <div className="thank_content otp_content text-center">
            <h3>Please type the verfication sent to</h3>
            <OtpInput
              value={formVar.otp}
              numInputs={6}
              isInputNum={false}
              containerStyle={"otpp"}
              onChange={(e: any) =>
                setFormVar({
                  ...formVar,
                  otp: e,
                })
              }
            />
            <div className="bottom_btn">
              {/* <a href="create_profile.html">Confirm</a> */}
              <button
                onClick={create_profile}
                className="btn btn-lg btn-primary btn-block"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(CreateProfile);
