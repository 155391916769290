import React, { useEffect, useState } from "react";
import { Layout } from "../common/Layout";
import { Button } from "react-bootstrap";
import { adminService } from "../../_services";
import loader_image from "../../images/loading.gif";

const BannerImage = () => {
  const [isLoader, setIsLoader] = useState(false)
  const [bannerList, setBannerList] = useState([])
  const [bannerData, setBannerData] = useState({
    previewBanner: "",
    fullBanner: ""
  })
  const [errorbannerData, setErrorbannerData] = useState({
    previewBanner: "",
    fullBanner: ""
  })
  useEffect(() => {
    get_Banner()
  }, [])

  // get All Banner List
  function get_Banner() {
    setIsLoader(true)
    adminService.getAllBannerImage().then(function (response) {
      if (response?.status === 200) {
        setBannerList(response?.data?.list)
        setIsLoader(false)

      }
    })
      .catch(function (error) {
        console.log("error getAllBannerImage-------------------", error);
        setIsLoader(false)

      });
  }

  // delete banner List
  function getDeleteBanner(id: any) {
    setIsLoader(true)

    adminService.getDeleteBanner(id).then((response) => {
      if (response?.status == 200) {
        console.log("delete banner successfully")
        get_Banner()
        setIsLoader(false)

      }
    })
      .catch((error) => {
        console.log("error", error)
        setIsLoader(false)

      })
  }

  const handleDeleteBanner = (item: any) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this Banner?');
    if (isConfirmed) {
      getDeleteBanner(item.id);
    }
  };

  const handleFileChange = (event: any, type: any) => {
    const file = event.target.files[0];
    const validFormats = ['image/jpeg', 'image/png', 'image/jpg'];
    if (!validFormats.includes(file.type)) {
      alert("Please select a valid image file (JPEG, JPG, PNG).");
      return;
    }
    setBannerData((prevState) => ({ ...prevState, [type]: file }))
    setErrorbannerData((prevState) => ({ ...prevState, [type]: "" }))

  };

  const handleUpload = (e: any) => {
    if (!bannerData?.previewBanner) {
      setErrorbannerData((prevState) => ({ ...prevState, previewBanner: "Please select banner image" }))
    } if (!bannerData?.fullBanner) {
      setErrorbannerData((prevState) => ({ ...prevState, fullBanner: "Please select full image" }))
    } else if (bannerData?.previewBanner && bannerData?.fullBanner) {
      const formData = new FormData();
      formData.append("previewBanner", bannerData.previewBanner);
      formData.append("fullBanner", bannerData.fullBanner);
      setIsLoader(true)

      adminService.uploadBannerImage(formData)
        .then((response) => {
          get_Banner();
          setBannerData({
            previewBanner: "",
            fullBanner: ""
          })
          setIsLoader(false)

        })
        .catch((error) => {
          console.error("Error uploading banner image", error);
          setIsLoader(false)

        });
    }
  };




  return (
    <Layout>

      <section className="inner_sction">
        <section className="table_sction accunt_table">
          <div className="container-fluid">
            <div className="table_sction_outer admin_index loader_parent banner-image-content">
              <div className="top-sctin mbtm-0">
                <h4>Banner Image</h4>
              </div>
              <div className="banner-image-top-area">
                <div className="upload-image-outer">
                  <label>Upload Banner Image</label>

                  <div className="images-area-main">
                    <div className="images-area-main">
                      <div className="banner-image-upload">
                        <label htmlFor="banner-upload" className="custom-file-upload">
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </label>
                        <input id="banner-upload" type="file" onChange={(e) => handleFileChange(e, "previewBanner")} />
                      </div>
                    </div>

                    <div className="images-preview">
                      {bannerData?.previewBanner &&
                        <div className="image-preview-inner">
                          <img src={URL.createObjectURL(bannerData.previewBanner)} />
                          {/* <i className="fa fa-trash-o" aria-hidden="true" 
                        ></i> */}
                        </div>
                      }
                    </div>

                  </div>
                  {
                    errorbannerData.previewBanner &&
                    <p className="error">{errorbannerData.previewBanner}</p>
                  }
                </div>

                <div className="upload-image-outer">
                  <label>Upload Full Image</label>

                  <div className="images-area-main">
                    <div className="images-area-main">
                      <div className="banner-image-upload">
                        <label htmlFor="banner-upload" className="custom-file-upload">
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </label>
                        <input id="banner-upload" type="file"
                          onChange={(e) => handleFileChange(e, "fullBanner")} />
                      </div>

                    </div>

                    <div className="images-preview">
                      {bannerData?.fullBanner &&
                        <div className="image-preview-inner">
                          <img src={URL.createObjectURL(bannerData?.fullBanner)} />
                        </div>
                      }
                    </div>
                  </div>
                  {
                    errorbannerData.fullBanner &&
                    <p className="error">{errorbannerData.fullBanner}</p>
                  }
                </div>
              </div>
              <div className="">
              </div>
              <Button type="button" variant="unset" onClick={(e) => handleUpload(e)}>Upload</Button>
              <div className="banner-listing">
                <h6>Banner Listing</h6>
                <div className="banner-listing-outer">
                  {bannerList?.map((item: any, index: any) => {
                    return (

                      <div className="banner-listing-image">
                        <div className="image-text">
                          <h6>Banner Image</h6>
                          <img src={item?.previewPath} />
                        </div>
                        <div className="image-text">
                          <h6>Full Image</h6>
                          <img src={item?.fullPath} />
                        </div>


                        <i className="fa fa-trash-o" aria-hidden="true" onClick={(e) => handleDeleteBanner(item)}></i>
                      </div>

                    )
                  })}
                </div>
              </div>
            </div>
            {/* <Modal
          show={true}
          onHide={() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
      <Modal.Body>
        <div>
        <ImageCropper
            imageToCrop={selectedFile}
            onImageCropped={(croppedImage: any, cropped_image: any) => {
              // if (croppedImage) {
              //   set_status_modal(1)
              // }
              // formData.coverImage = new File([cropped_image], new Date().toString() + '.jpeg', { type: "image/jpeg" })
              // setIsimagePreview(croppedImage);
              // formData.cover_videoshow = croppedImage;
              // setVidErr("")
              setModalShow(false)
            }}
            className="imgviewcrop"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setModalShow(false)}>Close</Button>
      </Modal.Footer>
    </Modal> */}
          </div>
        </section>



      </section>
      {isLoader &&
        <div id="" className="loaderbox loginloader"><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div>

      }
    </Layout>
  );
};
export default BannerImage;