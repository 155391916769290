import React, { useEffect, useState } from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { Field, reduxForm, SubmissionError } from 'redux-form'
// import {config} from '../config/config'
import { USER_MANAGEMENT } from '../../config/config';
import { FRONT_BASE } from '../../config/config';
// import account_logo from '../images/account_logo.png';
import account_logo from '../../images/logo.png';
import axios from 'axios';
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}



const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))


const renderField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
  <div className="form-group">
    <label>{label}</label>

    <input {...input} placeholder={label} type={type} className="form-control" />
    {touched && error && <span>{error}</span>}

  </div>
)

const renderCheckbox = ({ input, label, type }: any) => (
  <div className="group grup_chck">
    <label className="cb-container"><p>{label}</p>  <input {...input} type={type} className="check" /><span className="checkmark"></span></label>

  </div>
)

const Forgetpassword = (props: any) => {

  const [users, setUsers] = useState("string");
  const [errormsg, setErrormsg] = useState("");
  const [fieldserror, setFieldserror] = useState("");
  const [successmsg, setSuccessmsg] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    getData();
    localStorage.removeItem('err');
  }, []);

  const submit = (values: any) => {
    return sleep(1000).then(() => {
      console.log("infos")
      console.log(values.useremail)
      const access_token = localStorage.getItem('access_token');
      if (validateForm(values)) {
        console.log("submit start")
        let config1 = {
          headers: {
            'Content-Type': 'application/json',
            'deviceType': 'Android',
            'appVersion': '1.0'
          }
        }
        console.log("submit start end")
        axios.get(`${USER_MANAGEMENT}/users/forgotPassword?email=${email}`, config1).then(function (response) {

          console.log(response)
          // window.location.href = `${FRONT_BASE}`; 
          setSuccessmsg("An email is sent at your email address. Follow instructions in that mail to reset password.")
          setUsers("message")
          setTimeout(() => {
            setSuccessmsg('')
          }, 2000);

        }).catch(function (error) {
          setErrormsg(error.response.data.message)
          setTimeout(() => {
            setErrormsg('')
          }, 2000);
          console.log(error);
          setUsers("error")
        })
      }

    })
  }

  const validateForm = (data: any) => {
    let formIsValid: any = true;
    if (email == '') {
      formIsValid = false;
      setEmailError("Please Enter Email");
    } else {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(email)) {
        formIsValid = false;
        setEmailError("Please Enter Valid Email");
      }
    }
    return formIsValid;
  }

  function setEmailData(value: any) {
    setEmail(value);
    setEmailError('');
  }

  const getData = () => {
    localStorage.removeItem('err');
  }
  const is_error = localStorage.getItem('err');
  const { error, handleSubmit, pristine, reset, submitting } = props
  return (
    <div>
      <section className="outer_section">
        <div className="container">
          <div className="col-md-6 col-md-offset-3">
            <div className="inner_section">
              <div className="logo_form text-center">
                <a href="/"><img src={account_logo}></img></a>
                <p>Forgot Password?</p>
              </div>
              <div className="main_form">
                <div className="forgt_text text-center">
                  <h4>Enter the email address you registered with. We’ll send you an email that will let you choose another password. </h4>
                </div>
                {errormsg != "" && <h4 className="error">{errormsg}</h4>}
                {successmsg != "" && <h4 className="success-msg">{successmsg}</h4>}
                <form onSubmit={handleSubmit(submit)}>
                  <div className="form-group ">
                    <label>Email:</label>
                    <div className="input_box">
                      <input
                        className="form-control form-control-two"
                        value={email}
                        onChange={(e: any) => setEmailData(e.target.value)}
                        placeholder="Enter Email"
                        maxLength={64}
                      ></input>
                      {emailError.length > 0 && (
                        <span style={{ color: "red" }}>{emailError}</span>
                      )}
                    </div>
                  </div>
                  <div className="bottom_btn">
                    <button type="submit" disabled={submitting}>Reset Password</button>
                  </div>
                </form>
                <div className="bottom_links forgot_back text-center">
                  <a href="/login">Back to Login</a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section className="footer text-center">
        <p>© 2022, All Rights Reserved.</p>
      </section>
    </div>
  )
}

export default reduxForm({
  form: 'loginForm' // a unique identifier for this form
})(Forgetpassword)