import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col, Form, Button } from 'react-bootstrap' 
import { Field, reduxForm, SubmissionError } from 'redux-form'
// import {config} from '../config/config'
import { USER_MANAGEMENT } from '../../config/config';
import { FRONT_BASE } from '../../config/config';
// import account_logo from '../images/account_logo.png';
import account_logo from '../../images/logo.png';
import Footer from '../common/Footer';
import axios from 'axios';
import loader_image from "../../images/loading.gif";
import check_image from "../../images/check.png";

import Modal from 'react-modal';

interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
}

  
const renderField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
  <div className="form-group">
      <label>{label}</label>
      
        <input {...input} placeholder={label} type={type} className="form-control" />
        {touched && error && <span style={{color:"red"}}>{error}</span>}
      
    </div>
  )

const renderCheckbox = ({ input, label, type }:any) => (
  <div className="group grup_chck">
    <label className="cb-container"><p>{label}</p>  <input {...input} type={type} className="check"/><span className="checkmark"></span></label>
   
  </div>
)
 
  const Changepassword = (props: any) => {
    const [ users, setUsers ] = useState("string");
    const [ successmess, setSuccessMsg ] = useState("");


    useEffect(() => {
      getData();
     
    }, []);


    const getData = () => {    
      localStorage.removeItem('err'); 
      localStorage.removeItem('success'); 
    }  

const[passwordUpdatedVar, setPasswordUpdatedVar] = useState({
isPasswordUpdated: false,
responseMessage: ""
});

const [formVar, setFormVar] = useState({
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
  oldPasswordError: "",
  newPasswordError: "",
  confirmPasswordError: "",
  isOldPasswordValidate: false,
  isNewPasswordValidate: false,
  isConfirmPassworValidate: false
});

const [responseModal, setResponseModal] = useState(false);

const [isLoading, setisLoading] = useState(false);

const validateFormCase = (type:any, value:any) => {
  switch(type) {
    case 'old_password':{
      if (value == "" || value == null) {
        setFormVar({ ...formVar, oldPassword: "", oldPasswordError: "Please Enter Old-Password", isOldPasswordValidate: false  });
      }else {
          setFormVar({ ...formVar, oldPassword: value,  oldPasswordError: "", isOldPasswordValidate: true });
      }
      break;
    }
    case 'new_password':{
      if (value == "" || value == null) {
        setFormVar({ ...formVar, newPassword: "", newPasswordError: "Please Enter New-Password", isNewPasswordValidate: false  });
      }else {
        if (!/^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{9,}$/.test(value)) {
          setFormVar({ ...formVar, newPassword: value, newPasswordError: "Password Contain Atleast 9 Characters, 1-Letter and 1-Number", isNewPasswordValidate: false  });
        }else{
          setFormVar({ ...formVar, newPassword: value,  newPasswordError: "", isNewPasswordValidate: true});
        }
      }
      break;
    }
   
    case 'confirm_password':{
      if (value == "" || value == null) {
        setFormVar({ ...formVar, confirmPassword: "", confirmPasswordError: "Please Enter Your Confirm-Password", isConfirmPassworValidate: false });
      }else {
        if (value != formVar.newPassword){
          setFormVar({ ...formVar, confirmPassword: value, confirmPasswordError: "New-Password and Confirm-Password doesn't match", isConfirmPassworValidate: false });
        }else{
          setFormVar({ ...formVar, confirmPassword: value, confirmPasswordError: "", isConfirmPassworValidate: true });
        }
      }
      break;
    }
    
  }
}
const validateForm = () => {
  if (!formVar.isOldPasswordValidate){
    validateFormCase('old_password', formVar.oldPassword);
    return false;
  }else{
      if(!formVar.isNewPasswordValidate){
        validateFormCase('new_password', formVar.newPassword);
        return false;
      }else{
        if (!formVar.isConfirmPassworValidate){
          validateFormCase('confirm_password', formVar.confirmPassword);
          return false;
        }
        return true
      }
    }
  }

    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

    const submit = (values:any) => {
         console.log("infos")
        console.log(values)
      const access_token = localStorage.getItem('access_token');
      const user_id =   localStorage.getItem('user_id');
     if (validateForm()) {
      setisLoading(true);
        console.log("submit start")
        let config1 = {
          headers: {
              'Content-Type': 'application/json',
              'deviceType':'Android',
              'appVersion' : '1.0',
              'Authorization' : 'Bearer '+access_token
          }
        }
        console.log("submit start end")
        axios.post(`${USER_MANAGEMENT}/users/${user_id}/changePassword`, {
            currentPassword: formVar.oldPassword,
            newPassword: formVar.newPassword
           
        }, config1).then(function (response) {
          setPasswordUpdatedVar({...passwordUpdatedVar, isPasswordUpdated: true, responseMessage: response.data.message});
          openResponseModal();
          sleep(2000).then(() => {redirectToLogIn() });
          
        }).catch(function (error) {
          console.log("aaaaa")
          openResponseModal();
          setisLoading(false);
          if (error == "Error: Request failed with status code 406"){
            setPasswordUpdatedVar({...passwordUpdatedVar, isPasswordUpdated: false, responseMessage: "Wrong Old Password"});
          }
          else{
            setPasswordUpdatedVar({...passwordUpdatedVar, isPasswordUpdated: false, responseMessage: "Something Went Wrong"});
          }
          sleep(2000).then(() => {closeResponseModal() });
          
      })
     }
    }

    const customStyles1 = {
      overlay : {
       display: "block" ,
       paddingRight: "15px",
       background: "#00000054"
      },
     
      content:{
        position: "fixed",
        top: "24px",
        right: "588px",
        bottom: "",
        left: "617px"
      }
    };

    function openResponseModal(){
      setResponseModal(true);
    }
    function closeResponseModal() {
        setisLoading(false);
        setResponseModal(false);
    }

    function redirectToLogIn() {
      localStorage.clear();
      setResponseModal(false);
      sleep(1500).then(() => {window.location.href = "/login" });
    }

    const success_mess =  localStorage.getItem('success');
    const is_error2 =  localStorage.getItem('current_pwd_error');
    const is_error = localStorage.getItem('err');
      console.log("success_mess")
    console.log(users)

    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
    <div>
     <section className="outer_section">
          <div className="container">
            <div className="col-md-6 col-md-offset-3">
              <div className="inner_section">
                <div className="logo_form text-center">
                  <a href="/"><img src={account_logo}></img></a>
                  <p>Change Password</p>
                </div>
                <div className="main_form">
                  {successmess == "current_pwd_error" && <h4 className="error">Current password is not correct.</h4>}
                  {successmess == "done" && <h4 className="error">Password Changed Successfully</h4>}
                  <form onSubmit={handleSubmit(submit)}>


                  <div className="form-group ">
                  <label>Old Password:</label>
                  <div className="input_box">
                    <input
                      type="password"
                      className="form-control form-control-two"
                      value={formVar.oldPassword}
                      onChange={(e: any) => validateFormCase('old_password', e.target.value)}
                      placeholder="Enter Old Password"
                    ></input>
                    {formVar.oldPasswordError.length > 0 && (
                      <span style={{ color: "red" }}>
                        {formVar.oldPasswordError}
                      </span>
                    )}
                  </div>
                  </div>

                  <div className="form-group ">
                    <label>New Password:</label>
                    <div className="input_box">
                      <input
                        type="password"
                        className="form-control form-control-two"
                        value={formVar.newPassword}
                        onChange={(e: any) => validateFormCase('new_password', e.target.value)}
                        placeholder="Enter New Password"
                      ></input>
                      {formVar.newPasswordError.length > 0 && (
                        <span style={{ color: "red" }}>
                          {formVar.newPasswordError}
                        </span>
                      )}
                      </div>
                  </div>

                  <div className="form-group ">
                    <label>Confirm Password:</label>
                    <div className="input_box">
                      <input
                        type="password"
                        className="form-control form-control-two"
                        value={formVar.confirmPassword}
                        onChange={(e: any) => validateFormCase('confirm_password', e.target.value)}
                        placeholder="Enter Confirm Password"
                      ></input>
                      {formVar.confirmPasswordError.length > 0 && (
                        <span style={{ color: "red" }}>
                          {formVar.confirmPasswordError}
                        </span>
                      )}
                      </div>
                  </div>
                  <div style={{ color: 'grey', marginTop: '10px'}}><p>NOTE- Password Must Contain Atleast <span style={{color: '#4097e4'}}>9 Characters</span>, Containing <span style={{color: '#4097e4'}}>1-Letter</span> and <span style={{color: '#4097e4'}}>1-Number</span>.</p></div>
                  <br/>
                  <div className="bottom_btn"> 
                    <button type="submit" disabled={submitting}>Submit</button>                   
                  </div>
                </form>
              </div>
          
            </div>
          </div>
        </div>
      </section>

      <Modal
      ariaHideApp={false}
      isOpen={responseModal}
      // style={customStyles1}
      className={"modal-content custom_modal thakyou_pop"}
      contentLabel="Example Modal"
      overlayClassName="Overlay"
      >
        {/* {passwordUpdatedVar.isPasswordUpdated && 
      <div className="modal-header">
        <button type="button" className="close" onClick={()=> closeResponseModal()} ><i className="fa fa-times" aria-hidden="true"></i></button>
      </div>
  } */}
      <div className="modal-body text-center">
        {passwordUpdatedVar.isPasswordUpdated && <img src={check_image} style={{width: '80px', borderRadius: '40px'}}></img>}
       <h3>{passwordUpdatedVar.responseMessage}</h3>
      </div>

    </Modal>

      {isLoading && (
      <div id="" className="loaderbox loginloader">
        <div>
          <img
            className="bounce-2"
            src={loader_image}
            width="50"
            height="50"
          />
          <p>Loading...</p>
        </div>
      </div>
    )}
       <Footer/>
</div>
       )
  }
  
  export default reduxForm({
    form: 'loginForm' // a unique identifier for this form
  })(Changepassword)