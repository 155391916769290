import React, { useEffect, useState } from 'react';
import logo from '../../images/logo.png';
import user_image from '../../images/user_img_default.png';
import loader_image from '../../images/loading.gif';
import { adminService, userService } from "../../_services";

import { Col, Row, Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Link } from "react-router-dom";
import axios from 'axios';
import { USER_MANAGEMENT, FRONT_BASE, EXCHANGE_MANAGEMENT, TRANSACTION_MANAGEMENT } from '../../config/config';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface fieldInterface {
  input: any;
  type: string;
  label: string;
  id: string;
  accept: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const logout = () => {
  // localStorage.removeItem('user_type'); 
  // localStorage.removeItem('access_token');
  // localStorage.removeItem('user_role');
  // localStorage.removeItem('user_id');
  // localStorage.removeItem('lsid');
  // localStorage.removeItem('tocurrency');
  // localStorage.removeItem('fromcurrencyvalue');
  // localStorage.removeItem('currencyTovalue');
  // localStorage.removeItem('user_image');
  // localStorage.removeItem('current_path');
  // localStorage.removeItem('err');
  // window.sessionStorage.removeItem('investment_id');
  // window.sessionStorage.removeItem('investment_id_name');
  // localStorage.clear();
  // window.location.href = '/login'; 
}

const login = () => {
  window.location.href = '/login';
}

const AdminHeader = (props: any) => {
  var access_token = localStorage.getItem('access_token');
  const user_role = localStorage.getItem('user_role');
  const admin_user_country = localStorage.getItem('admin_user_country');
  const user_permissions = localStorage.getItem('user_permissions');
  const { title } = props
  const [image, setimage] = useState() as any;
  const [cover_pic, setcover_pic] = useState() as any;
  const [delete_msg, setdelete_msg] = useState() as any;
  const [currentpath, setcurrentpath] = useState();
  const [allcurrencies, setallcurrencies] = useState() as any;

  const [errormsg, setErrormsg] = useState() as any;
  const [currencyFromId, setCurrencyFromId] = useState();
  const [countries, setCountries] = useState([]);
  const [isLoading, setisLoading] = useState(0);


  var currentpathval = window.location.pathname
  console.log(currentpathval)

  const change_to_permission_name = () => {
    if (user_permissions == "GROUP_LEVEL") {
      return "";
    } else if (user_permissions == "ADMIN") {
      return "Admin";
    } else if (user_permissions == "COMPLIANCE") {
      return "Compliance";
    } else if (user_permissions == "OPERATION") {
      return "Operation";
    } else if (user_permissions == "GUEST") {
      return "Guest";
    } else {
      return "-";
    }
  }

  return (

    <section className="header admin_header" id="myHeader">
      <nav className="navbar">
        <div className="container-fluid">
          <div className="header_postn">
            <div className="navbar-header">
              <button type="button" className="navbar-toggle sidebarCollapse">
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a className="navbar-brand" href="/"><img src={logo} alt="logo" /></a>
            </div>

            <div className="page_name">
              <h3>{title}</h3>
            </div>
            {user_role !=="KYCADMIN" &&
            <div className="user_role">
                <h3>{change_to_permission_name()} {(admin_user_country === '' || admin_user_country === null) ? '' : ' - ' + admin_user_country} </h3>
            </div>}

            <div className="profile_optn">
              <ul>
                {access_token ?

                  <li className="dropdown">
                    {/* <a className="dropdown-toggle" data-toggle="dropdown" href="#" ><img src={cover_pic == "null" ? user_image  : cover_pic}  alt="logo" /><p>Menu<span className="caret"></span></p>
                    </a> */}
                    <a className="dropdown-toggle" data-toggle="dropdown" href="#" ><img src={user_image} alt="logo" /><p>Menu<span className="caret"></span></p>
                    </a>
                    <ul className="dropdown-menu">

                      {/* {user_role == "ADMIN" && <li><a data-toggle="modal" data-target="#editprofilepic"><i className="fa fa-user" aria-hidden="true"></i>&nbsp;Profile</a></li>}                      <li><a href="/change_password"><i className="fa fa-lock" aria-hidden="true"></i>&nbsp;Change Password</a></li> */}


                      {/* {user_role == "ADMIN" && <li><a href='/profile' ><i className="fa fa-user" aria-hidden="true"></i>&nbsp;Profile</a></li>} */}




                      {/* {user_role == "ADMIN" && <li><a href='/currency-pairing' ><i className="fa fa-usd" aria-hidden="true"></i>&nbsp;Currency Pairing</a></li>} */}
                      {/* {user_role == "MERCHANT" && <li><a href='/profile' ><i className="fa fa-user" aria-hidden="true"></i>&nbsp;Profile</a></li>} */}

                      {/* {user_role == "ADMIN" && <li><a className="change_cursor_to_pointer" data-toggle="modal" data-target="#pass_verifiedqueue"><i className="fa fa-th-list" aria-hidden="true"></i>&nbsp;Delete Queue Orders</a></li>} */}
                      {/* { access_token && user_role == "ADMIN" && <li className={currentpathval == '/merchant/topup' ? "active" : ""}><a href="/merchant/topup"><i className="fa fa-address-card" aria-hidden="true"></i> Merchants Top Up</a></li>} */}
                      {/* { access_token && user_role == "ADMIN" && <li className={currentpathval == '/admin/view-currencies' ? "active" : ""}><a href="/admin/view-currencies"><i className="fa fa-money" aria-hidden="true"></i>View Currencies</a></li>} */}
                      <li><a href="/change_password"><i className="fa fa-lock" aria-hidden="true"></i>&nbsp;Change Password</a></li>
                      <li><a href='javascript:void(0)' onClick={logout} id="logout-btn"><i className="fa fa-sign-out" aria-hidden="true"></i>&nbsp;Logout</a></li>

                    </ul>

                  </li>
                  :
                  <li className="dropdown">
                    <a href='javascript:void(0)' onClick={login} className="dropdown-toggle" data-toggle="dropdown"><img src={user_image} alt="logo" /><p>Login</p>
                    </a>
                  </li>
                }
              </ul>
            </div>
          </div>

        </div>
      </nav>
      <ToastContainer />
    </section>


  )
}


export default AdminHeader
