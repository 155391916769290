import React, {useEffect, useState} from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Layout } from '../common/Layout';


export const DataHandling = (props: any) => {
  const { error, handleSubmit, pristine, reset, submitting } = props
  if(props.data == null || props.data == "null null"){
    return "-"
  }else{
    return (
    
      props.data ? props.data : "-"
    )
  }
}






export function HandlePermission(permission_type: any , feature:any,action_type:any = "") {
//  const { error, handleSubmit, pristine, reset, submitting } = props
 let user_permissions =  localStorage.getItem("user_permissions")
 let user_role = localStorage.getItem("user_role");
 if(feature == "partners"){

  if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN" || user_permissions == "OPERATION" ){
     return true
   }else{
     return false
   }
  }
  else if(feature == "ekyc_settings"  ){
    if(permission_type == "edit"){
      if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN" || user_permissions == "COMPLIANCE" ){
        return true
      }else{
        return false
      }
    }else{
      // for view
      if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN" || user_permissions == "COMPLIANCE" || user_permissions == "OPERATION" || user_permissions == "GUEST"  ){
        return true
      }else{
        return false
      }
    }
 }
 else if(feature == "countries"){

  if(user_permissions == "GROUP_LEVEL"  ){
     return true
   }else{
     return false
   }
}




else if(feature == "frequency"){

  if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN" || user_permissions == "COMPLIANCE" ){
     return true
   }else{
     return false
   }
}






 else if(feature == "expired_transactions" ){
  if(permission_type == "edit"){
    if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN" || user_permissions == "OPERATION" ){
      return true
    }else{
      return false
    }
  }else{
    // for view
    if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN" || user_permissions == "COMPLIANCE" || user_permissions == "OPERATION" || user_permissions == "GUEST"  ){
      return true
    }else{
      return false
    }
  }
}

 else if(feature == "account"){
  if(permission_type == "edit"){
    if(user_permissions == "GROUP_LEVEL" || user_role=="KYCADMIN" ){
      return true
    }else{
      return false
    }
  }else{
    // for view
    if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN" || user_permissions == "COMPLIANCE" || user_permissions == "OPERATION" || user_permissions == "GUEST"   ){
      return true
    }else{
      return false
    }
  }
}
else if(feature == "accounts_advance_fetures"){
  if(permission_type == "view"){
    if(user_permissions == "GUEST" || user_permissions == "COMPLIANCE" || user_permissions == "OPERATION"){
      return true
    }else{
      return false
    }
  }else{
    if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN" ){
      return true
    }else{
      return false
    }
  }
}
 else if(feature == "commission"){
  if(permission_type == "edit"){
    if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN" || user_permissions == "OPERATION" ){
      return true
    }else{
      return false
    }
  }else{
    // for view
    if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN"  || user_permissions == "OPERATION" || user_permissions == "GUEST"  ){
      return true
    }else{
      return false
    }
  }
}
else if(feature == "collection_days"){
  if(permission_type == "edit"){
    if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN" || user_permissions == "OPERATION" ){
      return true
    }else{
      return false
    }
  }else{
    // for view
    if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN"  || user_permissions == "OPERATION" || user_permissions == "GUEST"  ){
      return true
    }else{
      return false
    }
  }
}

else if(feature == "suspicious_transactions"){
  if(permission_type == "edit"){
    if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN" || user_permissions == "COMPLIANCE" || user_permissions == "OPERATION"  ){
      return true
    }else{
      return false
    }
  }else{
    // for view
    if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN" || user_permissions == "COMPLIANCE" || user_permissions == "OPERATION" || user_permissions == "GUEST"  ){
      return true
    }else{
      return false
    }
  }
}

else if(feature == "branches" || feature == "rate_inventory" ){
  if(permission_type == "edit"){
    if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN" || user_permissions == "OPERATION" ){
      return true
    }else{
      return false
    }
  }else{
    // for view
    if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN"  || user_permissions == "COMPLIANCE" || user_permissions == "OPERATION" || user_permissions == "GUEST"  ){
      return true
    }else{
      return false
    }
  }
}
else if(feature == "sub_admins"){
    if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN" ){
      return true
    }else{
      return false
    }
  }
else if(feature == "settings"){

      if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN"  ){
        return true
      }else{
        return false
      }
 }else if(feature == "wallet"){
  if(user_permissions == "GROUP_LEVEL"){
    return true
  }else{
    return false
  }
} 
else if(feature == "account_additional_docs"){
  if(user_permissions == "ADMIN" || user_permissions == "COMPLIANCE" || user_permissions == "GROUP_LEVEL" ){
    return true
  }else{
    return false
  }
} 
else{
   console.log("------- check handle permissions");
   if(user_permissions == "GROUP_LEVEL" || user_permissions == "ADMIN" || user_permissions == "OPERATION" ){
    return true
  }else{
    return false
  }
 }
 
//  if(user_permissions == "group_level"){
//    console.log("true---------");
//     return true
//   }else{
//     console.log("false-------");
//     return false
//   }
  

}