import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from 'react-bootstrap' 
import { Field, reduxForm, SubmissionError } from 'redux-form'
import axios from 'axios';
import { USER_MANAGEMENT, FRONT_BASE, EXCHANGE_MANAGEMENT } from '../../config/config';
import { Layout } from '../common/Layout';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import loader_image from '../../images/loading.gif';
import user_image from '../../images/user_img_default.png';

interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
}



const AcceptedRequests = () => { 

    const [ users, setUsers ] = useState([]);
    useEffect(() => {
      getData();
    }, []);


    const [ currentPage, setCurrentPage ] = useState(0);
    const [ usersPerPage, setUsersPerPage ] = useState(20);
    const [ totalPage, setTotalPage ] = useState(1);
    const [ rateCount, setRateCount ] = useState(0);
    const [ isLoading, setisLoading ] = useState(0);
  const [isuserStatus, setisStatus] = useState(2);
  const [searchText, setSearchText] = useState("")
  const [isLoader , setIsLoader] = useState(false)
    

    const total = Math.ceil(totalPage/usersPerPage);

    const getData = () => {                
          const access_token = localStorage.getItem('access_token');
          if (access_token) {
            console.log("submit start")
            let config1 = {
              headers: {
                  'deviceType':'Android',
                  'appVersion' : '1.0',
                  'Authorization' : 'Bearer '+access_token
              }
            }
            axios.get(`${USER_MANAGEMENT}/admin/getMerchantsList?page=0`, config1).then(function (response) {
              var data1 = response.data.data
              console.log(data1)

              if (data1.count > 0){
                setisLoading(1)
                }else{
                setisLoading(2)
                }
              setTotalPage(data1.count)
              setUsers(data1.merchant)
            }).catch(function (error) {
            console.log(error);
            setisLoading(3)
            setUsers([])

          })
        }  
    }

  
    function searchaccepted(e:any) {
      setSearchText(e.target.value)
      makeHttpRequestWithPage(currentPage,e.target.value)
     }

    const makeHttpRequestWithPage = async (pageNumber:any,txt:any) => {
      if (pageNumber < 0){
        pageNumber = total-1
      }
      if (pageNumber == total){
        pageNumber = 0
      }
      const access_token = localStorage.getItem('access_token');
      setIsLoader(true)
      const response = await fetch(`${USER_MANAGEMENT}/admin/getMerchantsList?page=${pageNumber}&search=${txt}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'deviceType':'Android',
          'appVersion' : '1.0',
          'Authorization' : 'Bearer '+access_token
      },
      });
      setIsLoader(false)
      const data = await response.json();
      console.log("I am here")
      if (data.data.count > 0){
        setisLoading(1)
        }else{
        setisLoading(2)
        }
      console.log(data.data.merchant)
      setUsers(data.data.merchant)
      setCurrentPage(pageNumber)
   
    }

    const pagination = () =>{
      let table = []
      table.push(<li><a  href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(currentPage-1,searchText)}>Previous</a></li>)
      for (let i = 0; i < total; i++) {
        console.log("i------------------->"+currentPage)
        // table.push(<span onClick={() => makeHttpRequestWithPage(i)} className="active">{i}</span>)
        table.push(<li className={ i == currentPage ? "active block_cursor_event" :""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i,searchText)}  >{i+1}</a></li>)
      }
      table.push(<li><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(currentPage+1,searchText)}>Next</a></li>)
      return table
    }

    


  const UserTable = () => {     
      return (
              <table className="table">
                <thead>
                  <tr>
                  <th>ID</th>
                  <th>Phone Number</th>
                  <th>Transaction Type</th>               
                  <th>Name</th>
                  <th>Date & Time</th>
                  <th>Amount</th>
                  <th>Currency</th>
                  <th>Status</th>
                  </tr>
                </thead>
                <tbody>
               {users.map((item: any, index: any) => {
                console.log("ratecountval")
                 return(
                <tr key={index}>
                  <td>{(currentPage*20)+ (index+1)}</td>
                  <td>+91 987-654-3210</td>
                  <td>Exchange</td>
                  <td>Jack Thomas</td>
                  <td>2020-10/27 08:19</td>
                  <td>+1000/+320</td>
                  <td>MYR/SGD</td>
                  <td>
                    {isuserStatus==1 && <p className="yellow">Pending</p>}
                    {isuserStatus==2 && <p className="blue">Accepted</p>}
                    {isuserStatus==3 && <p className="red">Declined</p>}
                    {isuserStatus==4 && <p className="green">Collected</p>}
                    {isuserStatus==5 && <p className="">Not Collected</p>}
                  </td>
                </tr> )
                })}
                </tbody>
           </table>      
        )

    }

    function viewRate(user_id:any){
      console.log("view rate")
      window.sessionStorage.setItem('merchant_id', user_id)
      window.location.href = `${FRONT_BASE}/merchant/rate`; 
    }
                    
    return (      
      <Layout>  
        <section className="inner_sction">
          <section className="table_sction accunt_table">
            <div className="container-fluid">
              <div className="table_sction_outer admin_index">

              <div className="top-sctin mbtm-0">
                  <h4>Accepted Requests</h4>
                  <div className="form-group pull-right">
                    <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                    <input type="text" className="form-control" value={searchText} onChange={(e)=>searchaccepted(e)} placeholder="Search accepted request by name,phone"/>
                  </div>
              </div>

                {getData}
                <div className="table_inner">
                  <div className="table-responsive">
                  {isLoading == 0 && <div id="api_loader"><img src={loader_image} width="50" height="50" />Loading...</div> }
                  {isLoading == 1 &&  UserTable() }
                  {isLoading == 2 &&   <h1>Record Not Found</h1> }
                  {isLoading == 3 &&   <h1>Some Error Occur</h1> }
                  </div>
                  <div className="pagination_sction">
                    <ul className="pagination">
                      {total > 0 ? pagination() : ""}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          { isLoader &&
                <div id="" className="loaderbox loginloader innerloader"><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div>
            }
        </section>
      </Layout>
      
    )
}

export default (AcceptedRequests)