import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from 'react-bootstrap' 
import { Field, reduxForm, SubmissionError } from 'redux-form'
import axios from 'axios';
import { TRANSACTION_MANAGEMENT, FRONT_BASE } from '../config/config';
import { Layout } from './common/Layout';
import Moment from 'react-moment';
// import * as moment from 'moment';
import * as moment from 'moment-timezone';
import loader_image from '../images/loading.gif';
import jstz from 'jstz';
import { adminService , userService } from '../_services';
import {DataHandling} from '../components/common/DataHandling'
import {Timeformatter} from '../components/common/Timeformatter'
import {Amountformatter} from '../components/common/AmountFormatter'
interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
}


const Transactions = () => { 

    const [ users, setUsers ] = useState([]);
    const [ userskyc, setUsersKYC ] = useState([]);
    const [ isLoading, setisLoading ] = useState(0);
    const [countryList, setCountryList] = useState([]) as any;
    const [occupation, setOccupation] = useState([]) as any;
    const [periodSelected , setPeriodSelected] = useState("");
    const [searchTxt , SetSearchTxt] = useState("");
    const [role , SetRole] = useState("");
    const [selectedOccupation , SetselectedOccupation] = useState("");
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ usersPerPage, setUsersPerPage ] = useState(20);
    const [ totalPage, setTotalPage ] = useState(1);
    const [selectedCountry, setSelectedCountry] = useState("");

    const total = Math.ceil(totalPage/usersPerPage);
    let user_country_id =  localStorage.getItem("admin_user_country_id") as any;
    let user_role:any = localStorage.getItem("user_permissions") ? localStorage.getItem("user_permissions") : ""

    useEffect(() => {
      let tmp_country_id = user_role === 'GROUP_LEVEL' || user_role === 'GUEST' ? '' : user_country_id;
      getCountries();
      getData(0, "", tmp_country_id, "", "");
      SetRole(user_role);
    }, []);


    const getData = (pageNumber:any,txt:any,country:any,period:any, occupation:any) => {
          setisLoading(0);
          console.log("pageNumber",pageNumber);
          console.log("txt",txt);
          console.log("country",country);
          console.log("period",period);
          adminService.transaction_list(pageNumber,txt,country,period, occupation).then(function (response) {             
              console.log("transactions ----",response.data)
              if (response.data.count > 0){
                setisLoading(1)
              }else{
                setisLoading(2)
              }
              setTotalPage(response.data.count)
              setOccupation(response.data.occupations)
              setUsers(response.data.transactions)
              
            }).catch(function (error) {
            console.log(error);
            setisLoading(3)
            setUsers([])

          }) 
    }

    function getCountries() {
      adminService.countries_list().then(function (response) {
        setCountryList(response.data.data.countries)
      })
        .catch(function (error) {
          console.log("error get_countries-------------------");
        });
    }

  const UserTable = () => { 
    // console.log("users.length") 
    console.log(users.length) 
      return (
              <table className="table">
                <thead>
                  <tr>
                  <th>ID</th>
                  <th>PHONE NUMBER</th>
                  <th>TRANSACTION TYPE</th>    
                  <th>NAME</th>    
                  <th>DATE & TIME</th>
                  <th>COUNTRY</th>
                  <th >Exchange Between Currencies</th>  
                  <th>OCCUPATION</th>
                  {/* <th>CREATED AT</th> */}
                  {/* <th>CURRENCY</th> */}
                  <th>STATUS</th>
                  
                  </tr>
                </thead>
                <tbody>
               {users.length > 0 ? number_of_transactions != 0 && users.map((item: any, index: any) => {
                 console.log((item.merchantObj.country as any).name)
                 return(
                 <tr key= { ((currentPage*20) + (item.id))}>
                  <td>{ (currentPage*20) +  (index+1) }</td>
                  <td>{ item.userObj ? item.userObj.countryCode : "" }-{item.userObj ? item.userObj.phoneNumber : "" }</td>
                  <td>{item.transactionType == null ? item.transactionfor : item.transactionType == 'DEPOSITE' ? 'DEPOSIT' : item.transactionType}</td>
                  <td>{ item.userObj ? (item.userObj.firstName  ? item.userObj.firstName : '')  : "" } {item.userObj ?  (item.userObj.lastName ? item.userObj.lastName : '') : "" }</td>
                  <td><Timeformatter  timeget={item.createdOn}  /></td>
                  <td>{item.merchantObj ? (item.merchantObj.country as any).name : '-' }</td>
                  <td>
                  {/* -{item.value}/+{item.totalAmount} */}
                    <div className="flag_box" style={{margin: '0px'}}>
                        <div className="currency">
                          <p><span><img src={item.exchangeFromFlag} style={ { width:'33px', height: '33px' } }/></span>{item.currencyFrom}( <Amountformatter amount={item.value}/> )</p>
                        </div>
                        <div className="currency arrow">
                          <img src={require("../images/bold_arrow_right.png")} style={{ border:'0px solid #ddd', width: '15px', marginTop: '8px'}}/>
                        </div>
                        <div className="currency">
                          <p><span><img className="exchange_flag_image" src={item.exchangeToFlag}  style={ { width:`33px`, height: `33px` } }/></span>{item.currencyTo}( <Amountformatter amount={item.totalAmount}/> )</p>
                        </div>
                      </div>
                    </td>
                  <td>{item.userObj ? item.userObj.occupation ? item.userObj.occupation : '-' : '-' }</td>                  
                  {/* <td>{item.currencyFrom}/{item.currencyTo}</td> */}
                  <td>{ item.orderStatus }</td>
                  </tr> )
                }): 
                  <div id="" className="loaderbox" style={{marginTop: '80px'}}><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div>
                }
                </tbody>
           </table>      
        )

    }

    const SetSearchTxtChange = (event:any) => {
      var dataval:string = event.target.value
      SetSearchTxt(dataval)
      setCurrentPage(0);
      getData(0,dataval,selectedCountry,periodSelected, selectedOccupation)
    }

    function setCountryData(e: any) {
      var tmpCountryId: any = e.target.value;
      setSelectedCountry(tmpCountryId);
      setCurrentPage(0);
      getData(0,searchTxt,tmpCountryId,periodSelected, selectedOccupation)
    }

    const periodSelectedOnchange = (event:any) => {
      var dataval:string = event.target.value
      setPeriodSelected(dataval);
      setCurrentPage(0);
      getData(0,searchTxt,selectedCountry,dataval, selectedOccupation)
    }

    const setSelectedOccupationChange = (event:any) => {
      var dataval:string = event.target.value
      SetselectedOccupation(dataval);
      setCurrentPage(0);
      getData(0,searchTxt,selectedCountry,periodSelected,dataval)
    }
    
    

    const makeHttpRequestWithPage = async (pageNumber:any) => {
      setUsers([]);
      if (pageNumber < 0){
        pageNumber = total-1
      }
      if (pageNumber == total){
        pageNumber = 0
      }
      console.log("pageNumber",pageNumber)


      // here
      // getData(pageNumber,"","","","")
      getData(pageNumber,searchTxt,selectedCountry,periodSelected, selectedOccupation)

      setCurrentPage(pageNumber)
   
    }
  
    const pagination = () =>{
      let table = []
      table.push(<li><a  href="javascript:void(0)" className={currentPage == 0 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage-1)}>Previous</a></li>)
      for (let i = 0; i < total; i++) {
        console.log("i------------------->"+currentPage)
        // table.push(<span onClick={() => makeHttpRequestWithPage(i)} className="active">{i}</span>)
        table.push(<li className={ i == currentPage ? "active block_cursor_event" :""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}  >{i+1}</a></li>)
      }      
      table.push(<li><a href="javascript:void(0)" className={currentPage == total-1 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage+1)}>Next</a></li>)
      return table
    }
                    

    const number_of_transactions = users.length
    return (      
      <Layout>  
        <section className="inner_sction">
          <section className="table_sction accunt_table">
            <div className="container-fluid">
              <div className="table_sction_outer admin_index">

              <div className="top-sctin mbtm-0 big_select">
                  <h4>Transactions</h4>

                  <select value={selectedOccupation} className="form-control long-select" onChange={setSelectedOccupationChange}>
                    <option value="">Occupation</option>
                    {occupation && occupation.length > 0 && occupation.map((item: any, index: any) => { 
                      return(
                        <option value={item}>{item}</option>
                      )
                      
                     })}

                  </select>
                  <select className="form-control" value={periodSelected} onChange={periodSelectedOnchange} >
                    <option value="">All</option>
                    <option value="day">Day</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                  </select>
                 
                  {(role === 'GROUP_LEVEL') &&
                  <select value={selectedCountry} className="form-control" onChange={(e: any) => setCountryData(e)}>
                    <option value=''>Country</option>
                    {countryList && countryList.length > 0 && countryList.map((item: any, index: any) => {
                      return (<option value={item.id}>{item.name}</option>)
                    })}
                  </select>
                }
                  <div className="form-group pull-right">
                    <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                    <input type="text" value={searchTxt} onChange={(e) => SetSearchTxtChange(e)} className="form-control" placeholder="Search with name"/>
                  </div>

                  {/* <button className="pull-right" data-toggle="modal" data-target="#export_data">
                    <i className="fa fa-download" aria-hidden="true"></i> Export</button>  */}
                  <div className="clearfix"></div>
                  
              </div>

                {getData}
                <div className="table_inner">
                  <div className="table-responsive">
                    {isLoading == 0 && <div id="" className="loaderbox" style={{marginTop: '50px'}}><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div> }
                    {isLoading == 1 &&  UserTable() }
                    {isLoading == 2 &&   <h1>Record Not Found</h1> }
                    {/* {number_of_transactions != 0 ? UserTable() : <div className="table"><h1>NO Transactions Found</h1></div>} */}
                    <div className="pagination_sction">
                      <ul className="pagination">
                        {total > 0 ? pagination() : ""}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </Layout>
    )
}

  export default reduxForm({
    form: 'searchForm' // a unique identifier for this form
  })(Transactions)  