
export const BASE_URL = "https://api.e-fx.asia";
export const FRONT_BASE = 'https://e-fx.asia/';

// for local
// export const BASE_URL = "http://192.168.2.82:8762";
// export const FRONT_BASE = 'http://localhost:3000';


export const USER_MANAGEMENT = BASE_URL + '/EFXUserManagement/v1/api';
export const TRANSACTION_MANAGEMENT = BASE_URL + '/EfxCurrency/v1/api';
export const EXCHANGE_MANAGEMENT = BASE_URL + '/EfxExchange/v1/api';
export const MERCHANT_WALLET = BASE_URL + '/EfxCurrency/v1/api';
export const INVESTMENT_MANAGEMENT = BASE_URL + '/EfxInvestment/v1/api';
export const API_URL = BASE_URL;
export const PER_PAGE = 20;
export const config = {
  apiUrl: "https://api.e-fx.asia",
  image_base_url: "", // must be blank to handle s3 bucket

  // for local
  //  apiUrl: "http://192.168.2.82:8762",
  // image_base_url: "https://api.e-fx.asia/EFXUserManagement/"
  
  basis_api_key: "prod-GyNtgjCefFzWrOCMjYFCdoxpZTumokrl",
  basis_sha_key: "s2-HAwCJvRSROozaRRZpWYwrNlnqlMRbktI",

}
