import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import store from '../../store';
import Count from '../Count';
import Merchant from '../Merchant';
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Layout } from '../common/Layout'
import axios from 'axios';
import loader_image from '../../images/loading.gif';
import Moment from 'react-moment';
// import * as moment from 'moment';
import * as moment from 'moment-timezone';
import jstz from 'jstz';

import { EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT } from '../../config/config';
import Transactions from '../Transactions';
import { adminService, userService } from '../../_services';
import TimeAgo from 'react-timeago'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';

const hanldleClick = (type: string) => {
  store.dispatch({ type });
  console.log(store.getState().counter)
}

const AdminNotification = () => {

  // const [ notifications, setNotifications ] = useState([]) as any;
  const [notificationsResolved, setNotificationsResolved] = useState([]) as any;
  const [notificationsUnResolved, setNotificationsUnResolved] = useState([]) as any;
  const [currentPageResloved, setCurrentPageResloved] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(20);
  const [totalPageResloved, setTotalPageResloved] = useState(1);
  const [currentPageUnattended, setCurrentPageUnattended] = useState(0);
  const [totalPageUnattended, setTotalPageUnattended] = useState(1);
  const [allcurrencies, setallcurrencies] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [countryId, setCountryId] = useState(1) as any;
  // const [ defaultCountryId, setDefaultCountryId ] = useState(localStorage.getItem('user_permissions') === 'GROUP_LEVEL' ? 2 : localStorage.getItem('user_country_id')) as any;

  const [countryList, setCountryList] = useState([]);

  let userPermission: any = localStorage.getItem('user_permissions');
  // let userCountryId:any = localStorage.getItem('user_country_id')

  useEffect(() => {
    let id: any = userPermission === 'GROUP_LEVEL' || userPermission === 'GUEST' ? 1 : localStorage.getItem('admin_user_country_id')
    // let id:any = userPermission === 'GROUP_LEVEL' ? defaultCountryId : userCountryId;
    setCountryId(id);
    // setDefaultCountryId(id);
    getNotifications(id, 0, 0);
    getCountries();
  }, []);


  function mark_unmark_notification(item: any) {
    console.log("item---", item);

    let params = {
      id: item.id,
      hasSeen: !(item.hasSeen)

    }
    adminService.mark_unmark_notification(params)
      .then(function (response: any) {
        toast.success(response.data.message);
        getNotifications(countryId, currentPageResloved, currentPageUnattended);
      }).catch(function (error: any) {
        console.log("-----------error--------", error);
        // setLoaderstyle({display:"none"})
        userService.handleError(error);
      });



  }

  function getNotifications(tmpCountryId: any, currentPageNoResolved: any, currentPageNoUnattended: any) {
    setIsLoading(true);
    adminService.get_notifications(tmpCountryId, currentPageNoResolved, currentPageNoUnattended)
      .then(function (response: any) {
        setIsLoading(false);
        setTotalPageResloved(response.data.allNotificationsForAdminResolvedCount);
        setTotalPageUnattended(response.data.allNotificationsForAdminUnAttendedCount);
        setNotificationsResolved(response.data.allNotificationsForAdminResolved);
        setNotificationsUnResolved(response.data.allNotificationsForAdminUnAttended);
      }).catch(function (error: any) {
        setIsLoading(false);
        console.log("-----------error--------", error);
        // setLoaderstyle({display:"none"})
        userService.handleError(error);
      });

  }

  const totalResloved = Math.ceil(totalPageResloved / usersPerPage);
  const totalUnattended = Math.ceil(totalPageUnattended / usersPerPage);

  function getCountries() {
    adminService.countries_list().then(function (response) {
      console.log("countries_list-----", response.data.data.countries);
      setCountryList(response.data.data.countries)
    })
      .catch(function (error) {
        console.log("error get_countries-------------------");
      });
  }

  const makeHttpRequestWithPageResloved = async (pageNumber: any) => {
    if (pageNumber < 0) {
      pageNumber = totalResloved - 1
    }
    if (pageNumber == totalResloved) {
      pageNumber = 0
    }
    // setIsFormLoading(0);
    // getRate(pageNumber)
    setCurrentPageResloved(pageNumber);
    getNotifications(countryId, pageNumber, currentPageUnattended);
  }

  const paginationResloved = () => {
    let table = []
    table.push(<li><a href="javascript:void(0)" className={currentPageResloved == 0 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPageResloved(currentPageResloved - 1)}>Previous</a></li>)
    for (let i = 0; i < totalResloved; i++) {
      table.push(<li className={i == currentPageResloved ? "active block_cursor_event" : ""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPageResloved(i)}  >{i + 1}</a></li>)
    }
    table.push(<li><a href="javascript:void(0)" className={currentPageResloved == totalResloved - 1 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPageResloved(currentPageResloved + 1)}>Next</a></li>)
    return table
  }


  const makeHttpRequestWithPageUnattended = async (pageNumber: any) => {
    // setRates([]);
    if (pageNumber < 0) {
      pageNumber = totalUnattended - 1
    }
    if (pageNumber == totalUnattended) {
      pageNumber = 0
    }
    // setIsFormLoading(0);
    // getRate(pageNumber)
    setCurrentPageUnattended(pageNumber)
    getNotifications(countryId, currentPageResloved, pageNumber);
  }

  const paginationUnattended = () => {
    let table = []
    table.push(<li><a href="javascript:void(0)" className={currentPageUnattended == 0 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPageUnattended(currentPageUnattended - 1)}>Previous</a></li>)
    for (let i = 0; i < totalUnattended; i++) {
      table.push(<li className={i == currentPageUnattended ? "active block_cursor_event" : ""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPageUnattended(i)}  >{i + 1}</a></li>)
    }
    table.push(<li><a href="javascript:void(0)" className={currentPageUnattended == totalUnattended - 1 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPageUnattended(currentPageUnattended + 1)}>Next</a></li>)
    return table
  }

  function setCountryData(event: any) {
    var tmpCountryId: any = event.target.value;
    setCountryId(tmpCountryId);
    //setDefaultCountryId(val);
    setCurrentPageResloved(0);
    setCurrentPageUnattended(0);
    getNotifications(tmpCountryId, 0, 0);
    // getDatavalue(depositsselect, "",dataval)
  }

  var val = window.sessionStorage.getItem('merchant_id')

  return (
    <Layout>
      <section className="inner_sction">
        <section className="table_sction accunt_table">
          <div className="container-fluid">
            <div className="table_sction_outer admin_index">
              <div className="top-sctin mbtm-0">
                <h4>Notifications</h4>
                {(userPermission === 'GROUP_LEVEL' || userPermission === 'GUEST') &&
                  <select value={countryId} className="form-control" onChange={(e: any) => setCountryData(e)}>
                    {countryList && countryList.length > 0 && countryList.map((item: any, index: any) => {
                      return (<option value={item.id}>{item.name}</option>)
                    })}
                  </select>
                }
              </div>
              <Tabs>
                <TabList>
                  <Tab>Un-Attended</Tab>
                  <Tab>Resolved</Tab>
                </TabList>
                <TabPanel>
                  <h2>Un-Attended</h2>
                  {notificationsUnResolved && notificationsUnResolved.map((item: any, index: any) => {
                    return (
                      <ul className="notification_list">
                        <li>
                          <p className="notfy">{item.message}</p>
                          <p>
                            <TimeAgo date={item.createdAt} />
                          </p>
                          <button onClick={() => mark_unmark_notification(item)} className="right_btn_yellow" >
                            Mark Resolved
                          </button>
                        </li>
                      </ul>
                    )
                  })}
                  {notificationsUnResolved && notificationsUnResolved.length == 0 &&
                    <div className="no_notification">
                      No Notification
                    </div>
                  }
                  <div className="pagination_sction" style={{ margin: '50px 0px' }}>
                    <ul className="pagination">
                      {totalUnattended > 0 ? paginationUnattended() : ""}
                    </ul>
                  </div>
                </TabPanel>
                <TabPanel>
                  <h2>Resolved</h2>
                  {notificationsResolved && notificationsResolved.map((item: any, index: any) => {
                    return (
                      <ul className="notification_list">
                        <li>
                          <p className="notfy">{item.message}</p>
                          <p>
                            <TimeAgo date={item.createdAt} />
                          </p>
                          <button onClick={() => mark_unmark_notification(item)} className="right_btn_yellow">
                            Mark Un attended
              </button>
                        </li>
                      </ul>
                    )
                  })}
                  {notificationsResolved && notificationsResolved.length == 0 &&
                    <div className="no_notification">
                      No Notification
                    </div>
                  }

                  <div className="pagination_sction" style={{ margin: '50px 0px' }}>
                    <ul className="pagination">
                      {totalResloved > 0 ? paginationResloved() : ""}
                    </ul>
                  </div>

                </TabPanel>
              </Tabs>


              {/*  
              <ul className="notification_list">
                { notifications && notifications.map((item:any,index:any) =>{
                  return(
                    <li>
                      <p className="notfy">{item.message}</p>
                      <p>
                        <TimeAgo date={item.createdAt}  />
                      </p>
                    </li>
                  )
                  })}
              </ul> */}

            </div>
          </div>
        </section>
      </section>

      <div className="modal fade" id="accept_request" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content custom_modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
              <h3 className="modal-title">User Request</h3>
            </div>
            <div className="modal-body">
              <div className="notif_popup text-center">
                <h1>Jack Thomas</h1>
                <h3 className="phone">+01 9876543210</h3>
                <h3 className="request_amount">sent you a exchange request <br /> for <span>HKD 1000</span> into <span>USD</span></h3>

                <div className="flag_box">
                  <div className="currency">
                    <p><span><img src="../images/flag.png" /></span>HDK</p>
                    <p className="amount">1000 HKD</p>
                  </div>

                  <div className="currency arrow">
                    <img src={require("../../images/arrow_right.png")} />
                  </div>

                  <div className="currency">
                    <p><span><img src="../images/flag1.png" /></span>USD</p>
                    <p className="amount">127.76 USD</p>
                  </div>
                </div>
                <p>1HKD = 0.13 USD Execution rate</p>

                <div className="bottom_btn">
                  <button type="submit">Accept</button>
                  <button className="border_btn" data-toggle="modal" data-target="#reject_request" data-dismiss="modal">Reject</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="reject_request" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content custom_modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
              <h3 className="modal-title">Reject Request</h3>
            </div>
            <div className="modal-body">
              <div className="notif_popup text-center">

                <h3 className="request_amount">Are you sure you want to reject the <br /> request sent by <b>Jack Thomas</b> for exchange <br /> <span>HKD 1000</span> into <span>USD</span>?</h3>


                <div className="flag_box">
                  <div className="currency">
                    <p><span><img src="../images/flag.png" /></span>HDK</p>
                    <p className="amount">1000 HKD</p>
                  </div>

                  <div className="currency arrow">
                    <img src={require("../../images/arrow_right.png")} />
                  </div>

                  <div className="currency">
                    <p><span><img src="../images/flag1.png" /></span>USD</p>
                    <p className="amount">127.76 USD</p>
                  </div>
                </div>
                <p>1HKD = 0.13 USD Execution rate</p>

                <div className="form-group">
                  <label>Give a reason</label>
                  <textarea className="form-control" placeholder="Enter Reason" rows={4}>

                  </textarea>
                </div>

                <div className="bottom_btn">
                  <button type="submit">Submit</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <div id="" className="loaderbox loginloader">
          <div>
            <img
              className="bounce-2"
              src={loader_image}
              width="50"
              height="50"
            />
            <p>Loading...</p>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default connect()(AdminNotification);