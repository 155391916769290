import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import store from '../../store';
import Count from '../Count';
import Merchant from '../Merchant';
import { Container, Row, Col, Button } from 'react-bootstrap' 
import { Layout } from '../common/Layout'
import axios from 'axios';
import loader_image from '../../images/loading.gif';
import Moment from 'react-moment';
// import * as moment from 'moment';
import * as moment from 'moment-timezone';
import jstz from 'jstz';
import TimeAgo from 'react-timeago'
import { EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT} from '../../config/config';
import Transactions from '../Transactions';
import { merchantService,userService } from '../../_services';
const hanldleClick = (type: string) => {
  store.dispatch({type});
  console.log(store.getState().counter)
}


const MerchantActivites = () => {

  const [ data, setData ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(0);
  const [ currentPage, setCurrentPage ] = useState(0);
  const [ usersPerPage, setUsersPerPage ] = useState(20);
  const [ totalPage, setTotalPage ] = useState(1);
  const total = Math.ceil(totalPage/usersPerPage);

  useEffect(() => {
    getData(currentPage);
  }, []);

  const getData = (pageNo:any) => {
    setIsLoading(0);
    merchantService.merchantActivities(pageNo)
    .then(function (response:any) {
      console.log("response",response.data.data.activities)
      let data1 = response.data.data.activities;
      setIsLoading(data1.length > 0 ? 1 : 2);

      setTotalPage(response.data.data.count);
      console.log("response.data.data.count",response.data.data.count);
      
      setData(response.data.data.activities);
    }).catch(function(error:any){
      setIsLoading(3);
      console.log("-----------error--------",error);
        // setLoaderstyle({display:"none"})
        // userService.handleError(error);
    });
  }

  const makeHttpRequestWithPage = async (pageNumber:any) => {
    setData([]);
    if (pageNumber < 0){
      pageNumber = total-1
    }
    if (pageNumber == total){
      pageNumber = 0
    }

    getData(pageNumber);
    setCurrentPage(pageNumber);
  }

  function getPaginationLimit(){
    let last = (totalPage <= 4 ? totalPage : currentPage >= 3 ? currentPage + 3 : 5);
       console.log("totalPage",totalPage);
       console.log("currentPage",currentPage);
       console.log("total",total);
       return (currentPage < total-2) ? last : total;
  }

  const pagination = () =>{
    let table = []
    table.push(<li><a href="javascript:void(0)" className={currentPage == 0 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage-1)}>Previous</a></li>)
      
    // table.push(<li><a href="javascript:void(0)" style={{color:"black"}} className={currentPage == 0 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(0)}>First Page</a></li>)
    
    for (let i = currentPage < 3 ? 0 : currentPage - 2 ; i < getPaginationLimit(); i++) {
      table.push(<li className={ i == currentPage ? "active block_cursor_event" :""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}  >{i+1}</a></li>) 
    }
    table.push(<li><a href="javascript:void(0)" style={{color:"black"}} className={currentPage == total-1 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage+1)}>Next</a></li>)

    // table.push(<li><a href="javascript:void(0)" className={currentPage == total-1 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(31)}>Last Page</a></li>)
    return table
  }

    return (
        <Layout>  
        <section className="inner_sction">
          <section className="table_sction accunt_table">
            <div className="container-fluid">
              <div className="table_sction_outer admin_index">

              <div className="top-sctin activity_top_sec mbtm-0">
                  <h4>Activities</h4>
                  {/* <select className="form-control">
                    <option value="day">Select User</option>
                    <option value="weekly">User</option>
                    <option value="monthly">User</option>
                  </select> */}

                  {/* <div className="form-group pull-right">
                    <input type="text" className="form-control" placeholder="From"/>
                  </div>
                  
                  <div className="form-group pull-right">
                    <input type="text" className="form-control" placeholder="To"/>
                  </div> */}

              </div>
 
              
              {isLoading == 0 && <div id="api_loader"><div id="" className="loaderbox"><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div></div> }
              {isLoading == 1 &&  <ul className="notification_list activity_list">
                                    { data.map((item: any, index: any) => {
                                        return(<li>
                                          <div className="img_box"><img src={require("../../images/user_img_default.png")}/></div>
                                          <a >{item.activity}</a>
                                          <p><TimeAgo date={item.createdOn} live={false} /></p>
                                          </li>)
                                        })
                                    }
                                  </ul> }
              {isLoading == 2 &&   <h1>Record Not Found</h1> }
              {isLoading == 3 &&   <h1>Some Error Occur</h1> }
              <div className="pagination_sction" style={{marginBottom: '100px'}}>
                <ul className="pagination">
                  {total > 0 ? pagination() : ""}
                </ul>
              </div>
              </div>
            </div>
          </section>
        </section>

  
      </Layout>
    )
}

export default connect()(MerchantActivites);