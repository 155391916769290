import React, { useEffect, useState } from 'react';
import logo from '../../images/logo.png';
import user_image from '../../images/user_img_default.png';
import loader_image from '../../images/loading.gif';

import { Col, Row, Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Link } from "react-router-dom";
import axios from 'axios';
import { USER_MANAGEMENT, FRONT_BASE, EXCHANGE_MANAGEMENT, TRANSACTION_MANAGEMENT } from '../../config/config';
import $ from 'jquery';
import { DataHandling, HandlePermission } from '../common/DataHandling'



const AdminSidebar = (props: any) => {
    var user_permissions = localStorage.getItem("user_permissions")
    var user_role = localStorage.getItem("user_role");
    var currentpathval = window.location.pathname
    console.log(currentpathval)
    return (

        <aside className="sidemenu" id="sidebar" style={{ height: '100%' }}>
            <ul>
                {user_role !== "KYCADMIN" &&
                    <li className={currentpathval == '/' ? "active" : ""}>
                        <a href="/">
                            <span><i className="fa fa-tachometer" aria-hidden="true"></i></span> Dashboard
                        </a>
                    </li>
                }

                {user_role !== "KYCADMIN" &&
                    <li className={currentpathval == '/merchant/listing' ? "active" : ""}>
                        <a href="/merchant/listing">
                            <span><i className="fa fa-user-secret" aria-hidden="true"></i></span> Partners
                        </a>
                    </li>}
                {user_role !== "KYCADMIN" &&
                    <li className={currentpathval == '/transactions' ? "active" : ""}>
                        <a href="/transactions">
                            <span><i className="fa fa-money" aria-hidden="true"></i></span> Transactions
                        </a>
                    </li>}

                <li className={currentpathval == '/account' ? "active" : ""}>
                    <a href="/account">
                        <span><i className="fa fa-user-o" aria-hidden="true"></i></span> Accounts
                    </a>
                </li>

                {HandlePermission("view", "wallet") &&
                    <li className={currentpathval == '/wallet' ? "active" : ""}>
                        <a href="/wallet">
                            <span><i className="fa fa-usd" aria-hidden="true"></i></span> Wallet
                        </a>
                    </li>
                }
                {user_role !== "KYCADMIN" &&
                    <li className={currentpathval == '/admin/ExpiredTransactions' ? "active" : ""}>
                        <a href="/admin/ExpiredTransactions">
                            <span><i className="fa fa-info-circle" aria-hidden="true"></i></span> Expired Transactions
                        </a>
                    </li>}

                {/* <li className={currentpathval == '/admin/AcceptedRequests' ? "active" : ""}>
                        <a href="/admin/AcceptedRequests">
                            <span><i className="fa fa-check-circle-o" aria-hidden="true"></i></span> Accepted Requests
                        </a>
                    </li> */}
                {/* <li className={currentpathval == '/order' ? "active" : ""}>
                        <a href="/order">
                            <span><i className="fa fa-list-alt" aria-hidden="true"></i></span> Orders
                        </a>
                    </li> */}

                {/* {user_role !== "KYCADMIN" &&
                    <li className={currentpathval == '/admin/SuspiciousTransactions' ? "active" : ""}>
                        <a href="/admin/SuspiciousTransactions">
                            <span><i className="fa fa-repeat" aria-hidden="true"></i></span> Suspicious Transactions
                        </a>
                    </li>
                } */}
                {user_role !== "KYCADMIN" &&
                    <li className={currentpathval == '/admin/CustomerSupport' ? "active" : ""}>
                        <a href="/admin/CustomerSupport">
                            <span><i className="fa fa-envelope-o" aria-hidden="true"></i></span> Customer Support
                        </a>
                    </li>}
                {/* {user_role !== "KYCADMIN" &&
                    <li className={currentpathval == '/admin/compliance' ? "active" : ""}>
                        <a href="/admin/compliance">
                            <span><i className="fa fa-gavel" aria-hidden="true"></i></span> Compliance
                        </a>
                    </li>} */}
                {user_role !== "KYCADMIN" &&
                    <li className={currentpathval == '/admin/notification' ? "active" : ""}>
                        <a href="/admin/notification">
                            <span><i className="fa fa-bell-o" aria-hidden="true"></i></span> Notifications
                        </a>
                    </li>}

                {/* <li className={currentpathval == '/admin/RateInventory' ? "active" : ""}>
                        <a href="/admin/RateInventory">
                            <span><img src={require("../../images/rate.png")} /></span> Rates & Inventory
                        </a>
                    </li> */}

                {user_role !== "KYCADMIN" &&
                    <li className={currentpathval == '/admin/Branches' ? "active" : ""}>
                        <a href="/admin/Branches">
                            <span><img src={require("../../images/branch.png")} /></span> Branches
                        </a>
                    </li>}

                {user_role !== "KYCADMIN" &&
                    <li className={currentpathval == '/admin/Activites' ? "active" : ""}>
                        <a href="/admin/Activites">
                            <span><img src={require("../../images/activity.png")} /></span> Activities
                        </a>
                    </li>}
                
                {user_role !== "KYCADMIN" &&
                    <li className={currentpathval == '/admin/BannerImage' ? "active" : ""}>
                        <a href="/admin/BannerImage">
                            <span><i className="fa fa-picture-o" aria-hidden="true"></i></span>Banner Image
                        </a>
                    </li>
                }

                {HandlePermission("view", "sub_admins") &&
                    <li className={currentpathval == '/admin/SubAdmin' ? "active" : ""}>
                        <a href="/admin/SubAdmin">
                            <span><i className="fa fa-users" aria-hidden="true"></i></span> Users
                        </a>
                    </li>
                }
                {/* {HandlePermission("view", "sub_admins") &&
                    <li className={currentpathval == '/admin/KYCAdmin' ? "active" : ""}>
                        <a href="/admin/KYCAdmin">
                            <span><i className="fa fa-users" aria-hidden="true"></i></span> KYC Admin
                        </a>
                    </li>
                } */}


                {/* {user_permissions == 'GROUP_LEVEL' &&
                    <li className={currentpathval == '/admin/countries' ? "active" : ""}>
                        <a href="/admin/countries">
                            <span><i className="fa fa-flag" aria-hidden="true"></i></span>Countries
                        </a>
                    </li>
                } */}


                {/* {user_permissions == 'GROUP_LEVEL' &&
                    <li className={currentpathval == '/admin/amount-limit' ? "active" : ""}>
                        <a href="/admin/amount-limit">
                            <span><i className="fa fa-signal" aria-hidden="true"></i></span>Amount Limit
                        </a>
                    </li>
                } */}


                {/* {user_permissions == 'GROUP_LEVEL' &&
                    <li className={currentpathval == '/admin/time-limit' ? "active" : ""}>
                        <a href="/admin/time-limit">
                            <span><i className="fa fa-clock-o" aria-hidden="true"></i></span>Days And Transaction Limit Per User
                        </a>
                    </li>
                } */}

                {/* {user_role !== "KYCADMIN" &&
                    <li className={currentpathval ==
                        '/admin/MarketingMaterial' ? "active" : ""}>
                        <a href="/admin/MarketingMaterial">
                            <span><i className="fa fa-bullhorn" aria-hidden="true"></i></span> Marketing Material
                        </a>
                    </li>
                } */}

                {user_permissions == 'GROUP_LEVEL' &&

                    <li className={currentpathval == '/admin/Settings' ? "active" : ""}>
                        <a href="/admin/Settings">
                            <span><i className="fa fa-cog" aria-hidden="true"></i></span>Settings
                        </a>

                    </li>
                }


            </ul>
        </aside>

    )
}


export default reduxForm({
    form: 'imageForm' // a unique identifier for this form
})(AdminSidebar)

