import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from "react-bootstrap";
import { Field, reduxForm, SubmissionError } from "redux-form";
import axios from "axios";
import {
  USER_MANAGEMENT,
  FRONT_BASE,
  EXCHANGE_MANAGEMENT,
} from "../../config/config";
import { Layout } from "../common/Layout";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import loader_image from "../../images/loading.gif";
import user_image from "../../images/user_img_default.png";
import Modal from "react-modal";
import { adminService, userService } from "../../_services";
import Checkbox from "rc-checkbox";
import { toast } from "react-toastify";
import { Timeformatter } from "../common/Timeformatter";
import { DataHandling, HandlePermission } from "../common/DataHandling";

require("rc-checkbox/assets/index.css");

const KYCAdmin = (props: any) => {
  const { error, className, handleSubmit, pristine, reset, submitting } = props;
  const [users, setUsers] = useState([]);
  var user_role = localStorage.getItem("user_role");
  useEffect(() => {
    {
      (HandlePermission("view", "sub_admins") || user_role ==="KYCADMIN") && getData(currentPage, searchText);
      get_countries()
    }
  }, []);

  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(20);
  const [totalPage, setTotalPage] = useState(1);
  const [rateCount, setRateCount] = useState(0);
  const [isLoading, setisLoading] = useState(0);

  const [AddNewAdmin, setAddNewAdmin] = useState(false);
  const [EditNewAdmin, setEditNewAdmin] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [passwordStrength, setPasswordStrength] = useState('');
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [tmpStateLoader, setTmpStateLoader] = useState(0);
  const [searchText, setSearchText] = useState('');



  // const total = Math.round(totalPage/usersPerPage);
  let user_permissions = localStorage.getItem("user_permissions");
  let user_country = localStorage.getItem("admin_user_country") as any;
  let user_country_id = localStorage.getItem("admin_user_country_id") as any;

  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  function get_countries() {
    adminService.countries_list().then(function (response) {
      console.log("countries_list-----", response.data.data.countries);
      setCountryList(response.data.data.countries)
      // toast.success(response.data.message);

    })
      .catch(function (error) {
        console.log("error get_countries-------------------");
        // userService.handleError(error);
      });
    // api to get operational countries
  }

  const [formVar, setFormVar] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    permision: "GUEST",
    emailError: "",
    passwordError: "",
    firstNameError: "",
    lastNameError: "",
    confirmPasswordError: "",
    countryId: -1,
    countryError: "",
    passwordStrengthColor: "",
    passwordStrengthMsg: "",
    documentReport:false,
    facialSimilarityPhotoReport:false,
    watchlistStandardReport:false
  });

  const [formEditVar, setFormEditVar] = useState({
    email: "",
    subadmin_id: "",
    firstName: "",
    permision: "",
    emailError: "",
    firstNameError: "",
    countryId: -1,
    countryError: "",
    lastName: "",
    lastNameError: "",
    documentReport:false,
    facialSimilarityPhotoReport:false,
    watchlistStandardReport:false
  });

  const [formValidateVar, setFormvalidateVar] = useState({
    isEmailValidate: false,
    isPasswordValidate: false,
    isfirstNameValidate: false,
    isConfirmPasswordValidate: false,
    isLastNameValidate: false
  })

  function edit_admin(item: any) {
    setFormEditVar({
      ...formEditVar,
      firstName: item.firstName ? item.firstName : '',
      lastName: item.lastName ? item.lastName : '',
      email: item.email,
      permision: item.permision,
      subadmin_id: item.id,
      countryId: item.countryId,
      firstNameError: '',
      lastNameError: '',
      emailError: '',
      countryError: '',
      documentReport:item.documentReport,
      facialSimilarityPhotoReport:item.facialSimilarityPhotoReport,
      watchlistStandardReport:item.watchlistStandardReport
    });

    setEditNewAdmin(true);
  }
  function suspend_admin(item: any) {
    let msg: any = item.status
      ? "Are you sure you want to suspend?"
      : "Are you sure you want to active?";
    let params = {
      userId: item.id,
      status: !item.status,
    };
    if (window.confirm(msg)) {
      adminService
        .suspend_active_subadmin(params)
        .then(function (response) {
          toast.success(response.data.message);
          getData(currentPage, searchText);
        })
        .catch(function (error) {
          userService.handleError(error);
        });
    }
  }

  const total = Math.ceil(totalPage / usersPerPage);

  const getData = (pageNumber: any, searchTxt: any) => {
    adminService
      .kyc_admin_list(pageNumber, searchTxt)
      .then(function (response) {
        var data1 = response.data.subAdmins;
        console.log(data1);
        if (response.data.count > 0) {
          setisLoading(1);
        } else {
          setisLoading(2);
        }
        console.log(data1)
        setTotalPage(response.data.count);

        setUsers(data1);
      })
      .catch(function (error) {
        setisLoading(3);
        setUsers([]);
        userService.handleError(error);
      });
    }

  const submit = () => {
    if (validateForm() == true) {
      console.log(formVar);
      setIsFormLoading(true);
      adminService
        .add_kyc_admin(formVar)
        .then(function (response) {
          setIsFormLoading(false);
          closeModal();
          toast.success(response.data.message);
          getData(currentPage, searchText);
          // setProfiledata(response.data.merchant)
        })
        .catch(function (error) {
          setIsFormLoading(false);
          userService.handleError(error);
        });
    }
  };

  const submit_edit = () => {
    console.log("submit", formEditVar);
    return sleep(100).then(() => {
      if (editValidateForm()) {
        setIsFormLoading(true);
        adminService
          .edit_kyc_admin(formEditVar, formEditVar.subadmin_id)
          .then(function (response) {
            setIsFormLoading(false);
            closeModal();
            toast.success("KYC admin details updated successfully.");
            getData(currentPage, searchText);
          })
          .catch(function (error) {
            setIsFormLoading(false);
            userService.handleError(error);
          });
      }
    })
  };

  const validateForm = () => {
    if (!formValidateVar.isfirstNameValidate) {
      validateFormCase('first_name', formVar.firstName);
      return false;
    } else {
      if (!formValidateVar.isLastNameValidate) {
        validateFormCase('last_name', formVar.lastName);
        return false;
      } else {
        if (!formValidateVar.isEmailValidate) {
          validateFormCase('email', formVar.email);
          return false;
        } else {
          if (!formValidateVar.isPasswordValidate) {
            validateFormCase('password', formVar.password);
            return false;
          } else {
            if (!formValidateVar.isConfirmPasswordValidate) {
              validateFormCase('confirmPassword', formVar.confirmPassword);
              return false;
            } else {
              if (user_permissions == 'GROUP_LEVEL' && (formVar.permision == "ADMIN" || formVar.permision == "COMPLIANCE" || formVar.permision == "OPERATION")) {
                if (formVar.countryId == -1 || formVar.countryId === null) {
                  setFormVar({ ...formVar, countryError: "Please select country" });
                  return false
                }
              }
              return true
            }
          }
        }
      }
    }
  };




  const validateFormCase = (type: any, value: any) => {
    switch (type) {
      case 'first_name': {
        setFormvalidateVar({ ...formValidateVar, isfirstNameValidate: false });
        if (value == "" || value == null) {
          setFormVar({ ...formVar, firstName: "", firstNameError: "Please Enter First Name" });
          setFormvalidateVar({ ...formValidateVar, isfirstNameValidate: false });
        }
        else {
          if (!/^[A-Za-z ]+$/.test(value)) {
            setFormVar({ ...formVar, firstName: value, firstNameError: "Allowed Only Character's Here" });
          }
          else {
            setFormVar({ ...formVar, firstName: value, firstNameError: "" });
            setFormvalidateVar({ ...formValidateVar, isfirstNameValidate: true });
          }
        }
        break;
      }
      case 'last_name': {
        setFormvalidateVar({ ...formValidateVar, isLastNameValidate: false });
        if (value == "" || value == null) {
          setFormVar({ ...formVar, lastName: "", lastNameError: "Please Enter First Name" });
          setFormvalidateVar({ ...formValidateVar, isLastNameValidate: false });
        }
        else {
          if (!/^[A-Za-z ]+$/.test(value)) {
            setFormVar({ ...formVar, lastName: value, lastNameError: "Allowed Only Character's Here" });
          }
          else {
            setFormVar({ ...formVar, lastName: value, lastNameError: "" });
            setFormvalidateVar({ ...formValidateVar, isLastNameValidate: true });
          }
        }
        break;
      }

      case 'email': {
        setFormvalidateVar({ ...formValidateVar, isEmailValidate: false });
        if (value == "" || value == null) {
          setFormVar({ ...formVar, email: "", emailError: "Please Enter Email" });
        } else {
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(value)) {
            setFormVar({ ...formVar, email: value, emailError: "Please Enter Valid Email" });
          } else {
            setFormVar({ ...formVar, email: value, emailError: "" });
            setFormvalidateVar({ ...formValidateVar, isEmailValidate: true });
          }
        }
        break;
      }
      case 'password': {
        setFormvalidateVar({ ...formValidateVar, isPasswordValidate: false });
        if (value == "" || value == null) {
          setFormVar({ ...formVar, password: "", passwordError: "Please Enter Password", passwordStrengthMsg: "" });
        } else {
          let passwordStrengthColor: any = ""
          let passwordStrengthMsg: any = ""
          var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{9,})");
          var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{9,})");
          if (strongRegex.test(value)) {
            passwordStrengthColor = "green";
            passwordStrengthMsg = "* Strong Password";
            // setFormVar({ ...formVar, password: value, passwordStrengthColor: "green",  passwordStrengthMsg: "* Strong Password", passwordError: "" });
          } else if (mediumRegex.test(value)) {
            passwordStrengthColor = "orange";
            passwordStrengthMsg = "* Medium Password";
            // setFormVar({ ...formVar, password: value, passwordStrengthColor: "orange", passwordStrengthMsg: "* Medium Password", passwordError: "" });
          } else {
            passwordStrengthColor = "grey";
            passwordStrengthMsg = "* Weak Password";
            // setFormVar({ ...formVar, password: value, passwordStrengthColor: "grey", passwordStrengthMsg: "* Weak Password ", passwordError: "" });
          }


          if (!/^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{9,}$/.test(value)) {
            setFormVar({ ...formVar, password: value, passwordError: "Password Contain Atleast 9 Characters, 1-Letter and 1-Number", passwordStrengthColor: passwordStrengthColor, passwordStrengthMsg: passwordStrengthMsg });
            setFormvalidateVar({ ...formValidateVar, isPasswordValidate: true });
          } else {
            setFormVar({ ...formVar, password: value, passwordError: "", passwordStrengthColor: passwordStrengthColor, passwordStrengthMsg: passwordStrengthMsg });
            setFormvalidateVar({ ...formValidateVar, isPasswordValidate: true });
          }
        }
        break;
      }

      case 'confirm_password': {
        setFormvalidateVar({ ...formValidateVar, isConfirmPasswordValidate: false });
        if (value == "" || value == null) {
          setFormVar({ ...formVar, confirmPassword: "", confirmPasswordError: "Please Enter Your Confirm Password" });
        } else {
          if (value != formVar.password) {
            setFormVar({ ...formVar, confirmPassword: value, confirmPasswordError: "Password and Confirm Password doesn't match" });
          } else {
            setFormVar({ ...formVar, confirmPassword: value, confirmPasswordError: "" });
            setFormvalidateVar({ ...formValidateVar, isConfirmPasswordValidate: true });
          }
        }
        break;
      }
    }
  }


  const editValidateForm = () => {
    let formIsValid = true;
    if (formEditVar.firstName == "" || formEditVar.firstName == null) {
      formIsValid = false;
      formEditVar.firstNameError = "Please Enter First Name";
    } else {
      if (!/^[A-Za-z ]+$/.test(formEditVar.firstName)) {
        formIsValid = false;
        formEditVar.firstNameError = "Allowed Only Character's Here"
      }
    }
    if (formEditVar.lastName == "" || formEditVar.lastName == null) {
      formIsValid = false;
      formEditVar.lastNameError = "Please Enter Last Name";
    } else {
      if (!/^[A-Za-z ]+$/.test(formEditVar.lastName)) {
        formIsValid = false;
        formEditVar.lastNameError = "Allowed Only Character's Here"
      }
    }
    if (user_permissions === 'GROUP_LEVEL') {
      if (formEditVar.permision === "ADMIN" || formEditVar.permision === "COMPLIANCE" || formEditVar.permision === "OPERATION") {
        if (formEditVar.countryId == -1 || formEditVar.countryId === null) {
          formIsValid = false;
          formEditVar.countryError = "Please select country";
        }
      }
    }
    if (formEditVar.permision === "ADMIN" || formEditVar.permision === "COMPLIANCE" || formEditVar.permision === "OPERATION") {
      if (formEditVar.countryId == -1 && formEditVar.countryId === null) {
        formIsValid = false;
        formEditVar.countryError = "Please select country";
      }
    }

    if (formEditVar.email == "" || formEditVar.email == null) {
      formIsValid = false;
      formEditVar.emailError = "Please Enter Email";
    } else {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(formEditVar.email)) {
        formEditVar.emailError = "Please Enter Valid Email"
      }
    }
    setTmpStateLoader(tmpStateLoader + 1);
    return formIsValid;
  };

  const makeHttpRequestWithPage = async (pageNumber: any) => {
    if (pageNumber < 0) {
      pageNumber = total - 1;
    }
    if (pageNumber == total) {
      pageNumber = 0;
    }
    getData(pageNumber, searchText)
    setCurrentPage(pageNumber);
  };

  const pagination = () => {
    let table = [];

    table.push(<li><a href="javascript:void(0)" className={currentPage == 0 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage - 1)}>Previous</a></li>);
    for (let i = 0; i < total; i++) {
      table.push(<li className={i == currentPage ? "active  block_cursor_event" : ""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}>{i + 1}</a></li>);
    }
    table.push(<li><a href="javascript:void(0)" className={currentPage == total - 1 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage + 1)}>Next</a></li>);
    return table;
  };

  const UserTable = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>NAME</th>
            <th>Email Address</th>
            <th>Date</th>
            <th>Status</th>
            {HandlePermission("edit", "sub_admins") && <th>ACTION</th>}
          </tr>
        </thead>
        <tbody>
          {users.map((item: any, index: any) => {
            return (
              <tr key={index}>
                <td>
                  {(currentPage * 20) + (index + 1)}
                </td>

                <td>{(item.firstName ? item.firstName : "-") + " " + (item.lastName ? item.lastName : "")}</td>
                <td>
                  <DataHandling data={item.email} />
                </td>
                <td>
                  <Timeformatter timeget={item.createdAt} />
                </td>
                <td>{item.status ? "Active" : "Suspended"}</td>
                {HandlePermission("edit", "sub_admins") && !(((user_permissions == "ADMIN") && item.permision == "GROUP_LEVEL")) && !(item.id == localStorage.user_id) && (

                  <td>
                    <div className="dropdown slect_profile">
                      <button
                        className="btn btn-default dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                      >
                        Select
                        <span className="caret"></span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a href="#" onClick={() => edit_admin(item)}>
                            Edit
                          </a>
                        </li>
                        <li>
                          <a onClick={() => suspend_admin(item)}>
                            {" "}
                            {item.status ? "Suspend" : "Active"}{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  function closeModal() {
    setAddNewAdmin(false);
    setEditNewAdmin(false);
  }

  function openAddNewAdminModal() {
    setFormVar({
      ...formVar,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      permision: "GUEST",
      emailError: "",
      passwordError: "",
      firstNameError: "",
      lastNameError: "",
      confirmPasswordError: "",
      countryId: -1,
      countryError: "",
      passwordStrengthColor: "",
      passwordStrengthMsg: "",
      documentReport:false,
      facialSimilarityPhotoReport:false,
      watchlistStandardReport:false
    });
    setAddNewAdmin(true);
  }

  function changeEditPermission(permission_type: any) {
    setFormEditVar({ ...formEditVar, countryId: (user_permissions === 'GROUP_LEVEL' || permission_type === 'GUEST' ? -1 : user_country_id), permision: permission_type, countryError: '' });
  }

  function getSearchedData(val: any) {
    setSearchText(val);
    getData(0, val);
  }
  return (
    <Layout>
      <section className="inner_sction">
        <section className="table_sction accunt_table">
          <div className="container-fluid">
            <div className="table_sction_outer admin_index loader_parent">
              <div className="top-sctin mbtm-0">
                <h4>KYC Admin</h4>

                {HandlePermission("edit", "sub_admins") && (
                  <a
                    href="#"
                    onClick={() => openAddNewAdminModal()}
                    id="add_rate_button"
                    className="btn btn-primary"
                  >
                    Add New KYC Admin
                  </a>
                )}
                <div className="form-group pull-right">
                  <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                  <input type="text" className="form-control" placeholder="Search user by Name, Email" onChange={(e) => getSearchedData(e.target.value)} />
                </div>
                <div className="form-group pull-right">
                  {/* <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button> */}
                  {/* <input type="text" className="form-control" placeholder="Search with name"/> */}
                </div>
              </div>


              <div className="table_inner">
                <div className="table-responsive">
                  {isLoading == 0 && (
                    <div id="api_loader">
                      {/* <img src={loader_image} width="50" height="50" />Loading... */}
                      <div id="" className="loaderbox">
                        <div>
                          <img
                            className="bounce-2"
                            src={loader_image}
                            width="50"
                            height="50"
                          />
                          <p>Loading...</p>
                        </div>
                      </div>
                    </div>
                  )}
                  {isLoading == 1 && UserTable()}
                  {isLoading == 2 && <h1>Record Not Found</h1>}
                  {isLoading == 3 && <h1>Some Error Occur</h1>}
                </div>
                <div className="pagination_sction">
                  <ul className="pagination">
                    {total > 0 ? pagination() : ""}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <Modal
        ariaHideApp={false}
        isOpen={EditNewAdmin}
        onRequestClose={closeModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setEditNewAdmin(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Edit</h3>
        </div>
        <div className="modal-body">
          <div className="user_request">
            <form onSubmit={handleSubmit((val: any) => submit_edit())}>

              <div className="form-group ">
                <label>First Name:</label>
                <div className="input_box">
                  <input
                    className="form-control form-control-two"
                    value={formEditVar.firstName}
                    // onChange={(e: any) => validateEditFormCase('first_name', e.target.value)}
                    onChange={(e: any) => setFormEditVar({ ...formEditVar, firstName: e.target.value, firstNameError: "" })}
                    placeholder="Enter First Name"
                    maxLength={30}
                  ></input>
                  {formEditVar.firstNameError.length > 0 && (
                    <span style={{ color: "red" }}>{formEditVar.firstNameError}</span>
                  )}
                </div>
              </div>

              <div className="form-group ">
                <label>Last Name:</label>
                <div className="input_box">
                  <input
                    className="form-control form-control-two"
                    value={formEditVar.lastName}
                    // onChange={(e: any) => validateEditFormCase('last_name', e.target.value)}
                    onChange={(e: any) => setFormEditVar({ ...formEditVar, lastName: e.target.value, lastNameError: "" })}
                    placeholder="Enter Your Last Name"
                    maxLength={30}
                  ></input>
                  {formEditVar.lastNameError.length > 0 && (
                    <span style={{ color: "red" }}>{formEditVar.lastNameError}</span>
                  )}
                </div>
              </div>

              <div className="form-group ">
                <label>Email:</label>
                <div className="input_box">
                  <input
                    className="form-control form-control-two"
                    value={formEditVar.email}
                    // onChange={(e: any) => validateEditFormCase('email', e.target.value)}
                    onChange={(e: any) => setFormEditVar({ ...formEditVar, email: e.target.value, emailError: "" })}
                    placeholder="Enter Email"
                    maxLength={64}
                  ></input>
                  {formEditVar.emailError.length > 0 && (
                    <span style={{ color: "red" }}>
                      {formEditVar.emailError}
                    </span>
                  )}
                </div>
              </div>
              
              <div className="form-group ">
                <label>Permission:</label>
                <div className="check_box_list">
                <p>
                    <label>
                      <input
                        type="checkbox"
                        value="documentReport"
                        checked={formEditVar.documentReport}
                        onChange={(e: any) => setFormEditVar({...formEditVar, documentReport:e.target.checked})
                        }
                      />
                      &nbsp; Document Report
                    </label>
                  </p>

                  <p>
                    <label>
                      <input
                        type="checkbox"
                        value="facialSimilarityPhotoReport"
                        checked={formEditVar.facialSimilarityPhotoReport}
                        onChange={(e: any) =>
                          setFormEditVar({...formEditVar, facialSimilarityPhotoReport:e.target.checked})
                        }
                      />
                      &nbsp; Facial Similarity 
                    </label>
                  </p>

                  <p>
                    <label>
                      <input
                        type="checkbox"
                        value="watchlistStandardReport"
                        checked={formEditVar.watchlistStandardReport}
                        onChange={(e: any) =>
                          setFormEditVar({...formEditVar, watchlistStandardReport:e.target.checked})
                        }
                      />
                      &nbsp; Watchlist
                    </label>
                  </p>
                  </div>
              </div>
              <div className="bottom_btn">
                <button className="btn btn-lg btn-primary btn-block">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        {isFormLoading && (
          <div id="" className="loaderbox loginloader">
            <div>
              <img
                className="bounce-2"
                src={loader_image}
                width="50"
                height="50"
              />
              <p>Loading...</p>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={AddNewAdmin}
        onRequestClose={closeModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setAddNewAdmin(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Add New KYC Admin</h3>
        </div>
        <div className="modal-body">
          <div className="user_request">
            <form onSubmit={handleSubmit((val: any) => submit())}>

              <div className="form-group ">
                <label>First Name:</label>
                <div className="input_box">
                  <input
                    className="form-control form-control-two"
                    value={formVar.firstName}
                    onChange={(e: any) => validateFormCase('first_name', e.target.value)}
                    placeholder="Enter First Name"
                    maxLength={30}
                  ></input>
                  {formVar.firstNameError.length > 0 && (
                    <span style={{ color: "red" }}>{formVar.firstNameError}</span>
                  )}
                </div>
              </div>

              <div className="form-group ">
                <label>Last Name:</label>
                <div className="input_box">
                  <input
                    className="form-control form-control-two"
                    value={formVar.lastName}
                    onChange={(e: any) => validateFormCase('last_name', e.target.value)}
                    placeholder="Enter Your Last Name"
                    maxLength={30}
                  ></input>
                  {formVar.lastNameError.length > 0 && (
                    <span style={{ color: "red" }}>{formVar.lastNameError}</span>
                  )}
                </div>
              </div>

              <div className="form-group ">
                <label>Email:</label>
                <div className="input_box">
                  <input
                    className="form-control form-control-two"
                    value={formVar.email}
                    onChange={(e: any) => validateFormCase('email', e.target.value)}
                    placeholder="Enter Email"
                    maxLength={64}
                  ></input>
                  {formVar.emailError.length > 0 && (
                    <span style={{ color: "red" }}>{formVar.emailError}</span>
                  )}
                </div>
              </div>

              <div className="form-group ">
                <label>Password:</label> {<span style={{ color: formVar.passwordStrengthColor, position: 'absolute', marginTop: '50px', marginLeft: '47%' }}>{formVar.passwordStrengthMsg}</span>}
                <div className="input_box">
                  <input
                    type="password"
                    className="form-control form-control-two"
                    value={formVar.password}
                    onChange={(e: any) => validateFormCase('password', e.target.value)}
                    placeholder="Enter Password"
                    minLength={9}
                  ></input>
                  {formVar.passwordError.length > 0 && (
                    <span style={{ color: "red" }}>
                      {formVar.passwordError}
                    </span>
                  )}
                </div><div style={{ color: 'grey', marginTop: '10px' }}><p>NOTE- Password Must Contain Atleast <span style={{ color: '#4097e4' }}>9 Characters</span>, Containing <span style={{ color: '#4097e4' }}>1-Letter</span> and <span style={{ color: '#4097e4' }}>1-Number</span>.</p></div>
                {/* <div style={{ color: 'grey', marginTop: '10px'}}><span>NOTE- Password must have minimum 9 Character's.</span></div> */}
              </div>

              <div className="form-group ">
                <label>Confirm Password:</label>
                <div className="input_box">
                  <input
                    type="password"
                    className="form-control form-control-two"
                    value={formVar.confirmPassword}
                    onChange={(e: any) => validateFormCase('confirm_password', e.target.value)}
                    placeholder="Re-Enter Password"
                    minLength={9}
                  ></input>
                  {formVar.confirmPasswordError.length > 0 && (
                    <span style={{ color: "red" }}>
                      {formVar.confirmPasswordError}
                    </span>

                  )}
                </div>
              </div>
              <div className="form-group ">
                {/* while adding */}
                <label>Permission:</label>

                <div className="check_box_list">
                  <p>
                    <label>
                      <input
                        type="checkbox"
                        value="documentReport"
                        checked={formVar.documentReport}
                        onChange={(e: any) => setFormVar({...formVar, documentReport:e.target.checked})
                        }
                      />
                      &nbsp; Document Report
                    </label>
                  </p>

                  <p>
                    <label>
                      <input
                        type="checkbox"
                        value="facialSimilarityPhotoReport"
                        checked={formVar.facialSimilarityPhotoReport}
                        onChange={(e: any) =>
                          setFormVar({...formVar, facialSimilarityPhotoReport:e.target.checked})
                        }
                      />
                      &nbsp; Facial Similarity 
                    </label>
                  </p>

                  <p>
                    <label>
                      <input
                        type="checkbox"
                        value="watchlistStandardReport"
                        checked={formVar.watchlistStandardReport}
                        onChange={(e: any) =>
                          setFormVar({...formVar, watchlistStandardReport:e.target.checked})
                        }
                      />
                      &nbsp; Watchlist
                    </label>
                  </p>
                </div>
              </div>
              <div className="bottom_btn">
                <button className="btn btn-lg btn-primary btn-block">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        {isFormLoading && (
          <div id="" className="loaderbox loginloader">
            <div>
              <img
                className="bounce-2"
                src={loader_image}
                width="50"
                height="50"
              />
              <p>Loading...</p>
            </div>
          </div>
        )}
      </Modal>
    </Layout>
  );
};

export default reduxForm({
  form: "searchForm", // a unique identifier for this form
})(KYCAdmin);