import * as React from 'react';
import { Col, Row } from 'react-bootstrap'

const Footer = () => {
    return (
        // <footer className="App-footer">
        //     <div className="footer-copyright text-center py-3">© 2022, All Rights Reserved
        //     </div>
        // </footer>
        <div style={{ position: 'fixed', left: '0', bottom: '0', width: '100%', color: 'dark', textAlign: 'center', backgroundColor: 'white', zIndex: 100 }}>
            <p style={{ margin: '20px' }}>© {new Date().getFullYear()}, All Rights Reserved</p>
        </div>
    )
}

export default Footer