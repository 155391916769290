import React, { useEffect, useState } from 'react';
import loader_image from '../../images/loading.gif';
import ReactPlayer from 'react-player'
import { adminService, userService } from '../../_services';
import { sha256, sha224 } from 'js-sha256';
import { config } from '../../config/config';
import Modal from "react-modal";

const CustomereKYCDetails = (props: any) => {

  const [kycUserData, setKycUserData] = useState() as any;
  const [loaderKyc, setLoaderKyc] = useState(true);
  const { email, iskycverified } = props
  const logged_user_id = localStorage.getItem("user_id")
  const [viewOriginalDocumentModal, setViewOriginalDocumentModal] = useState(false);
  const [viewOriginalVideoMohal, setViewOriginalVideoModal] = useState(false);
  const [viewDocumentModal, setViewDocumentModal] = useState(false);
  const [viewVideoMohal, setViewVideoModal] = useState(false);

  useEffect(() => {
    if (iskycverified) {
      get_kyc_status(email);
    }
  }, []);

  const get_kyc_status = (email: any) => adminService
    .account_detail(email)
    .then(res => {
      console.log("sssssssssss", res.data)
      setKycUserData(res.data)
      setLoaderKyc(false)
    })
    .catch(err => {
      console.log("errrrr", err)
      setLoaderKyc(false)
    })


  // function get_kyc_status(email: any) {
  //     let api_key = "prod-GyNtgjCefFzWrOCMjYFCdoxpZTumokrl";
  //     let sha_key = sha256(email + "s2-HAwCJvRSROozaRRZpWYwrNlnqlMRbktI");
  //     userService.get_kyc_status(email, api_key, sha_key).then(function (response) {
  //         console.log("responsee---get_kyc_status ", response.data.data);
  //         console.log("responsee---get_kyc_status ", response.data.profile);
  //         setKycUserData(response.data.data);
  //         setLoaderKyc(false)
  //     }).catch(function (error) {
  //         console.log("error---", error);
  //         setLoaderKyc(false);
  //         userService.handleError(error);
  //         //   return "Not Available"
  //     })
  // }

  const closeViewVideoModal = () => {
    setViewVideoModal(false);
  }

  const closeViewDocumentModal = () => {
    setViewDocumentModal(false)
  }

  const closeViewOriginalDocumentModal = () => {
    setViewOriginalDocumentModal(false)
  }

  const closeOriginalViewVideoModal = () => {
    setViewOriginalVideoModal(false)
  }

  function show_data(data: string) {
    return data ? data : "-"


  }

  function KycDetails() {

    return (
      <div className="table_sction_outer kyc_details loader_parent">
        {/* { loaderKyc &&
                    <div id="" className="loaderbox loginloader innerloader"><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div>
                } */}
        {/* <div className="top-sctin profile_inner_sction">
                    {kycUserData && kycUserData.status == "user_not_found" &&
                     <div style={{ textAlign: 'center' }}>
                         <img src={require("../../images/kyc_iocn.png")} />
                         <p className="step_name">eKYC not submitted yet.</p>
                     </div>
                        // <div className='text-center'><p className="step_name red">eKYC not submitted</p></div>
                    }
                </div> */}
        <div className="profile_filled">

          {kycUserData && kycUserData.userDto &&
            <div className="listng_user_info ">
              {/* <p className="step_name">Step1: Tell us about yourself </p> */}
              <ul>

                <li>
                  <p>First Name:</p>
                  <h5>{show_data(kycUserData.userDto.firstName)}</h5>
                </li>

                <li>
                  <p>Last Name:</p>
                  <h5>{show_data(kycUserData.userDto.lastName)}</h5>
                </li>
              </ul>

              <hr />
              {kycUserData.userDto?.onfidoOriginalDocumentLink &&
              <button className="action-btn1" onClick={() => { setViewOriginalDocumentModal(true) }}>View Original Document</button>}
              {kycUserData.userDto?.onfidoOriginalVideoLink &&
              <button className="action-btn1" onClick={() => { setViewOriginalVideoModal(true) }}>View Original Video</button>}
              {kycUserData.userDto?.onfidoDocumentLink && <button className="action-btn1" onClick={() => { setViewDocumentModal(true) }}>View Document</button>}
              {kycUserData.userDto?.onfidoVideoLink &&<button className="action-btn1" onClick={() => { setViewVideoModal(true) }}>View Video</button>}
              {/* <div className="row">
                                <div className="col-sm-12">
                                    <div className="doc_box text-center">
                                        {kycUserData.userDto.onfidoOriginalDocumentLink &&
                                            <div className="input_box">
                                                <img src={config.image_base_url + kycUserData.userDto.onfidoOriginalDocumentLink.trim()} alt="" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="doc_box text-center">
                                        {kycUserData.userDto.onfidoOriginalDocumentLink &&
                                            <div className="input_box">
                                                <video autoPlay controls>
                                                    <source src={config.image_base_url + kycUserData.userDto.onfidoOriginalVideoLink} type="video/mp4"></source>
                                                    <source src={config.image_base_url + kycUserData.userDto.onfidoOriginalVideoLink} type="video/ogg"></source>
                                                </video>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div> */}
            </div>
          }
        </div>
        <Modal
          ariaHideApp={false}
          isOpen={viewDocumentModal}
          onRequestClose={closeViewDocumentModal}
          //  style={customStyles1}
          className={"modal-content custom_modal thakyou_pop"}
          contentLabel="Example Modal"
          overlayClassName="Overlay"
        >
          <div className="modal-header">
            <button
              type="button"
              className="close"
              onClick={() => setViewDocumentModal(false)}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
            <h3 className="modal-title">Document</h3>
          </div>

          <div className="modal-body">
            <div className="user_request">
              <div className="form-group ">
                <div className="input_box">
                  <img src={config.image_base_url + kycUserData?.userDto.onfidoDocumentLink} alt="" />
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={viewOriginalDocumentModal}
          onRequestClose={closeViewOriginalDocumentModal}
          //  style={customStyles1}
          className={"modal-content custom_modal thakyou_pop doc_modal"}
          contentLabel="Example Modal"
          overlayClassName="Overlay"
        >
          <div className="modal-header">
            <button
              type="button"
              className="close"
              onClick={() => setViewOriginalDocumentModal(false)}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
            <h3 className="modal-title">Original Document</h3>
          </div>

          <div className="modal-body">
            <div className="user_request">
              <div className="form-group ">
                {kycUserData?.userDto?.onfidoOriginalDocumentLink !== null && kycUserData?.userDto?.onfidoOriginalDocumentLink.split(",").map((image: any) => (
                  <div className="input_box">
                    <img src={config.image_base_url + image.trim()} alt="" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal>


        <Modal
          ariaHideApp={false}
          isOpen={viewVideoMohal}
          onRequestClose={closeViewVideoModal}
          //  style={customStyles1}
          className={"modal-content custom_modal thakyou_pop"}
          contentLabel="Example Modal"
          overlayClassName="Overlay"
        >
          <div className="modal-header">
            <button
              type="button"
              className="close"
              onClick={() => setViewVideoModal(false)}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
            <h3 className="modal-title">Video</h3>
          </div>

          <div className="modal-body">
            <div className="user_request">
              <div className="form-group ">
                <div className="input_box">
                  <video autoPlay controls>
                    <source src={config.image_base_url + kycUserData?.userDto?.onfidoVideoLink} type="video/mp4"></source>
                    <source src={config.image_base_url + kycUserData?.userDto?.onfidoVideoLink} type="video/ogg"></source>
                  </video>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={viewOriginalVideoMohal}
          onRequestClose={closeOriginalViewVideoModal}
          //  style={customStyles1}
          className={"modal-content custom_modal thakyou_pop"}
          contentLabel="Example Modal"
          overlayClassName="Overlay"
        >
          <div className="modal-header">
            <button
              type="button"
              className="close"
              onClick={() => setViewOriginalVideoModal(false)}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
            <h3 className="modal-title">Original Video</h3>
          </div>

          <div className="modal-body">
            <div className="user_request">
              <div className="form-group ">
                <div className="input_box">
                  <video autoPlay controls>
                    <source src={config.image_base_url + kycUserData?.userDto?.onfidoOriginalVideoLink} type="video/mp4"></source>
                    <source src={config.image_base_url + kycUserData?.userDto?.onfidoOriginalVideoLink} type="video/ogg"></source>
                  </video>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>



    );
  }




  function NoKycDetails() {
    return (
      <div className="table_sction_outer nokyc_details">
        <div style={{ textAlign: 'center' }}>
          <img src={require("../../images/kyc_iocn.png")} />
          <p className="step_name">eKYC not submitted yet.</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      {iskycverified ? <KycDetails /> : <NoKycDetails />}
    </div>


  )
}

export default (CustomereKYCDetails)
