
import Header from './Header';
import Footer from './Footer';

import AdminSidebar from './AdminSidebar';
import AdminHeader from './AdminHeader';
import React, {useEffect, useState} from 'react';

export class Layout extends React.Component<{}, {}> {

componentWillMount() {
    console.log("hteloooooooo")
    this.getData();
    
}
componentDidMount() {
  this.getData();
}


  getData(){
  
    const element = document.querySelector(".sidebarCollapse");
    const element2 = document.querySelector(".responsive_icon.sidebarCollapse");
    
    if (element!=null){
     element.addEventListener("click", this.myScript);
    }
    if (element2!=null){
      element2.addEventListener("click", this.myScript);
     }
  }
   myScript(){
    const element = document.querySelector(".sidebarCollapse");
    var has_active =element!=null && element.classList.contains("active") 

    if (has_active==false){

      var adminheader = document.querySelector(".admin_header")
      let has_active_hesder = adminheader!=null && adminheader.classList.contains("active")
      
     if(has_active_hesder==false){
      var header = document.querySelector(".header")
      header!=null && header.classList.add("active")
      var admin_main = document.querySelector(".admin-main")
      admin_main!=null && admin_main.classList.add("active") 
      var sidebar = document.querySelector("#sidebar")
      sidebar!=null && sidebar.classList.add("active") 

     }else{
      var header = document.querySelector(".header")
      header!=null && header.classList.remove("active")
      var admin_main = document.querySelector(".admin-main")
      admin_main!=null && admin_main.classList.remove("active") 
      var sidebar = document.querySelector("#sidebar")
      sidebar!=null && sidebar.classList.remove("active") 
     }
      
    }else{
      var header = document.querySelector(".header")
      header!=null && header.classList.remove("active")
      var admin_main = document.querySelector(".admin-main")
      admin_main!=null && admin_main.classList.remove("active") 
      var sidebar = document.querySelector("#sidebar")
      sidebar!=null && sidebar.classList.remove("active") 
    }
  }
  render() {



    // useEffect(() => {
   
  
    //   getData();
    // }, []);

    
    const {children} = this.props;
    const user_role = localStorage.getItem('user_role');

    var pathname = window.location.pathname;
    var title = '';

    if(pathname === '/')
      title = 'Dashboard'

    else if(pathname === '/admin/RequestDetails')
      title = 'Admin Request'

    else if(pathname === '/merchant/listing')
      title = 'Partners'

    else if(pathname === '/admin/PartnerProfile')
      title = 'Partner'
    
    else if(pathname === '/transactions')
      title = 'Transactions'

    else if(pathname === '/account')
      title = 'Accounts'

    else if(pathname === '/admin/AccountDetails')
      title = 'Accounts'

    else if(pathname === '/wallet')
      title = 'Wallet'

    else if(pathname === '/admin/ExpiredTransactions')
      title = 'Expired Transactions'
    
    else if(pathname === '/admin/AcceptedRequests')
      title = 'Accepted Requests'
    
    else if(pathname === '/order')
      title = 'Orders'

    else if(pathname === '/admin/CustomerSupport')
      title = 'Customer Support'

    else if(pathname === '/merchant/notification')
      title = 'Notification'
    
    else if(pathname === '/admin/RateInventory')
      title = 'Rate & Inventory'
      
    else if(pathname === '/admin/Branches')
      title = 'Branches'
    
    else if(pathname === '/admin/BranchDetails')
      title = 'Branches'
    
    else if(pathname === '/admin/SuspiciousTransactions')
      title = 'Suspicious Transactions'
  
    else if(pathname === '/admin/Settings')
      title = 'Settings'
      
    else if(pathname === '/admin/Activites')
      title = 'Activites'


    else if(pathname === '/admin/SubAdmin')
      title = 'Users'
      
    else if(pathname === '/admin/amount-limit')
    title = 'Amount Limit'

    else if(pathname === '/admin/time-limit')
    title = 'Days And Transactions Limit Per User'
    
    else if(pathname === '/admin/countries')
    title = 'Countries'

    else if(pathname === '/admin/compliance')
    title = 'Compliance'
    return (
        <div className={(user_role === "ADMIN" || user_role==="KYCADMIN") ? "main admin-main": "main"}>
            {(user_role === "ADMIN" || user_role === "KYCADMIN") &&<AdminSidebar/>}
            {(user_role === "ADMIN" || user_role === "KYCADMIN") &&<AdminHeader title={title}/>}
            {(user_role === "MERCHANT" || user_role === "BRANCH" ) &&<Header/>}
            {(user_role === "MERCHANT" || user_role === "BRANCH" ) &&<Footer/>}
            {children}
        </div>
    )
  }
}
