import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError, change } from 'redux-form'
import Count from '../Count';
import Merchant from '../Merchant';
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Layout } from '../common/Layout'
import axios from 'axios';
import $ from 'jquery';
import user_image from '../../images/user_img_default.png';
import { MERCHANT_WALLET, USER_MANAGEMENT, EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT } from '../../config/config';
import loader_image from '../../images/loading.gif';
import ReactTelephoneInput from 'react-telephone-input'
import { adminService, merchantService, userService } from '../../_services';
import { DataHandling, HandlePermission } from '../common/DataHandling'
import { Amountformatter } from '../../components/common/AmountFormatter';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import Transaction from '../merchant/Transaction';
import { useLocation } from 'react-router-dom';
interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
}



const PartnerProfile = (props: any) => {
    let merchant_id = props.match.params.id;

    const [rates, setRates] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [usersPerPage, setUsersPerPage] = useState(20);
    const [totalPage, setTotalPage] = useState(1);
    const [isLoading, setisLoading] = useState(0);
    const [image, setimage] = useState() as any;
    const [cover_pic, setcover_pic] = useState() as any;
    const [profiledata, setProfiledata] = useState() as any;
    const [countryCode, setcountryCode] = useState();
    const [newCommision, setNewCommision] = useState();
    const [commisionModal, setCommisionModal] = useState(false)
    const [dailyLimitModal, setDailyLimitModal] = useState(false);
    const [currencyDailyLimit, setCurrencyDailyLimit] = useState("") as any;
    const [currencyDailyLimitSelected, setCurrencyDailyLimitSelected] = useState("") as any;
    const [mechantCurrencies, setMechantCurrencies] = useState([]);
    const { error, handleSubmit, pristine, reset, submitting } = props
    const [selectCurrencyError, setSelectCurrencyError] = useState("");
    const [downloadFile, setdownloadFile] = useState(false)
    const [currencyDailyLimitError, setCurrencyDailyLimitError] = useState("");
    const [newCommisionError, setNewCommisionError] = useState("");
    const [isFormLoading, setIsFormLoading] = useState(false);

    const urlLocation = useLocation();
    const queryParams = new URLSearchParams(urlLocation.pathname);
    const merchentId = queryParams.get('id')
    console.log("merchentId", merchentId)
    // const activeMenu = queryParams.get('activeMenu');


    const [formDailyLimitVar, setFormDailyLimitVar] = useState({
        amount: "",
        currencyId: "-1",
        amountError: "",
        currencyIdError: "",
        isAmountValidate: false,
        isCurrencyIdValidate: false
    });

    const customStyles12 = {
        overlay: {
            display: "block",
            paddingRight: "15px",
            background: "#00000054"
        }
    };
    const customStyles1 = {
        overlay: {
            display: "block",
            paddingRight: "15px",
            backgroundColor: 'rgba(51,51,51,0.8)',
            zIndex: 99
        },

        content: {
            position: "",
            top: "",
            right: "",
            bottom: "",
            left: "",
        }
    };


    function setdailycurrecncydailylimit(e: any) {
        setCurrencyDailyLimit(e.target.value)
        setCurrencyDailyLimitError("");
    }

    function setNewCommision_feild(e: any) {
        setNewCommision(e.target.value)
        setNewCommisionError("");

    }


    function save_commision() {
        let commision: any = newCommision

        if (commision == "") {
            setNewCommisionError("Please Enter Commission");
        } else {
            if (/^[ ]+$/.test(commision) || !/^\d+(\.\d+)?$/.test(commision) || /^[0.]+$/.test(commision)) {
                setNewCommisionError("Please Enter Valid Commission");
            } else {
                setIsFormLoading(true);
                let params = {
                    merchantId: merchant_id,
                    commision: newCommision
                }
                adminService.set_merchant_commission(params).then(function (response) {
                    setIsFormLoading(false);
                    toast.success(response.data.data.message)
                    setCommisionModal(false)
                    setProfiledata(response.data.data.merchant)
                }).catch(function (error) {
                    setIsFormLoading(false);
                    console.log("error")
                    userService.handleError(error);
                })
            }

        }

    }

    function submit() {
        if (validateDailyLimitForm()) {
            let params = {
                id: merchant_id,
                currencyId: formDailyLimitVar.currencyId,
                limit: formDailyLimitVar.amount
            }
            if (validate_form(params, "daily_limit")) {
                setIsFormLoading(true);
                adminService.merchant_daily_limit(params).then(function (response) {
                    toast.success(response.data.message)
                    setIsFormLoading(false);
                    setProfiledata(response.data.merchant)
                    closeModal()

                }).catch(function (error) {
                    setIsFormLoading(false);
                    userService.handleError(error);
                })
            }
        }
    }

    function validate_form(formdata: any, typ: any) {
        let is_valid: any = true

        if (typ == "daily_limit") {
            if (formdata.currencyId == "") {
                setSelectCurrencyError("Please select currency");
                is_valid = false;
            }
            if (!(/^[-+]?\d*\.?\d*$/.test(formdata.limit))) {
                is_valid = false;
                setCurrencyDailyLimitError("Please enter valid amount");
            }
        }
        if (typ == "commision") {
            if (!(/^[-+]?\d*\.?\d*$/.test(formdata.commision))) {
                is_valid = false;
                setNewCommisionError("Please enter valid commision");
            }
        }
        return is_valid;
    }

    const total = Math.round(totalPage / usersPerPage);


    const setDailyLimitCurrency = (event: any) => {
        let index = event.target.selectedIndex;
        let optionElement = event.target.childNodes[index];
        let optionElementId = optionElement.getAttribute('id');
        setFormDailyLimitVar({ ...formDailyLimitVar, currencyId: optionElementId, currencyIdError: (optionElementId == "-1" ? "Please Select Currency" : ""), isCurrencyIdValidate: (optionElementId == "-1" ? false : true) })
    }

    const validateFormCase = (type: any, value: any) => {
        switch (type) {
            case 'daily_limt_currency': {
                setFormDailyLimitVar({ ...formDailyLimitVar, currencyId: value, currencyIdError: (value == "-1" ? "Please Select Currency" : ""), isCurrencyIdValidate: (value == "-1" ? false : true) })
                break;
            }

            case 'daily_limit_amount': {
                if (value == "" || value == null) {
                    setFormDailyLimitVar({ ...formDailyLimitVar, amount: "", amountError: "Please Enter Amount", isAmountValidate: false });
                }
                else {
                    if (!/^\d+(\.\d+)?$/.test(value) || /^[0.]+$/.test(value)) {
                        setFormDailyLimitVar({ ...formDailyLimitVar, amount: value, amountError: "Please Enter Valid Amount", isAmountValidate: false });
                    }
                    else {
                        setFormDailyLimitVar({ ...formDailyLimitVar, amount: value, amountError: "", isAmountValidate: true });
                    }
                }
                break;
            }
        }

    }

    function validateDailyLimitForm() {
        if (!formDailyLimitVar.isCurrencyIdValidate) {
            validateFormCase('daily_limt_currency', formDailyLimitVar.currencyId);
            return false;
        } else {
            if (!formDailyLimitVar.isAmountValidate) {
                validateFormCase('daily_limit_amount', formDailyLimitVar.amount);
                return false;
            }
            return true;
        }
    }

    function closeModal() {
        setDailyLimitModal(false)
        setCommisionModal(false)
    }

    useEffect(() => {
        getData();
        getCurrency()
    }, []);

    function getCurrency() {
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
            let config1 = {
                headers: {
                    'Content-Type': 'application/json',
                    'deviceType': 'Android',
                    'appVersion': '1.0',
                    'Authorization': 'Bearer ' + access_token
                }
            }

            axios.get(`${MERCHANT_WALLET}/currencies`, config1).then(function (responsedata) {
                setMechantCurrencies(responsedata.data.data.currencies)
            }).catch(function (error) {
                console.log(error)
            })

        }
    }

    function active_suspend_account(status: any) {
        let msg: any = status ? "Are you sure you want to active?" : "Are you sure you want to suspend?"
        let params = {
            id: merchant_id,
            status: (status)
        }
        if (window.confirm(msg)) {
            adminService.suspend_merchant(params).then(function (response) {
                toast.success(response.data.message)
                setProfiledata(response.data.merchant)

            }).catch(function (error) {
                userService.handleError(error);
            })
        }
    }



    const getData = () => {
        adminService.get_merchant_profile(merchant_id).then(function (response) {
            setProfiledata(response.data.data.merchant)
            setFormDailyLimitVar({ ...formDailyLimitVar, amount: response.data.data.merchant.wallets.length > 0 ? response.data.data.merchant.wallets[0].dailyLimit : "" })
        }).catch(function (error) {
            userService.handleError(error);

        })


    }
    function openDailyLimitModal() {
        setFormDailyLimitVar({ ...formDailyLimitVar, amount: "", isAmountValidate: false, amountError: "", currencyId: "-1", currencyIdError: "", isCurrencyIdValidate: false })


        setDailyLimitModal(true);

    }
    return (
        <Layout>
            <section className="inner_sction">
                <section className="table_sction">
                    <div className="container-fluid">
                        <a href="/merchant/listing" className="page_back_btn"><i className="fa fa-chevron-left" aria-hidden="true"></i>Back</a>
                        {profiledata &&
                            <div className="table_sction_outer">
                                <div className="top-sctin profile_inner_sction">
                                    <h4>Partner Details</h4>

                                    <a className="right-anchor-tag" href={adminService.get_download_report(merchant_id)} target="_blank">
                                        Click here to download
                                    </a>
                                </div>
                                <div className="profile_filled">
                                    <div className="profile_upd text-center">
                                    </div>
                                    <div className="listng_user_info">
                                        <ul>
                                            <li>
                                                <p>Name:</p>
                                                <h5>
                                                    <DataHandling data={profiledata.firstName + " " + profiledata.lastName} />
                                                </h5>
                                            </li>
                                            <li>
                                                <p>Phone Number:</p>
                                                <h5>{profiledata.countryCode != null ? "+" + profiledata.countryCode + "-" : ""}{profiledata ? (profiledata.phoneNumber == null ? " - " : profiledata.phoneNumber) : " - "}</h5>
                                            </li>
                                            <li>
                                                <p>Email Address:</p>
                                                <h5><DataHandling data={profiledata.email} /></h5>
                                            </li>
                                            <li>
                                                <p>Account Status:</p>
                                                <h5 className={profiledata.status ? "green" : "red"}> {profiledata.status ? "Active" : "Suspended"} </h5>
                                            </li>
                                            <li>
                                                <p>Bank Name:</p>
                                                <h5><DataHandling data={profiledata?.bankName} /></h5>
                                            </li>
                                            <li>
                                                <p>Account Number:</p>
                                                <h5><DataHandling data={profiledata?.accountNumber} /></h5>
                                            </li>
                                            <hr />
                                            <li>
                                                <p>Partner Daily Limit:</p>
                                                <ul className="ul_daily_limit">
                                                    {profiledata && profiledata.wallets.length > 0 && profiledata.wallets.map((item: any, index: any) => {
                                                        return (
                                                            <li key={index}>
                                                                <span>{item.currency ? item.currency.name : ""}</span>
                                                                <span><img src={require("../../images/bold_arrow_right.png")} style={{ border: '0px solid #ddd', width: '15px' }} /></span>
                                                                <span>{item.dailyLimit ? <Amountformatter amount={item.dailyLimit} /> : '0.00'}</span>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                {profiledata && profiledata.wallets.length == 0 &&
                                                    <h5>{profiledata.dailyLimit ? profiledata.dailyLimit : profiledata.merchantServices ? profiledata.merchantServices[0].universalDailyLimit : ""}</h5>
                                                }

                                            </li>

                                            {profiledata.merchantCommision != null &&
                                                <li>
                                                    <p>
                                                        Merchant Commision
                                                    </p>
                                                    {profiledata.merchantCommision.commision}
                                                </li>
                                            }
                                        </ul>

                                        {HandlePermission("edit", "partners") &&
                                            <div className="bottom_btn">

                                                <a className="change_cursor_to_pointer" onClick={openDailyLimitModal} id="merchnt_profile" >Set Daily Limit</a>
                                                {profiledata.status ?
                                                    <a className="blue_btn change_cursor_to_pointer" onClick={() => active_suspend_account(!(profiledata.status))} id="merchnt_profile" >Click to Suspend</a>
                                                    :

                                                    <a className="blue_btn change_cursor_to_pointer" onClick={() => active_suspend_account(!(profiledata.status))} id="merchnt_profile" >Click to Activate</a>
                                                }
                                                <a className="change_cursor_to_pointer" onClick={() => setCommisionModal(true)} id="merchnt_profile" >Set Commission</a>


                                            </div>
                                        }
                                    </div>



                                </div>
                            </div>
                        }
                    </div>
                    {console.log('merchant_id:', merchant_id)}
                    <div>
                        {merchant_id && <div className='transaction-content'>
                            <Transaction />
                        </div>}
                    </div>
                </section>

            </section>



            <Modal
                ariaHideApp={false}
                isOpen={false}
                onRequestClose={closeModal}
                className="modal-content new_modal_content login_modal"
                contentLabel="Example Modal"
                overlayClassName="Overlay"
            >
                <div className="modal-content new_modal_content add_new_card">
                    <div className="modal-header">
                        <h4 className="modal-title">Add Bank Details<span><i onClick={() => closeModal()} className="fa fa-times-circle-o" aria-hidden="true" data-dismiss="modal"></i></span> </h4>
                    </div>

                    <div className="modal-body">
                        <div className="input_form_box form_input_box">

                        </div>

                    </div>


                </div>
            </Modal>



            {profiledata &&
                <Modal
                    ariaHideApp={false}
                    isOpen={dailyLimitModal}
                    onRequestClose={closeModal}
                    className={
                        "modal-content custom_modal thakyou_pop"}
                    contentLabel="Example Modal"
                    overlayClassName="Overlay"
                >
                    <div className="modal-header">
                        <button type="button" className="close" onClick={() => closeModal()} ><i className="fa fa-times" aria-hidden="true"></i></button>
                        <h3 className="modal-title">Daily Limit  </h3>
                    </div>
                    <div className="modal-body">
                        <div className="user_request padding">
                            <div className="form-group">
                                <label>Currency:</label>

                                {<select className="form-control" onChange={(event) => setDailyLimitCurrency(event)} >

                                    <option id="-1">Select Currency</option>
                                    {
                                        mechantCurrencies.length > 0 && mechantCurrencies.map((item: any, index: any) => {
                                            return (<option key={item.id} id={item.id}>{item.name}</option>)
                                        })
                                    }
                                </select>}
                                {formDailyLimitVar.currencyIdError.length > 0 && (
                                    <span style={{ color: "red" }}>{formDailyLimitVar.currencyIdError}</span>
                                )}
                            </div>

                            <div className="form-group">
                                <label>Amount:</label>
                                <div className="input_box">
                                    <input className="form-control form-control-two"
                                        type="text"
                                        placeholder="Enter Amount"
                                        value={formDailyLimitVar.amount}
                                        onChange={(e: any) => validateFormCase("daily_limit_amount", e.target.value)} />
                                    {formDailyLimitVar.amountError.length > 0 && (
                                        <span style={{ color: "red" }}>{formDailyLimitVar.amountError}</span>
                                    )}
                                </div>

                            </div>

                            <div className="bottom_btn">
                                <button className="btn btn-lg btn-block" onClick={submit}>Save</button>
                            </div>

                        </div>
                    </div>
                    {isFormLoading && (
                        <div id="" className="loaderbox loginloader">
                            <div>
                                <img
                                    className="bounce-2"
                                    src={loader_image}
                                    width="50"
                                    height="50"
                                />
                                <p>Loading...</p>
                            </div>
                        </div>
                    )}
                </Modal>


            }


            {profiledata &&
                <Modal
                    ariaHideApp={false}
                    isOpen={commisionModal}
                    onRequestClose={closeModal}
                    className={
                        "modal-content custom_modal thakyou_pop"}
                    contentLabel="Example Modal"
                    overlayClassName="Overlay"
                >
                    <div className="modal-header">
                        <button type="button" className="close" onClick={() => closeModal()}><i className="fa fa-times" aria-hidden="true"></i></button>
                        <h3 className="modal-title">Commission</h3>
                    </div>
                    <div className="modal-body">
                        <div className="user_request padding">
                            <div className="form-group">
                                <label>Set Commission:</label>
                                <div className="input_box">
                                    <input type="text"
                                        value={newCommision}
                                        className="form-control form-control-two"
                                        onChange={(e: any) =>
                                            setNewCommision_feild(e)} maxLength={3}
                                    />
                                </div>
                                {newCommisionError.length > 0 && <span style={{ color: "red" }}>{newCommisionError}</span>}
                            </div>
                            <div className="bottom_btn">
                                <button className="btn btn-lg btn-block" onClick={save_commision}>Save</button>
                            </div>
                        </div>
                    </div>
                    {isFormLoading && (
                        <div id="" className="loaderbox loginloader">
                            <div>
                                <img
                                    className="bounce-2"
                                    src={loader_image}
                                    width="50"
                                    height="50"
                                />
                                <p>Loading...</p>
                            </div>
                        </div>
                    )}
                </Modal>






            }





        </Layout>
    )
}

export default (PartnerProfile)
