import React, {useEffect, useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap' 
import axios from 'axios';
import { USER_MANAGEMENT, FRONT_BASE, EXCHANGE_MANAGEMENT } from '../../config/config';
import { Layout } from '../common/Layout';

import { BrowserRouter as Router, Route, Link, Switch , useRouteMatch , useParams} from 'react-router-dom';
import user_image from '../../images/user_img_default.png';
import { adminService,userService } from '../../_services';
import {DataHandling , HandlePermission } from '../../components/common/DataHandling'
import loader_image from '../../images/loading.gif';
import { toast } from 'react-toastify';
import {CollectionDateFormater} from '../common/CollectionDateFormater'
import {Amountformatter} from '../common/AmountFormatter'
interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
}







const RequestDevelopment = (props: any) => {

    let order_id = props.match.params.order_id;
    const [ orderDetail, setOrderDetail ] = useState() as any;
    const [searchText ,setSearchText] = useState("");
    const [isLoader , setIsLoader] = useState(false)

    useEffect(() => {
        getData("")
      }, []);

      function assign_request(merchant_id:any){
        let params = {
            "orderId": order_id,
            "merchantId":merchant_id
        }
        setIsLoader(true)
        console.log("assign_request",merchant_id);
        adminService.assign_request(params)
         .then(function (response:any) {
             console.log("-----------------------------------------response",response.data.data);
            setIsLoader(false);
            console.log(response.data);
          toast.success(response.data.message);
          setTimeout(function(){ 
            window.location.href = "/";
           }, 2000);
         }).catch(function(error:any){
            setTimeout(function(){ 
                window.location.href = "/";
               }, 2000);
           console.log("-----------error--------",error);
             setIsLoader(false);
             userService.handleError(error);
         });        
        


      }

      function search_money_changers(e:any){
        setSearchText(e.target.value);
        getData(e.target.value)
      }
      
      

      function getData(txt:any){
         setIsLoader(true) 
        let params = {
            "orderId": order_id,
            "roleName":"ADMIN"
        }  
    
        adminService.get_exchange_detail(params,txt)
         .then(function (response:any) {
            setIsLoader(false)
             console.log("-----------------------------------------response",response.data.data);
             setOrderDetail(response.data.data);
         }).catch(function(error:any){
           console.log("-----------error--------",error);
           setIsLoader(false)
             userService.handleError(error);
         });
    }

          
    //   let { order_idkdnkdn } = useParams();

    return (      
      <Layout  >  
        
        <section className="inner_sction loader_parent">
        { orderDetail &&
          <section className="table_sction accunt_table">
            <div className="container-fluid">
            <a href="/" className="page_back_btn"><i className="fa fa-chevron-left" aria-hidden="true"></i>Back</a>
              <div className="table_sction_outer request_dtl_box">

              <div className="exchange_request_box">
                <div className="no_request">
                    <img src={require("../../images/no_exchange.png")} />
                    <p>No exchange request received.</p>
                </div>

                <div className="exchange_request">
                    <p className='collection_date'>Collection Date :-  {orderDetail.order && <CollectionDateFormater  timeget={orderDetail.order.exchangeOn}  />}</p>
                    <p>Request pending from {orderDetail.user.firstName} for {orderDetail.order.currencyFrom} <Amountformatter amount={orderDetail.order.value}/> to {orderDetail.order.currencyTo}.</p>
                    <div className="flag_box">
                        <div className="currency">
                            <p><span>
                            <img className="exchange_flag_image" src={orderDetail.order.exchangeFromFlag} />
                                </span>
                            {orderDetail.order.currencyFrom}
                            </p>
                            <p className="amount"><Amountformatter amount={orderDetail.order.value}/> {orderDetail.order.currencyFrom} </p>
                        </div>

                        <div className="currency arrow">
                            <img src={require("../../images/arrow_right.png")} />
                        </div>

                        <div className="currency">
                            <p><span>
                            <img className="exchange_flag_image" src={orderDetail.order.exchangeToFlag} />
                                {/* <img src="../images/flag1.png" /> */}
                                </span>{orderDetail.order.currencyTo }</p>
                            <p className="amount"><Amountformatter amount={orderDetail.order.totalAmount}/> {orderDetail.order.currencyTo }</p>
                        </div>
                    </div>

                    <p>Exchange Rate 1.00 {orderDetail.order.currencyFrom} = <Amountformatter amount={orderDetail.order.executionRate}/> {orderDetail.order.currencyTo}</p>
                </div>
              </div>

              <div className="request_alert red text-center">
                  <p>Request declined by “{orderDetail.merchant.firstName} {orderDetail.merchant.lastName}”,“{orderDetail.merchant.address}”.</p>
              </div>


              <div className="money_changer_list">
                <div className="top-sctin mbtm-0">
                    <h4>Nearest Money Changers</h4>
                    
                    
                    <div className="form-group pull-right">
                        <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                        <input type="text" className="form-control" onChange={(e) => search_money_changers(e)} value={searchText} placeholder="Search money changer by name"/>
                    </div>

            
                </div>

                <ul>
                    {orderDetail.merchantMapArrayList.map((item:any,index:any)=> {
                        return(
                    <li>
                        <div className="money_changer">
                        <p className="name">{item.firstName} {item.lastName}
                        {item.roleName == "BRANCH" ? 
                            <span className="branch">Branch</span>
                        :
                        null
                        
                        }
                        </p>
                        <p className="location">{item.address}</p>
                            <p className="distance">{(item.distanceFromCurrentUser/1000).toFixed(2)} km away</p>
                            <p className="exchange_price">1.00 {orderDetail.order.currencyFrom} = <Amountformatter amount={item.initialExchangeRate}/> {orderDetail.order.currencyTo}</p>
                            <div className="right_btn">
                                {HandlePermission("edit","partners") &&
                                    <a onClick={() => assign_request(item.id)} >Assign Request</a>
                                }
                            </div>
                        </div>
                    </li>
                        )
                    })}
                </ul>
              </div>
               
               
              </div>
            </div>
          </section>
        }

            { isLoader &&
                <div id="" className="loaderbox loginloader innerloader"><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div>
            }
        </section>
        
        
       </Layout>
    )
}

  export default (RequestDevelopment)