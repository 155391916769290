import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError, change } from 'redux-form'

// import store from '../../store';
import Count from '../Count';
import Merchant from '../Merchant';
import { Container, Row, Col, Button } from 'react-bootstrap' 
import { Layout } from '../common/Layout'
import axios from 'axios';
import $ from 'jquery'; 
import user_image from '../../images/user_img_default.png';
import { USER_MANAGEMENT,EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT} from '../../config/config';
import loader_image from '../../images/loading.gif';
import ReactTelephoneInput from 'react-telephone-input';
import { adminService, userService } from "../../_services";

interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
  }
  
const Profile = (props: any) => {
    const [ rates, setRates ] = useState([]);
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ usersPerPage, setUsersPerPage ] = useState(20);
    const [ totalPage, setTotalPage ] = useState(1);
    const [ isLoading, setisLoading ] = useState(0);
    const [image,setimage] = useState() as any;
    const [cover_pic,setcover_pic] = useState() as any;
    const [profiledata,setProfiledata] = useState() as any;
    const [countryCode,setcountryCode] = useState()  as any;


    const total = Math.round(totalPage/usersPerPage);

    useEffect(() => {
        // getData();
        getProfile();
        const img = localStorage.getItem('user_image');
      if((img && img!= null) || (image != null) || (image != "")) {
        console.log(img)
        setcover_pic(img);
        
      }else{
        setcover_pic(user_image);
      }
      }, []);


      const getProfile = () => {
        
        const user_id =  localStorage.getItem('user_id');
        adminService
          .get_merchant_profile(user_id)
          .then(function (response) {
            console.log("merchant profile-----", response.data);
            var data1 = response.data.data;
            console.log(data1);
            if (response.data.count > 0) {
              setisLoading(1);
            } else {
              setisLoading(2);
            }
          })
          .catch(function (error) {
            setisLoading(3);
            console.log(error);
            setRates([])
          });
        }

      const getProfile2 = () => {                
        const user_id =  localStorage.getItem('user_id');              
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
            console.log("submit start")
            let config1 = {
              headers: {
                  'Content-Type': 'application/json',
                  'deviceType':'Android',
                  'appVersion' : '1.0',
                  'Authorization' : 'Bearer '+access_token
              }
            }
            axios.get(`${USER_MANAGEMENT}/${user_id}/getMerchantProfile`, config1).then(function (response) {
              var data1 = response.data.data
              console.log("profile")
              console.log(data1)
              setProfiledata(data1.merchant)
             
            }).catch(function (error) {
              setisLoading(3)
            console.log(error);
        
          })
        } 

      }

      const getData = () => {                
        const user_id =  localStorage.getItem('user_id');              
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
          console.log("submit start")
          let config1 = {
            headers: {
                'Content-Type': 'application/json',
                'deviceType':'Android',
                'appVersion' : '1.0',
                'Authorization' : 'Bearer '+access_token
            }
          }
          axios.get(`${TRANSACTION_MANAGEMENT}/${user_id}/getTranctionListByMerchant?page=0`, config1).then(function (response) {
            var data1 = response.data.data
            console.log(data1)
            setTotalPage(data1.count)
            if (data1.count > 0){
            setisLoading(1)
            }else{
            setisLoading(2)
            }
            setRates(data1.transactions)
          }).catch(function (error) {
            setisLoading(3)
          console.log(error);
          setRates([])
        })
      }  
  }
    const submit = (values:any) => {
        console.log(values)
        console.log(countryCode)
        const user_id =  localStorage.getItem('user_id'); 
        console.log(user_id)             
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
          console.log("submit start")
          let config1 = {
            headers: {
                'Content-Type': 'application/json',
                'deviceType':'Android',
                'appVersion' : '1.0',
                'Authorization' : 'Bearer '+access_token
            }
          }
          axios.put(`${USER_MANAGEMENT}/merchants/${user_id}`, {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            businessName: values.businessName,
            phoneNumber: values.phoneNumber,
            countryCode: countryCode
          } ,config1).then(function (response) {
            var data1 = response.data.data
            // if (data1.exchangeRates.length > 0){
            //   setisLoading(1)
            // }else{
            //   setisLoading(2)
            // }
            console.log(data1)
            // console.log(data1.exchangeRates.length)
            // setRates(data1.exchangeRates)
          }).catch(function (error) {
            setisLoading(3)
          console.log(error);
          setRates([])
        })
      }  
    }

    const renderField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (    
        <div className={type=="hidden" ? "" : "form-group"}>
          <label>{label}</label>
            <input {...input} placeholder={label} type={type} className={type=="hidden" ? "" : "form-control"} required/>
            {touched && error && <span>{error}</span>}          
        </div>      
      )

      function _handleSubmit(e:any, value:string) {
        e.preventDefault();
        console.log(value)
        // TODO: do something with -> this.state.file
        console.log('handle uploading-', image);
        const access_token = localStorage.getItem('access_token');
        const user_id = localStorage.getItem('user_id');
        let config1 = {
          headers: {
              'Accept': 'application/json',
              'deviceType':'Android',
              'appVersion' : '1.0',
              'Authorization' : 'Bearer '+access_token
          }
        }
        const formData = new FormData();
  
        formData.append('file',image)
  
        axios.post(`${USER_MANAGEMENT}/users/${user_id}/updateImage`, formData, config1).then(function (response) {
          var data1 = response.data.data
          console.log("data1---------------------------",data1);
          console.log(response.data)
          $("#wait").css("display", "none");
          localStorage.setItem('user_image',data1.user.image);
        //   window.location.href = `${FRONT_BASE}/profile`;
        }).catch(function (error) {
          $("#wait").css("display", "none");
          console.log(error);
        })
      }
    
      function _handleImageChange_image(e:any) {
        console.log("_handleImageChangee");
        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];
        setimage(file)
        reader.onloadend = () => {
          setcover_pic(reader.result)
          // this.setState({
          //   file: file,
          //   imagePreviewUrl: reader.result
          // });
          console.log("onloadend")
  
          console.log(reader.result)
        }
    
        reader.readAsDataURL(file)
      }

      function handleInputChange(telNumber:any, selectedCountry:any) {
        console.log('input changed. number: ', telNumber, 'selected country: ', selectedCountry);
        setcountryCode(telNumber)
    }

    const { error, handleSubmit, pristine, reset, submitting } = props
    
    return (
        <Layout>  

            <section className="inner_sction">
                <section className="table_sction">
                    <div className="container">
                        <div className="table_sction_outer">
                            <div className="top-sctin profile_inner_sction">
                                <h4>My Profile</h4>
                            </div>
                            <div className="profile_filled">
                                <div className="profile_upd text-center">
                                {/* {"https://staging.e-fx.asia/"+cover_pic} */}
                                <img src={cover_pic == "null" ? user_image  : cover_pic}/>

                                    <a data-toggle="modal" data-target="#update_profile_picture">Update Profile Picture</a>
                                </div>
                                <div className="listng_user_info">
                                    <ul>

                                        <li>
                                            <p>Name:</p>
                                            <h5>{ profiledata ? profiledata["firstName"]: ""} {profiledata ? profiledata["lastName"] : "" }</h5>
                                        <li>
                                        </li>
                                            <p>Company Name:</p>
                                            <h5>{ profiledata ? profiledata["businessName"] : ""}</h5>
                                        </li>
                                        <li>
                                            <p>Email Address:</p>
                                            <h5>{ profiledata ? profiledata["email"] : ""}</h5>
                                        </li>
                                        <li>
                                            <p>Phone Number:</p>
                                            <h5>{ profiledata ? profiledata["countryCode"] : ""} {profiledata ? profiledata["phoneNumber"]: ""}</h5>
                                        </li>



                                        <hr/>

                                        <p className="title">Company Information</p>

                                        <li>
                                            <p>Company Name:</p>
                                            <h5>Ronan Exchanger</h5>
                                        </li>

                                        <li>
                                            <p>Company Phone Number:</p>
                                            <h5>+1 987-6543-210</h5>
                                        </li>

                                        <li>
                                            <p>Company Address:</p>
                                            <h5>202 main town, kimsel</h5>
                                        </li>

                                        <li>
                                            <p>Website:</p>
                                            <h5><a href="#">ronan-exchanger.com</a></h5>
                                        </li>


                                    </ul>
                                    <div className="bottom_btn">
                                        <a href="#" data-toggle="modal" data-target="#add_rate" id="merchnt_profile" >Edit Profile</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </section>
            </section>
            <div className="modal fade" id="add_rate" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content custom_modal">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
                <h3 className="modal-title">Edit Profile</h3>
              </div>
              <div className="modal-body">
                <div className="user_request">

                  <form>
                    
                    <Field
                      name="firstName"
                      type="text"

                    //   component={renderField}
                      label="First Name"
                    />

                    <Field
                      name="lastName"
                      type="text"
                      // component={renderField}

                      label="Last Name"
                    />

                    <Field
                      name="businessName"
                      type="text"

                    //   component={renderField}
                      label="Business Name"
                    /> 

                    <Field
                      name="email"
                      type="email"

                    //   component={renderField}
                      label="Email"
                    /> 

                    <div className="phone_number">  
                       <div className="country_code">
                       <ReactTelephoneInput defaultCountry='us' preferredCountries={['us', 'ca', 'zz', 'hk']} />
                       </div>    
                      <Field
                        name="phoneNumber"
                        type="number"

                        // component={renderField}
                        label="Phone Number"
                      />
                    </div>

                    <Field
                      name="companyname"
                      type="text"

                    //   component={renderField}
                      label="Company Name"
                    />

                    <Field
                      name="companyphonenumber"
                      type="text"

                    //   component={renderField}
                      label="Company Phone Number"
                    />

                    <Field
                      name="companyaddress"
                      type="text"

                    //   component={renderField}
                      label="Company Address"
                    />

                    <Field
                      name="companywebsite"
                      type="text"
                    //   component={renderField}
                      label="Website"
                    />

                    <div className="bottom_btn"> 
                      <button id="add_rate_btn" type="submit">Submit</button>                   

                    </div>

                  </form>
                </div>
              </div>
            </div>          
          </div>
        </div>

        <div className="modal fade" id="update_profile_picture" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content custom_modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
              <h3 className="modal-title">Edit Profile Picture</h3>
            </div>
            <div className="modal-body">
              <div className="user_request">

              <form className="main_form" >
                <div className="profl_chng text-center">                
                  <div className="avatar-upload">
                  <div className="avatar-edit">
                    <input type="file" id="imageUpload_1" />
                    <label htmlFor="imageUpload_1"></label>
                  </div>
                    <div className="avatar-preview">
                      <div id="imagePreview" >
                      </div>
                    </div>
                  </div>
                  <h6>

                  Upload Profile Picture
                  </h6>
                </div>
                <div className="bottom_btn">
                  <button  id="upload_image_btn">Upload!</button> 
                  <div id="wait"><img src={loader_image} width="50" height="50" /></div>
                </div>
              </form>
            </div>
          </div>
        </div>          
      </div>
    </div>
      </Layout>
    )
}

export default reduxForm({
    form: 'searchForm', // a unique identifier for this form
    enableReinitialize : true
  })(Profile)
