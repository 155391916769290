import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from 'react-bootstrap' 
import { Field, reduxForm, SubmissionError, change } from 'redux-form'
import axios from 'axios';
import { EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT} from '../../config/config';
import { Layout } from '../common/Layout';
import $ from 'jquery'
import { Alert } from 'reactstrap';
import { any, array } from 'prop-types';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import {DataHandling , HandlePermission} from '../common/DataHandling'

interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
      touched: boolean;
      error: string;
  };
}

const RateInventory = (props: any) => { 

    const [ rates, setRates] = useState([]);
    const [ userskyc, setUsersKYC ] = useState([]);
    const [ currencies, setCurrencies ] = useState([]);
    const [ currenciesto, setCurrenciesto ] = useState([]);
    const [ checked, setChecked ] = useState();
    const [ tocurrency, setTocurrency ] = useState();
    const [ rateupdate, setRateupdate ] = useState(false);
    const [ rateid, setRateid ] = useState() as any;
    const [ currencyFromId, setCurrencyFromId ] = useState();
    const [ currencyToId, setCurrencyToId ] = useState();
    const [ errormsg, setErrormsg ] = useState("") as any;
    const [ currencyratevalue, setcurrencyratevalue ] = useState('');
    const [ currencyrateval, setcurrencyrateval ] = useState();
    const [ exchangeratevalue, setexchangeratevalue ] = useState();
    const [ currencyFromtext, setCurrencyFromtext ] = useState();
    const [ currencyTotext, setCurrencyTotext ] = useState();
    const [ currentrate, setCurrentrate ] = useState([]);
    const [ erroroccur, setErroroccur ] = useState();

    const [ passwordcase, setPasswordcase ] = useState();
    const [ addRateModal, setAddRateModal ] = useState(false);
    const [passwordModal , setPasswordModal] = useState(false);
    // setAddRateModal
    const customStyles1 = {
      overlay : {
       display: "block" ,
       paddingRight: "15px",
       background: "#00000054"
      }
    }; 

    function closeModal(){
      console.log("--- closeModal");
      setAddRateModal(false)
      setPasswordModal(false)
    }



    useEffect(() => {
      getRate();
      getData();
      getCurrentExchangeRate();
    }, []);

    const getData = () => {                
      const access_token = localStorage.getItem('access_token');
      if (access_token) {
        let config1 = {
          headers: {
              'Content-Type': 'application/json',
              'deviceType':'Android',
              'appVersion' : '1.0',
              'Authorization' : 'Bearer '+access_token
          }
        }
        axios.get(`${EXCHANGE_MANAGEMENT}/getCurrencyPairList`, config1).then(function (response) {
          localStorage.setItem('same_value',"false"); 
          setCurrencies(response.data.data.currencyPairs)
        }).catch(function (error) {
        console.log(error);
        setCurrencies([])
      })
    }  
}

    const pass_verify_case = (cases:any) => {
      console.log(cases)
      localStorage.setItem('pass_verify',cases); 
      // setPasswordcase(cases)
    }

    const submit = (values:any) => {
      
      const access_token = localStorage.getItem('access_token');
      const user_id = localStorage.getItem('user_id');

      console.log(values)
      
      if (validateForm(values, currencyFromId, currencyToId, user_id)) {
        console.log("submit start")
        let config1 = {
          headers: {
              'Content-Type': 'application/json',
              'deviceType':'Android',
              'appVersion' : '1.0',
              'Authorization' : 'Bearer '+access_token
          }
        }

        console.log("submit start end")
          axios.post(`${EXCHANGE_MANAGEMENT}/exchangeRates`, {
            currencyFrom: currencyFromId,
            currencyFromId: currencyFromtext,
            currencyTo: currencyToId,
            currencyToId: currencyTotext,
            exchangeRate: exchangeratevalue,
            merchantId: user_id,
            password: values.user_password
            
          }, config1).then(function (response) {
              // getRate();
              // getData();
              // UserTable();
              // localStorage.setItem('same_value',"false");
              // setErrormsg("")
              // window.location.href = '/merchant/exchange'
              console.log("response----",response);
              toast.success(response.data.message)
              closeModal()
              getRate();
              getData();
              
              
          }).catch(function (error) {
            console.log(error)
            if(error.response.status == 400){
              localStorage.setItem('same_value',"true"); 
              setErrormsg("already") 
            }
            else{
              localStorage.setItem('same_value',"true"); 

              setErrormsg("pass_failed") 
            }     
            setTimeout(() => {
              setErrormsg('')
            }, 2000);
          })
        }
    }

    const validateForm= (data:any,currencyFrom:any,currencyTo:any,user_id:any) => {
      let formIsValid = true;
      let errors :any = [];
     
      if(data == "" && currencyFrom == "" && currencyTo == "" && user_id == "")
      {
        formIsValid = false;
        localStorage.setItem('same_value',"true"); 
        setErrormsg("error_field")

        setTimeout(() => {
          setErrormsg('')
        }, 2000);
        throw new SubmissionError({ email: 'Please enter valid email-ID.', _error: 'Register failed!' })
      }
      else if (currencyFrom == currencyTo)
      {
        formIsValid = false;
        localStorage.setItem('same_value',"true"); 
        setErrormsg("error")

        setTimeout(() => {
          //window.location.reload();
          setErrormsg('')
        }, 2000);
        throw new SubmissionError({ email: 'Please enter valid email-ID.', _error: 'Register failed!' })
      }
      else{
        formIsValid = true;
      }

      return formIsValid;
    }

    const renderHiddenfield = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (     
      <input {...input} placeholder={label} id={input.name} value={input.name == "currencyToId" ? localStorage.getItem('currencyTo') : input.name == "currencyFromId" ? localStorage.getItem('fromcurrency') : localStorage.getItem('user_id')} type={type} className={type=="hidden" ? "" : ""}   />           
    )
    
    const renderField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (    
      <div className={type=="hidden" ? "" : "form-group"}>
        <label>{label}</label>
          <input {...input} placeholder={label} type={type} className={type=="hidden" ? "" : "form-control"} required/>
          {touched && error && <span>{error}</span>}          
      </div>      
    )

    const renderHiddenField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (    
      <div className={type=="hidden" ? "" : "form-group"}>
          <input {...input} placeholder={label} type={type} className={type=="hidden" ? "" : "form-control"} required/>
          {touched && error && <span>{error}</span>}          
      </div>      
    )
    
    const renderSelectField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
      <div className="form-group">

          <label>
            {label}
              
            </label>
            <select {...input} className="form-control"  value={ localStorage.getItem('fromcurrency')} id={input.name}>
                <option>Select Currency</option>
                {
                  currencies.length > 0 && currencies.map((item: any, index: any) => { 
                    return (<option key={index} value={item.currencyFromId}>{item.currencyFromName}</option>)
                  })
                }
              </select> 
        
      </div>
    )



    const renderSelectFieldto = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
      <div className="form-group">

        <label>
            {label}
            </label>
            <select {...input} className="form-control"  value={ localStorage.getItem('currencyTo')} id={input.name} >
                <option>Select Currency</option>                
                {
                  currenciesto.length > 0 && currenciesto.map((item: any, index: any) => {
                    return (<option value={item.currencyToId}>{item.currencyToName}</option>)
                  })
                }
              </select>
      </div>
    )



   
    $(document).ready(function(){
      setTimeout(function(){
      $('#currencyFrom').on('change', function(this:any) {
        localStorage.setItem('fromcurrency', this.value);
        var optionsText = this.options[this.selectedIndex].text;
        localStorage.setItem('fromcurrencyvalue', optionsText);
        // $("#currencyFromId").val(this.value)
      }); }, 1000);
  
      setTimeout(function(){
      $('#currencyTo').on('change', function(this:any) {
        localStorage.setItem('currencyTo', this.value);
        var optionsText = this.options[this.selectedIndex].text;
        localStorage.setItem('currencyTovalue', optionsText);
        // $("#currencyToId").val(this.value)
      }); }, 1000);

    });

    const getRate = () => { 
      const user_id =   localStorage.getItem('user_id');               
      const access_token = localStorage.getItem('access_token');
      if (access_token) {
        console.log("submit start")
        let config1 = {
          headers: {
              'Content-Type': 'application/json',
              'deviceType':'Android',
              'appVersion' : '1.0',
              'Authorization' : 'Bearer '+access_token
          }
        }
        axios.get(`${EXCHANGE_MANAGEMENT}/${user_id}/getExchangeRatesByMerchant?page=0`, config1).then(function (response) {
          var data1 = response.data.data
          console.log("data1",data1);
          setRates(data1.exchangeRates)
        }).catch(function (error) {
        console.log(error);
        setRates([])
      })
    }  
}


const setUpdate = async (id:any) => {
  setRateupdate(true)
  setRateid(id)
}


const submitrate = (values:any) => {
        // $("#pass_verified2").modal("hide");

  const user_id =   localStorage.getItem('user_id');               
  const access_token = localStorage.getItem('access_token');
  if (access_token) {
    console.log("submit start")
    let config1 = {
      headers: {
          'Content-Type': 'application/json',
          'deviceType':'Android',
          'appVersion' : '1.0',
          'Authorization' : 'Bearer '+access_token
      }
    }
    console.log(parseFloat(values.exchangeRate))
    var val = parseFloat(values.exchangeRate)
    axios.put(`${EXCHANGE_MANAGEMENT}/${rateid}/editExchangeRate?exchangeRate=${val}&password=${values.user_password}`, {},config1).then(function (response) {
      var data1 = response.data.data
      setRateid("")
      getRate()
      UserTable()
      window.location.reload();
      // window.$('#pass_verified2').modal('hide');
      // $("#pass_verified2").modal("hide");
    }).catch(function (error) {
      console.log("error",error)
      // setRateid("")
        localStorage.setItem('same_value',"true"); 
        setErrormsg("pass_failed")
          
      setTimeout(() => {
        setErrormsg('')
      }, 2000);
      UserTable()
    })
  }  
}

const getCurrentExchangeRate = async () => {
  let config1 = {
    headers: {
        'Content-Type': 'application/json'
    }
  }
  console.log("getCurrentExchangeRate")
  var resp = await axios.get(`https://api.exchangeratesapi.io/latest?base=USD`, config1).then(function (response) {
      console.log("Exchange Rate-------------------------------")
      var aa = response.data.rates
      setCurrentrate(aa)
      }).catch(function (error) {
      console.log(error);
    })

}


const calculateRate = (from:any, to:any) => {
let from_currency = currentrate[from]
let to_currency = currentrate[to]
let currencyRate = 1.0
if (from_currency && to_currency){
 currencyRate = (to_currency/from_currency)
}
return currencyRate
}

const [ deleteStatus, setdeleteStatus ] = useState(false);

const deleteRate = async (id:any) => {
  const access_token = localStorage.getItem('access_token');
  if (access_token) {
    console.log("submit start")
    let config1 = {
      headers: {
          'Content-Type': 'application/json',
          'deviceType':'Android',
          'appVersion' : '1.0',
          'Authorization' : 'Bearer '+access_token
      }
    }
    alert("Are you sure?")
    axios.delete(`${EXCHANGE_MANAGEMENT}/${id}/deleteExchangeRate`,config1).then(function (response) {
      setdeleteStatus(true)
      setTimeout(() => {
        //window.location.reload();
        setdeleteStatus(false)

      }, 2000);
      getRate()
      UserTable()
    }).catch(function (error) {
      UserTable()
    })
  } 

}


const deleteRatepass = async (values:any) => {
  const access_token = localStorage.getItem('access_token');
  const id = localStorage.getItem('delete_rate_id');
  const user_id = localStorage.getItem('user_id');
  if (access_token && id && validatepasswordForm(values)) {
    console.log("submit start")
    let config1 = {
      headers: {
          'Content-Type': 'application/json',
          'deviceType':'Android',
          'appVersion' : '1.0',
          'Authorization' : 'Bearer '+access_token
      }
    }
    // alert("Are you sure?")
    axios.post(`${EXCHANGE_MANAGEMENT}/${id}/deleteExchangeRate`,{
      userId: user_id,
      password: values.user_password
    },config1).then(function (response) {
      setdeleteStatus(true)
      setTimeout(() => {
        //window.location.reload();
        setdeleteStatus(false)

      }, 2000);
      localStorage.removeItem('delete_rate_id');
      // window.location.href = `/merchant/exchange`;
      
      toast.success(response.data.message);
      closeModal();
      getRate()
      UserTable()
      window.location.reload();
    }).catch(function (error) {
      if(error.response.status == 404){
        localStorage.setItem('same_value',"true"); 
        setErrormsg("already") 
      }
      else{
        localStorage.setItem('same_value',"true"); 

        setErrormsg("pass_failed") 
      }     
      setTimeout(() => {
        setErrormsg('')
      }, 2000);
      UserTable()
    })
  } 

}

const validatepasswordForm= (data:any) => {
  let formIsValid = true;
  let errors :any = [];
  if(!data.user_password)
  {
    formIsValid = false;
    localStorage.setItem('same_value',"true"); 
    setErrormsg("error_field")

    setTimeout(() => {
      setErrormsg('')
    }, 2000);
    throw new SubmissionError({ email: 'Please enter valid email-ID.', _error: 'Register failed!' })
  }
  else{
    formIsValid = true;
    setErrormsg("")
  }

  return formIsValid;
}


  const UserTable = () => {
      return(
        <div className="table_inner">
            <div className="table-responsive">
              {deleteStatus && <h4>Item Successfully Deleted</h4>}
                <table className="table">
                    <thead>
                    <tr>
                        <th>FROM</th>
                        <th>TO</th>
                        <th>EXCHANGE PRICE</th>
                        <th>YOUR BEST RATE</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {rates.map((item: any, index: any) => {
                    var val = calculateRate(item.currencyFrom, item.currencyTO)
                    console.log(val)
                      return(
                      <tr key={index}>
                        <td><img src={item.currencyFromFlag} style={ { width:`33px`, height: `33px` } }/>{item.currencyFrom}</td>
                        <td><img src={item.currencyToFlag} style={ { width:`33px`, height: `33px` } }/>{item.currencyTO}</td>
                        <td>1 {item.currencyFrom}= {val} {item.currencyTO}</td>
                        <td>1 {item.currencyFrom}=<span>{item.exchangeRate} {item.currencyTO}</span></td>
                        {HandlePermission("edit","rate_inventory") &&
                        <td>{(rateid != item.id) ? <button className="btn" onClick={() => setUpdate(item.id)} >EDIT RATE</button>
                              : 
                            <form onSubmit={handleSubmit(submitrate)}>
                              <Field
                                name="exchangeRate"
                                type="number"
                                component={renderField}
                                label="Exchange Rate"
                              />

                              {pass_verify_case('edit')}
                                <a href="#" data-toggle="modal" data-target="#pass_verified2" id="add_rate_btns" className="btn save_btn" >Submit</a>
                                {/* <button type="submit" className="btn save_btn" disabled={submitting}>Save Rate</button>     */}
                            </form>
                          }</td>
                        }
                        {HandlePermission("edit","rate_inventory") &&
                          <td>
                            {/* <button className="btn btn-danger" onClick={() => deleteRate(item.id)} >DELETE RATE</button>  */}
                            <a href="#" data-toggle="modal" data-id={item.id} data-target="#pass_verified3" id="delete_rate_btns" >DELETE RATE</a>
                          </td>
                        }
                        </tr> )
                      })}							  
                    </tbody>
                </table>
            </div>        
        </div>
      )
    }

    const user_id =  localStorage.getItem('user_id');

    const setcurrencyFromId = (event:any) => {
      var index = event.nativeEvent.target.selectedIndex;
      console.log(event.nativeEvent.target[index].text)
      setCurrencyFromtext(event.nativeEvent.target.selectedIndex)
      setCurrencyFromId(event.nativeEvent.target[index].text)
      var id = event.nativeEvent.target[index].value
      var text = event.nativeEvent.target[index].text
      if (text != "Select Currency"){
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
          let config1 = {
            headers: {
                'Content-Type': 'application/json',
                'deviceType':'Android',
                'appVersion' : '1.0',
                'Authorization' : 'Bearer '+access_token
            }
          }
          axios.get(`${EXCHANGE_MANAGEMENT}/getCurrencyPairFrom?fromCurrency=${id}`, config1).then(function (response) {
            localStorage.setItem('same_value',"false"); 
            setCurrenciesto(response.data.data.currencyPairs)
          }).catch(function (error) {
          console.log(error);
          setCurrencies([])
        })
      }
    }  

    }

    const setcurrencyToId = (event:any) => {
      var index = event.nativeEvent.target.selectedIndex;
      setCurrencyTotext(event.nativeEvent.target.selectedIndex)
      setCurrencyToId(event.nativeEvent.target[index].text)
    }

    const setExachangeRate = (event:any) => {
      console.log(event.target.value)
      setexchangeratevalue(event.target.value)
      // var index = event.nativeEvent.target.selectedIndex;
      // setCurrencyToId(event.nativeEvent.target[index].text)
    }
                    
    const { error, handleSubmit, pristine, reset, submitting } = props
    return (      
      <Layout>  
        <section className="inner_sction">
          <section className="table_sction">
            <div className="container-fluid">
                <div className="table_sction_outer">
                    <div className="top-sctin">
                        <h4>Rate & Inventory</h4>
                        {HandlePermission("edit","rate_inventory") &&
                          <a href="#" data-toggle="modal" onClick={() => setAddRateModal(true)} data-target="#add_rate" id="add_rate_button" className="btn btn-primary">+ ADD RATE</a>
                        }
                    </div>
                    {UserTable()}
              </div>
            </div>
          </section>
        </section>


        {/* setAddRateModal */}
<Modal
ariaHideApp={false}
 isOpen={addRateModal}
 // onAfterOpen={afterOpenModal}
 onRequestClose={ closeModal }
//  style={customStyles1}
 className={
   "modal-content custom_modal thakyou_pop"}
 contentLabel="Example Modal"
 overlayClassName="Overlay"
 // className="reset_password"
>
<div className="modal-header">
          <button type="button" className="close" onClick={closeModal} ><i className="fa fa-times" aria-hidden="true"></i></button>
                <h3 className="modal-title">Add Rate</h3>
              </div>
              <div className="modal-body">
                <div className="user_request">

                  <form onSubmit={handleSubmit(submit)}>
                    {errormsg == "error" && <h4 className="error">Please Select Different Value</h4> }
                    {errormsg == "already" && <h4 className="error">Already Exists</h4>}
                    <Field
                      name="currencyFrom"
                      type="select"
                      component={renderSelectField}
                      label="From Currency"
                      onChange = {setcurrencyFromId}
                    />
                    <Field
                      name="currencyTo"
                      type="select"
                      component={renderSelectFieldto}
                      label="To Currency"
                      onChange = {setcurrencyToId}
                    />
                      <div className="form-group">
                        <label>
                        Exchange Rate
                      </label>
                      <div className="input_box">
                        <input type="text" name="exchangeRate" value={exchangeratevalue} onChange = {setExachangeRate} className="form-control form-control-two" />
                      </div>

                      </div>


                  
                     {pass_verify_case('add')}
                    <div className="bottom_btn"> 
                      <a onClick={()=> setPasswordModal(true) } id="add_rate_btns" >Submit</a>
                    </div>
                  </form>
                </div>
              </div>
              </Modal>
              {/* passwordModal */}



<Modal
ariaHideApp={false}
 isOpen={passwordModal}
 // onAfterOpen={afterOpenModal}
 onRequestClose={ closeModal }
//  style={customStyles1}
 className={
   "modal-content custom_modal thakyou_pop"}
 contentLabel="Example Modal"
 overlayClassName="Overlay"
 // className="reset_password"
>
  <div className="modal-header">
    <button type="button" className="close" onClick={() => setPasswordModal(false)} ><i className="fa fa-times" aria-hidden="true"></i></button>
      <h3 className="modal-title">Password Verfied</h3>
    </div>
  <div className="modal-body">
    <div className="user_request">
      <form onSubmit={handleSubmit(submit)}>
          {errormsg == "error" && <h4 className="error">Please Select Different Value for currencies</h4> }
          {errormsg == "already" && <h4 className="error">Already Exists</h4>}
          {errormsg == "error_field" && <h4 className="error">Please Fill all the Fields for Rate</h4>}
          {errormsg == "pass_failed" && <h4 className="error">Password Not Matched</h4>}
          {passwordcase}
          <Field
            name="user_password"
            type="password"
            component={renderField}
            label="Enter Password"
          />
          <div className="bottom_btn">                      
            <button id="add_pass_btn" type="submit" disabled={submitting}>Submit</button>                   
          </div>
      </form>
    </div>
  </div>
</Modal>

        <div className="modal fade" id="pass_verified2" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content custom_modal">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
                <h3 className="modal-title">Password Verfied</h3>
              </div>
              <div className="modal-body">
                <div className="user_request">
                  <form onSubmit={handleSubmit(submitrate)}>
                      {errormsg == "error" && <h4 className="error">Please Select Different Value for currencies</h4> }
                      {errormsg == "already" && <h4 className="error">Already Exists</h4>}
                      {errormsg == "error_field" && <h4 className="error">Please Fill all the Fields for Rate</h4>}
                      {errormsg == "pass_failed" && <h4 className="error">Password Not Matched</h4>}
                      {passwordcase}
                      <Field
                        name="user_password"
                        type="password"
                        component={renderField}
                        label="Enter Password"
                      />
                      <div className="bottom_btn">                      
                        <button id="add_pass_btn" type="submit" disabled={submitting}>Submit</button>                   
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="pass_verified3" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content custom_modal">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
                <h3 className="modal-title">Confirm to Delete Rate</h3>
              </div>
              <div className="modal-body">
                <div className="user_request">
                  <form onSubmit={handleSubmit(deleteRatepass)} id="delete_rate_form">
                      {errormsg == "error" && <h4 className="error">Please Select Different Value for currencies</h4> }
                      {errormsg == "already" && <h4 className="error">Already Exists</h4>}
                      {errormsg == "error_field" && <h4 className="error">Please Fill all the Fields for Rate</h4>}
                      {errormsg == "pass_failed" && <h4 className="error">Password Not Matched</h4>}
                      <Field
                        name="user_password"
                        type="password"
                        component={renderField}
                        label="Enter Password"
                      />
                      <div className="bottom_btn">                      
                        <button id="add_pass_btn" type="submit" disabled={submitting}>Submit</button>                   
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      
    )
}

  export default reduxForm({
    form: 'searchForm', // a unique identifier for this form
    enableReinitialize : true
  })(RateInventory)