import React, { useEffect, useState } from "react";
import { Field, reduxForm, SubmissionError, change } from "redux-form";
import Count from "../Count";
import Merchant from "../Merchant";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Layout } from "../common/Layout";
import axios from "axios";
import $ from "jquery";
import user_image from "../../images/user_img_default.png";
import {
  USER_MANAGEMENT,
  EXCHANGE_MANAGEMENT,
  FRONT_BASE,
  TRANSACTION_MANAGEMENT,
} from "../../config/config";
import loader_image from "../../images/loading.gif";
import ReactTelephoneInput from "react-telephone-input";
import Modal from "react-modal";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import { userService, adminService } from "../../_services";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataHandling, HandlePermission } from "../common/DataHandling";
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const Branches = (props: any) => {
  const [isLoading, setisLoading] = useState(0);
  const { error, handleSubmit, pristine, reset, submitting } = props;
  const [addBranch, setAddBranch] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoader, setIsLoader] = useState(false)
  const [tempVar, setTempVar] = useState(0);
  const [countryList, setCountryList] = useState([]);
  const [formVar, setFormVar] = useState({
    email: "",
    password: "",
    firstName: "",
    phoneNumber: "",
    fullPhoneNumber: "",
    countryCode: "",
    companyName: "",
    address: "",
    latitude: "",
    longitude: "",
    emailError: "",
    passwordError: "",
    firstNameError: "",
    phoneNumberError: "",
    countryCodeError: "",
    companyNameError: "",
    addressError: "",
    fullPhoneNumberError: "",
    countryId: -1,
    countryError: "",
    companyAddress: '',
    companyAddressError: ''
  });

  let user_permissions = localStorage.getItem("user_permissions");
  let user_country = localStorage.getItem("admin_user_country") as any;
  let user_country_id = localStorage.getItem("admin_user_country_id") as any;

  useEffect(() => {
    setFormVar({ ...formVar, countryId: user_country_id });
    get_countries();
    get_branches("");
  }, []);
  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  const renderField = ({
    input,
    label,
    type,
    meta: { touched, error },
  }: fieldInterface) => (
    <div className={type == "hidden" ? "" : "form-group"}>
      <label>{label}</label>
      <input
        {...input}
        placeholder={label}
        type={type}
        className={type == "hidden" ? "" : "form-control"}
        required
      />
      {touched && error && <span>{error}</span>}
    </div>
  );

  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      background: "#00000054",
    },

    content: {
      // position: "absolute",
      // top: "24px",
      // right: "588px",
      // bottom: "",
      // left: "617px"
    },
  };

  function searchBranches(e: any) {
    setSearchText(e.target.value);
    get_branches(e.target.value)
    console.log("e.target.value", e.target.value)
  }
  function get_branches(txt: any) {
    setIsLoader(true)
    adminService
      .branches_list(txt)
      .then(function (response) {
        setIsLoader(false)
        console.log("get_branch-----", response);
        setBranchList(response.data.branchesList);
        if (response.data.branchesList.length > 0) {
          setisLoading(1)
        } else {
          setisLoading(2)
        }
      })
      .catch(function (error) {
        // console.log("error",error);
        // setLoaderstyle({display:"none"})
        setisLoading(3)
        userService.handleError(error);
      });
  }
  function closeModal() {
    setAddBranch(false);
  }
  const handleCompanyAddress = (e: any) => {
    geocodeByAddress(e.description)
      .then((results: any[]) => getLatLng(results[0]))
      .then(({ lat, lng }: any) =>
        // setLatlng({ lat, lng })
        setFormVar({
          ...formVar,
          latitude: lat,
          longitude: lng,
          companyAddress: e.description,
          companyAddressError: "",
        })
      );
    //   setFormVar({...formVar, "companyAddress": e.description , "companyAddressError": ""})
    console.log("e------", e);
    setFormVar({ ...formVar, companyAddress: e.description, companyAddressError: "" });
  };

  function get_countries() {
    userService.countries_list().then(function (response) {
      console.log("countries_list-----", response.data.data.countries);
      setCountryList(response.data.data.countries)
    })
      .catch(function (error) {
        console.log("error get_countries-------------------");
      });
  }

  const validateForm = (data: any) => {
    // return true;
    let fields = data;
    let errors: any = [];
    let formIsValid = true;
    console.log("validateForm");
    console.log("fields", fields);
    console.log("formVar", formVar);

    if (formVar.firstName == "" || formVar.firstName == null) {
      formIsValid = false;
      //errors["firstName"] = "*Please enter your password.";
      formVar.firstNameError = "Please Enter Name";
    }
    if (formVar.email == "" || formVar.email == null) {
      formIsValid = false;
      formVar.emailError = "Please Enter Email";
    }

    if (typeof formVar.email !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(formVar.email)) {
        formIsValid = false;
        formVar.emailError = "Please Enter Valid Email";
      }
    }
    if (user_permissions == 'GROUP_LEVEL') {
      if (formVar.countryId == -1) {
        formIsValid = false;
        formVar.countryError = "Please select country";
      }
    }
    if (formVar.companyName == "" || formVar.companyName == null) {
      formIsValid = false;
      // errors["password"] = "*Please enter your password.";
      // throw new SubmissionError({ confirmpassword: 'Please confirm your password.', _error: 'Register failed!' })
      formVar.companyNameError = "Please Enter Company Name";
    }
    if (formVar.address == "" || formVar.address == null) {
      formIsValid = false;
      formVar.addressError = "Please Enter Valid Unit No, Floor";
    }
    if (formVar.companyAddress == "" || formVar.companyAddress == null || formVar.latitude == "lat" || formVar.longitude == "lng") {
      formIsValid = false;
      formVar.companyAddressError = "Please Enter Valid Company Address";
    }
    if (formVar.password == "" || formVar.password == null) {
      formIsValid = false;
      // throw new SubmissionError({ confirmpassword: 'Please confirm your password.', _error: 'Register failed!' })
      formVar.passwordError = "Please Enter Password";
    } else {
      if (!/^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{9,}$/.test(formVar.password)) {
        formIsValid = false;
        formVar.passwordError = "Password Contain Atleast 9 Characters, 1-Letter and 1-Number"
      }
    }


    if (formVar.fullPhoneNumber == "" || formVar.fullPhoneNumber == null) {
      formIsValid = false;
      formVar.fullPhoneNumberError = "Please Enter Phone Number";
    }
    setTempVar(tempVar + 1)
    return formIsValid;
  };

  const submit = (values: any) => {
    // setLoader(1)
    if (validateForm(values)) {
      setIsLoader(true);
      console.log("submit start");
      console.log("formdata", formVar);
      console.log("validation complete");

      const mobileNumber = parsePhoneNumber(formVar.fullPhoneNumber);
      if (mobileNumber == undefined) {
      } else {
        console.log(mobileNumber.countryCallingCode);
        console.log(mobileNumber.nationalNumber);
        formVar.countryCode = mobileNumber.countryCallingCode;
        formVar.phoneNumber = mobileNumber.nationalNumber;
      }
      adminService.create_branch(formVar).then(function (response) {
        console.log("create_branch-----", response);
        // window.location.reload()
        setAddBranch(false);
        alert(response.data.message);
        get_branches(searchText);
      })
        .catch(function (error) {
          setIsLoader(false);
          userService.handleError(error);
        });
    }
  };

  const BranchesTable = () => {
    return (
      <div className="table_inner">
        <div className="table-responsive">
          <>
          </>
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>PHONE</th>
                <th>EMAIL</th>
                <th>COMPANY NAME</th>
                <th>COUNTRY</th>
                <th>COLLECTION FEES (MYR)</th>
                {/* <th>COLLECTION AMOUNT</th> */}
                {HandlePermission("edit", "branches") && (
                  <th>DETAILS</th>
                )}
              </tr>
            </thead>
            {branchList && (
              <tbody>
                {branchList.map((item: any, index: any) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        +{item.countryCode}-{item.phoneNumber}
                      </td>
                      <td>{item.email}</td>
                      <td>{(item.companyName == null || item.collectionFee == "") ? "-" : item.companyName}</td>
                      <td>{item.country == null ? "-" : item.country.name}</td>
                      <td>{(item.collectionFee == null || item.collectionFee == "") ? "-" : item.collectionFee}</td>
                      {/* <td>{item.CollectionAmount}</td> */}
                      {HandlePermission("edit", "branches") && (
                        <td>
                          <a href={`/admin/BranchDetails/${item.id}`}>View</a>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </div>
    );
  };
  function openAddBranchModal() {
    setFormVar({
      ...formVar,
      email: "",
      password: "",
      firstName: "",
      phoneNumber: "",
      fullPhoneNumber: "",
      countryCode: "",
      companyName: "",
      address: "",
      latitude: "",
      longitude: "",
      emailError: "",
      passwordError: "",
      firstNameError: "",
      phoneNumberError: "",
      countryCodeError: "",
      companyNameError: "",
      addressError: "",
      fullPhoneNumberError: "",
      countryId: user_permissions == 'GROUP_LEVEL' ? -1 : user_country_id,
      countryError: "",
      companyAddress: '',
      companyAddressError: ''
    });
    setAddBranch(true);
  }
  return (
    <Layout>
      <section className="inner_sction">
        <section className="table_sction">
          <div className="container-fluid">
            <div className="table_sction_outer">
              <div className="top-sctin mbtm-0">
                <h4>Branches</h4>
                {HandlePermission("edit", "branches") && (
                  <a
                    onClick={() => openAddBranchModal()}
                    className="btn btn-primary pull-right"
                  >
                    Add Branch
                  </a>
                )}
                {/* <select className="form-control"> */}
                {/* <option value="day">Day</option> */}
                {/* <option value="weekly">Week</option> */}
                {/* <option value="monthly">Month</option> */}
                {/* </select> */}
                <div className="form-group pull-right">
                  <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                  <input type="text" className="form-control" value={searchText} onChange={(e) => searchBranches(e)} placeholder="Search branches by phone,email" />
                </div>
                <div className="clearfix"></div>
              </div>
              {isLoading == 0 && <div id="" className="loaderbox loginloader"><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div>}
              {isLoading == 1 && BranchesTable()}
              {isLoading == 2 && <h1>Record Not Found</h1>}
              {isLoading == 3 && <h1>Some Error Occur</h1>}
            </div>

          </div>
        </section>

        {/* { isLoader &&
                <div id="" className="loaderbox loginloader innerloader"><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div>
            } */}
      </section>
      <ToastContainer />
      <Modal
        ariaHideApp={false}
        isOpen={addBranch}
        onRequestClose={closeModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setAddBranch(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Add Branch</h3>
        </div>
        <div className="modal-body">
          <div className="user_request">
            <form onSubmit={handleSubmit(submit)}>
              <div className="form-group ">
                <label>Name:</label>
                <div className="input_box">
                  <input
                    onChange={(e: any) =>
                      setFormVar({
                        ...formVar,
                        firstName: e.target.value,
                        firstNameError: "",
                      })
                    }
                    maxLength={30}
                    className="form-control form-control-two"
                    value={formVar.firstName}
                    placeholder="Enter Name"
                  ></input>
                  {formVar.firstNameError.length > 0 && (
                    <span style={{ color: "red" }}>
                      {formVar.firstNameError}
                    </span>
                  )}
                </div>
              </div>

              <div className="form-group ph_field">
                <label>Phone Number:</label>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={formVar.fullPhoneNumber}
                  maxLength="20"
                  onChange={(e: any) =>
                    setFormVar({
                      ...formVar,
                      fullPhoneNumber: e,
                      fullPhoneNumberError: "",
                    })
                  }
                />
                {formVar.fullPhoneNumberError.length > 0 && (
                  <span style={{ color: "red" }}>
                    {formVar.fullPhoneNumberError}
                  </span>
                )}
              </div>

              <div className="form-group ">
                <label>Email:</label>
                <div className="input_box">
                  <input
                    onChange={(e: any) =>
                      setFormVar({
                        ...formVar,
                        email: e.target.value,
                        emailError: "",
                      })
                    }
                    maxLength={64}
                    className="form-control form-control-two"
                    value={formVar.email}
                    placeholder="Enter Email"
                  ></input>
                  {formVar.emailError.length > 0 && (
                    <span style={{ color: "red" }}>{formVar.emailError}</span>
                  )}
                </div>
              </div>


              {user_permissions != 'GROUP_LEVEL' ?
                <div className="form-group ">
                  <label>Country:</label>
                  <input
                    className="form-control form-control-two"
                    value={user_country}
                  ></input>
                </div>
                :
                <div className="form-group ">
                  <label>Select Country:</label>
                  <select value={formVar.countryId} className="form-control" style={{ height: '55.9px' }} onChange={(e: any) =>
                    setFormVar({ ...formVar, countryId: e.target.value, countryError: "" })
                  }>
                    <option value="-1">Select Country</option>
                    {countryList && countryList.length > 0 && countryList.map((item: any, index: any) => {
                      return (<option value={item.id}>{item.name}</option>)
                    })}
                  </select>
                  {formVar.countryError.length > 0 && (
                    <span style={{ color: "red" }}>
                      {formVar.countryError}
                    </span>
                  )}
                </div>
              }
              <div className="form-group ">
                <label>Company Name:</label>
                <div className="input_box">
                  <input
                    onChange={(e: any) =>
                      setFormVar({
                        ...formVar,
                        companyName: e.target.value,
                        companyNameError: "",
                      })
                    }
                    maxLength={30}
                    className="form-control form-control-two"
                    value={formVar.companyName}
                    placeholder="Enter Company Name"
                  ></input>
                  {formVar.companyNameError.length > 0 && (
                    <span style={{ color: "red" }}>
                      {formVar.companyNameError}
                    </span>
                  )}
                </div>
              </div>

              {/* <div className="form-group ">
                <label>Address:</label>
                <GooglePlacesAutocomplete
                  onSelect={(e: any) => handleCompanyAddress(e)}
                  inputClassName="form-control"
                  initialValue={formVar.address}
                />
                {formVar.addressError.length > 0 && (
                  <span style={{ color: "red" }}>{formVar.addressError}</span>
                )}
              </div> */}
              <div className="form-group ">
                <label>Unit No, Floor:</label>
                <div className="input_box">
                  <input
                    onChange={(e: any) => setFormVar({ ...formVar, address: e.target.value, addressError: "", })}
                    maxLength={200}
                    className="form-control form-control-two"
                    value={formVar.address}
                    placeholder="Enter Unit No, Floor"
                  ></input>
                  {formVar.addressError.length > 0 && (<span style={{ color: "red" }}>{formVar.addressError}</span>)}
                </div>
              </div>
              <div className="form-group ">
                <label>Company Address:</label>
                <GooglePlacesAutocomplete
                  onSelect={(e: any) => handleCompanyAddress(e)}
                  inputClassName="form-control"
                  initialValue={formVar.companyAddress}
                  placeholder='Company Address'
                />
                {formVar.companyAddressError.length > 0 && (
                  <span style={{ color: "red" }}>{formVar.companyAddressError}</span>
                )}
              </div>
              <input type='email' style={{ opacity: 0, position: 'absolute', width: '1px', height: '1px' }}></input>
              <div className="form-group ">
                <label>Password:</label>
                <div className="input_box">
                  <input
                    type="password"
                    onChange={(e: any) =>
                      setFormVar({
                        ...formVar,
                        password: e.target.value,
                        passwordError: "",
                      })
                    }
                    minLength={9}
                    maxLength={32}
                    className="form-control form-control-two"
                    value={formVar.password}
                    placeholder="Enter Password"
                  ></input>
                  {formVar.passwordError.length > 0 && (
                    <span style={{ color: "red" }}>
                      {formVar.passwordError}
                    </span>
                  )}
                </div>
              </div>
              <div style={{ color: 'grey', marginTop: '10px' }}><p>NOTE- Password Must Contain Atleast <span style={{ color: '#4097e4' }}>9 Characters</span>, Containing <span style={{ color: '#4097e4' }}>1-Letter</span> and <span style={{ color: '#4097e4' }}>1-Number</span>.</p></div>
              <div className="bottom_btn">
                <button
                  className="btn btn-lg btn-primary btn-block"
                  onClick={() => submit}
                >
                  Submit
                </button>
              </div>
              <br />
              <br />
              <br />
            </form>
          </div>
        </div>
        {isLoader && (
          <div id="" className="loaderbox loginloader">
            <div>
              <img
                className="bounce-2"
                src={loader_image}
                width="50"
                height="50"
              />
              <p>Loading...</p>
            </div>
          </div>
        )}
      </Modal>
    </Layout>
  );
};

export default reduxForm({
  form: "searchForm", // a unique identifier for this form
  enableReinitialize: true,
})(Branches);
