import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
// import store from '../../store';
import { Field, reduxForm, SubmissionError, change } from 'redux-form'

import { Container, Row, Col, Button } from 'react-bootstrap' 
import { Layout } from '../common/Layout'
import $ from 'jquery'; 
import { INVESTMENT_MANAGEMENT,TRANSACTION_MANAGEMENT,FRONT_BASE } from '../../config/config';
import axios from 'axios';
import user_image from '../../images/user_img_default.png';
import loader_image from '../../images/loading.gif';
import Moment from 'react-moment';
// import * as moment from 'moment';
import * as moment from 'moment-timezone';
import jstz from 'jstz';


interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
  }
  


const Investmentdetail = (props: any) => {

    const [ investments, setInvestments] = useState([]);
    const [ withdralist, setWithdralists ] = useState([]);
    const [ currencyToId, setCurrencyToId ] = useState();
    const [ modal, setModal ] = useState(false);

    const [ isLoading, setisLoading ] = useState(0);
    const [ activeinvestment, setactiveInvestment ] = useState() as any;
    const [ completeinvestment, setcompleteinvestment ] = useState() as any;
    const [ data, setdata ] = useState() as any;

    const [ rateid, setRateid ] = useState() as any;
    const [ rateupdate, setRateupdate ] = useState(false);



    useEffect(() => {
        getData();
        getWithdrawlist();
    }, []);

    const getWithdrawlist = () => { 
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
          console.log("submit start")
          let config1 = {
            headers: {
                'Content-Type': 'application/json',
                'deviceType':'Android',
                'appVersion' : '1.0',
                'Authorization' : 'Bearer '+access_token
            }
          } 
          axios.get(`${INVESTMENT_MANAGEMENT}/getWithdrawRequest?page=0`, config1).then(function (responsedata) { 
              setWithdralists(responsedata.data.data.list)
          }).catch(function (error) {
            console.log(error)
            setWithdralists([])
          })
        }

      }

    const getData = () => { 
        const access_token = localStorage.getItem('access_token');
        const user_id = localStorage.getItem('user_id');
        const investmentPlanId = window.sessionStorage.getItem('investment_id')
        if (access_token) {
          console.log("submit start")
          let config1 = {
            headers: {
                'Content-Type': 'application/json',
                'deviceType':'Android',
                'appVersion' : '1.0',
                'Authorization' : 'Bearer '+access_token
            }
          } 
          axios.get(`${INVESTMENT_MANAGEMENT}/admin/getInvestmentPlansByPlanId/${investmentPlanId}?page=0`, config1).then(function (responsedata) { 
            
            setcompleteinvestment(responsedata.data.data.listUserInvestmentsCompleted)
            setactiveInvestment(responsedata.data.data.listUserInvestmentsActive)
            setInvestments(responsedata.data.data.plans)
            setdata(responsedata.data.data)
            if (responsedata.data.data.listUserInvestmentsActive.length > 0){
              setisLoading(1)
              }else{
              setisLoading(2)
              }
          }).catch(function (error) {
            console.log(error)
            setisLoading(3)
            setactiveInvestment([])
            setcompleteinvestment([])
            setInvestments([])
          })
        }
      }


    const setUpdate = async (id:any, finalAmount:any) => {
        setRateupdate(true)
        setRateid(id)
    }


    function viewDetail(user_id:any,name:string){
      console.log("view rate")
      window.sessionStorage.setItem('investment_id', user_id)
      window.sessionStorage.setItem('investment_id_name', name)

      window.location.href = `${FRONT_BASE}/profit-loss`; 
    }

    function setInvestmentID(user_id:any,name:string){
      console.log("view rate")
      window.sessionStorage.setItem('investment_id', user_id)
      window.sessionStorage.setItem('investment_id_name', name)

      window.location.href = `${FRONT_BASE}/edit-investment`; 
    }
  
    const timezone = jstz.determine();
    const tz = timezone.name()

  const UserTable = () => { 
    return (	

        <div className="table-responsive">
            <h2>Active Investments</h2>
            <table className="table">
                <thead>
                <tr>
                    <th>USER ID</th>
                    <th>INVESTMENT AMOUNT</th>
                    <th>CLOSING AMOUNT</th>
                    <th>PAID AMOUNT</th>
                    <th>CREATED AT</th>
                </tr>
                </thead>
                <tbody>
                {activeinvestment.map((item: any, index: any) => {
                    console.log(item)
                    var aa = moment.tz(item.createdAt, tz)
                    return(
                    <tr key={index}>
                        <td>{item.userId}</td>
                        <td>{item.investedAmount}</td>
                        <td>{item.closingAmount}</td>
                        <td>{item.paidAmount}</td>
                        <td><Moment format="YYYY-MM-DD HH:mm"  withTitle>{aa}</Moment> </td>
                    </tr>                    
                    )
                    })}										  
                </tbody>
            </table>    
        </div>  
      )

  }

  const renderField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (    
    <div className={type=="hidden" ? "" : "form-group"}>
      <label>{label}</label>
        <input {...input} placeholder={label} type={type} className={type=="hidden" ? "" : "form-control"} />
        {touched && error && <span>{error}</span>}          
    </div>      
  )

  const submitrate = (values:any) => {
    const user_id =   localStorage.getItem('user_id');               
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      console.log("submit start")
      let config1 = {
        headers: {
            'Content-Type': 'application/json',
            'deviceType':'Android',
            'appVersion' : '1.0',
            'Authorization' : 'Bearer '+access_token
        }
      }

      axios.put(`${INVESTMENT_MANAGEMENT}/completeUserInvestmentPlan`, {
          id : rateid,
          finalClosingAmount : values.finalamount
      },config1).then(function (response) {
        var data1 = response.data.data
        setRateid("")
        getData()
        CompletedTable()
      }).catch(function (error) {
        setRateid("")
        getData()
        CompletedTable()
      })
    }  
}

  const CompletedTable = () => { 
    return (	

        <div className="table-responsive">
            <h2>Completed Investments</h2>
            <table className="table">
                <thead>
                <tr>
                    <th>USER ID</th>
                    <th>INVESTMENT AMOUNT</th>
                    <th>CLOSING AMOUNT</th>
                    <th>PAID AMOUNT</th>
                    <th>CREATED AT</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {completeinvestment.map((item: any, index: any) => {
                    console.log(item)
                    var aa = moment.tz(item.createdAt, tz)
                    return(
                    <tr key={index}>
                        <td>{item.userId}</td>
                        <td>{item.investedAmount}</td>
                        <td>{item.closingAmount}</td>
                        <td>{item.paidAmount}</td>
                        <td><Moment format="YYYY-MM-DD HH:mm"  withTitle>{aa}</Moment> </td>
                        <td>{(rateid != item.id) ? <button className="btn" onClick={() => setUpdate(item.id, item.finalClosingAmount)} >EDIT AMOUNT</button>
                              : 
                            <form onSubmit={handleSubmit(submitrate)}>
                              <Field
                                name="finalamount"
                                type="number"
                                component={renderField}
                                label="Paid Amount"
                              />
                                <button type="submit" className="btn save_btn" disabled={submitting}>Save Rate</button>    
                            </form>
                          }</td> 
                    </tr>                    
                    )
                    })}										  
                </tbody>
            </table>    
        </div>  
      )

  }

const [ checked, setChecked ] = useState();
const [ currentPage, setCurrentPage ] = useState(0);
const [ usersPerPage, setUsersPerPage ] = useState(20);
const [ totalPage, setTotalPage ] = useState(1);

const total = Math.round(totalPage/usersPerPage);

const makeHttpRequestWithPage = async (pageNumber:any) => {
    console.log("total",total)
    
    if (pageNumber < 0){
      pageNumber = total-1
    }
    if (pageNumber == total){
      pageNumber = 0
    }
    console.log("pageNumber",pageNumber)
    const access_token = localStorage.getItem('access_token');


    const response = await fetch(`${INVESTMENT_MANAGEMENT}/getWithdrawRequest?page=${pageNumber}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'deviceType':'Android',
        'appVersion' : '1.0',
        'Authorization' : 'Bearer '+access_token
    },
    });

    const data = await response.json();
    console.log(data)
    setWithdralists(data.data.list)
    setCurrentPage(pageNumber)
 
  }

  const pagination = () =>{
    let table = []
    table.push(<li><a  href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(currentPage-1)}>Previous</a></li>)
    for (let i = 0; i < total; i++) {
      console.log("i------------------->"+currentPage)
      // table.push(<span onClick={() => makeHttpRequestWithPage(i)} className="active">{i}</span>)
      table.push(<li className={ i == currentPage ? "active  block_cursor_event" :""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}  >{i+1}</a></li>)
    }      
    table.push(<li><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(currentPage+1)}>Next</a></li>)
    return table
  }
       

  const WithdrawTable = () => { 
    return (	

        <div className="table-responsive">
            <h2>Withdraw Investments</h2>
            <table className="table">
                <thead>
                <tr>
                    <th>USER ID</th>
                    <th>WITHDRAW AMOUNT</th>
                    <th>INVESTED PLAN</th>
                    <th>CLOSING AMOUNT</th>
                    <th>USER INVESTMENT STATUS</th>
                    <th>CREATED AT</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {withdralist.map((item: any, index: any) => {
                    console.log(item)
                    var aa = moment.tz(item.createdAt, tz)
                    return(
                    <tr key={index}>
                        <td>{item.userInvestment.userId}</td>
                        <td>{item.withdrawAmount}</td>
                        <td>{item.userInvestment.investedPlan.name}</td>
                        <td>{item.userInvestment.closingAmount}</td>
                        <td>{item.userInvestment.userInvestmentStatus}</td>
                        <td><Moment format="YYYY-MM-DD HH:mm"  withTitle>{aa}</Moment> </td>
                        <td>
                        <select onChange={setPrimaryCurrency} className="form-control withdraw-list" id={item.id}>
                            <option value="1">Pending</option>
                            <option value="2">Approved</option>
                            <option value="3">Rejected</option>                        
                        </select>
                            </td> 
                    </tr>                    
                    )
                    })}										  
                </tbody>
            </table>  
            <div className="pagination_sction">
                <ul className="pagination">
                {total > 0 ? pagination() : ""}
                </ul>
            </div>  
        </div>  
      )

  }

  const setPrimaryCurrency = (event:any) => {
    setChecked(event.target.value)
    var status = event.target.value
    var dataval = event.target.id
    const access_token = localStorage.getItem('access_token');
      if (access_token) {
        console.log("submit start")
        let config1 = {
          headers: {
              'Content-Type': 'application/json',
              'deviceType':'Android',
              'appVersion' : '1.0',
              'Authorization' : 'Bearer '+access_token
          }
        }
        axios.post(`${INVESTMENT_MANAGEMENT}/${dataval}/responseToWithdrawRequest/${status}`,{}, config1).then(function (response) {
          getWithdrawlist()
      
        }).catch(function (error) {
        console.log(error);
      
      })
    }  
  }


  $(document).ready(function(){

    setTimeout(function(){
    $('#currencyTo').on('change', function(this:any) {
      localStorage.setItem('currencyTo', this.value);
      var optionsText = this.options[this.selectedIndex].text;
      localStorage.setItem('currencyTovalue', optionsText);
      // $("#currencyToId").val(this.value)
    }); }, 1000);

    

  });

  const setcurrencyToId = (event:any) => {
    var index = event.nativeEvent.target.selectedIndex;
    setCurrencyToId(event.nativeEvent.target[index].text)
  }

  const [ errormsg, setErrormsg ] = useState();
  const closeModal = () => {
    setTimeout(function(){
      console.log("status----->",modal)
      if (modal == true)
      {
        //  $("#newinvestment").modal('hide');
        // $('#newinvestment').modal('toggle');
      }
   }, 1000);
  }



  if (data)
  {
      console.log("dataaaaaaaaaa")
      // console.log(data.totalInvestment)
  }
  const { error, handleSubmit, pristine, reset, submitting } = props
    return (
    <Layout>
        <section className="inner_sction">
            <section className="table_sction merchnt_main_sctn">
                <div className="container">
                <div className="dashboard_merchnt">
                <div className="wallt_outer admin_grdant merchnt_dashbod">
                    <div className="wallet_lft gradnt_1">
                        <div className="wllat_icon">
                            <img src="../images/deposit_icon.png"/>
                            <div>
                                <h1>{data ? data.totalInvestment == null ? 0 : data.totalInvestment : 0}</h1>
                                <p>Total Amount</p>
                                {/* <h6>(IN MONTH)</h6> */}
                            </div>
                            </div>
                        </div>
                    <div className="wallet_ryt gradnt_2">
                    <div className="wllat_icon">
                        <img src="../images/withdrwn_icon.png"/>
                        <div>
                            {/* <h1>{data && data.countActive+data.countCompleted}</h1> */}
                            <h1>{data ? data.countActive+data.countCompleted : 0}</h1>
                            <p>Total Users</p>
                            {/* <h6>(IN MONTH)</h6> */}
                        </div>
                        </div>
                    </div>
                    <div className="wallet_lft gradnt_3">
                        <div className="wllat_icon">
                            <img src="../images/user_icon.png"/>
                            <div>
                                <h1>{data ? (data.countActive+data.countCompleted == 0 || data.totalInvestment == null) ? 0 : Math.round(data.totalInvestment/data.countActive+data.countCompleted) : 0}</h1>
                                <p>Average</p>
                                {/* <h6>(IN MONTH)</h6> */}
                            </div>
                            </div>
                        </div>
                </div>
                </div>


                    <div className="table_sction_outer investment_sction">
                        <div className="top-sctin profile_new">
                            {/* <a className="btn btn btn-success complete-investmet" href="/completed-investment"> COMPLETED INVESTMENT </a> */}
                            {/* <div className="btn_add_new">
                                <button className="btn add_new"  data-toggle="modal" data-target="#newinvestment"><i className="fa fa-plus" aria-hidden="true"></i>&nbsp; ADD</button>                                
                            </div> */}
                        </div>
                        <div className="table_inner">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item active">
                                <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                                aria-selected="true">Active</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"
                                aria-selected="false">Completed</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="profile-tab" data-toggle="tab" href="#withdraw" role="tab" aria-controls="withdraw"
                                aria-selected="false">Withdraw</a>
                            </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade active in" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    {isLoading == 0 && <div id="api_loader"><img src={loader_image} width="50" height="50" />Loading...</div> }
                                    {isLoading == 1 &&  UserTable() }
                                    {activeinvestment && activeinvestment.length == 0 &&  <h2>No Active Data Found</h2> }
                                </div>
                                <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    {   isLoading == 0 && <div id="api_loader"><img src={loader_image} width="50" height="50" />Loading...</div> }
                                    
                                    {completeinvestment && completeinvestment.length > 0 ? CompletedTable() : <h2>No Completed Data Found</h2>}
                                </div>
                                <div className="tab-pane fade" id="withdraw" role="tabpanel" aria-labelledby="withdraw-tab">
                                    {   isLoading == 0 && <div id="api_loader"><img src={loader_image} width="50" height="50" />Loading...</div> }
                                    
                                    {withdralist && withdralist.length > 0 ? WithdrawTable() : <h2>No Withdraw Data Found</h2>}
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </section>
        </section>    
    </Layout>
    )
}

export default reduxForm({
    form: 'searchForm', // a unique identifier for this form
    enableReinitialize : true
  })(Investmentdetail)