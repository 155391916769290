import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import store from '../../store';
import Count from '../Count';
import Merchant from '../Merchant';
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Layout } from '../common/Layout'
import axios from 'axios';
import loader_image from '../../images/loading.gif';
import Moment from 'react-moment';
// import * as moment from 'moment';
import * as moment from 'moment-timezone';
import jstz from 'jstz';

import { EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT } from '../../config/config';
import Transactions from '../Transactions';
import { adminService, userService, merchantService } from '../../_services';
import TimeAgo from 'react-timeago'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import { AnyMxRecord } from 'dns';

const hanldleClick = (type: string) => {
  store.dispatch({ type });
  console.log(store.getState().counter)
}

const Notification = () => {

  const [notifications, setNotifications] = useState([]) as any;
  const [isLoading, setIsLoading] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(20);
  const [totalPage, setTotalPage] = useState(1);
  const total = Math.ceil(totalPage / usersPerPage);

  useEffect(() => {
    getNotifications(currentPage);
  }, []);

  function getNotifications(pageNo: any) {
    setIsLoading(0);
    merchantService.notifications(pageNo)
      .then(function (response: any) {
        setIsLoading(response.data.notificationList.length > 0 ? 1 : 2);
        setTotalPage(response.data.notificationListCount);
        setNotifications(response.data.notificationList);
      }).catch(function (error: any) {
        setIsLoading(3);
        console.log("-----------error--------", error);
        userService.handleError(error);
      });

  }
  const makeHttpRequestWithPage = async (pageNumber: any) => {
    setNotifications([]);
    if (pageNumber < 0) {
      pageNumber = total - 1
    }
    if (pageNumber == total) {
      pageNumber = 0
    }
    getNotifications(pageNumber);
    setCurrentPage(pageNumber);
  }

  const pagination = () => {
    let table = []
    table.push(<li><a href="javascript:void(0)" className={currentPage == 0 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage - 1)}>Previous</a></li>)
    for (let i = 0; i < total; i++) {
      table.push(<li className={i == currentPage ? "active block_cursor_event" : ""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}  >{i + 1}</a></li>)
    }
    table.push(<li><a href="javascript:void(0)" className={currentPage == total - 1 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage + 1)}>Next</a></li>)
    return table
  }

  var val = window.sessionStorage.getItem('merchant_id')

  return (
    <Layout>

      <section className="inner_sction">
        <section className="table_sction accunt_table">
          <div className="container">
            <div className="table_sction_outer admin_index">
              <div className="top-sctin mbtm-0">
                <h4>Notifications</h4>
              </div>
              {isLoading == 0 && <div id="api_loader"><div id="" className="loaderbox"><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div></div>}
              {isLoading == 1 && <ul className="notification_list">
                {notifications && notifications.map((item: any, index: any) => {
                  return (
                    <li>
                      <p className="notfy">{item.message}</p>
                      <p>
                        <TimeAgo date={item.createdAt} />
                      </p>
                    </li>
                  )
                })}
              </ul>}
              {isLoading == 2 && <h1>Record Not Found</h1>}
              {isLoading == 3 && <h1>Some Error Occur</h1>}
            </div>
            <div className="pagination_sction" style={{ marginBottom: '100px' }}>
              <ul className="pagination">
                {total > 0 ? pagination() : ""}
              </ul>
            </div>
          </div>
        </section>
      </section>

      <div className="modal fade" id="accept_request" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content custom_modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
              <h3 className="modal-title">User Request</h3>
            </div>
            <div className="modal-body">
              <div className="notif_popup text-center">
                <h1>Jack Thomas</h1>
                <h3 className="phone">+01 9876543210</h3>
                <h3 className="request_amount">sent you a exchange request <br /> for <span>HKD 1000</span> into <span>USD</span></h3>

                <div className="flag_box">
                  <div className="currency">
                    <p><span><img src="../images/flag.png" /></span>HDK</p>
                    <p className="amount">1000 HKD</p>
                  </div>

                  <div className="currency arrow">
                    <img src={require("../../images/arrow_right.png")} />
                  </div>

                  <div className="currency">
                    <p><span><img src="../images/flag1.png" /></span>USD</p>
                    <p className="amount">127.76 USD</p>
                  </div>
                </div>
                <p>1HKD = 0.13 USD Execution rate</p>

                <div className="bottom_btn">
                  <button type="submit">Accept</button>
                  <button className="border_btn" data-toggle="modal" data-target="#reject_request" data-dismiss="modal">Reject</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="reject_request" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content custom_modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
              <h3 className="modal-title">Reject Request</h3>
            </div>
            <div className="modal-body">
              <div className="notif_popup text-center">

                <h3 className="request_amount">Are you sure you want to reject the <br /> request sent by <b>Jack Thomas</b> for exchange <br /> <span>HKD 1000</span> into <span>USD</span>?</h3>


                <div className="flag_box">
                  <div className="currency">
                    <p><span><img src="../images/flag.png" /></span>HDK</p>
                    <p className="amount">1000 HKD</p>
                  </div>

                  <div className="currency arrow">
                    <img src={require("../../images/arrow_right.png")} />
                  </div>

                  <div className="currency">
                    <p><span><img src="../images/flag1.png" /></span>USD</p>
                    <p className="amount">127.76 USD</p>
                  </div>
                </div>
                <p>1HKD = 0.13 USD Execution rate</p>

                <div className="form-group">
                  <label>Give a reason</label>
                  <textarea className="form-control" placeholder="Enter Reason" rows={4}>

                  </textarea>
                </div>

                <div className="bottom_btn">
                  <button type="submit">Submit</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default connect()(Notification);