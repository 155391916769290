import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
// import store from '../../store';
import { Field, reduxForm, SubmissionError, change } from 'redux-form'

import Count from '../Count';
import Merchant from '../Merchant';
import { Container, Row, Col, Button } from 'react-bootstrap' 
import { Layout } from '../common/Layout'
import $ from 'jquery'; 
import { INVESTMENT_MANAGEMENT,TRANSACTION_MANAGEMENT,FRONT_BASE } from '../../config/config';
import axios from 'axios';
import loader_image from '../../images/loading.gif';

interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
  }
  


const Completedinvestment = (props: any) => {
    const [ errormsg, setErrormsg ] = useState() as any;
    const [ interest, setInterest ] = useState([]);
    const [ plans, setPlans ] = useState('');
    const [ isLoading, setIsloading ] = useState(0);
    const [ checked, setChecked ] = useState();
    const [ rateupdate, setRateupdate ] = useState(false);
    const [ rateid, setRateid ] = useState() as any;
    const [ finalamount, setFinalamount ] = useState();


    useEffect(() => {
        getPlans();
        getInterest();
    }, []);

    const getPlans = () => { 
        // const access_token = localStorage.getItem('access_token');
        // const user_id = localStorage.getItem('user_id');
        // if (access_token) {
        //   console.log("submit start")
        //   let config1 = {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'deviceType':'Android',
        //         'appVersion' : '1.0',
        //         'Authorization' : 'Bearer '+access_token
        //     }
        //   } 
        //   axios.get(`${INVESTMENT_MANAGEMENT}/${user_id}/getInvestmentPlans`, config1).then(function (responsedata) { 
        //     console.log(responsedata.data.data)
        //     var investment_id = window.sessionStorage.getItem('investment_id');
        //     var investment_id_name = window.sessionStorage.getItem('investment_id_name');
        //     console.log("investment_id--"+investment_id+"name---"+investment_id_name)
        //    // setPlans(investment_id)
        //   }).catch(function (error) {
        //     console.log(error)
        //     setPlans('')
        //   })
        //}
      }

      const getInterest = () => { 
        var investment_id = window.sessionStorage.getItem('investment_id');
        const access_token = localStorage.getItem('access_token');
        const user_id = localStorage.getItem('user_id');
        if (access_token) {
          console.log("submit start")
          let config1 = {
            headers: {
                'Content-Type': 'application/json',
                'deviceType':'Android',
                'appVersion' : '1.0',
                'Authorization' : 'Bearer '+access_token
            }
          } 
          axios.get(`${INVESTMENT_MANAGEMENT}/getCompletedUserInvestmentsList`, config1).then(function (responsedata) { 
            console.log(responsedata.data.data)
            setInterest(responsedata.data.data.userInvestmentList)
            console.log(responsedata.data.data.userInvestmentList.length)
            if (responsedata.data.data.userInvestmentList.length > 0){
              setIsloading(1)
            }else{              
              setIsloading(2)
            }
          }).catch(function (error) {
            console.log(error)
            setIsloading(3)
            setInterest([])
          })
        }
      }

    const setUpdate = async (id:any, finalAmount:any) => {
        setRateupdate(true)
        setRateid(id)
        setFinalamount(finalAmount)
    }

    const submitrate = (values:any) => {
        const user_id =   localStorage.getItem('user_id');               
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
          console.log("submit start")
          let config1 = {
            headers: {
                'Content-Type': 'application/json',
                'deviceType':'Android',
                'appVersion' : '1.0',
                'Authorization' : 'Bearer '+access_token
            }
          }

          axios.put(`${INVESTMENT_MANAGEMENT}/completeUserInvestmentPlan`, {
              id : rateid,
              finalClosingAmount : values.finalamount
          },config1).then(function (response) {
            var data1 = response.data.data
            setRateid("")
            getInterest()
            UserTable()
          }).catch(function (error) {
            setRateid("")
            UserTable()
          })
        }  
    }

    const UserTable = () => { 
        return (	
            <table className="table">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>INVESTED PLAN</th>
                    <th>CURRENCY</th>
                    <th>PAID AMOUNT</th>
                    <th>PROFIT AMOUNT</th>
                    <th></th>
                    {/* <th>ACTION</th> */}
                </tr>
                </thead>
                <tbody>
                {interest.map((item: any, index: any) => {
                  console.log(item)
                    return(
                      <tr key={index}>
                          <td>{index+1}</td>
                          <td>{item.investedPlan.name}</td>
                          <td>{item.investedPlan.currency}</td>
                          <td>{item.paidAmount}</td>
                          <td>{item.profitAmount}</td> 
                          <td>{(rateid != item.id) ? <button className="btn" onClick={() => setUpdate(item.id, item.finalClosingAmount)} >EDIT AMOUNT</button>
                              : 
                            <form onSubmit={handleSubmit(submitrate)}>
                              <Field
                                name="finalamount"
                                type="number"
                                component={renderField}
                                label="Paid Amount"
                              />
                                <button type="submit" className="btn save_btn" disabled={submitting}>Save Rate</button>    
                            </form>
                          }</td>               
                          {/* <td> 
                              <div className="dropdown slect_profile">
                                  <button className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">Select
                                  <span className="caret"></span></button>
                                  <ul className="dropdown-menu">
                                  <li><a href="#">Edit</a></li>
                                  <li><a href="#">Delete</a></li>
                                  </ul>
                              </div>
                          </td> */}
                      </tr>	
                    )
                  })}								  
                </tbody>
            </table>      
      )

  }

  const submit = (values:any) => {
      console.log(checked)
      console.log(values)
      const access_token = localStorage.getItem('access_token');
      const user_id = localStorage.getItem('user_id');
      if (access_token) {
        console.log("submit start")
        let config1 = {
          headers: {
              'Content-Type': 'application/json',
              'deviceType':'Android',
              'appVersion' : '1.0',
              'Authorization' : 'Bearer '+access_token
          }
        } 
        var checked_id = window.sessionStorage.getItem('investment_id');
        var id = {
                'id': checked_id
        }
        axios.post(`${INVESTMENT_MANAGEMENT}/addMonthlyInterest`, {
            investedPlan: id,
            returnAmount: values.returnAmount,
            onMonth: values.onMonth,
            performanceFee: values.performanceFee,
            portfolioFee: values.portfolioFee ,
          }, config1).then(function (response) {
                getInterest();
                  UserTable();
                  localStorage.setItem('same_value',"false");
                  setErrormsg("")
                
            }).catch(function (error) {
              setErrormsg("already")     
              setTimeout(function(){
                setErrormsg('')
              },3000)  
            })
      }
  }

  const renderField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (    
    <div className={type=="hidden" ? "" : "form-group"}>
      <label>{label}</label>
        <input {...input} placeholder={label} type={type} className={type=="hidden" ? "" : "form-control"} />
        {touched && error && <span>{error}</span>}          
    </div>      
  )

  const renderlockingfield = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className="form-group">
      <label>
          {label}
          <h5>{window.sessionStorage.getItem('investment_id_name')}</h5>
          {/* <select {...input} className="form-control"  onChange={setPrimaryCurrency}  value={checked} id={input.name} >
                {
                  plans.map((item: any, index: any) => { 
                    return (<option value={item.id}>{item.name}</option>)
                  })
                }
              </select> */}
          </label>
    </div>
  )

  const setPrimaryCurrency = (event:any) => {
    setChecked(event.target.value)
  }

  const { error, handleSubmit, pristine, reset, submitting } = props
    return (
    <Layout>
        <section className="inner_sction">
            <section className="table_sction">
                <div className="container">
                    <div className="table_sction_outer investment_sction">
                        <div className="top-sctin profile_new">
                            <h4>Completed Investments</h4>
                        </div>
                        <div className="table_inner">
                            <div className="table-responsive">
                            {isLoading == 0 && <div id="api_loader"><img src={loader_image} width="50" height="50" />Loading...</div> }
                            {isLoading == 1 &&  UserTable() }
                            {isLoading == 2 &&   <h1>Record Not Found</h1> }
                            {isLoading == 3 &&   <h1>Some Error Occur</h1> }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>    
        <div className="modal fade" id="newinterest" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content custom_modal">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
                        <h3 className="modal-title">Add New Investment</h3>
                    </div>
                    <div className="modal-body">
                      {errormsg == "already" && <h4 className="error">Please Add all the fields</h4>}
                        <div className="user_request">
                        <form onSubmit={handleSubmit(submit)} id="investment_add">
                            
                            <Field
                            name="investedPlan"
                            type="select"
                            component={renderlockingfield}
                            label="Invested Plan"
                            /> 
                            <Field
                            name="returnAmount"
                            type="number"
                            component={renderField}
                            label="Return Amount"
                            />
                            <Field
                            name="onMonth"
                            type="date"
                            component={renderField}
                            label="On Month"
                            />
                            <Field
                            name="performanceFee"
                            type="number"
                            component={renderField}
                            label="Performance Fee"
                            />
                            <Field
                            name="portfolioFee"
                            type="number"
                            component={renderField}
                            label="Portfolio Fee"
                            />
                            <div className="bottom_btn"> 
                            <button id="add_rate_btn" type="submit" disabled={submitting}>Submit</button>                   
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    )
}

export default reduxForm({
    form: 'searchForm', // a unique identifier for this form
    enableReinitialize : true
  })(Completedinvestment)