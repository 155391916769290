import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from "react-bootstrap";
import { Field, reduxForm, SubmissionError } from "redux-form";
import axios from "axios";
import { USER_MANAGEMENT, FRONT_BASE } from "../../config/config";
import { Layout } from "../common/Layout";
// import loader_image from '../images/loading.gif';
import jstz from "jstz";

import { sha256, sha224 } from "js-sha256";
//import { adminService,userService } from '../../_services';
import { adminService, userService } from "../../_services";
import { Timeformatter } from "../common/Timeformatter";
import { DataHandling, HandlePermission } from "../common/DataHandling";
import { toast } from "react-toastify";
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const MarketingMaterial = () => {
  const [data, setData] = useState([]);
  const [useres, setUsers] = useState([]) as any;
  const [isLoading, setIsLoading] = useState(0) as any;
  const [searchText, setSearchText] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [disable, setDisable] = useState(false);
 
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(20);
  const [totalPage, setTotalPage] = useState(1);

  const total = Math.ceil(totalPage/usersPerPage);

  const timezone = jstz.determine();
  const tz = timezone.name();

  // useEffect(() => {
  //   adminService
  //     .export_document()
  //     .then(function (response) {
  //       console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", response.data);
  //       setUsers(response.data.data.users);
  //       //    console.log("ddddddddddddddddddddddddd",response.data.data.users[0].id);

  //       //   debugger
  //       // toast.success("Data Export")
  //       // setUsers(response.data.data.users[0].id)
  //     })
  //     .catch(function (error) {
  //       userService.handleError(error);
  //       console.log("export error", error);
  //       //  toast.error("Data Error")
  //     });
  // }, []);

  useEffect(() => {
    getData(currentPage);
  }, []);


  const getData = (page:any) => {
    setIsLoading(0);
    adminService
      .export_document(page)
      .then(function (response) {
        setUsers(response.data.data.users);
        console.log("response", response.data.data.users);
        let data1 = response.data.data.users;
        setIsLoading(data1.length > 0 ? 1 : 2);
       
        setTotalPage(response.data.data.count);
        console.log("response.data.data.count",response.data.data.count);
       
      })
      .catch(function (error) {
        setIsLoading(3);
        console.log("-----------error--------", error);
        // setLoaderstyle({display:"none"})
        // userService.handleError(error);
      });
  };

  const makeHttpRequestWithPage = async (pageNumber: any) => {
    setData([]);
    if (pageNumber < 0) {
      pageNumber = total - 1;
    }
    if (pageNumber == total) {
      pageNumber = 0;
    }

    getData(pageNumber);
    setCurrentPage(pageNumber);
  };

  function getPaginationLimit() {
    let last =
      (totalPage <= 4 ? totalPage : currentPage >= 3 ? currentPage + 3 : 5);
    console.log("totalPage", totalPage);
    console.log("currentPage", currentPage);
    console.log("total", total);
    return currentPage < total - 2 ? last : total;
  }

  const pagination = () => {
    let table = [];
    table.push(
      <li>
        <a
          href="javascript:void(0)"
          className={currentPage == 0 ? "isDisabled" : ""}
          onClick={() => makeHttpRequestWithPage(currentPage - 1)}
        >
          Previous
        </a>
      </li>
    );

    // table.push(<li><a href="javascript:void(0)" style={{color:"black"}} className={currentPage == 0 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(0)}>First Page</a></li>)

    for (
      let i = currentPage < 3 ? 0 : currentPage - 2;
      i < getPaginationLimit();
      i++
    ) {
      table.push(
        <li className={i == currentPage ? "active block_cursor_event" : ""}>
          <a
            href="javascript:void(0)"
            onClick={() => makeHttpRequestWithPage(i)}
          >
            {i + 1}
          </a>
        </li>
      );
    }
    table.push(
      <li>
        <a
          href="javascript:void(0)"
          style={{ color: "black" }}
          className={currentPage == total - 1 ? "isDisabled" : ""}
          onClick={() => makeHttpRequestWithPage(currentPage + 1)}
        >
          Next
        </a>
      </li>
    );

    // table.push(<li><a href="javascript:void(0)" className={currentPage == total-1 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(31)}>Last Page</a></li>)
    return table;
  };

  const UserTable = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>NAME</th>
            <th>EMAIL ADDRESS</th>
            <th>terms & conditions </th>
            <th>Marketing Material</th>
          </tr>
        </thead>

        <tbody>
          {useres.map((item: any, index: any) => {
            return (
              <tr>
                <td>
                  {currentPage * 20 + index + 1}
                </td>
                <td>
                  {item.firstName +  item.lastName == 0 ? "-" : item.firstName + " " + item.lastName}
                </td>
                <td>
                  {item.email}
                </td>
                <td style={{ textTransform: "capitalize" }}>
                   
                    {String(item.checkTerms)}
                  
                </td>
                <td style={{ textTransform: "capitalize" }}>
                  
                    {String(item.checkSubscribed)}
                  
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  // console.log("usersPerPage"+usersPerPage)

  return (
    <Layout>
      <section className="inner_sction">
        <section className="table_sction accunt_table">
          <div className="container-fluid">
            <div className="table_sction_outer admin_index loader_parent">
              {/* {getData} */}
              <div className="table_inner">
                <div className="table-responsive">
                  <div className="top-sctin mbtm-0">
                    <h4> Marketing Material</h4>
                    <a
                      href="http://18.141.141.36:8083/v1/api/downloadMarketingMaterial"
                      target="_blank"
                      className="page_back_btn"
                      style={{ cursor: "pointer" }}
                      //   onClick={() => {
                      //     onExport();
                      //   }}
                    >
                      Export
                    </a>
                    <div className="form-group pull-right">
                      {/* <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button> */}
                      {/* <input type="text" className="form-control" placeholder="Search accounts by name,email,phone" value={searchText} onChange={(e) => search_account(e)}/> */}
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  {isLoading == 1 && UserTable()}

                  {isLoading == 2 && <h1>Record Not Found</h1>}
                  {isLoading == 3 && <h1>Some Error Occur</h1>}
                </div>
                <div className="pagination_sction">
                  <ul className="pagination">
                    {total > 0 ? pagination() : ""}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* { isLoader &&
                <div id="" className="loaderbox loginloader innerloader"><div><img className="bounce-2"  width="50" height="50" /><p>Loading...</p></div></div>
            } */}
      </section>
    </Layout>
  );
};

export default reduxForm({
  form: "searchForm", // a unique identifier for this form
})(MarketingMaterial);
