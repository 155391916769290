export function authHeader() {
 
    // return authorization header with basic auth credentials
    let user = localStorage.getItem('access_token')
    const access_token = localStorage.getItem('access_token');
    if (user && user) {
        return { 
            'Content-Type': 'application/json',
            'deviceType':'Android',
            'appVersion' : '1.0',
            'Authorization' : 'Bearer '+access_token,
            'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
            };
    } else {
        return {};
    }
}