import React, {useEffect, useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap' 
import { Field, reduxForm, SubmissionError } from 'redux-form'
import axios from 'axios';
import { EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT } from '../../config/config';
import { Layout } from '../common/Layout';
import Moment from 'react-moment';
import loader_image from '../../images/loading.gif';
import * as moment from 'moment-timezone';
import jstz from 'jstz';
import { adminService, userService } from "../../_services";

interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
}


const Countries = () => { 

    const [ users, setUsers ] = useState([]);
    const [ userskyc, setUsersKYC ] = useState([]);
    const [ isLoading, setisLoading ] = useState(0);

    // Default TimeZone
    const timezone = jstz.determine();
    const tz = timezone.name()

    useEffect(() => {
      getData();
    }, []);


    const getData = () => {
        adminService.countries_list().then(function (response) {
            console.log("countries_list-----", response.data.data.countries);
            setCountries(response.data.data.countries)
            setisLoading(1);
            
          })
          .catch(function (error) {
            console.log("error get_countries-------------------");
            userService.handleError(error);
          });
    }

    function updateCountry(item:any){
        // url : countries/{id}
        // Method : PUT
        // body :
        // {
        // "id" : 1,
        // "name" : "India",
        // "isRisky" : true
        // }

        let params = {
            "id": item.id,
            "name": item.name,
            "isRisky" : item.isRisky ? false : true
        }    
        setisLoading(0);
        
        adminService.update_country(params,item.id).then(function (response) {
            console.log("countries_list--saaad---", response.data.data.countries);
            setCountries(response.data.data.countries);
            setisLoading(1);
          })
          .catch(function (error) {
            setisLoading(3);
            console.log("error get_countries-------------------");
            userService.handleError(error);
          });    
    
    }

  const UserTable = () => { 
    
      return (
              <table className="table">
                <thead>
                  <tr>
                  <th>ID</th>
                  <th>Country</th>
                  <th style={{maxWidth: '100px'}}>Risky</th>
                  </tr>
                </thead>
                <tbody>
               {countries.map((item: any, index: any) => {   
                 return(
                 <tr key={index}>
                  <td>{ index+1 }</td>
                  <td>{ item.name }</td>
                  <td style={{maxWidth: '100px'}}>{ item.isRisky ?
                    <button id="mark_as_not_risky" onClick={(e) => updateCountry(item) } > Mark as Not Risky</button>
                    : 
                    <button id="mark_as_risky" onClick={(e) => updateCountry(item) } > Mark as Risky</button>
                  }</td>

                  </tr> 
                       )
                })}
                </tbody>
           </table>      
        )

    }

    const [ currentPage, setCurrentPage ] = useState(0);
    const [ usersPerPage, setUsersPerPage ] = useState(20);
    const [ totalPage, setTotalPage ] = useState(1);
    const [ col, setCol ] = useState(2);
    const [countries , setCountries] = useState([]);

    const total = Math.round(totalPage/usersPerPage);
    

    const makeHttpRequestWithPage = async (pageNumber:any) => {
      console.log("total",total)
      
      if (pageNumber < 0){
        pageNumber = total-1
      }
      if (pageNumber == total){
        pageNumber = 0
      }
      console.log("pageNumber",pageNumber)
      const access_token = localStorage.getItem('access_token');
      const response = await fetch(`${EXCHANGE_MANAGEMENT}/getOrdersListByAdmin/${pageNumber}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'deviceType':'Android',
          'appVersion' : '1.0',
          'Authorization' : 'Bearer '+access_token
      },
      });
  
      const data = await response.json();
      console.log(data)
      setUsers(data.data.queuedOrders)
      setCurrentPage(pageNumber)
   
    }
  
    const pagination = () =>{
      let table = []
      table.push(<li><a  href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(currentPage-1)}>Previous</a></li>)
      for (let i = 0; i < total; i++) {
        console.log("i------------------->"+currentPage)
        // table.push(<span onClick={() => makeHttpRequestWithPage(i)} className="active">{i}</span>)
        table.push(<li className={ i == currentPage ? "active block_cursor_event" :""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}  >{i+1}</a></li>)
      }      
      table.push(<li><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(currentPage+1)}>Next</a></li>)
      return table
    }
                    

    // const number_of_transactions = users.length
    return (      
      <Layout>  
        <section className="inner_sction">
          <section className="table_sction accunt_table">
            <div className="container">
              <div className="col-md-8 col-md-offset-2">
                <div className="inner_section signup_form">
                  <div className="table_sction_outer admin_index">
                    {getData}
                    <div className="table_inner">
                      <div className="table-responsive">
                        {isLoading == 0 && <div style={{textAlign:'center'}}><img src={loader_image} width="50" height="50" />Loading...</div> }
                        {isLoading == 1 &&  UserTable() }
                        {isLoading == 2 &&   <h1>Record Not Found</h1> }
                        {/* {number_of_transactions != 0 ? UserTable() : <div className="table"><h1>NO Transactions Found</h1></div>} */}
                        <div className="pagination_sction">
                          <ul className="pagination">
                            {total > 0 ? pagination() : ""}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </Layout>
    )
}

  export default reduxForm({
    form: 'searchForm' // a unique identifier for this form
  })(Countries)