import React, { useEffect, useState } from "react";
import { Field, reduxForm, SubmissionError, change } from "redux-form";
import Count from "../Count";
import Merchant from "../Merchant";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Layout } from "../common/Layout";
import axios from "axios";
import $ from "jquery";
import user_image from "../../images/user_img_default.png";
// import MultiSelect from "react-multiple-select-dropdown-lite";
import Select from "react-select";
import "react-multiple-select-dropdown-lite/dist/index.css";
import {
  USER_MANAGEMENT,
  EXCHANGE_MANAGEMENT,
  FRONT_BASE,
  TRANSACTION_MANAGEMENT,
} from "../../config/config";
import loader_image from "../../images/loading.gif";
import ReactTelephoneInput from "react-telephone-input";
import ReactPlayer from "react-player";
import { adminService, userService } from "../../_services";
import { sha256, sha224 } from "js-sha256";
import { config } from "../../config/config";
import Modal from "react-modal";
import { DataHandling, HandlePermission } from "../common/DataHandling";
import { toast } from "react-toastify";
import { number } from "prop-types";
import { Document, Page } from 'react-pdf';
import ReactImageAnnotate from "react-image-annotate";
import { profile } from "console";
// import {PDFtoIMG} from 'react-pdf-to-image';
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const localData: any = localStorage.getItem("user_id");
const options: any = ["Income Proof", "Bank Statement", "Invoice", "Others"];

// const ongetData=()=>{
//   const uploadImage = localStorage.getItem("fileseee");
//   const uploadDocument = JSON.parse(localStorage.getItem("valueeee") as any).label;
// }

const AccountDetails = (props: any) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [rates, setRates] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(20);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setisLoading] = useState(0);
  const [isAddCommentLoading, setIsAddCommentLoading] = useState(false);
  const [image, setimage] = useState() as any;
  const [cover_pic, setcover_pic] = useState() as any;
  const [profiledata, setProfiledata] = useState() as any;
  const [countryCode, setcountryCode] = useState();
  const [iskycverified, setiskycverified] = useState(true);

  const total = Math.round(totalPage / usersPerPage);
  const { error, handleSubmit, pristine, reset, submitting } = props;
  const [kycUserData, setKycUserData] = useState([]) as any;
  const [loaderAccountDetail, setLoaderAccountDetail] = useState(true);
  const [loaderKyc, setLoaderKyc] = useState(true);
  const [modalShow, setShowModal] = useState(false);
  const [comments, setComments] = useState("");
  const [ekycStatus, setEkycStatus] = useState("");
  const [riskLevelAry, setRiskLevelAry] = useState([]);
  const [commentError, setCommentError] = useState("");
  const [isEnhancedKycDone, setIsEnhancedKycDone] = useState(false);
  const [isSuspended, setIsSuspended] = useState(false);
  const [flag2Docments, setFlag2Documents] = useState(false);
  const [flag3Docments, setFlag3Documents] = useState(false);
  const [value, setValue] = useState("");
  const [files, setFiles] = useState("");
  const [fileName, setFileName] = useState("");
  const [additionDocName, setAdditionDocName] = useState("");
  const [additionDocFile, setAdditionDocFile] = useState("");
  // const [docType, setDocType] = useState("");
  const [compliances, setCompliances] = useState([]);
  const [status, setStatus] = useState("");
  const [fidoReports, setFidoReports] = useState([]) as any;
  const [documentModal, setDocumentModal] = useState(false);
  const [Document, setDocument] = useState("");
  const [videoModal, setVideoModal] = useState(false);
  const [video, setVideo] = useState("");
  const [viewDocumentModal, setViewDocumentModal] = useState(false);
  const [viewVideoMohal, setViewVideoModal] = useState(false);
  const user_role = localStorage.getItem("user_role");
  const [doc_link, set_doc_link] = useState("");
  const [viewOriginalDocumentModal, setViewOriginalDocumentModal] = useState(false);
  const [viewOriginalVideoMohal, setViewOriginalVideoModal] = useState(false);
  const [watchList, setWatchList] = useState({});
  const [watch_sanction, set_watch_sanctiont] = useState("");
  const [watch_legal, set_watch_legal] = useState("");
  const [watch_political, set_watch_political] = useState("");
  const [watch_property, set_watch_property] = useState([]);


  // const [ upImage,setUpimage ]=useState(uploadImage)

  function deleteItems(id: any) {
    if (
      window.confirm(
        "Are you sure you want to delete this Additional Document?"
      )
    ) {
      deleteItem(id);
    }
  }

  const deleteItem = (docId: any) => {
    // debugger
    const user_id = props.match.params.id;
    adminService
      .delete_document(user_id, docId)
      .then(function (response) {
        console.log("Additional Documents Deletes Successfully", response.data);
        getProfile();
        // setCompliances(response.data)
        // debugger
        toast.success("Additional Document Deleted Successfully");
      })
      .catch(function (error) {
        console.log("deletedocument", error);
        toast.error("Something went Wrong");
      });
  };
  console.log("fileNamefileName", fileName);

  const onSubmit = () => {
    console.log("filesfiles", files);
    if (value == "" && files == "") {
      toast.error("Pleace Select Additional Document and Image or .pdf Fields");
    } else if (value == "") {
      toast.error("Pleace Select Additional Document");
    }
    // else if ((files as any).size > 1048576) {
    //   toast.error("Pleace select Document less then 1MB");
    // }
    else if ((files as any).type == "video/mp4") {
      toast.error("Do not accept video");
    } else if ((files as any).type == "image/gif") {
      toast.error("Pleace select valid document");
    } else if (files == "avi") {
      toast.error("Do not accept video");
    } else if ((files as any).type == "audio/mpeg") {
      toast.error("Do not accept song");
    } else if (files == "") {
      toast.error("Pleace select image or pdf file");
    } else {
      var formData = new FormData();
      formData.append("type", value);
      formData.append("file", files);
      formData.append("uploadBy", localData);
      formData.append("uploadFor", props.match.params.id);

      adminService
        .upload_document(formData)
        .then(function (response) {
          toast.success("Additional Document Uploaded Successfully");
          setFiles("");
          setFileName("");
          setValue("");
          let tmpArry: any = [...compliances];
          tmpArry.push(response.data.assets);
          setCompliances(tmpArry);
          // debugger
        })
        .catch(function (error) {
          console.log("netsetsoftwareerrordata", error);
          toast.error("Something went Wrong");
        });
    }
    localStorage.setItem("valueeee", JSON.stringify(value));
    localStorage.setItem("fileseee", fileName);
  };

  const user_permissions = localStorage.getItem("user_permissions");
  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      background: "#00000054",
    },
  };

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    const user_id = props.match.params.id;
    const logged_user_id = localStorage.getItem("user_id")
    const user_role = localStorage.getItem("user_role");
    if (user_role == "KYCADMIN" || user_role == "ADMIN") {
      adminService
        .account_detail_kyc(user_id, logged_user_id)
        .then(function (response) {
          get_kyc_status(response.data.userDto.email);
          // setFlagData(response.data.userDto.flags)
          console.log(
            "rrrrrrrrrrrrrrrrrr",
            response.data
          );
          setFidoReports(response.data.userOnfidoReports)
          if (JSON.parse(response.data.userDto.onfidoWatchlistResponse)?.result) {
            set_watch_sanctiont(JSON.parse(response.data.userDto.onfidoWatchlistResponse)?.breakdown?.sanction?.result)
          }
          if (JSON.parse(response.data.userDto.onfidoWatchlistResponse)?.breakdown?.legal_and_regulatory_warnings?.result) {
            set_watch_legal(JSON.parse(response.data.userDto.onfidoWatchlistResponse)?.breakdown?.legal_and_regulatory_warnings?.result)
          }
          if (JSON.parse(response.data.userDto.onfidoWatchlistResponse)?.breakdown?.politically_exposed_person?.result) {
            set_watch_political(JSON.parse(response.data.userDto.onfidoWatchlistResponse)?.breakdown?.politically_exposed_person?.result)
          }
          if (JSON.parse(response.data.userDto.onfidoWatchlistResponse)?.properties?.records) {
            set_watch_property(JSON.parse(response.data.userDto.onfidoWatchlistResponse)?.properties?.records)
          }
          setWatchList(JSON.parse(response.data.userDto.onfidoWatchlistResponse))
          if (response.data.userDto.isEnhancedKycDone) {
            setIsEnhancedKycDone(true);
          }
          if (response.data.userDto.flagTwoDocs) {
            setFlag2Documents(true);
          }
          if (response.data.userDto.flagThreeDocs) {
            setFlag3Documents(true);
          }
          setProfiledata(response.data.userDto);
          setCompliances(response.data.compliances);
          setRiskLevelAry(response.data.userRiskLevelsList);
          // setAdditionDocuments()
          setLoaderAccountDetail(false);
        })
        .catch(function (error) {
          userService.handleError(error);
          setLoaderAccountDetail(false);
        });
    }
    else {
      adminService
        .account_detail(user_id)
        .then(function (response) {
          get_kyc_status(response.data.userDto.email);
          // setFlagData(response.data.userDto.flags)
          console.log(
            "rrrrrrrrrrrrrrrrrr",
            response.data
          );
          setFidoReports(response.data.userOnfidoReports)
          setWatchList(JSON.parse(response.data.userDto.onfidoWatchlistResponse))
          if (response.data.userDto.isEnhancedKycDone) {
            setIsEnhancedKycDone(true);
          }
          if (response.data.userDto.flagTwoDocs) {
            setFlag2Documents(true);
          }
          if (response.data.userDto.flagThreeDocs) {
            setFlag3Documents(true);
          }
          console.log("response.data.userDto-----", response.data.userDto);
          console.log("response.data.userDto-----", response.data.userDto.basisStatus);
          setProfiledata(response.data.userDto);
          console.log("irt90u9yry9yu9ruy9ruryuoiuouiuoiu ", profiledata);
          setCompliances(response.data.compliances);
          setRiskLevelAry(response.data.userRiskLevelsList);
          // setAdditionDocuments()
          setLoaderAccountDetail(false);
        })
        .catch(function (error) {
          userService.handleError(error);
          setLoaderAccountDetail(false);
        });
    }
  };

  const UploadDocument = () => {
    if (Document == "") {
      toast.error("Pleace select a document.");
    }
    else if ((Document as any).type == "image/png" || (Document as any).type == "image/jpeg" || (Document as any).type == "application/pdf" || (Document as any).type == "image/jpg") {
      var formData = new FormData();
      formData.append("file", Document);
      adminService.upload_onfido_document(formData, profiledata.id)
        .then(response => {
          setDocumentModal(false);
          getProfile()
          toast.success("Doument uploaded successfully.")
        })
        .catch(err => {
          alert(err.response.data.message)
        })
    }
    else {
      toast.error("Please upload valid document format.");
    }
  }

  const UploadVideo = () => {
    if (video == "") {
      toast.error("Pleace select a video.");
    }
    else if ((video as any).type == "video/mp4" || (video as any).type == "avi") {
      var formData = new FormData();
      formData.append("file", video);
      adminService.upload_onfido_video(formData, profiledata.id)
        .then(response => {
          setVideoModal(false);
          getProfile()
          toast.success("Video uploaded successfully.")
        })
        .catch(err => {
          console.log("errrrrrrrrrr", err)
        })
    }
    else {
      toast.error("Please upload valid video format.");
    }
  }

  function closeModal() {
    setShowModal(false);
  }

  function setCommentsss(val: any) {
    setComments(val);
  }

  function get_kyc_status(email: any) {
    let api_key = "prod-GyNtgjCefFzWrOCMjYFCdoxpZTumokrl";
    let sha_key = sha256(email + "s2-HAwCJvRSROozaRRZpWYwrNlnqlMRbktI");
    userService
      .get_kyc_status(email, api_key, sha_key)
      .then(function (response) {
        setKycUserData(response.data.data);
        setLoaderKyc(false);
      })
      .catch(function (error) {
        setLoaderKyc(false);

      });

    // setTimeout(function(){
    //  return tmp;
    //  }, 1000);
  }

  function show_data(data: string) {
    return data ? data : "-";
  }

  function approve() {
    setShowModal(true);
  }

  function submit() {
    makeApprove();
  }

  function makeApprove() {
    if (comments == "") {
      setCommentError("Please Enter Comment");
    } else {
      if (/^[ ]+$/.test(comments)) {
        setCommentError("Please Enter Valid Comment");
      } else {
        setIsAddCommentLoading(true);
        let params: any = {};
        params = {
          comment: comments,
        };

        adminService
          .account_approve(profiledata.id, params)
          .then(function (response) {
            setIsAddCommentLoading(false);
            setShowModal(false);
            getProfile();
          })
          .catch(function (error) {
            setIsAddCommentLoading(false);
            userService.handleError(error);
          });
      }
    }
  }

  const UserKYCVerify = (userId: any) => {
    adminService
      .verifyUserKYC(userId)
      .then(function (response) {
        getProfile()
        toast.success("KYC verified successfully")
      })
      .catch(function (error) {
        setIsAddCommentLoading(false);
        userService.handleError(error);
      });
  }

  const getData = () => {
    const user_id = localStorage.getItem("user_id");
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      let config1 = {
        headers: {
          "Content-Type": "application/json",
          deviceType: "Android",
          appVersion: "1.0",
          Authorization: "Bearer " + access_token,
        },
      };
      axios
        .get(
          `${TRANSACTION_MANAGEMENT}/${user_id}/getTranctionListByMerchant?page=0`,
          config1
        )
        .then(function (response) {
          var data1 = response.data.data;
          setTotalPage(data1.count);
          if (data1.count > 0) {
            setisLoading(1);
          } else {
            setisLoading(2);
          }
          setRates(data1.transactions);
        })
        .catch(function (error) {
          setisLoading(3);
          setRates([]);
        });
    }
  };

  function basisStatus(status: any) {
    // alert(status)
    if (status == 3) {
      return "Provide Additional Data";
    } else if (status == 10) {
      return "Approved";
    } else if (status == 11) {
      return "Rejected";
    } else {
      return "Pending";
    }
  }

  // function KycDetails() {
  //   var docType = "image";
  //   var type = "";
  //   if (profiledata) {
  //     if (profiledata.utilityBill) {
  //       let tmp = profiledata.utilityBill.split(".");
  //       type = tmp[tmp.length - 1];
  //     }
  //     console.log("profiledataprofiledata", profiledata);

  //     docType =
  //       type === "image/jpg" || type === "image/jpeg" || type === "image/png" ? "image" : "pdf";
  //   }

  //   const handleFile = (e: any) => {
  //     e.preventDefault();
  //     if (e.target.files[0]) {
  //       // debugger
  //       setFiles(e.target.files[0]);
  //       // debugger
  //       setFileName(e.target.files[0].name);
  //     }
  //   };

  //   const capitalizeFirstLetter = (string: any) => {
  //     return string.charAt(0).toUpperCase() + string.slice(1);
  //   }

  //   return (
  //     <div className="table_sction_outer kyc_details loader_parent">
  //       {loaderAccountDetail && (
  //         //  loaderKyc &&
  //         <div id="" className="loaderbox loginloader innerloader">
  //           <img
  //             className="bounce-2"
  //             src={loader_image}
  //             width="50"
  //             height="50"
  //           />
  //           <p>Loading...</p>
  //         </div>
  //       )}

  //       <div className="top-sctin profile_inner_sction reason_tab">
  //         <h4 >eKYC Status:
  //           {profiledata?.ekycStatus !== null && profiledata?.ekycStatus !== "NOT_APPLIED" ?
  //             profiledata && profiledata.ekycStatus === "DONE" ?

  //               <span className="step_name red"> APPROVED BY ADMIN</span>
  //               :
  //               <span className="step_name red"> {profiledata?.ekycStatus}</span>
  //             : <span className="step_name red"> NOT APPLIED</span>}
  //         </h4>

  //       </div>
  //       <div className="top-sctin profile_inner_sction reason_tab">
  //         {profiledata?.ekycStatus !== null && profiledata?.ekycStatus == "REJECTED" && profiledata?.ourReasonToRejectOnfido !== null ?
  //           <><h4 className="reason_name red">Rejection Reason:</h4>
  //             <span className="step_name">{profiledata?.ourReasonToRejectOnfido}</span></>
  //           : ""}

  //       </div>
  //       <div className="profile_filled">
  //         {/* {profiledata && kycUserData && kycUserData.profile && */}
  //         {profiledata && profiledata?.ekycStatus !== null && profiledata?.ekycStatus !== "NOT_APPLIED" && (

  //           <div className="listng_user_info ">
  //             <ul>
  //               {(user_role == "KYCADMIN" || user_role == "ADMIN") ?
  //                 fidoReports?.map((data: any, index: any) => {

  //                   return (<li>
  //                     <p>
  //                       {data.name == "document" ? "Document" : data.name == "facial_similarity_photo" ? "Facial Similarity" : data.name == "watchlist_standard" ? "Watchlist Standard" : ""}:
  //                       <h5>Onfido Current Status:  {show_data(capitalizeFirstLetter(data.currentStatus))}</h5>
  //                       {data.currentStatus == "consider" ? <h5>Current SubStatus: {show_data(capitalizeFirstLetter(data.currentSubStatus))}</h5> : ""}
  //                     </p>
  //                     {data.name == "document" && profiledata.onfidoOriginalDocumentLink !== null &&
  //                       <button className="action-btn1" onClick={() => { setViewOriginalDocumentModal(true) }}>View Original Document</button>
  //                     }
  //                     {data.name == "document" && profiledata.onfidoDocumentLink !== null &&
  //                       <button className="action-btn1" onClick={() => { setViewDocumentModal(true) }}>View Document</button>
  //                     }
  //                     {data.name == "document" && <button className="action-btn" onClick={() => { set_doc_link(data.onfidoDocumentLink); setDocumentModal(true) }}>Upload Document</button>
  //                     }
  //                     {data.name == "facial_similarity_photo" && profiledata.onfidoOriginalVideoLink !== null &&
  //                       <button className="action-btn1" onClick={() => { setViewOriginalVideoModal(true) }}>View Original Video</button>
  //                     }
  //                     {data.name == "facial_similarity_photo" && profiledata.onfidoVideoLink !== null &&
  //                       <button className="action-btn1" onClick={() => { setViewVideoModal(true) }}>View Video</button>
  //                     }
  //                     {data.name == "facial_similarity_photo" &&
  //                       <button className="action-btn" onClick={() => { setVideoModal(true) }}>Upload Video</button>}
  //                     {data.name === "watchlist_standard" && watch_sanction && <h5>Sanction: {capitalizeFirstLetter(watch_sanction)}</h5>}
  //                     {data.name === "watchlist_standard" && watch_legal && <h5>Legal and Regulatory Warning : {capitalizeFirstLetter(watch_legal)}</h5>}
  //                     {data.name === "watchlist_standard" && watch_political && <h5>Politically Exposed Person : {capitalizeFirstLetter(watch_political)}</h5>}
  //                     {data.name === "watchlist_standard" && watch_political && (
  //                       watch_property.map((data: any) => (<><h5>Property Record Type: {capitalizeFirstLetter(data.types)}</h5>
  //                         <h5>Property Record Source: {capitalizeFirstLetter(data.sources)}</h5>
  //                         <h5>Property Record Country: {capitalizeFirstLetter(data.entity_fields_countries)}</h5>
  //                       </>)))}
  //                   </li>)
  //                 }) : ""}

  //               <li>
  //                 <p>Building Number:</p>
  //                 <h5>{show_data(profiledata.onfidoBuildingNumber)}</h5>
  //               </li>

  //               <li>
  //                 <p>Postal Code:</p>
  //                 <h5>{show_data(profiledata.onfidoPostcode)}</h5>
  //               </li>

  //               <li>
  //                 <p>Street:</p>
  //                 <h5>{show_data(profiledata.onfidoStreet)}</h5>
  //               </li>

  //               <li>
  //                 <p>Town:</p>
  //                 <h5>{show_data(profiledata.onfidoTown)}</h5>
  //               </li>
  //             </ul>
  //           </div>
  //         )}

  //         {profiledata && profiledata?.ekycStatus !== null && profiledata?.ekycStatus !== "NOT_APPLIED" && ((user_role == "KYCADMIN" && fidoReports?.length == 3) || user_role !== "KYCADMIN") && (
  //           ((profiledata?.ekycStatus == "REJECTED" || profiledata?.ekycStatus == "DONE" || (profiledata?.ekycStatus == "APPLIED")) && !profiledata?.isKycVerified) ?
  //             <div className="text-right">
  //               <button
  //                 className="btn vrfy_btn"
  //                 onClick={() => UserKYCVerify(profiledata.id)} style={{ backgroundColor: "black" }}>
  //                 Verify KYC
  //               </button>
  //             </div> : <div className="text-right"><button
  //               className="btn vrfy_btn">
  //               KYC Verified
  //             </button></div>)}
  //       </div>
  //     </div>
  //   );
  // }

  // function findselectedObj(riskLevel:any){
  //   console.log("riskLevel",riskLevel);
  //   return riskLevel.id === 1;
  // }
  function updateuser_risk_level(sel_val: any) {
    let params: any = {};
    // riskLevelAry.find(x => x.id === sel_val);

    // params = riskLevelAry.find(findselectedObj);
    params = {
      userRiskLevelId: parseInt(sel_val),
    };
    console.log("params", params);

    adminService
      .risk_level_update(profiledata.id, params)
      .then(function (response) {
        //   setShowModal(false)
        toast.success(response.data.data.message);
        getProfile();
      })
      .catch(function (error) {
        console.log("-----------error--------", error);

        // setLoaderstyle({display:"none"})
        userService.handleError(error);
      });
  }

  function update_enhanced_kyc() {
    setIsEnhancedKycDone(!isEnhancedKycDone);
    adminService
      .update_enhanced_kyc(profiledata.id)
      .then(function (response) {
        setIsEnhancedKycDone(response.data.data.user.isEnhancedKycDone);
      })
      .catch(function (error) {
        console.log("-----------error--------", error);
        //userService.handleError(error);
      });
  }

  function update_flag_2_documents() {
    setFlag2Documents(!flag2Docments);
    adminService
      .update_flag_2(profiledata.id)
      .then(function (response) {
        setProfiledata(response.data.data.user);
      })
      .catch(function (error) {
        console.log("-----------error--------", error);
        //userService.handleError(error);
      });
  }

  function update_flag_3_documents() {
    setFlag3Documents(!flag3Docments);
    adminService
      .update_flag_3(profiledata.id)
      .then(function (response) {
        setProfiledata(response.data.data.user);
      })
      .catch(function (error) {
        console.log("-----------error--------", error);
        //userService.handleError(error);
      });
  }

  function active_suspend_account(user_id: any, status: any) {
    let msg: any = status
      ? "Are you sure you want to active?"
      : "Are you sure you want to suspend?";
    let params = {
      id: user_id,
      status: status,
    };
    if (window.confirm(msg)) {
      adminService
        .suspend_or_activate_app_user(params)
        .then(function (response) {
          toast.success(response.data.message);
          getProfile();
        })
        .catch(function (error) {
          getProfile();
          userService.handleError(error);
        });
    }
  }

  function ekycUnVerified(user_id: any) {
    if (user_id) {
      adminService
        .un_verify_user_eKYC(profiledata.id)
        .then(function (response) {
          var data1 = response.data.data;
          console.log(response.data);
          setProfiledata(response.data.data);
          // getProfile();
        })
        .catch(function (error) {
          console.log(error);
          getProfile();
        });
    }
  }

  const setCommentData = (value: any) => {
    setComments(value);
    setCommentError("");
  };

  const closeDocumentModal = () => {
    setDocumentModal(false);
  }

  const closeVideoModal = () => {
    setVideoModal(false)
  }

  const closeViewVideoModal = () => {
    setViewVideoModal(false);
  }

  const closeViewDocumentModal = () => {
    setViewDocumentModal(false)
  }

  const closeViewOriginalDocumentModal = () => {
    setViewOriginalDocumentModal(false)
  }

  const closeOriginalViewVideoModal = () => {
    setViewOriginalVideoModal(false)
  }

  // function NoKycDetails() {
  //   return (
  //     <div className="table_sction_outer nokyc_details">
  //       <div className="text-center">
  //         <img src={require("../../images/kyc_iocn.png")} />
  //         <p className="step_name">eKYC not submitted yet.</p>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <Layout>
      <section className="inner_sction ">
        <section className="table_sction">
          <div className="container-fluid">
            <a href="/account" className="page_back_btn">
              <i className="fa fa-chevron-left" aria-hidden="true"></i>Back
            </a>
            <div className="table_sction_outer loader_parent">
              <div className="top-sctin profile_inner_sction">
                <h4>Account Details</h4>
              </div>
              {profiledata && (
                <div className="profile_filled">
                  <div className="profile_upd text-center">
                    <img
                      src={profiledata.image ? config.image_base_url + profiledata.image : user_image}
                    />
                    {/* <a data-toggle="modal" data-target="#editprofilepic">Update Profile Picture</a> */}
                  </div>
                  <div className="listng_user_info">
                    <ul>
                      <li>
                        <p>Name:</p>
                        <h5>
                          {profiledata.firstName} {profiledata.lastName}
                        </h5>
                      </li>
                      <li>
                        <p>Phone Number:</p>
                        <h5>
                          {profiledata.countryCode}-{profiledata.phoneNumber}
                        </h5>
                      </li>
                      <li>
                        <p>Email Address:</p>
                        <h5>{profiledata.email}</h5>
                      </li>
                      <li>
                        <p>Country:</p>
                        {profiledata.country == null && <h5>-</h5>}
                        {profiledata.country != null &&
                          profiledata.country &&
                          (profiledata.country as any).name && (
                            <h5>{(profiledata.country as any).name}</h5>
                          )}
                      </li>
                      {/* <li>
                                            <p>Basis eKYc Status:</p>
                                            <h5 className={profiledata.ekycStatus ? "green" : "red"}>{profiledata.ekycStatus ? "Verified" : "Not-Verified"}</h5>
                                        </li> */}
                      <li>
                        <p>Account Status:</p>
                        <h5 className={profiledata.status ? "green" : "red"}>
                          {" "}
                          {profiledata.status ? "Active" : "Suspended"}{" "}
                        </h5>
                      </li>

                      <li>
                        <p>Date Of Birth:</p>
                        <h5>{show_data(profiledata.dob)}</h5>
                      </li>

                      <li>
                        <p>ID/Passport Number:</p>
                        <h5>{show_data(profiledata.passportNumber)}</h5>
                      </li>
                      <li>
                        <p>Gender:</p>
                        <h5>{show_data(profiledata.gender)}</h5>
                      </li>
                      <li>
                        <p>Occupation:</p>
                        <h5>{show_data(profiledata.occupation)}</h5>
                      </li>
                      <li>
                        <p>College Or Company Name:</p>
                        <h5>{show_data(profiledata.collegeOrCompanyName)}</h5>
                      </li>
                      <li>
                        <p>Address:</p>
                        <h5>{show_data(profiledata.onfidoBuildingNumber + ', ' + profiledata.onfidoStreet + ', ' + profiledata.onfidoTown + ', ' + profiledata.onfidoPostcode)}</h5>
                      </li>


                      <li>
                        <p>
                          Admin Comment:
                          <h5>{show_data(profiledata.ekycComment)}</h5>
                        </p>
                      </li>
                      {/* {HandlePermission("edit", "accounts_advance_fetures") ? (
                        <li>
                          <p>Risk Level:</p>
                          {profiledata.userRiskLevel ? (
                            <select
                              value={profiledata.userRiskLevel.id}
                              onChange={(e: any) =>
                                updateuser_risk_level(e.target.value)
                              }
                              className="form-control"
                              style={{ maxWidth: "150px" }}
                            >
                              {riskLevelAry &&
                                riskLevelAry.length > 0 &&
                                riskLevelAry.map((item: any, index: any) => {
                                  return (
                                    <option value={item.id}>
                                      {item.userRiskLevel}
                                    </option>
                                  );
                                })}
                            </select>
                          ) : (
                            <select
                              value={profiledata.userRiskLevel}
                              onChange={(e: any) =>
                                updateuser_risk_level(e.target.value)
                              }
                              className="form-control"
                              style={{ maxWidth: "150px" }}
                            >
                              <option>Select</option>
                              {riskLevelAry &&
                                riskLevelAry.length > 0 &&
                                riskLevelAry.map((item: any, index: any) => {
                                  return (
                                    <option value={item.id}>
                                      {item.userRiskLevel}
                                    </option>
                                  );
                                })}
                            </select>
                          )}
                        </li>
                      ) : (
                        <li>
                          <p>Risk Level:</p>
                          <h5>
                            {profiledata.userRiskLevel &&
                              profiledata.userRiskLevel.userRiskLevel}
                          </h5>
                        </li>
                      )}

                      {profiledata.residenceCountry !== null ? <li>
                        <p>
                          Enhanced kyc:
                          <p>
                            {HandlePermission(
                              "edit",
                              "accounts_advance_fetures"
                            ) ? (
                              <input
                                type="checkbox"
                                // style={{ height: '28px', width: '28px' }}
                                defaultChecked={isEnhancedKycDone}
                                onChange={() => update_enhanced_kyc()}
                              />
                            ) : (
                              <input
                                type="checkbox"
                                // style={{ height: '28px', width: '28px' }}
                                className="block_cursor_event"
                                defaultChecked={isEnhancedKycDone}
                              />
                            )}
                          </p>
                        </p>
                      </li> : null}
                      {(profiledata.flags === 2 || profiledata.flags === 3) ? <li>
                        <p>
                          Flag 2 Documents:
                          <p>
                            {HandlePermission(
                              "edit",
                              "accounts_advance_fetures"
                            ) ? (
                              <input
                                type="checkbox"
                                // style={{ height: '28px', width: '28px' }}
                                defaultChecked={flag2Docments}
                                onChange={() => update_flag_2_documents()}
                              />
                            ) : (
                              <input
                                type="checkbox"
                                // style={{ height: '28px', width: '28px' }}
                                className="tdblock_cursor_event"
                                defaultChecked={flag2Docments}
                              />
                            )}
                          </p>
                        </p>
                      </li> : null}
                      {profiledata.flags === 3 ? <li>
                        <p>
                          Flag 3 Documents:
                          <p>
                            {HandlePermission(
                              "edit",
                              "accounts_advance_fetures"
                            ) ? (
                              <input
                                type="checkbox"
                                // style={{ height: '28px', width: '28px' }}
                                defaultChecked={flag3Docments}
                                onChange={() => update_flag_3_documents()}
                              />
                            ) : (
                              <input
                                type="checkbox"
                                // style={{ height: '28px', width: '28px' }}
                                className="block_cursor_event"
                                defaultChecked={flag3Docments}
                              />
                            )}
                          </p>
                        </p>
                      </li> : null} */}
                    </ul>
                    <div className="row col-xs-8">
                      {HandlePermission("edit", "account") && (
                        <div className="right_btn " style={{ float: "left" }}>
                          <a
                            className="change_cursor_to_pointer"
                            onClick={approve}
                          >
                            Add Comment
                          </a>
                        </div>
                      )}
                      {((user_role == "KYCADMIN" && fidoReports?.length == 3) || (user_role != "KYCADMIN" && HandlePermission("edit", "account"))) && (
                        <div className="right_btn " style={{ float: "left" }}>
                          <a
                            className="change_cursor_to_pointer"
                            onClick={() =>
                              active_suspend_account(
                                profiledata.id,
                                !profiledata.status
                              )
                            }
                          >
                            {profiledata.status ? "Suspend" : "Activate"}
                          </a>
                        </div>
                      )}
                      {/* {HandlePermission("edit","account") &&
                                      <div className="right_btn " style={{float: 'left'}}> <a className="change_cursor_to_pointer" onClick={approve} >Add Comment</a> </div>
                                    } */}
                    </div>
                    <br />
                  </div>

                  {/* <div className="text-right">
                    <button
                      className="btn vrfy_btn"
                      onClick={() => UserKYCVerify(profiledata.id)}
                    >
                      Verify KYC
                    </button>
                  </div> */}
                </div>

              )}

              {loaderAccountDetail && (
                <div id="" className="loaderbox loginloader innerloader">
                  <img
                    className="bounce-2"
                    src={loader_image}
                    width="50"
                    height="50"
                  />
                  <p>Loading...</p>
                </div>
              )}
            </div>

            {/* {iskycverified && <KycDetails />}

            {!iskycverified && <NoKycDetails />} */}
          </div>
        </section>
      </section>

      <Modal
        ariaHideApp={false}
        isOpen={modalShow}
        onRequestClose={closeModal}
        //  style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setShowModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Add Comment</h3>
        </div>

        <div className="modal-body">
          <div className="user_request">
            <div className="form-group ">
              <label>Comment:</label>
              <div className="input_box">
                <input
                  className="form-control form-control-two"
                  type="text"
                  value={comments}
                  onChange={(e: any) => setCommentData(e.target.value)}
                />
              </div>
              {commentError.length > 0 && (
                <span style={{ color: "red" }}>{commentError}</span>
              )}
            </div>
            <div className="bottom_btn">
              <button
                className="btn btn-lg btn-block"
                onClick={() => makeApprove()}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* fkjd */}
      <Modal
        ariaHideApp={false}
        isOpen={documentModal}
        onRequestClose={closeDocumentModal}
        //  style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setDocumentModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Upload Document</h3>
        </div>

        <div className="modal-body">
          <div className="user_request">
            <div className="form-group ">
              <label>Select Document:</label>
              <div className="input_box">
                <input
                  className="form-control form-control-two"
                  type="file"
                  onChange={(e: any) => setDocument(e.target.files[0])}
                />
              </div>
            </div>
            <div className="bottom_btn">
              <button
                className="btn btn-lg btn-block"
                onClick={() => UploadDocument()}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={videoModal}
        onRequestClose={closeVideoModal}
        //  style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setVideoModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Upload Video</h3>
        </div>

        <div className="modal-body">
          <div className="user_request">
            <div className="form-group ">
              <label>Select Video:</label>
              <div className="input_box">
                <input
                  className="form-control form-control-two"
                  type="file"
                  onChange={(e: any) => setVideo(e.target.files[0])}
                />
              </div>
            </div>
            <div className="bottom_btn">
              <button
                className="btn btn-lg btn-block"
                onClick={() => UploadVideo()}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </Modal>


      <Modal
        ariaHideApp={false}
        isOpen={viewDocumentModal}
        onRequestClose={closeViewDocumentModal}
        //  style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setViewDocumentModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Document</h3>
        </div>

        <div className="modal-body">
          <div className="user_request">
            <div className="form-group ">
              <div className="input_box">
                <img src={config.image_base_url + profiledata?.onfidoDocumentLink} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={viewOriginalDocumentModal}
        onRequestClose={closeViewOriginalDocumentModal}
        //  style={customStyles1}
        className={"modal-content custom_modal thakyou_pop doc_modal"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setViewOriginalDocumentModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Original Document</h3>
        </div>

        <div className="modal-body">
          <div className="user_request">
            <div className="form-group ">
              {profiledata?.onfidoOriginalDocumentLink !== null && profiledata?.onfidoOriginalDocumentLink.split(",").map((image: any) => (
                <div className="input_box">
                  <img src={config.image_base_url + image.trim()} alt="" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>


      <Modal
        ariaHideApp={false}
        isOpen={viewVideoMohal}
        onRequestClose={closeViewVideoModal}
        //  style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setViewVideoModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Video</h3>
        </div>

        <div className="modal-body">
          <div className="user_request">
            <div className="form-group ">
              <div className="input_box">
                <video autoPlay controls>
                  <source src={config.image_base_url + profiledata?.onfidoVideoLink} type="video/mp4"></source>
                  <source src={config.image_base_url + profiledata?.onfidoVideoLink} type="video/ogg"></source>
                </video>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={viewOriginalVideoMohal}
        onRequestClose={closeOriginalViewVideoModal}
        //  style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setViewOriginalVideoModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Original Video</h3>
        </div>

        <div className="modal-body">
          <div className="user_request">
            <div className="form-group ">
              <div className="input_box">
                <video autoPlay controls>
                  <source src={config.image_base_url + profiledata?.onfidoOriginalVideoLink} type="video/mp4"></source>
                  <source src={config.image_base_url + profiledata?.onfidoOriginalVideoLink} type="video/ogg"></source>
                </video>
              </div>
            </div>
          </div>
        </div>
      </Modal>


      {isAddCommentLoading && (
        <div id="api_loader">
          <div id="" className="loaderbox">
            <div>
              <img
                className="bounce-2"
                src={loader_image}
                width="50"
                height="50"
              />
              <p>Loading...</p>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default AccountDetails;
