import React, { useEffect, useState } from 'react';
import { Field, reduxForm, SubmissionError, change } from 'redux-form'
import Count from '../Count';
import Merchant from '../Merchant';
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Layout } from '../common/Layout'
import axios from 'axios';
import $ from 'jquery';
import user_image from '../../images/user_img_default.png';
import { USER_MANAGEMENT, EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT } from '../../config/config';
import loader_image from '../../images/loading.gif';
import ReactTelephoneInput from 'react-telephone-input'
import { adminService, userService } from '../../_services';
import { DataHandling, HandlePermission } from '../../components/common/DataHandling'
import { toast } from 'react-toastify';
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const Settings = (props: any) => {

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {

    adminService.get_kyc_setting().then(function (response) {
      console.log("get_kyc_setting-----", response.data);
      let tmp_ary = response.data.data.ekycDetails;
      tmp_ary = tmp_ary.filter((el: any, i: any) => ![4, 13, 15, 16].includes(i))
      setEkycSettings(tmp_ary);
      setAlreadyCommision(response.data.data.commission.configValue);
      setexpiryDays(response.data.data.receiptExpiryDate.configValue)
      setCollectionDays(response.data.data.exchangeDaysLimit.configValue)
      // receiptExpiryDate:

      // toast.success(response.data.message);
    }).catch(function (error) {
      // setisLoading(3)
      // setUsers([])
      userService.handleError(error);
    })
  }


  function validate_form(formdata: any, typ: any) {
    let is_valid: any = true

    if (typ == "EFXC") {
      // if(formdata.currencyId == ""){

      //     is_valid = false;
      // }
      // if (!(/^[-+]?\d*\.?\d*$/.test(formdata.limit))) {
      //     is_valid = false;
      // }
      if (formdata == "") {
        toast.error("Please enter value in commission")
        is_valid = false;
      }
      if (!(/^[-+]?\d*\.?\d*$/.test(formdata))) {
        toast.error("Please enter valid value in commission")
        is_valid = false;
      }
      if (formdata < 0 || formdata > 100) {
        toast.error("Please enter valid value in commission between 0 to 100")
        is_valid = false;
      }




    }
    if (typ == "RED") {
      if (formdata == "") {
        toast.error("Please enter value in Expiry days")
        is_valid = false;
      }
      if (!(/^[-+]?\d*\.?\d*$/.test(formdata))) {
        toast.error("Please enter valid value in Expiry days")
        is_valid = false;
      }
      if (formdata < 1 || formdata > 1000) {
        toast.error("Please enter valid value in Expiry between 1 to 1000")
        is_valid = false;
      }


    }

    if (typ == "EMID") {
      if (formdata == "") {
        toast.error("Please enter value in Collection days")
        is_valid = false;
      }
      if (!(/^[-+]?\d*\.?\d*$/.test(formdata))) {
        toast.error("Please enter valid value in Collection days")
        is_valid = false;
      }
      if (formdata < 1 || formdata > 1000) {
        toast.error("Please enter valid value in Collection between 1 to 1000")
        is_valid = false;
      }
    }

    console.log("is_valid", is_valid);
    return is_valid;
  }

  function save_settings(val: any, typ: any,) {
    console.log("val", val);
    console.log("typ", typ);

    if (validate_form(val, typ)) {
      adminService.save_settings(val, typ).then(function (response) {
        console.log("save_nodays-----", response.data);
        // setEkycSettings(response.data.data.ekycDetails);
        toast.success(response.data.message);
        getData()
      }).catch(function (error) {
        // setisLoading(3)
        // setUsers([])
        userService.handleError(error);
      })
    }


  }



  function changekyc(id: any, status: any) {
    let params = {
      "isRequired": status,
      "idEkycSetting": id
    }
    console.log("params", params);
    adminService.update_kyc_setting(params).then(function (response) {
      console.log("get_kyc_setting-----", response.data);
      let tmp_ary = response.data.data.ekycDetails;
      tmp_ary = tmp_ary.filter((el: any, i: any) => ![4, 13, 15, 16].includes(i))
      setEkycSettings(tmp_ary);
      // setEkycSettings(response.data.data.ekycDetails);
    }).catch(function (error) {
      userService.handleError(error);
    })
  }


  const [isLoading, setisLoading] = useState(0);

  const { error, handleSubmit, pristine, reset, submitting } = props
  const [isRequiredbtn, setisRequiredbtn] = useState(1);
  const [ekycSettings, setEkycSettings] = useState([]) as any;
  const [expiryDays, setexpiryDays] = useState("") as any;
  const [collectionDays, setCollectionDays] = useState("") as any;
  const [alreadyCommision, setAlreadyCommision] = useState("") as any;


  const renderField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className={type == "hidden" ? "" : "form-group"}>
      <label>{label}</label>
      <input {...input} placeholder={label} type={type} className={type == "hidden" ? "" : "form-control"} required />
      {touched && error && <span>{error}</span>}
    </div>
  )

  const CommisionTable = () => {
    return (
      <div className="inlineform">
        {HandlePermission("edit", "commission") ?
          <form>
            {/* <label>
          Set Commision
          </label>
           
          <input type="text" value={alreadyCommision} onChange={(e:any) => setAlreadyCommision(e.target.value)} />      */}
            <div className="form-group ">
              <div className="input_box">
                <input
                  className="form-control form-control-two col-3"
                  value={alreadyCommision}
                  onChange={(e: any) => setAlreadyCommision(e.target.value)}
                  placeholder="Enter Commision"
                  maxLength={30}
                ></input>
              </div>
            </div>
            <div className="bottom_btn">
              <a onClick={() => save_settings(alreadyCommision, "EFXC")}>Save</a>

            </div>
          </form>
          :
          <div>

            <span className="margin_right_10">
              Commision
            </span>
            <span className="margin_right_10">
              {alreadyCommision}
            </span>
          </div>
        }
      </div>
    )
  }



  return (


    <Layout>
      <section className="inner_sction">
        <section className="table_sction">
          <div className="container-fluid">
            {HandlePermission("view", "expired_transactions") &&
              <div className="table_sction_outer">
                <div className="top-sctin mbtm-0">
                  <h4>Set expiry days of receipt</h4>
                </div>
                <div className="inlineform">
                  {HandlePermission("edit", "expired_transactions") ?
                    <form>
                      {/* <label>
                        Enter Day
                        </label> 
                        <input type="text" value={expiryDays} onChange={(e:any) => setexpiryDays(e.target.value)} /> */}
                      <div className="form-group ">
                        <div className="input_box">
                          <input
                            className="form-control form-control-two"
                            value={expiryDays}
                            onChange={(e: any) => setexpiryDays(e.target.value)}
                            placeholder="Enter expiry days of receipt"
                            maxLength={30}
                          ></input>
                        </div>
                      </div>
                      <div className="bottom_btn">
                        <a onClick={() => save_settings(expiryDays, "RED")}>Save</a>
                      </div>
                    </form>
                    :
                    <div>
                      <span className="margin_right_10">
                        Expiry Days
                      </span>
                      <span className="margin_right_10">
                        {expiryDays}
                      </span>
                    </div>
                  }
                </div>

              </div>
            }
            {HandlePermission("view", "collection_days") &&
              <div className="table_sction_outer">
                <div className="top-sctin mbtm-0">
                  <h4>Set Collection Days</h4>
                </div>
                <div className="inlineform">
                  {HandlePermission("edit", "collection_days") ?
                    <form>
                      {/* <label>
                        Enter Collection fee
                        </label> 
                        <input type="text" value={collectionFee} onChange={(e:any) => setCollectionFee(e.target.value)} />*/}
                      <div className="form-group ">
                        <div className="input_box">
                          <input
                            className="form-control form-control-two"
                            value={collectionDays}
                            onChange={(e: any) => setCollectionDays(e.target.value)}
                            placeholder="Enter Collection Days"
                            maxLength={30}
                          ></input>
                        </div>
                      </div>
                      <div className="bottom_btn">
                        <a onClick={() => save_settings(collectionDays, "EMID")}>Save</a>
                      </div>

                    </form>
                    :
                    <div>
                      <span className="margin_right_10">
                        Collection Days
                      </span>
                      <span className="margin_right_10">
                        {collectionDays}
                      </span>
                    </div>
                  }
                </div>

              </div>
            }
            {HandlePermission("view", "commission") &&
              <div className="table_sction_outer">

                <div className="top-sctin mbtm-0">
                  <h4>Set Commission (%)</h4>
                  <div className="clearfix"></div>
                </div>
                {CommisionTable()}
              </div>
            }
            {/* {ekycSettings.length > 0 && HandlePermission("view", "ekyc_settings") &&
              <div className="table_sction_outer">
                <div className="top-sctin profile_inner_sction">
                  <h4>eKYC Settings</h4>
                  <div className="clearfix"></div>
                  <p className="step_name">Step1: Tell us about yourself</p>
                </div>

                <div className="">
                  <div className="row">
                    {
                      ekycSettings.map((item: any, index: any) => {
                        console.log("item---", item.ekycSetting.settingName);
                        return (
                          <div className="col-sm-6">
                            {HandlePermission("edit", "ekyc_settings") && !(item.ekycSetting.isMandatory) ?

                              <div className="required_area">
                                <p className="pull-left">{item.ekycSetting.settingName}</p>

                                {item.isRequired ?
                                  <button className="pull-right blue" onClick={() => changekyc(item.ekycSetting.idEkycSetting, false)}>REQUIRED</button>
                                  :
                                  <button className="pull-right yellow" onClick={() => changekyc(item.ekycSetting.idEkycSetting, true)}>NOT REQUIRED</button>
                                }
                                <div className="clearfix"></div>
                              </div>
                              :
                              <div className="required_area">
                                <p className="pull-left">{item.ekycSetting.settingName}</p>
                                {item.isRequired ?
                                  <span className="pull-right blue" >REQUIRED</span>
                                  :
                                  <span className="pull-right yellow" >NOT REQUIRED</span>
                                }
                                <div className="clearfix"></div>
                              </div>
                            }
                          </div>

                        )
                      })
                    }
                  </div>
                </div>
              </div>

            } */}
          </div>


        </section>
      </section>



    </Layout>
  )
}

export default reduxForm({
  form: 'searchForm', // a unique identifier for this form
  enableReinitialize: true
})(Settings)
