import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form'
import { Layout } from '../common/Layout'
import loader_image from '../../images/loading.gif';
import { adminService, userService } from '../../_services';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
}

const TimeLimit = (props: any) => {

    useEffect(() => {
      setisLoading(0);
      getCountries();
        getData();
    }, []);

    const [formVar, setFormVar] = useState({
        id: '', 
        currencyId: "-1",
        frequency: "",
        min: "",
        max: "",
        countryName: "",
        countryId: "-1",
        frequencyError: "",
        currencyIdError: "",
        minError: "",
        maxError: "",
        countryError: ""
    });


    const [currencyList, setCurrencyList] = useState([]) as any;
    const [isLoading, setisLoading] = useState(0);
    const [frequencyModal, setFrequencyModal] = useState(false);
    const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    const { handleSubmit } = props;
    const [frequencyModalHeader, setFrequencyModalHeader] = useState('Add');
    const [itemIndex, setItemIndex] = useState(-1);
    const [frequencyModalLoading, setFrequencyModalLoading] = useState(false);
    const [tempForLoadingState, setTempForLoadingState] = useState(0);
    const [countryList, setCountryList] = useState([]);
    const [tempCountryList, setTempCountryList] = useState([]);

    const [formValidateVar, setFormvalidateVar] = useState({
        isFrequencyValidate: false,
        isCourrencyValidate: false,
        isMinValidate: false,
        isMaxValidate: false,
      })



    const getData = () => {
      adminService.get_amount_time_limit().then(function (response) {
        let data:any = response.data.data.flagsForTimeLimit
        setCurrencyList(data)
        if (data.length > 0){
          setisLoading(1);
        }else{
          setisLoading(2);
        }
      })
      .catch(function (error) {
        setisLoading(3);
      });
    }

    function getCountries() {
      adminService.countries_list().then(function (response) {
        setCountryList(response.data.data.countries)
      }).catch(function (error) {
        console.log("error get_countries-------------------");
      });
    }

    const submit = () => {
        if (validateForm()){
          setFrequencyModalLoading(true);
          var user_id:any = localStorage.getItem('user_id')
          if (user_id != null){
            var params:any =  {userId : user_id , noOfDays: formVar.frequency, startTimesLimit : formVar.min, endTimesLimit : formVar.max, countryId: formVar.countryId}
            if (frequencyModalHeader == 'Add'){  
              // debugger
              adminService.add_new_amount_time_limit(params).then(function (response) {
                let tmp_arry:any = [response.data.data.flagsForTimeLimit];
                setCurrencyList(tmp_arry.concat(currencyList));
                toast.success(response.data.message);
                setFrequencyModal(false);
                setFrequencyModalLoading(false);
              })
              .catch(function (error) {
                userService.handleError(error);
              });
            }else{
              params["id"] = formVar.id;
              adminService.edit_amount_time_limit(params).then(function (response) {
                let tmp_arry:any = currencyList;
                tmp_arry[itemIndex] = response.data.data.flagsForTimeLimit
                setCurrencyList(tmp_arry);
                toast.success(response.data.message);
                setFrequencyModal(false);
                setFrequencyModalLoading(false);
              })
              .catch(function (error) {
                userService.handleError(error);
              });
            }
        }
      }
    }

    const validateForm = () => {
      var isValdate: any = true;
      if (formVar.frequency == "" || formVar.frequency == null) {
        isValdate = false;
        formVar.frequencyError = "Please Enter Frequency"
      }
      else {
        if (!/^[0-9]+$/.test(formVar.frequency)) {
          isValdate = false;
          formVar.frequencyError = "Allowed Only Digit's Here"
        }
      }

      if (formVar.min == "" || formVar.min == null) {
        isValdate = false;
        formVar.minError = "Please Enter Min"
      }
      else {
        if (!/^[0-9]+$/.test(formVar.min)) {
          isValdate = false;
          formVar.minError = "Please Enter Valid Min Value"
        }
      }

      if (formVar.max == "" || formVar.max == null) {
        isValdate = false;
        formVar.maxError = "Please Enter Max"
      }
      else {
        if (!/^[0-9]+$/.test(formVar.max)) {
          isValdate = false;
          formVar.maxError = "Please Enter Valid Max Value"
        }
        else {
          if (formVar.min >= formVar.max) {
            isValdate = false;
            formVar.maxError = "Max Time is Greater than Min Time"
          }
        }
      }

      if (frequencyModalHeader == 'Add' && formVar.countryId == "-1") {
        alert('sdh')
        isValdate = false;
        formVar.countryError = "Please Select Country"
      }

      setTempForLoadingState(tempForLoadingState + 1);
      return (isValdate);
    }
    
      const customStyles1 = {
        overlay : {
         display: "block" ,
         paddingRight: "15px",
         background: "#00000054"
        },
       
        content:{
          position: "fixed",
          top: "24px",
          right: "588px",
          bottom: "",
          left: "617px"
        }
      }; 

      function openFrequencyModal(type:any, item:any, index:any){
        var temp_arry:any = [];
        var status:any = false;
        if (type == 'new'){
          setFrequencyModalHeader('Add')
          setFormVar({...formVar,
            currencyId: "-1",
            frequency: "",
            min: "",
            max: "",
            countryId: "-1",
            frequencyError: "",
            minError: "",
            maxError: "",
            countryError: ""
        });
        setFormvalidateVar({...formValidateVar,
          isFrequencyValidate:false,
          isMinValidate: false,
          isMaxValidate: false,
        });
        countryList.map((country:any)=>{
          status = false
          currencyList.map((item: any) => {
            if (item.countryId == country.id) {
              status = true;
            }
          });
          if (status == false) {
            temp_arry.push(country);
          }
        });
        setTempCountryList(temp_arry);
        }else{
          setItemIndex(index);
          setFrequencyModalHeader('Update')
          setFormVar({...formVar,
            id: item.id,
            frequency: item.noOfDays,
            min: item.startTimesLimit,
            max: item.endTimesLimit,
            countryId: item.countryId,
            countryName: getCountryName(item.countryId),
            frequencyError: "",
            minError: "",
            maxError: ""
          });
          setFormvalidateVar({...formValidateVar,
            isFrequencyValidate: true,
            isMinValidate: true,
            isMaxValidate: true,
          });
        }
        
        setFrequencyModal(true);
      }

      const getCountryName = (id:any) =>{
        let name:any = '-'
        countryList.map((country:any)=>{
          if (country.id == id){
            name = country.name;
          }
        })
        return name;
      }

      const UserTable = () => {
        return (
                <table className="table">
                  <thead>
                    <tr>
                    <th style={{width:'125px'}}>ID </th>
                    <th style={{width:'230px'}}>COUNTRY</th>
                    <th>NUMBER OF DAYS</th>
                    <th>MIN TRANSACTIONS COUNT</th>
                    <th>MAX TRANSACTIONS COUNT</th>
                    <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currencyList && countryList && currencyList.length>0 && countryList.length > 0 && currencyList.map((item:any, index:any)=>{
                      var tmp_country_name = getCountryName(item.countryId);
                      return(
                        <tr>
                        <td>{index +1}</td>
                        <td >{tmp_country_name}</td>
                        <td style={{width: '325px'}}>{item.noOfDays}</td>
                        <td>{item.startTimesLimit}</td>
                        <td>{item.endTimesLimit}</td>
                        <td  style={{width: '325px'}}>
                          <div className="dropdown slect_profile">
                            <button className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">Select
                            <span className="caret"></span></button>
                            <ul className="dropdown-menu">
                            <li><a onClick={() => openFrequencyModal('edit', item, index)}>Edit</a></li>
                            {/* <li><a>Delete</a></li> */}
                            </ul>
                          </div>
                        </td>
                      </tr> 
                      )
                    })}
                  
                  {/* <div id="" className="loaderbox" style={{marginTop: '80px'}}><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div> */}
              
                  </tbody>
             </table>      
          )
  
      }

  return (
    <Layout>
      <section className="inner_sction">
        <section className="table_sction accunt_table">
          <div className="container-fluid">
            <div className="table_sction_outer admin_index loader_parent">
              <div className="table_inner">
                <div className="table-responsive">
                  <div className="top-sctin mbtm-0">
                    <h4>Days And Transactions Limit Per User</h4>
                      <div className="form-group pull-right">
                        <a onClick={() => openFrequencyModal('new', 'no value', '-1')} id="add_rate_button" className="btn btn-primary"> Add </a>
                      </div>
                    <div className="clearfix"></div>
                  </div>
                  {isLoading == 0 && <div id="" className="loaderbox loginloader"><div><img className="bounce-2" src={loader_image} width="50" height="50"/><p>Loading...</p></div></div> }
                  {isLoading == 1 && UserTable()}
                  {isLoading == 2 &&   <h1>Record Not Found</h1> }
                  {isLoading == 3 && <h1>Some Error Occur</h1>}
                </div>
                {/* <div className="pagination_sction">
                    <ul className="pagination">
                      {total > 0 ? pagination() : ""}
                    </ul>
                  </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* { isLoader &&
                <div id="" className="loaderbox loginloader innerloader"><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div>
            } */}
      </section>

      <Modal
        ariaHideApp={false}
        isOpen={frequencyModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop edit_profile"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button type="button" className="close" onClick={() => setFrequencyModal(false)}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">{frequencyModalHeader} Days And Transactions Limit Per User</h3>
        </div>
        <div className="modal-body">
          <div className="user_request">
            <form onSubmit={handleSubmit((val: any) => submit())}>
              <div className="col-md-12">
                {/* <div className="form-group ">
                  <label>Currency:</label>
                  <select className="form-control long-select" onChange={(e: any) => validateFormCase('currency', e.target.value)} style={{ height: '56px' }}>
                    <option value="-1">Select Currency</option>
                    {currencyList && currencyList.length > 0 && currencyList.map((currency: any, index: any) => {
                      return (
                        <option value={currency.id}>{currency.name}</option>
                      )
                    })}
                  </select>
                  {formVar.currencyIdError.length > 0 && (
                    <span style={{ color: "red" }}>{formVar.currencyIdError}</span>
                  )}
                </div> */}
                <div className="form-group ">
                  <label>Country:</label>
                {frequencyModalHeader == 'Add' ? 
                  <select className="form-control long-select"
                    onChange={(e:any) => setFormVar({...formVar, countryId: e.target.value, countryError: ''})}
                    style={{ height: '56px', color: 'rgb(101 101 101)'}}>
                      <option value="-1">Select Country</option>
                      {tempCountryList && tempCountryList.length > 0 && tempCountryList.map((country: any, index: any) => {
                        return (
                          <option value={country.id}>{country.name}</option>
                        )
                      })}
                    </select>
                  :
                  <div className="input_box">
                      <input
                        className="form-control form-control-two"
                        value={formVar.countryName}
                        disabled
                      ></input>
                    </div>
                  }
                  {formVar.countryError.length > 0 && (
                    <span style={{ color: "red" }}>{formVar.countryError}</span>
                  )}
                  </div>
                <div className="form-group ">
                  <label>Number of Days:</label>
                  <div className="input_box">
                    <input
                      className="form-control"
                      value={formVar.frequency}
                      onChange={(e:any) => setFormVar({...formVar, frequency: e.target.value, frequencyError: ''})}
                      placeholder="Enter Number of Days"
                      maxLength={30}
                    ></input>
                    {formVar.frequencyError.length > 0 && (
                      <span style={{ color: "red" }}>{formVar.frequencyError}</span>
                    )}
                  </div>
                </div>
                <div className="form-group ">
                  <label>Min Transactions Count:</label>
                  <div className="input_box">
                    <input
                      className="form-control form-control-two"
                      value={formVar.min}
                      onChange={(e:any) => setFormVar({...formVar, min: e.target.value, minError: ''})}
                      placeholder="Enter Min Transactions Count"
                      maxLength={30}
                    ></input>
                    {formVar.minError.length > 0 && (
                      <span style={{ color: "red" }}>{formVar.minError}</span>
                    )}
                  </div>
                </div>
                <div className="form-group ">
                  <label>Max Transactions Count:</label>
                  <div className="input_box">
                    <input
                      className="form-control form-control-two"
                      value={formVar.max}
                      onChange={(e:any) => setFormVar({...formVar, max: e.target.value, maxError: ''})}
                      placeholder="Enter Max Transactions Count"
                      maxLength={30}
                    ></input>
                    {formVar.maxError.length > 0 && (
                      <span style={{ color: "red" }}>{formVar.maxError}</span>
                    )}
                  </div>
                </div>
                <div className="bottom_btn">
                  <button className="btn btn-lg btn-primary btn-block">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        { frequencyModalLoading &&
          <div id="" className="loaderbox loginloader innerloader"><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div>
        }
      </Modal>
    </Layout>
  )
}

export default reduxForm({
    form: 'searchForm', // a unique identifier for this form
    enableReinitialize: true
})(TimeLimit)
