import React, { Component, useEffect, useState } from 'react'
import QrReader from 'react-qr-reader'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import axios from 'axios';
import { BASE_URL, TRANSACTION_MANAGEMENT } from '../config/config';
import firebase from './firebase/Firebase';
import $ from 'jquery'; 
import { merchantService,userService } from '../_services';
import Modal from "react-modal";
import CustomereKYCDetails from '../components/merchant/CustomereKYCDetail'
// import PropTypes from 'prop-types'
// 
interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
}


class Merchant extends React.Component<any, any>{
    constructor(props:any){
    super(props)
    this.state = {
      delay: 300,
      result: 'No result',
      words: [],
      advanced_words: [],
      scanstatus: false,
      status: 'false',
      scanpopup: false,
      isQRCode: true,
      manualCodeValue: '',
      manualCodeError: '',
      // eKYCModal: false,
      // transactionReceiptModal: false,
      // iskycverified: false,
      // customStyles2: {
      //   overlay : {
      //     display: "block" ,
      //     paddingRight: "15px",
      //     background: "#00000054",
      //     zIndex: 100000
      //    },
        
      //    content:{
      //      position: "fixed",
      //      top: "24px",
      //      right: "588px",
      //      bottom: "",
      //      left: "617px"
      //    }
      //  }
    }
  //   const [eKYCModal, setEKYCModal] = useState(false);
  // const [transactionReceiptModal, setTransactionReceiptModal] = useState(true);
    this.handleScan = this.handleScan.bind(this)
    this.setCustomerId = this.setCustomerId.bind(this)
    // this.toggleCustomerEKYCDetailModal = this.toggleCustomerEKYCDetailModal.bind(this)

  }

  componentDidMount()
  {
    // (document.getElementById("customer-detail") as any).addEventListener("click", this.setCustomerId);
    {this.popopOpen()}
    // if(navigator.getUserMedia){
    //   navigator.getUserMedia(
    //   {
    //     video: true
    //   }, 
    //   function(localMediaStream){}, 
    //   function(err){
    //     alert('The following error occurred when trying to access the camera: ' + err); 
    //   }
    // );
    // } else {
    //   alert('Sorry, browser does not support camera access');
    // }
  }

  handleScan(data:any)
  {
    console.log("handle scan");
    if(data){
      this.setState({
        result: data,
      })
      console.log("start")
      console.log($('#scanbarcode').is(':visible'));
      console.log("end")
      console.log("this.state.status",this.state.status)

      if (this.state.status == 'false'){
        this.scanApi(this.state.result)
      }
    }
  }


  scanApi(data:any){
    const qr_code = data;
    this.setState({
      status: 'true'
    });

    let merchant_id = localStorage.getItem("user_id")
    let $this = this;
     merchantService.merchant_exchange_scan(qr_code, '1')
     .then(function (response) {
        console.log("-----------------------------------------response accept_reject",response)
        console.log("firebase hit")
        console.log("api hit", response.data)
        localStorage.setItem('qr_code', qr_code);
          // const exchange_list_firebase:any = firebase.database().ref("exchanges/"+response.data.orderId);
          // console.log(exchange_list_firebase);
          // exchange_list_firebase.ref.update({
          //       scanned: true
          //   });
          $this.setCustomerId(response.data)
        // alert(response.data.message);
        $this.setState({
          status: 'false'
        })
        //localStorage.setItem('customer_id', '1');
     }).catch(function(error){
       console.log("-----------error--------",error);
         // setLoaderstyle({display:"none"})
         $this.setState({
          status: 'false'
        })
         userService.handleError(error);
     });




  }

  scanApi_old(data:any){
    const qr_value = data;
    const access_token = localStorage.getItem('access_token');
    const merchant_id_value = localStorage.getItem('user_id');
   this.setState({
      status: 'true'
    });
    if (access_token) {
      console.log("submit start")
      let config1 = {
        headers: {
            'Content-Type': 'application/json',
            'deviceType':'Android',
            'appVersion' : '1.0',
            'Authorization' : 'Bearer '+access_token
        }
      }

  
      axios.get(`${TRANSACTION_MANAGEMENT}/transactions/trasactionCode/${qr_value}?merchantId=${merchant_id_value}`, config1).then(function (response) {
         var data1 = response.data.data
        // console.log(data1)
          console.log("api hit")
         if (data1.user.id){


          const wordRef = firebase.database().ref(data1.transaction.id);
          let words:any;
          let newState:any = [];

          // wordRef.set({qrscanned: true, transactionId: data1.transaction.id, merchantName: "Paul Merchants Ltd"}).then().catch();

          console.log("firebase hit")

          wordRef.on('value',(snapshot)=> {
             words = snapshot.val();
              console.log(words)

              if (!words.qrscanned){
                wordRef.set({qrscanned: true, transactionId: data1.transaction.id, createdAt: data1.transaction.createdAt, totalAmount: data1.transaction.totalAmount, userAmount: data1.transaction.userAmount, currencyName: data1.transaction.currency.name, merchantFee: data1.transaction.fee, transactionType: data1.transaction.transactionType, merchantName: "Paul Merchants Ltd"}).then().catch();          
              }else{
                console.log("Alredy try")
              }
              // newState.push({
              //   id:word,
              //   qrscanned: words[word].qrscanned
              // });
            
            setTimeout(() => {
              window.location.reload();
            }, 5000);
              
          });
         
         }
        
      }).catch(function (error) {
        alert(error.response.data.message);

    })
    } 
  }

  handleError(err:any)
  {
    console.log(err);
  }

  onClick = () => {
    console.log("Div is clicked")
    return(
      <p>this is test</p>
      )
  }

  popopOpen(){
    const popupstatus = localStorage.getItem('popupisopen');
    if(!popupstatus){
    console.log(popupstatus)
    this.setState({
      scanpopup: popupstatus,
    })
    }else{
    console.log(popupstatus)
     //this.cameraAccess();
      this.setState({
      scanpopup: popupstatus,
      })
    }
    console.log('Pop Up Status State')
    console.log(this.state.scanpopup)
  }

  // checkPopup(){
  //   console.log("checkPopup")
  //   $('#btnTest').click(function() {
  //   });
  // }

   qrScan = () =>  {   
    return(
      <div> 
        <QrReader
          delay={this.state.delay}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: '100%' }}
          />
          <p>{this.state.result}</p>
      </div>
    )
  }

  setCustomerId(data:any){
    $("#customer-detail").trigger("click");
    setTimeout(() => {
      this.props.onSubmitMessage(data);
    }, 100);
    
  }

  render()
  {
    return(
        <div className="modal-body">
          {/* <div style={{paddingBottom: '45px'}}>
            <button className="pull-right barcode_text" onClick={()=> this.toggelQRButton()}>{this.state.isQRCode ? "Manual Code" : "Scan QR Code"}</button>
          </div> */}
          <div className="user_request">
            {/* {this.state.isQRCode ?  */}
            <div className=" text-center">
              <div className="scan_box">                     
                  {this.qrScan()}
                <div className="scanline"></div>                  
              </div>
            </div>
            {/* <button onClick={()=> this.setCustomerId()}>fff</button> */}
            {/* : */}
            {/* <div>
              <div className="form-group" >
                <label>Code:</label>
                  <input  placeholder="Enter Code Here" 
                  value={this.state.manualCodeValue} 
                  type="text" 
                  onChange={(e: any) => this.setState({manualCodeValue: e.target.value, manualCodeError: ''})}
                  className="form-control"
                  />
                  {this.state.manualCodeError.length > 0 && ( <span style={{ color: "red" }}>{this.state.manualCodeError}</span>)}
              </div>
              <div className="bottom_btn"> 
                <a className="change_cursor_to_pointer" onClick={() => this.saveManualCode()}>Save</a>
              </div>
            </div> */}
            {/* } */}

            <div className="text-center">
              {/* <div className="row">
                <div className="col-xs-6" style={{float: 'left'}}>
                    <button className="qr_code_response_button" onClick={()=> this.setCustomerId()}>View Detail</button>
                </div>
                <div className="col-xs-6" style={{float: 'right'}}>
                    <button className="qr_code_response_button" style={{backgroundColor: "#f16a6e"}}  >Cancel</button>
                </div>
            </div> */}
            {/* <button onClick={()=> this.setCustomerId()}>get detail</button> */}
            {/* <button data-dismiss="modal"  className="qr_code_response_button" id="customer-detail">View Customer Detail</button> */}

            <button data-dismiss="modal" data-toggle="modal" data-target="#get-user-detail" id="customer-detail" style={{opacity: 0}}>View Customer Detail</button>
          </div>
        </div>
      </div>
      )
  }
}

  export default Merchant 
