import { authHeader } from '../_helpers';
import { config } from '../config/config'
import axios from 'axios';

export const adminService = {
    get_exchange,
    get_exchange_detail,
    assign_request,
    create_branch,
    branches_list,
    branches_detail,
    expired_transactions,
    account_detail,
    get_notifications,
    get_suspicious_transactions,
    extend_order,
    claim_money,
    suspicious_tran_action,
    customerRequests,
    subAdminActivities,
    account_approve,
    get_merchant_profile,
    merchant_daily_limit,
    suspend_merchant,
    set_merchant_commission,
    add_subadmin,
    subadmin_list,
    edit_subadmin,
    get_kyc_setting,
    update_kyc_setting,
    transaction_list,
    save_settings,
    suspend_active_subadmin,
    getExchangeDashboardData,
    get_currencies,
    mark_unmark_notification,
    edit_branch,
    collectedAmount,
    getMerchants,
    getAccounts,
    get_merchantsearch_detail,
    get_account_detail,
    get_expiredAccount_detail,
    get_download_report,
    send_reply,
    countries_list,
    update_country,
    get_wallet_by_merchant,
    risk_level_update,
    new_amount_limit,
    get_amount_limits,
    edit_amount_limit,
    add_new_amount_time_limit,
    get_amount_time_limit,
    edit_amount_time_limit,
    edit_merchant_profile,
    update_enhanced_kyc,
    add_currency_merchant_by_CSV,
    un_verify_user_eKYC,
    suspend_or_activate_app_user,
    get_compliance_list,
    update_flag_2,
    update_flag_3,
    mark_as_suspicius,
    get_current_exchange_rate,
    upload_document,
    delete_document,
    export_document,
    add_kyc_admin,
    edit_kyc_admin,
    kyc_admin_list,
    verifyUserKYC,
    account_detail_kyc,
    upload_onfido_video,
    upload_onfido_document,
    getAllBannerImage,
    getDeleteBanner,
    uploadBannerImage
};
var configheaders: any = {
    headers: authHeader()
}

// get Banner list
function getAllBannerImage() {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/banners`;
    return axios.get(url, configheaders)
}

// get Deelete banner
function getDeleteBanner(user_id: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/banners/${user_id}`;
    return axios.delete(url, configheaders)
}
// Add Banner Image
function uploadBannerImage(params: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/banners`;
    return axios.post(url, params, configheaders)
}



function delete_document(user_id: any, doc_id: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/deleteUserComplience?userId=${user_id}&documentId=${doc_id}`;
    return axios.delete(url, configheaders)
}


function export_document(page: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/fetchMarketingMaterial?page=${page}`;
    return axios.get(url, configheaders)
}

function get_exchange() {
    let url = `${config.apiUrl}/EfxExchange/v1/api/admin/allPendingRequestForAdmin`;
    return axios.get(url, configheaders)
}

function risk_level_update(user_id: any, params: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/updateUserRiskLevel/${user_id}`;
    return axios.put(url, params, configheaders)
}

function create_branch(params: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/branchSignup`;
    return axios.post(url, params, configheaders)
}

function upload_document(params: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/uploadUserDocumentAdmin`;
    return axios.post(url, params, configheaders)
}

function get_exchange_detail(params: any, text: any) {
    let url = `${config.apiUrl}/EfxExchange/v1/api/rejectedMoneyExchangeRequest?name=${text}`;
    return axios.post(url, params, configheaders)
}

function assign_request(params: any) {
    let url = `${config.apiUrl}/EfxExchange/v1/api/redirectExchangeRequestFromAdmin`;
    return axios.post(url, params, configheaders)
}


function countries_list() {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/countries`;
    return axios.get(url, configheaders)
}

function update_country(params: any, id: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/countries/${id}`;
    return axios.put(url, params, configheaders)
}



function get_merchantsearch_detail(page: any, text: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/getMerchantsList?page=${page}&search=${text}`;
    return axios.get(url, configheaders)
}
function get_account_detail(page: any, text: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/getAppUsersList?page=${page}&search=${text}`;
    return axios.get(url, configheaders)
}
function get_expiredAccount_detail(page: any, text: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/allExpiredOrders?page=${page}&search=${text}`;
    return axios.get(url, configheaders)
}


function branches_list(txt: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/allRegisteredBranches?name=${txt}`;
    return axios.get(url, configheaders)
}

function branches_detail(id: any) {
    let url = `${config.apiUrl}/EfxExchange/v1/api/branchDetails/${id}`;
    return axios.get(url, configheaders)
}

function expired_transactions(page: any, txt: any) {
    let url = `${config.apiUrl}/EfxExchange/v1/api/allExpiredOrders?page=${page}&search=${txt}`;
    return axios.get(url, configheaders)
}

function account_detail(user_id: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/getUserAccountDetails/${user_id}`;
    return axios.get(url, configheaders)
}

function account_detail_kyc(user_id: any, logged_user: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/getUserAccountDetails/${user_id}?loggedInUserId=${logged_user}`;
    return axios.get(url, configheaders)
}

function get_notifications(countryId: any, resolvedPage: any, unattendedPage: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/notificationListForAdmin?countryId=${countryId}&resolvedPage=${resolvedPage}&unattendedPage=${unattendedPage}`;
    return axios.get(url, configheaders)
}

function get_suspicious_transactions(page: any, txt: any) {
    let url = `${config.apiUrl}/EfxExchange/v1/api/suspiciousOrders?page=${page}&search=${txt}`;
    return axios.get(url, configheaders)
}

function extend_order(id: any, params: any) {
    let url = `${config.apiUrl}/EfxExchange/v1/api/expiredTransactionsAction/${id}?subStatus=EXTEND        `;
    return axios.post(url, params, configheaders)
}

function claim_money(id: any, params: any) {
    let url = `${config.apiUrl}/EfxExchange/v1/api/claimExchangeOrder/${id}`;
    return axios.post(url, params, configheaders)
}

function suspicious_tran_action(params: any) {
    let url = `${config.apiUrl}/EfxExchange/v1/api/suspiciousTransactionsAction`;
    return axios.post(url, params, configheaders)
}

function customerRequests(page: any, txt: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/customerRequests?page=${page}&search=${txt}`;
    return axios.get(url, configheaders)
}

function subAdminActivities(page: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/subadminActivities?page=${page}`;
    return axios.get(url, configheaders)
}

function account_approve(id: any, params: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/update-ekyc/${id}`;
    return axios.put(url, params, configheaders)
}

function get_merchant_profile(merchant_id: any) {
    // ${USER_MANAGEMENT}/${merchant_id}/getMerchantProfile`
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/${merchant_id}/getMerchantProfile`;
    return axios.get(url, configheaders)
}

function merchant_daily_limit(params: any) {
    let url = `${config.apiUrl}/EfxCurrency/v1/api/setDailyLimit`;
    return axios.post(url, params, configheaders)
}

function suspend_merchant(params: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/mechantApproval`;
    return axios.post(url, params, configheaders)
}

function set_merchant_commission(params: any) {
    let url = `${config.apiUrl}/EfxExchange/v1/api/createOrUpdateMerchantCommission`;
    return axios.post(url, params, configheaders)
}

function add_subadmin(params: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/addSubAdmin`;
    return axios.post(url, params, configheaders)
}

function subadmin_list(pageNumber: any, searchText: any = '') {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/allRegisteredSubAdmins?page=${pageNumber}&name=${searchText}`;
    return axios.get(url, configheaders)
}

function edit_subadmin(params: any, subadmin_id: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/editSubAdmin/${subadmin_id}`;
    return axios.post(url, params, configheaders)
}

function get_kyc_setting() {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/adminSetting`;
    return axios.get(url, configheaders)
}

function update_kyc_setting(params: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/updateEkycSetting`;
    return axios.put(url, params, configheaders)
}

function save_settings(days: any, typ: any) {
    let url = `${config.apiUrl}/EfxExchange/v1/api/setMoneyExchangeExpiry?configValue=${days}&configType=${typ}`;
    return axios.post(url, days, configheaders)
}

function transaction_list(page: any, txt: any, country: any, period: any, occupation: any) {
    let url = `${config.apiUrl}/EfxExchange/v1/api/getMoneyChangerOrders2?page=${page}&type=${period}&country=${country}&search=${txt}&occupation=${occupation}`;
    return axios.get(url, configheaders)
}

function suspend_active_subadmin(params: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/suspend-subadmin`;
    return axios.post(url, params, configheaders)
}

function getExchangeDashboardData(params: any) {
    let url = `${config.apiUrl}/EfxExchange/v1/api/getExchangeDashboardData`;
    return axios.post(url, params, configheaders)
}

function get_currencies() {
    let url = `${config.apiUrl}/EfxCurrency/v1/api/currencies`;
    return axios.get(url, configheaders)
}

function mark_unmark_notification(params: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/markStatus`;
    return axios.post(url, params, configheaders)
}

function edit_branch(params: any, id: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/editBranch/${id}`;
    return axios.post(url, params, configheaders)
}

function collectedAmount(params: any) {
    let url = `${config.apiUrl}/EfxExchange/v1/api/adminBenifits`;
    return axios.post(url, params, configheaders)
}


function getMerchants(page: any, txt: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/getMerchantsList?page=${page}&search=${txt}`;
    return axios.get(url, configheaders)
}

function getAccounts(page: any, txt: any) {
    let current_user_id = localStorage.user_id;
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/getAppUsersList/${current_user_id}?page=${page}&search=${txt}`;
    return axios.get(url, configheaders)
}
function get_download_report(merchantId: any) {
    return `${config.apiUrl}/EfxExchange/v1/api/downloadReportForMerchant?merchantId=${merchantId}`;
}

function send_reply(params: any, id: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/replyCustomerQueries`;
    return axios.post(url, params, configheaders)
}
function get_wallet_by_merchant(merchant_id: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/getWalletByMerchant/${merchant_id}`;
    return axios.post(url, configheaders)
}
function get_admin_notifications(pageNumber: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/getAdminNotifications?page=${pageNumber}`;
    return axios.post(url, configheaders)
}
function new_amount_limit(params: any) {
    let url = `${config.apiUrl}/EfxCurrency/v1/api/flagsForAmountLimit`;
    return axios.post(url, params, configheaders)
}

function get_amount_limits() {
    let url = `${config.apiUrl}/EfxCurrency/v1/api/flagsForAmountLimit`;
    return axios.get(url, configheaders)
}


function edit_amount_limit(params: any) {
    let url = `${config.apiUrl}/EfxCurrency/v1/api/flagsForAmountLimit`;
    return axios.put(url, params, configheaders)
}
function add_new_amount_time_limit(params: any) {
    let url = `${config.apiUrl}/EfxCurrency/v1/api/flagsForTimeLimit`;
    return axios.post(url, params, configheaders)
}

function get_amount_time_limit() {
    let url = `${config.apiUrl}/EfxCurrency/v1/api/flagsForTimeLimit`;
    return axios.get(url, configheaders)
}
function edit_amount_time_limit(params: any) {
    let url = `${config.apiUrl}/EfxCurrency/v1/api/flagsForTimeLimit`;
    return axios.put(url, params, configheaders)
}

function edit_merchant_profile(params: any, merchant_id: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/editMerchant/${merchant_id}`;
    return axios.put(url, params, configheaders)
}
function update_enhanced_kyc(user_id: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/${user_id}/verifyEnhancedKYC`;
    return axios.put(url, {}, configheaders)
}
function add_currency_merchant_by_CSV(user_id: any, params: any) {
    let url = `${config.apiUrl}/EfxCurrency/v1/api/${user_id}/addCurrencyMerchantByCSV`;
    return axios.post(url, params, configheaders)
}
function un_verify_user_eKYC(user_id: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/${user_id}/unverifyKYC`;
    return axios.put(url, {}, configheaders)
}
function suspend_or_activate_app_user(params: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/userApproval`;
    return axios.post(url, params, configheaders)
}
function get_compliance_list(page: any, countryId: any) {
    let url = `${config.apiUrl}/EfxExchange/v1/api/complianceLogs?page=${page}&countryId=${countryId}`;
    return axios.get(url, configheaders)
}
function update_flag_2(user_id: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/${user_id}/setFlagTwoDocs`;
    return axios.put(url, {}, configheaders)
}
function update_flag_3(user_id: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/${user_id}/setFlagThreeDocs`;
    return axios.put(url, {}, configheaders)
}
function mark_as_suspicius(params: any) {
    let url = `${config.apiUrl}/EfxExchange/v1/api/markTransactionSuspicious`;
    return axios.put(url, params, configheaders)
}
function get_current_exchange_rate() {
    let url = `${config.apiUrl}/EfxExchange/v1/api/currentExchangeRates`;
    return axios.get(url, configheaders)
}

function add_kyc_admin(params: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/addKYCAdmin`;
    return axios.post(url, params, configheaders)
}

function edit_kyc_admin(params: any, user_id: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/editKYCAdmin/${user_id}`;
    return axios.post(url, params, configheaders)
}

function kyc_admin_list(pageNumber: any, searchText: any = '') {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/allRegisteredKYCAdmins?page=${pageNumber}&name=${searchText}`;
    return axios.get(url, configheaders)
}

function verifyUserKYC(user_id: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/${user_id}/verifyKYC`;
    return axios.put(url, {}, configheaders)
}

function upload_onfido_video(params: any, user_id: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/${user_id}/uploadOnfidoVideo`;
    return axios.post(url, params, configheaders)
}
function upload_onfido_document(params: any, user_id: any) {
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/${user_id}/uploadOnfidoDocument`;
    return axios.post(url, params, configheaders)
}