import React from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from 'react-bootstrap'

const Web3 = require('web3')

const  abi = require('../config/abi.json')
const web3 = new Web3(Web3.givenProvider)
//const SolidityFunction = require('web3/lib/web3/function');
var _ = require('lodash');

class Token extends React.Component {

  private executionCallback: () => void = () => {}

  constructor (props: any){
    super(props);
  }
  componentDidMount() {
    (window as any).ethereum.enable()
    //this.compoundApi()
    this.getTotalBorrowsCurrent();
    this.transactionWithRawData();
  }

  private transactionWithRawData = async () => {
    console.info('**********', web3)
    var contract = new web3.eth.Contract(abi)
    //contract.options.address = '0xd6801a1dffcd0a410336ef88def4320d6df1883e'
    var callData = contract.methods.redeem(200000000).encodeABI();
    // var callData = contract.methods.mint().encodeABI();
    console.info('______________', callData)
    web3.eth.getAccounts().then(async(accounts : any) => {
      console.info('account: ', accounts[0])
      var result = await web3.eth.sendTransaction({
          to: "0xd6801a1dffcd0a410336ef88def4320d6df1883e", 
          from: accounts[0],
          // value: 200000000000000000,
          data: callData,
          // gas: 450000, 
          // gasPrice: 8000000000
      });
      console.info('result:====', result)
    })
    console.info('===++++', callData)
  }

  private getTotalBorrowsCurrent = async () => {
    var MyContract = new web3.eth.Contract(abi);;
    MyContract.options.address = '0xd6801a1dffcd0a410336ef88def4320d6df1883e'

    try{
    var val = await MyContract.methods .totalBorrowsCurrent().call();
    console.info('+++++', val.toString())
    console.log('+++++',web3.utils.fromWei(val.toString(), "ether" ) );
    } catch(e) {
        console.error(e)
    }
  }

private compoundApi = () => {
    console.info('Compound method call')
        this.executionCallback = () => {
          this.executeMethodddd(
            abi, 
              '0xd6801a1dffcd0a410336ef88def4320d6df1883e', 
              // methods => methods.mint(),
              methods => methods.redeem(400000000),
              tx => {
                console.info('Mint: ', tx)
              },
              (confirmationNumber, receipt) => {
                console.info('confirmationNumber : ', confirmationNumber, 'receipt: ', receipt)
                
              },
              () => {
                console.info('burn successfully')
              },
              err => {
                  console.error(err)
              }
          )
        }
      }
    
      private executeMethodddd<T>(abi: any, 
        contractAddress: string,
        onMethod: (mathods: any) => any, // TransactionObject<any>,
        onPending: (txid: string) => void, 
        onConfirmation: (confirmationNumber: number, receipt: any) => void,
        onComplete: (result?: T) => void,
        onError: (err: any) => void) {
            web3.eth.getAccounts().then((accounts : any) => {
              console.info('===++++===', accounts)
                if(accounts.length > 0) { 
                    const contract = new web3.eth.Contract(abi)
                    contract.options.address = contractAddress
                    // contract.options.from = this.state.receipt.from
                    // contract.options.gasPrice = this.gasPrice
                    // onMethod(contract.methods).send({from: accounts[0], value: 500000000000000000, gas: 450000, gasPrice: 8000000000})
                    onMethod(contract.methods).send({from: accounts[0], gas: 450000, gasPrice: 8000000000})
                    .on('transactionHash', onPending)
                    .on('confirmation', (confirmationNumber: any, receipt: any) => { 
                      console.info('confirmationNumber: ', confirmationNumber, 'receipt: ', receipt)
                      onConfirmation(confirmationNumber, receipt)
                    })
                    .then(onComplete)
                    .catch(onError)
                }
            })
    }

    render() {
        return (
            <h2>
                hello
            </h2>
        ) 
    }
}
const Home = () => { 
    return (
        <Container className="main-page about">
            <Row className="justify-content-md-center">
                <Col xs sm="3">
                <h3 className="page-title">Test</h3>
                </Col>
            </Row>
            <Row>
                <div>This is test page</div>
                <Token/>
            </Row>
        </Container>
    )
}


export default Home;