import React, { useEffect, useState } from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from 'react-bootstrap'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import axios from 'axios';
import { MERCHANT_WALLET, FRONT_BASE, USER_MANAGEMENT, TRANSACTION_MANAGEMENT } from '../../config/config';
import { Layout } from '../common/Layout';
import Modal from "react-modal";
import { adminService, userService, merchantService } from "../../_services";
import loader_image from "../../images/loading.gif";
import { ToastContainer, toast } from 'react-toastify';
import XLSX from 'xlsx';
import { nextTick } from 'process';
import { CSVLink, CSVDownload } from "react-csv";
import {Amountformatter} from '../../components/common/AmountFormatter';
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  option_data: any;
  meta: {
    touched: boolean;
    error: string;
  };
}



const Wallet = (props: any) => {

  const [wallets, setWallets] = useState([]);
  // const [currencies, setCurrencies] = useState([]);
  const [checked, setChecked] = useState();
  const [showInventoryUpdateModal, setShowInventoryUpdateModal] = useState(false);
  const [passwordVerifyModal, setPasswordVerifyModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [ topUpModal, setTopUpModal ] = useState(false);
  const [ topUpPasswwordModal, setTopUpPasswwordModal ] = useState(false);
  const [ batchUploadModal, setBatchUploadModal ] = useState(false);
  const [ addMinusValue, setAddMinusValue ] = useState('+');
  const [ walletGridView, setWalletGridView ] = useState(true);
  const [ batchFileData, setBatchFileData ] = useState([]);
  const [ batchFileError, setBatchFileError ] = useState('');
  const [batchPassword, setBatchPassword] = useState("");
  const [batchPasswordError, setBatchPasswordError] = useState("");
  const [isBatchPasswordValidate, setIsBatchPasswordValidate] = useState(false);
  const [batchPasswordModal, setBatchPasswordModal] = useState(false);
  const [isBatchUploadLoading, setIsBatchUploadLoading] = useState(false);

  const [topUpForm, setTopUpForm] = useState({
    currencyId: "-1",
    amount: "",
    currencyError: "",
    amountError: "",
    isCurrencyValidate: false,
    isAmountValidate: false,
    password: "",
    passwordError: "",
    isPasswordValidate: false
  });

  const [allcurrencies,setallcurrencies] = useState() as any;
  const [ errormsg, setErrormsg ] = useState() as any;
  const [ currencyFromId, setCurrencyFromId ] = useState();
  const [isFormLoading, setIsFormLoading] = useState(0);
  const [formVar, setFormVar] = useState({
    amount: "",
    currencyId: "",
    password: "",
    amountError: "",
    passwordError: "",
    walletHeader: ""
   
  });
  const [formValidateVar, setFormvalidateVar] = useState({
    isAmountValidate: false,
    isPasswordValidate: false
  })

  const [ inventoryCSVHeaders, setInventoryCSVHeaders ] = useState([]);
  const [ inventoryCSVData, setInventoryCSVData ] = useState([]);
  const [ batchFileName, setBatchFileName ] = useState('');

  useEffect(() => {
    setIsFormLoading(0);
    setIsLoading(true);
    getData();
    getAllCurrencies()
  }, []);

  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

  const setPrimaryCurrency = (event: any) => {
    setChecked(event.target.value)
    var dataval = event.target.value
    const access_token = localStorage.getItem('access_token');
    const user_id = localStorage.getItem('user_id');
    if (access_token) {
      console.log('Bearer ' + access_token)
      let config1 = {
        headers: {
          'deviceType': 'Android',
          'appVersion': '1.0',
          'Authorization': 'Bearer ' + access_token
        }
      }
      console.log("access_token Bearer ", access_token)
      //   axios.put(`${MERCHANT_WALLET}/admin/${dataval}/makePrimaryCurrencies`,{}, config1).then(function (response) {
      //     console.log('data----------------------------->')
      //     console.log(response.data)
      //     if (response.data.message == "Success"){
      //       getData();
      //       setWallets(response.data.data.adminWallets)
      //     }

      //   }).catch(function (error) {
      //   console.log(error);

      // })


      axios.get(`${MERCHANT_WALLET}/${user_id}/makePrimaryCurrenciesMerchant?currencyId=${dataval}`, config1).then(function (responsedata) {
        console.log('data----------------------------->')
        console.log(responsedata)
        setWallets(responsedata.data.data.merchantWallets)
      }).catch(function (error) {
        console.log(error)
        setWallets([])
      })


    }
  }


  const submit = (values: any) => {
    const access_token = localStorage.getItem('access_token');
    const user_id = localStorage.getItem('user_id');
      let config1 = {
        headers: {
            'Content-Type': 'application/json',
            'deviceType':'Android',
            'appVersion' : '1.0',
            'Authorization' : 'Bearer '+access_token
        }
      }
  if (access_token && validateForm("password")){
        setIsBatchUploadLoading(true);
        axios.post(`${TRANSACTION_MANAGEMENT}/${user_id}/addCurrencyMerchant`, {
          amount: addMinusValue + formVar.amount,
          currencyId: formVar.currencyId,
          password: formVar.password
        }, config1).then(function (response) {
          setIsBatchUploadLoading(false);
          setPasswordVerifyModal(false);
          setWallets(response.data.data.merchantWallets);   
        }).catch(function (error) {
          setIsBatchUploadLoading(false);
          console.log(error);
          userService.handleError(error);
        })
    }
  }

const validateFormCase = (type:any, value:any) => {
    switch(type) {
      case 'amount':{
        setFormvalidateVar({ ...formValidateVar, isAmountValidate: false });
        if (value == "" || value == null) {
          setFormVar({ ...formVar, amount: "", amountError: "Please Enter Amount" });
        }
        else{
          if (!/^\d+(\.\d+)?$/.test(value) || /^[0.]+$/.test(value)) {
          // if (!/^\s*(\+|-)?((\d+(\.\d+)?)|(\.\d+))\s*$/.test(value) || /^[0.+-]+$/.test(value)) {
            setFormVar({ ...formVar, amount: value, amountError: "Please Enter Valid Amount" });
          } 
          else{
              setFormVar({ ...formVar, amount: value, amountError: "" });
              setFormvalidateVar({ ...formValidateVar, isAmountValidate: true });               
          }
        }
        break;
      }

      case 'password':{
        if (value == "" || value == null) {
          setFormVar({ ...formVar, password: "", passwordError: "Please Enter Password"  });
          setFormvalidateVar({ ...formValidateVar, isPasswordValidate: false });
        }else {
          setFormVar({ ...formVar, password: value,  passwordError: "" });
          setFormvalidateVar({ ...formValidateVar, isPasswordValidate: true });
        }
        break;
      }
      case 'batch_upload_password':{
        if (value == "" || value == null) {
          setBatchPasswordError("Please Enter Password");
          setBatchPassword('');
          setIsBatchPasswordValidate(false);
        }else {
          setBatchPasswordError("");
          setBatchPassword(value);
          setIsBatchPasswordValidate(true);
        }
        break;
      }
      
  }
}
  const validateForm = (type: any) => {
    if (type == "amount" && formValidateVar.isAmountValidate == false){
      validateFormCase('amount', formVar.amount)
      return false;
    }
    if (type == "password" && formValidateVar.isPasswordValidate == false){
      validateFormCase('password', formVar.password)
      return false;
    }
    return true;
  }


  const getData = () => {
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      merchantService.get_merchant_wallets().then(function (response) {
        var data1 = response.data.data
        if (data1.merchantWallets.length >0){
          setIsFormLoading(1);
        }
        else{
          setIsFormLoading(2);
        }
        setWallets(data1.merchantWallets);
        setIsLoading(false);
      }).catch(function (error) {
        setIsFormLoading(3);
        setIsLoading(false);
        console.log(error);
        setWallets([])
        userService.handleError(error);
      });
    }
  }

const updateInventory = (item:any) => {

  setFormVar({...formVar,
    amount: "",
    currencyId: item.currency.id,
    password: "",
    amountError: "",
    passwordError: "",
    walletHeader: item.currency.name
   
  });
 setFormvalidateVar({...formValidateVar,
    isAmountValidate: false,
    isPasswordValidate: false
  })

  setShowInventoryUpdateModal(true);
}
  const UserGridTable = () => {
    return (
      <ul>
        {wallets.map((item: any, index: any) => {
          return (
            <li key={index}>
              <div className="wallet_bottom">
                <h4>{item.currency.name} Wallet</h4>
                <div className="flag_image"><img src={item.currency.imagePath} /></div>
                <div className="wallet_top_txt">
                  <h5>{item.currency.name}</h5>
                  <h2>{item.balance > 0 ? <Amountformatter amount={item.balance}/> : '0.00' }</h2>
                  <button onClick={() => updateInventory(item)}>Update Inventory</button>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    )
  }

  const UserListTable = () => {
    return (
      <div className="table_inner">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>CURRENCY</th>
                <th>COUNTRY</th>
                <th>BALANCE</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {wallets.map((item: any, index: any) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.currency.name}</td>
                    <td><img style={{ width: '40px', height: '40px' }} src={item.currency.imagePath} /></td>
                    <td>{item.balance > 0 ? <Amountformatter amount={item.balance}/> : '0.00' }</td>
                    <td style={{ width: '222px' }}><button className="add_minus_button" onClick={() => updateInventory(item)}>Update Inventory</button></td>
                  </tr>)
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  // const PrimaryCurrency = () => {
  //    return (
  //       <div>
  //           {wallets.map((item: any, index: any) => {
  //             return(
  //             <div>
  //               <div key={index}>
  //               {item.isPrimaryCurrency == true ?
  //                 <div>
  //                  <h4>Master Wallet</h4>
  //                  <div className="flag_image"><img src={item.currency.imagePath}/></div>
  //                   <div className="wallet_top_txt">
  //                     <h5>{item.currency.name}</h5>
  //                     <h2>{item.balance}</h2>
  //                   </div>
  //                 </div>
  //                 :
  //                 <h4> </h4>
  //               }
  //               </div>
  //             </div>
  //             )
  //           })} 
  //         </div>  
  //    )
  // }


  const renderField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className={type == "hidden" ? "" : "form-group"}>
      <label>{label}</label>
      <input {...input} placeholder={label} type={type} className={type == "hidden" ? "" : "form-control"} required />
      {touched && error && <span>{error}</span>}
    </div>
  )
  const customStyles1 = {
    overlay : {
     display: "block" ,
     paddingRight: "15px",
     background: "#00000054",
    },
   
    content:{
      position: "fixed",
      top: "24px",
      right: "588px",
      bottom: "",
      left: "617px",
    }
  }; 

  function closeModal() {
    setShowInventoryUpdateModal(false);
  }

  function openPasswordVerifyModal(){
    if (validateForm("amount")){
      setShowInventoryUpdateModal(false);
      setPasswordVerifyModal(true);
    }
  
  }


  function openTopUpModal() {
    setTopUpForm({...topUpForm,
      currencyId: "-1",
      amount: "",
      currencyError: "",
      amountError: "",
      isCurrencyValidate: false,
      isAmountValidate: false,
      password: "",
      passwordError: "",
      isPasswordValidate: false

    });
    setTopUpModal(true);
  }

  const validateTopUpFormCase = (type: any, value: any) => {
    switch(type) {
    case 'amount':{
      if (value == "" || value == null) {
        setTopUpForm({ ...topUpForm, amount: "", amountError: "Please Enter Amount", isAmountValidate: false });
      }
      else{
        if (!/^\d+(\.\d+)?$/.test(value) || /^[0.]+$/.test(value) ) {
          setTopUpForm({ ...topUpForm, amount: value, amountError: "Please Enter Valid Amount",  isAmountValidate: false });
        } 
        else{
            setTopUpForm({ ...topUpForm, amount: value, amountError: "",  isAmountValidate: true });
        }
      }
      break;
    }

    case 'currency_id':{
      if (value == "-1" || value == null) {
        setTopUpForm({...topUpForm, currencyError: "Please Select Currency", isCurrencyValidate: false})
      }else {
        setTopUpForm({...topUpForm, currencyError: "", isCurrencyValidate: true})
      }
      break;
      }
      case 'password':{
      if (value == "" || value == null) {
        setTopUpForm({ ...topUpForm, password: "", passwordError: "Please Enter Password", isPasswordValidate: false  });
      }else {
        setTopUpForm({ ...topUpForm, password: value, passwordError: "", isPasswordValidate: true });
      }
      break;
      }
    } 
  }

  function openTopUpPasswordMoal() {
    if (validateTopUpForm()){
      setTopUpPasswwordModal(true);
    }
  }

  const validateTopUpForm = () => {
    if (!topUpForm.isCurrencyValidate){
      validateTopUpFormCase('currency_id', topUpForm.currencyId);
      return false;
    }else{
      if(!topUpForm.isAmountValidate){
        validateTopUpFormCase('amount', topUpForm.amount);
        return false;
      }
      return true;
    }

  }

  function validateTopUpPassword() {
    if (!topUpForm.isPasswordValidate){
      validateTopUpFormCase('password', topUpForm.password);
      return false;
    }
      return true;
  }

  const submittopup = (values:any) => {
            
    const access_token = localStorage.getItem('access_token');
    const user_id = localStorage.getItem('user_id');
   
    if (validateTopUpPassword()) {
      setIsBatchUploadLoading(true);
      console.log("submit start")
      let config1 = {
        headers: {
            'Content-Type': 'application/json',
            'deviceType':'Android',
            'appVersion' : '1.0',
            'Authorization' : 'Bearer '+access_token
        }
      }
      axios.post(`${TRANSACTION_MANAGEMENT}/${user_id}/addCurrencyMerchant`, {
        amount: topUpForm.amount,
        currencyId: topUpForm.currencyId,
        password: topUpForm.password
      }, config1).then(function (response) {
        if (response.data.data.merchantWallets.length == 1){
          window.location.reload();
        }
        setTopUpModal(false);
        setIsBatchUploadLoading(false);
        setTopUpPasswwordModal(false);
        setWallets(response.data.data.merchantWallets);          
      }).catch(function (error) {
        setIsBatchUploadLoading(false);
        console.log(error)
        userService.handleError(error);
      })
    }
  }

  const setCurrencyData = (event:any) => {
    let index = event.target.selectedIndex;
    let optionElement = event.target.childNodes[index];
    let optionElementId = optionElement.getAttribute('id');
    setTopUpForm({...topUpForm, currencyId: optionElementId, currencyError: (optionElementId != -1 ? "" : "Please Select Currency"),
     isCurrencyValidate: (optionElementId != -1 ? true : false)});
  }

  const getAllCurrencies = () => { 
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      merchantService.get_currencies().then(function (responseData) {
        setallcurrencies(responseData.data.data.currencies)
      }).catch(function (error) {
        console.log(error);
        setallcurrencies([]);
      });
    } 
  }

  function setAddMinus(value:any){
    setAddMinusValue(value);
  }

  function toggleWalletView(){
    setWalletGridView(!walletGridView)
  }

  function validateBatchUpload() {
    if (batchFileData.length == 0){
      setBatchFileError('Please select a CSV File.');
      return false;
    }else if (batchFileError.length > 0){
      return false;
    }
    else{
      setBatchPassword('');
      setBatchPasswordError('');
      setIsBatchPasswordValidate(false);
      setBatchUploadModal(false);
      setBatchPasswordModal(true);
    }
  }
  
  function validateBatchPassword(){
    validateFormCase("batch_upload_password", batchPassword)
    if (!isBatchPasswordValidate){
      return false;
    }
    return true;
  }

function openBatchUploadModal() {
  let tmp_data:any = [];
  let tmp_headers:any = [
      { label: "Currency Name", key: "currency_name" },
      { label: "Amount", key: "amount" }
    ];
    wallets.map((item: any, index: any) => {
      tmp_data.push({currency_name: item.currency.name, amount: item.balance});
    });
  setBatchFileName('');
  setBatchFileData([]);
  setBatchFileError('');
  setInventoryCSVData(tmp_data);
  setInventoryCSVHeaders(tmp_headers);
  setBatchUploadModal(true);
}
  const handleBatchUpload = (e: any) => {
    if (e.target.files.length > 0){
      setBatchFileError('');
      setBatchFileData([]);
      setBatchFileName(e.target.files[0].name);
      e.preventDefault();
      var isAnyError = false;
      var files = e.target.files, f = files[0];
      var reader = new FileReader();
      let errorMessage = "Invalid CSV Data. Please follow the sample CSV file."
      reader.onload = function (e:any) {
        var data = e.target.result;
        var data_arry:any = [];
        var file_data:any = [];
        let readedData = XLSX.read(data, {type: 'binary'});
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        /* Convert array to json*/
        data_arry = XLSX.utils.sheet_to_json(ws, {header:1});
        if (data_arry.length == 1){
          setBatchFileError(errorMessage);
        }
        data_arry.map((item:any, index:any) => {
          if (index == 0){
            if (item[0] != 'Currency Name' || item[1] != 'Amount'){
              setBatchFileError(errorMessage);
              isAnyError = true;
            }
            return;
          }
          if (item[0] == '' ||  (!/^[A-Z]+$/.test(item[0])) || !/^\s*(\+|-)?((\d+(\.\d+)?)|(\.\d+))\s*$/.test(item[1]) || /^[0.+-]+$/.test(item[1])){
            setBatchFileError(errorMessage);
          }
          let status:any = false;
          allcurrencies.map((currency:any, index:any)=>{
            if (item[0] == currency.name){
              status = true;
            }
          });
          if (status == false){
            setBatchFileError(errorMessage);
          }
          file_data.push({'currencyId': item[0], 'amount': item[1]});
      });
      setBatchFileData(file_data);
    };
    reader.readAsBinaryString(f)
  }else{
    setBatchFileName('');
  }
}

function submitBatchFile(){
  if (validateBatchPassword()){
    const access_token = localStorage.getItem('access_token');
    const user_id = localStorage.getItem('user_id');
    var tmp_arry:any = []
    batchFileData.map((item:any, index:any) =>{
      tmp_arry.push({currencyId: item.currencyId, amount: item.amount, password: batchPassword})
    })
    let params:any = tmp_arry
    if (access_token){
      setIsBatchUploadLoading(true);
      adminService.add_currency_merchant_by_CSV(user_id, params).then(function (response) {
        setBatchPasswordModal(false);
        setIsBatchUploadLoading(false);
        setIsLoading(true);
        getData();
      }).catch(function (error) {
        setIsBatchUploadLoading(false);
        console.log(error);
        userService.handleError(error);
      })
    }
  }
}

  const { error, handleSubmit, pristine, reset, submitting } = props
  return (
    <Layout>
      <section className="inner_sction">
        <section className="table_sction">
          <div className="container">
            <h2 className="pull-left" style={{marginTop: '0px'}}>Inventory</h2>
            <button className="pull-right update_inventory_button" onClick={openTopUpModal}>Update Inventory</button>
            <button className="pull-right update_inventory_button" style={{marginRight: '24px'}} onClick={() => toggleWalletView()}>{ walletGridView ? "List View" : "Grid View"}</button>
          </div>
          <div className="container">
            {/* <div className="wallet_top">
               {PrimaryCurrency()}                
                <div className="wallet_curerency">
                  <form onSubmit={handleSubmit(submit)}>
                  <Field
                    name="country2"
                    type="select"
                    component={renderSelectField}
                    label="Change Currency"
                    option_data = {checked}
                    onChange = {setPrimaryCurrency}
                  />
                  </form>
                </div>                 
              </div> */}
                <div className="table_sction_outer">
                    {isFormLoading == 0 && <div id="api_loader"><img src={loader_image} width="50" height="50" />Loading...</div> }
                    {isFormLoading == 1 && <div className="wallt_listing">{ walletGridView ? UserGridTable(): UserListTable()}</div>}
                    {isFormLoading == 2 &&   <h1>Record Not Found</h1> }
                    {isFormLoading == 3 &&   <h1>Some Error Occur</h1> }
              </div>            
            <div className="bar_code" style={{bottom: '47px'}}>
                <button className="batch_upload_button" onClick={() => openBatchUploadModal()} >Batch Upload</button>
            </div>
          </div>
        </section>
      </section>
      <ToastContainer />

      <Modal
        ariaHideApp={false}
        isOpen={showInventoryUpdateModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop edit_profile"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setShowInventoryUpdateModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Update Inventory({formVar.walletHeader})</h3>
        </div>
        <div className="modal-body">
          <div className="user_request">
                <form onSubmit={handleSubmit((val: any) => submit(val))}>
                  <div className="form-group ">
                    <label>Add/Minus:</label>
                    <div className="form-att">
                      <div className="row">
                        <div className="col-md-2">
                          <select className="form-control" style={{paddingLeft: '24px'}} onChange={(e) => setAddMinus(e.target.value)}>
                            <option>+</option>
                            <option>-</option>
                          </select>
                        </div>
                        <div className="col-md-10" style={{paddingLeft: '0px'}}>
                        <div className="input_box">
                          <input
                            className="form-control form-control-two"
                            value={formVar.amount}
                            onChange={(e: any) => validateFormCase('amount', e.target.value)}
                            placeholder="Enter Amount"
                            maxLength={30}
                          ></input>
                          </div>
                        </div>
                      </div>
                      {formVar.amountError.length > 0 && (
                          <span style={{ color: "red" }}>{formVar.amountError}</span>
                      )}
                    </div>
                  </div>
                  <div className="bottom_btn"> 
                      <a className="change_cursor_to_pointer" onClick={openPasswordVerifyModal} >Submit</a>
                    </div>
                </form>
              </div>
            </div>
      </Modal>



      <Modal
        ariaHideApp={false}
        isOpen={passwordVerifyModal}
        // onRequestClose={closeModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setPasswordVerifyModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Verify Your Password</h3>
        </div>
        <div className="modal-body">
          <div className="user_request">
            <form onSubmit={handleSubmit((val: any) => submit(val))}>
                <div className="form-group ">
                <label>Password:</label> 
                <div className="input_box">
                  <input
                    type="password"
                    className="form-control form-control-two"
                    value={formVar.password}
                    onChange={(e: any) => validateFormCase('password', e.target.value)}
                    placeholder="Enter Password"
                  ></input>
                  {formVar.passwordError.length > 0 && (<span style={{ color: "red" }}>{formVar.passwordError}</span>)}
                  {/* {errorMsg == "error" && <h4 className="error">Please Select Different Value for currencies</h4> }
                  {errorMsg == "already" && <h4 className="error">Already Exists</h4>}
                  {errorMsg == "error_field" && <h4 className="error">Please Fill all the Fields.</h4>}
                  {errorMsg == "pass_failed" && <h4 className="error">Incorrect Password</h4>} */}
                  </div>
              </div>


                <div className="bottom_btn">                      
                  <button id="add_pass_btn" type="submit">Submit</button>                   
                </div>
            </form>
          </div>
        </div>
        {isBatchUploadLoading && (
        <div id="" className="loaderbox loginloader">
          <div>
            <img
              className="bounce-2"
              src={loader_image}
              width="50"
              height="50"
            />
            <p>Loading...</p>
          </div>
        </div>
      )}
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={topUpModal}
        // onRequestClose={closeModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
      <div className="modal-header">
        <button
          type="button"
          className="close"
          onClick={() => setTopUpModal(false)}
        >
          <i className="fa fa-times" aria-hidden="true"></i>
        </button>
        <h3 className="modal-title">Add/Update Inventory</h3>
      </div>
      <div className="modal-body">
        <div className="user_request">
          <form onSubmit={handleSubmit(submittopup)}>
            {/* {errormsg == "error" && <h4 className="error">Please Select Different Value</h4> }
            {errormsg == "already" && <h4 className="error">Already Exists</h4>} */}

            <div className="form-group">
              <label>Currency:</label>
              <select className="form-control" onChange={(e) => setCurrencyData(e)}>
              <option id="-1">Select Currency</option>
              {
                allcurrencies && allcurrencies.map((item: any, index: any) => { 
                  return (<option key={index} id={item.id}>{item.name}</option>)
                })
              }
              </select>
              {topUpForm.currencyError != "" && (<span style={{ color: "red" }}>{topUpForm.currencyError}</span> )}
            </div>

            <div className="form-group" >
              <label>Amount:</label>
              <input  placeholder="Enter Amount" 
              value={topUpForm.amount} 
              type="text" 
              onChange={(e: any) => validateTopUpFormCase('amount', e.target.value)}
              className="form-control"
              />
              {topUpForm.amountError.length > 0 && ( <span style={{ color: "red" }}>{topUpForm.amountError}</span>)}
            </div>


            <div className="form-group">
              <label>Pay Using:</label>
              <select className="form-control">
              <option >Cash</option>
              </select>
            </div>
        
            <div className="bottom_btn"> 
              <a className="change_cursor_to_pointer" onClick={openTopUpPasswordMoal}>Submit</a>
              {/* <a href="#" data-toggle="modal" data-target="#pass_verifiedtopup" id="add_rate_btns" >Submit</a> */}

            </div>
          </form>
    
        </div>          
      </div>
    </Modal>

    <Modal
        ariaHideApp={false}
        isOpen={topUpPasswwordModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
      <div className="modal-header">
        <button
          type="button"
          className="close"
          onClick={() => setTopUpPasswwordModal(false)}
        >
          <i className="fa fa-times" aria-hidden="true"></i>
        </button>
        <h3 className="modal-title">Verify Your Password</h3>
      </div>
      <div className="modal-body">
        <div className="user_request">
          <form onSubmit={handleSubmit(submittopup)}>
            <div className="form-group ">
              <label>Password:</label> 
              <div className="input_box">
                <input 
                  type="password" 
                  className="form-control form-control-two" 
                  onChange={(e: any) => validateTopUpFormCase('password', e.target.value)}
                  value={topUpForm.password}
                  placeholder="Enter Password"  />              
              </div>
              {topUpForm.passwordError != "" && (<span style={{ color: "red" }}>{topUpForm.passwordError}</span>)}
              {/* {errormsg == "error" && <h4 className="error">Please Select Different Value for currencies</h4> }
              {errormsg == "already" && <h4 className="error">Already Exists</h4>}
              {errormsg == "error_field" && <h4 className="error">Please Fill all the Fields.</h4>}
              {errormsg == "pass_failed" && <h4 className="error">Incorrect Password</h4>} */}
            </div>
            <div className="bottom_btn">                      
              <button id="add_pass_btn" type="submit" disabled={submitting}>Submit</button>                   
            </div>
          </form>
        </div>
      </div>
      {isBatchUploadLoading && (
        <div id="" className="loaderbox loginloader">
          <div>
            <img
              className="bounce-2"
              src={loader_image}
              width="50"
              height="50"
            />
            <p>Loading...</p>
          </div>
        </div>
      )}
    </Modal>

    <Modal
        ariaHideApp={false}
        isOpen={batchUploadModal}
        onRequestClose={closeModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop edit_profile"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setBatchUploadModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Update Inventory</h3>
        </div>
        <div className="modal-body">
          <div className="user_request">
                <div className="sample_file">
                {/* <CSVLink data={inventoryCSVData} headers={inventoryCSVHeaders} className='add_minus_button pull-left' target="_blank" filename={"Inventory.csv"}><i className="fa fa-download" aria-hidden="true"></i> Download Inventory CSV</CSVLink> */}
                  <a className="add_minus_button pull-right" href="/inventory_sample.csv" download="inventory_sample.csv"><i className="fa fa-download" aria-hidden="true"></i> Download Sample CSV</a>
                </div>
                <div className="input_form_box form_input_box input-upload">
                  <form autoComplete={"off"} onSubmit={handleSubmit((val: any) => submit(val))}>
                    <div className="input_content upload_content">
                      <input contentEditable={false} type="text" className="form-control" placeholder="Upload CSV" value={batchFileName}/>
                      <div className="upload_file-btn-box">
                          <div className="upload-btn-wrapper">
                              <button className="btn" style={{fontSize: '19px'}}>Select</button>
                              <input type="file" name="myfile" onChange={(e)=> handleBatchUpload(e)} />
                          </div>
                      </div>
                    </div>
                    {batchFileError.length > 0 && ( <span style={{ color: "red" }}>{batchFileError}</span>)}
                    <br/><br/>
                    <div className="bottom_btn"> 
                      <a className="change_cursor_to_pointer" onClick={() => validateBatchUpload()}>Upload!</a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
      </Modal>
      <Modal
      ariaHideApp={false}
      isOpen={batchPasswordModal}
      // style={customStyles1}
      className={"modal-content custom_modal thakyou_pop"}
      contentLabel="Example Modal"
      overlayClassName="Overlay"
      >

      <div className="modal-header">
        <button type="button" className="close" onClick={()=>setBatchPasswordModal(false)} ><i className="fa fa-times" aria-hidden="true"></i></button>
        <h3 className="modal-title"> Verify Your Password </h3>
      </div>
      <div className="modal-body">
        <div className="form-group ">
          <label>Password:</label> 
          <div className="input_box">
            <input 
              type="password" 
              className="form-control form-control-two" 
              onChange={(e: any) => validateFormCase('batch_upload_password', e.target.value)}
              value={batchPassword}
              placeholder="Enter Password"  />              
          </div>
          {batchPasswordError != "" && (<span style={{ color: "red" }}>{batchPasswordError}</span>)}
        </div>
        <div className="bottom_btn">                      
          <button id="add_pass_btn" onClick={()=>submitBatchFile()} >Submit</button>                   
        </div>
      </div>
      {isBatchUploadLoading && (
        <div id="" className="loaderbox loginloader">
          <div>
            <img
              className="bounce-2"
              src={loader_image}
              width="50"
              height="50"
            />
            <p>Loading...</p>
          </div>
        </div>
      )}
    </Modal>

      {isLoading && (
        <div id="" className="loaderbox loginloader">
          <div>
            <img
              className="bounce-2"
              src={loader_image}
              width="50"
              height="50"
            />
            <p>Loading...</p>
          </div>
        </div>
      )}

    </Layout>
  )
}

export default reduxForm({
  form: 'searchForm' // a unique identifier for this form
})(Wallet)