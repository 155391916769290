import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
// import store from '../../store';
import { Field, reduxForm, SubmissionError, change } from 'redux-form'

import { Container, Row, Col, Button } from 'react-bootstrap' 
import { Layout } from '../common/Layout'
import $ from 'jquery'; 
import { INVESTMENT_MANAGEMENT,TRANSACTION_MANAGEMENT,FRONT_BASE } from '../../config/config';
import axios from 'axios';
import { exportDefaultSpecifier } from '@babel/types';


interface fieldInterface {
    input: any;
    label: string;
    type: string;
    inputvalue: string;
    meta: {
        touched: boolean;
        error: string;
    };
  }
  


const Editinvestment = (props: any) => {

    const [ investments, setInvestments] = useState([]);
    const [ currencies, setCurrencies ] = useState([]);
    const [ currencyToId, setCurrencyToId ] = useState();
    const [ modal, setModal ] = useState(false);
    const [ islocking, setislocking ] = useState("true");
    const [ investmentRollOverStatus, setinvestmentRollOverStatus ] = useState("USER_CHOICE");

    const [ name, setname ] = useState('');
    const [ managementFee, setmanagementFee ] = useState(0);
    const [ lockingPeriod, setlockingPeriod ] = useState(0);
    const [ minimumInvestement, setminimumInvestement ] = useState(0);
    const [ maximumInvestement, setmaximumInvestement ] = useState(0);
    const [ liquidity, setliquidity ] = useState(0);
    const [ minimumWithdrawAmount, setminimumWithdrawAmount ] = useState(0);
    const [ targetReturns, settargetReturns] = useState(0);
    const [ noticePeriodForCancellation, setnoticePeriodForCancellation] = useState(0);
    const [ advantagesDetail, setadvantagesDetail] = useState('');
    const [ description, setdescription] = useState('');

    const [ isdocument, setIsdocument] = useState(false);
   
    useEffect(() => {
        getData();
        getCurrency();
    }, []);

    const getCurrency = () => { 
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
          console.log("submit start")
          let config1 = {
            headers: {
                'Content-Type': 'application/json',
                'deviceType':'Android',
                'appVersion' : '1.0',
                'Authorization' : 'Bearer '+access_token
            }
          } 
          axios.get(`${TRANSACTION_MANAGEMENT}/currencies`, config1).then(function (responsedata) { 
            setCurrencies(responsedata.data.data.currencies)
          }).catch(function (error) {
            console.log(error)
            setCurrencies([])
          })
        }

      }

    const getData = () => { 
        const access_token = localStorage.getItem('access_token');
        const investment_id = window.sessionStorage.getItem('investment_id')
        if (access_token) {
          console.log("submit start")
          let config1 = {
            headers: {
                'Content-Type': 'application/json',
                'deviceType':'Android',
                'appVersion' : '1.0',
                'Authorization' : 'Bearer '+access_token
            }
          } 
          axios.get(`${INVESTMENT_MANAGEMENT}/${investment_id}/getInvestmentPlansById`, config1).then(function (responsedata) { 
            console.log(responsedata.data.data)
            setname(responsedata.data.data.plans.name)
            setmanagementFee(responsedata.data.data.plans.managementFee)
            setlockingPeriod(responsedata.data.data.plans.lockingPeriod)
            setminimumInvestement(responsedata.data.data.plans.minimumInvestement)
            setmaximumInvestement(responsedata.data.data.plans.maximumInvestement)
            setliquidity(responsedata.data.data.plans.liquidity)
            setminimumWithdrawAmount(responsedata.data.data.plans.minimumWithdrawAmount)
            settargetReturns(responsedata.data.data.plans.targetReturns)
            setnoticePeriodForCancellation(responsedata.data.data.plans.noticePeriodForCancellation)
            setadvantagesDetail(responsedata.data.data.plans.advantagesDetail)
            setdescription(responsedata.data.data.plans.description)
            setinvestmentRollOverStatus(responsedata.data.data.plans.investmentRollOverStatus)
            setislocking(responsedata.data.data.plans.isLocking)

            setInvestments(responsedata.data.data.plans)
          }).catch(function (error) {
            console.log(error)
            setInvestments([])
          })
        }
      }

    // function viewDetail(user_id:any,name:string){
    //   console.log("view rate")
    //   window.sessionStorage.setItem('investment_id', user_id)
    //   window.sessionStorage.setItem('investment_id_name', name)

    //   window.location.href = `${FRONT_BASE}/profit-loss`; 
    // }

    const [ document, setdocument ] = useState("null");
    const setPrimaryCurrency = (event:any) => {
        console.log(event.target.name)
        if(event.target.name == "name"){
            setname(event.target.value)
        }
        else if(event.target.name == "managementFee"){
            setmanagementFee(event.target.value)
        }
        else if(event.target.name == "lockingPeriod"){
            setlockingPeriod(event.target.value)
        }
        else if(event.target.name == "minimumInvestement"){
            setminimumInvestement(event.target.value)
        }
        else if(event.target.name == "maximumInvestement"){
          setmaximumInvestement(event.target.value)
        }
        else if(event.target.name == "liquidity"){
            setliquidity(event.target.value)
        }
        else if(event.target.name == "minimumWithdrawAmount"){
            setminimumWithdrawAmount(event.target.value)
        }
        else if(event.target.name == "targetReturns"){
            settargetReturns(event.target.value)
        }
        else if(event.target.name == "noticePeriodForCancellation"){
            setnoticePeriodForCancellation(event.target.value)
        }
        else if(event.target.name == "advantagesDetail"){
            setadvantagesDetail(event.target.value)
        }
        else if(event.target.name == "description"){
            setdescription(event.target.value)
        }
        else if(event.target.name == "investmentRollOverStatus"){
            setinvestmentRollOverStatus(event.target.value)
        }
        else if(event.target.name == "islocking"){
            setislocking(event.target.value)
        }

        else{

        }
    }

    function _handleImageChange(e:any) {
      e.preventDefault();
  
      let reader = new FileReader();
      let file = e.target.files[0];
      setdocument(file)
      reader.onloadend = () => {
        // this.setState({
        //   file: file,
        //   imagePreviewUrl: reader.result
        // });
        console.log("onloadend")
  
        console.log(reader.result)
      }
  
      reader.readAsDataURL(file)
    }
  
  const UserTable = () => { 
    return (	
        <div>
            {errormsg == "already" && <h4 className="error">Please Add all the fields</h4>}
            <div className="user_request">
            <form onSubmit={handleSubmit((val: any) => submit(val,name,managementFee,lockingPeriod,minimumInvestement,liquidity,minimumWithdrawAmount,targetReturns,investmentRollOverStatus,noticePeriodForCancellation,islocking,advantagesDetail,description,maximumInvestement))}>
                    <div className="form-group">
                        <label>Name</label>
                        <input type="text" name="name" value={name} onChange={setPrimaryCurrency} className="form-control" id="email" />        
                    </div>  
                    <div className="form-group">
                        <label>Management Fee (%)</label>
                        <input type="number" name="managementFee" value={managementFee} onChange={setPrimaryCurrency} className="form-control" id="email" />        
                    </div> 
                    <div className="form-group">
                        <label>Lock Up Period (Days)</label>
                        <input type="number" name="lockingPeriod" value={lockingPeriod} onChange={setPrimaryCurrency} className="form-control" id="email" />        
                    </div> 
                    <div className="form-group">
                        <label>Minimum Investment</label>
                        <input type="number" name="minimumInvestement" value={minimumInvestement} onChange={setPrimaryCurrency} className="form-control" id="email" />        
                    </div> 
                    <div className="form-group">
                        <label>Maximum Investment</label>
                        <input type="number" name="maximumInvestement" value={maximumInvestement} onChange={setPrimaryCurrency} className="form-control" id="email" />        
                    </div> 
                    <div className="form-group">
                        <label>Redemptions</label>
                        <input type="number" name="liquidity" value={liquidity} onChange={setPrimaryCurrency} className="form-control" id="email" />        
                    </div> 
                    <div className="form-group">
                        <label>Minimum Withdraw Amount</label>
                        <input type="number" name="minimumWithdrawAmount" value={minimumWithdrawAmount} onChange={setPrimaryCurrency} className="form-control" id="email" />        
                    </div> 
                    <div className="form-group">
                        <label>Target Returns (%)</label>
                        <input type="number" name="targetReturns" value={targetReturns} onChange={setPrimaryCurrency} className="form-control" id="email" />        
                    </div> 
                    <div className="form-group">
                        <label>
                            Roll Over
                            <select value={investmentRollOverStatus} name="investmentRollOverStatus" className="form-control"  id="investmentRollOverStatus" onChange={setPrimaryCurrency}>
                                <option value="USER_CHOICE">USER_CHOICE</option>
                                <option value="YES" >yes</option>
                                <option value="NO">no</option>
                            </select>
                        </label>
                    </div>
                    <div className="form-group">
                        <label>Notice Period For Cancellation (Days)</label>
                        <input type="number" name="noticePeriodForCancellation" value={noticePeriodForCancellation} onChange={setPrimaryCurrency} className="form-control" id="email" />        
                    </div> 
                    <div className="form-group">
                        <label>
                            Lock Up
                                <select value={islocking} name="islocking" className="form-control"  id="isLocking" onChange={changeIslocking} >
                                <option value="true">true</option>
                                <option value="false">false</option>
                                </select>
                            </label>
                        </div>
                    <div className="form-group">
                      <label>Upload File</label>
                      <input type="file" id="imageUpload" onChange={(e)=>_handleImageChange(e)}/>
                    </div>
                    <div className="form-group">
                        <label>Advantages Detail</label>
                        <input type="text" name="advantagesDetail" value={advantagesDetail} onChange={setPrimaryCurrency} className="form-control" id="email" />        
                    </div> 
                    <div className="form-group">
                        <label>Description</label>
                        <input type="textarea" name="description" value={description} onChange={setPrimaryCurrency} className="form-control" id="email" />        
                    </div> 
                    <div className="bottom_btn"> 
                    <button type="submit" disabled={submitting} className="btn">Update</button>                 
                    </div>
                </form>
            </div> 
        </div>
      )

  }

  const changeIslocking = (event:any) => {
    setislocking(event.target.value)
  }

  $(document).ready(function(){

    setTimeout(function(){
    $('#currencyTo').on('change', function(this:any) {
      localStorage.setItem('currencyTo', this.value);
      var optionsText = this.options[this.selectedIndex].text;
      localStorage.setItem('currencyTovalue', optionsText);
      // $("#currencyToId").val(this.value)
    }); }, 1000);

    

  });

  const setcurrencyToId = (event:any) => {
    var index = event.nativeEvent.target.selectedIndex;
    setCurrencyToId(event.nativeEvent.target[index].text)
  }

  const [ errormsg, setErrormsg ] = useState() as any;
  const closeModal = () => {
    setTimeout(function(){
      console.log("status----->",modal)
      if (modal == true)
      {
        //  $("#newinvestment").modal('hide');
        // $('#newinvestment').modal('toggle');
      }
   }, 1000);
  }

  const submit = (values:any,namess:any,managementFees:any,lockingPeriod:any,minimumInvestement:any,liquidity:any,minimumWithdrawAmount:any,targetReturns:any,investmentRollOverStatus:any,noticePeriodForCancellation:any,islocking:any,advantagesDetail:any,description:any,maximumInvestement:any) => {
      console.log(managementFees)
      const access_token = localStorage.getItem('access_token');
      const user_id = localStorage.getItem('user_id');
      if (access_token && currencies[6]["id"]){
        let config1 = {
          headers: {
              'Content-Type': 'application/json',
              'deviceType':'Android',
              'appVersion' : '1.0',
              'Authorization' : 'Bearer '+access_token
          }
        }
        const investment_id = window.sessionStorage.getItem('investment_id')
        var currency_id = currencies[6]["id"]
        console.log(currencies[6]["id"])
        console.log(currencies[6])

        const formData = new FormData();

        console.log("docum,ent----------")
        console.log(document)
        formData.append('file',document)
        console.log(currency_id)
        axios.put(`${INVESTMENT_MANAGEMENT}/updateInvestmentPlan`, {
            id: investment_id,
          name: namess,
          description: description,
          managementFee: managementFee,
          lockingPeriod: lockingPeriod,
          isLocking: islocking,
          minimumInvestement: minimumInvestement,
          maximumInvestement: maximumInvestement,
          liquidity: liquidity,
          minimumWithdrawAmount: minimumWithdrawAmount,
          investmentRollOverStatus: investmentRollOverStatus ,
          currencyId: currency_id,
          currency: currencies[6]["name"],
          addedBy: user_id, 
          targetReturns: targetReturns,
          noticePeriodForCancellation: noticePeriodForCancellation,
          advantagesDetail: advantagesDetail,
        }, config1).then(function (response) {

                getData();
                UserTable();
                localStorage.setItem('same_value',"false");
                setErrormsg("")
                setModal(true)

            if (document != "null" )
            {
              axios.post(`${INVESTMENT_MANAGEMENT}/${response.data.data.investmentPlan.id}/investmentPlanDoc`, formData, config1).then(function (response) {                
                window.location.href = `${FRONT_BASE}/investment`;
              }).catch(function (error) {
                console.log("file upload failed")
              })
            }
            else{
              window.location.href = `${FRONT_BASE}/investment`;
            }
          }).catch(function (error) {
            setErrormsg("already")     
            setTimeout(function(){
              setErrormsg('')
            },3000)  
          })
        }
  }

  const renderField = ({ input, label,inputvalue, type, meta: { touched, error } }: fieldInterface) => (    
    <div className={type=="hidden" ? "" : "form-group"}>
      <label>{label}</label>
        <input {...input} placeholder={label} type={type} className={type=="hidden" ? "" : "form-control"}  required/>
        {touched && error && <span>{error}</span>}          
    </div>      
  )

  const renderDoubleField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (    
    <div className={type=="hidden" ? "" : "form-group"}>
      <label>{label}</label>
        <input {...input} placeholder={label} type={type} className={type=="hidden" ? "" : "form-control"} step="0.01" required/>
        {touched && error && <span>{error}</span>}          
    </div>      
  )


  const renderTextareaField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className={type=="hidden" ? "" : "form-group"}>
        <label>{label}</label>
        <textarea {...input} className={type=="hidden" ? "" : "form-control"} />
        {touched && error && <span>{error}</span>}          
    </div>   
  )

  const renderSelectField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className="form-group">
      <label>
          {label}
            <select {...input} className="form-control"  value={ localStorage.getItem('currencyTo')} id={input.name} >
              <option>Select Currency</option>
              {
                currencies.map((item: any, index: any) => { 
                  return (<option value={item.id}>{item.name}</option>)
                })
              }
            </select>
          </label>
    </div>
  )


  const renderlockingfield = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className="form-group">
      <label>
          {label}
            <select {...input} className="form-control"  id={input.name} >
             <option value="true">true</option>
             <option value="false">false</option>
            </select>
          </label>
    </div>
  )

  const renderinvestmentRollOverStatus = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className="form-group">
      <label>
          {label}
            <select {...input} className="form-control"  id={input.name} >
             <option value="USER_CHOICE">USER_CHOICE</option>
             <option value="YES">yes</option>
             <option value="NO">no</option>
            </select>
          </label>
    </div>
  )
  
  const { error, handleSubmit, pristine, reset, submitting } = props
    return (
    <Layout>
        <section className="inner_sction">
            <section className="table_sction">
                <div className="container">
                    <div className="table_sction_outer investment_sction">
                        <div className="top-sctin profile_new">
                            <h4>Investments</h4>
                        </div>
                        <div className="table_inner">
                            {UserTable()}
                        </div>
                    </div>
                </div>
            </section>
        </section>    
        <div className="modal fade" id="newinvestment" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content custom_modal">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
                        <h3 className="modal-title">Add New Investment</h3>
                    </div>
                    <div className="modal-body">
                     
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    )
}

export default reduxForm({
    form: 'searchForm', // a unique identifier for this form
    enableReinitialize : true
  })(Editinvestment)