import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import store from '../../store';
import Count from '../Count';
import Merchant from '../Merchant';
import { Container, Row, Col, Button } from 'react-bootstrap' 
import { Layout } from '../common/Layout'
import axios from 'axios';

import { EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT} from '../../config/config';
import loader_image from '../../images/loading.gif';

const hanldleClick = (type: string) => {
    store.dispatch({type});
    console.log(store.getState().counter)
}

const Merchantrate = () => {
  const [ rates, setRates ] = useState([]);
  //pagination
  const [ currentPage, setCurrentPage ] = useState(0);
  const [ usersPerPage, setUsersPerPage ] = useState(20);
  const [ totalPage, setTotalPage ] = useState(1);
  const [ isLoading, setisLoading ] = useState(0);
  const [ merchantLoading, setmerchantLoading ] = useState(0);
  const [ merchantdetail, setmerchantdetail ] = useState() as any;

  useEffect(() => {
      getData();
      getCurrentExchangeRate();
    }, []);

  const total = Math.round(totalPage/usersPerPage);

    const getData = () => {                
        const user_id =  window.sessionStorage.getItem('merchant_id');              
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
          console.log("submit start")
          let config1 = {
            headers: {
                'Content-Type': 'application/json',
                'deviceType':'Android',
                'appVersion' : '1.0',
                'Authorization' : 'Bearer '+access_token
            }
          }
          axios.get(`${EXCHANGE_MANAGEMENT}/${user_id}/getExchangeRatesByMerchant?page=0`, config1).then(function (response) {
            var data1 = response.data.data
            if (data1.exchangeRates.length > 0){
              setisLoading(1)
            }else{
              setisLoading(2)
            }
            console.log(data1)
            if (data1.merchant){
              setmerchantLoading(1)
            }
            setmerchantdetail(data1.merchant)
            setRates(data1.exchangeRates)
          }).catch(function (error) {
            setisLoading(3)
          console.log(error);
          setRates([])
        })
      }  
  }

  const makeHttpRequestWithPage = async (pageNumber:any) => {
    if (pageNumber < 0){
      pageNumber = total-1
    }
    if (pageNumber == total){
      pageNumber = 0
    }
    const user_id =  window.sessionStorage.getItem('merchant_id');
    const access_token = localStorage.getItem('access_token');
    const response = await fetch(`${EXCHANGE_MANAGEMENT}/${user_id}/getExchangeRatesByMerchant?page=${pageNumber}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'deviceType':'Android',
        'appVersion' : '1.0',
        'Authorization' : 'Bearer '+access_token
    },
    });
    const data = await response.json();
    console.log("I am here")
    console.log(data.data.exchangeRates.length)
    if (data.data.exchangeRates.length > 0){
      setisLoading(1)
    }else{
      setisLoading(2)
    }
    setRates(data.data.exchangeRates)
    setCurrentPage(pageNumber)
 
  }

  const [ currentrate, setCurrentrate ] = useState([]);
  const getCurrentExchangeRate = async () => {
    let config1 = {
      headers: {
          'Content-Type': 'application/json'
      }
    }
    console.log("getCurrentExchangeRate")
    var resp = await axios.get(`https://api.exchangeratesapi.io/latest?base=USD`, config1).then(function (response) {
        console.log("Exchange Rate-------------------------------")
        var aa = response.data.rates
        setCurrentrate(aa)
        }).catch(function (error) {
        console.log(error);
      })
  }

  const calculateRate = (from:any, to:any) => {
    let from_currency = currentrate[from]
    let to_currency = currentrate[to]
    let currencyRate = 1.0
    if (from_currency && to_currency){
     currencyRate = (to_currency/from_currency)
    }
    return currencyRate
    }


  const UserTable = () => {     
    return(
      <div className="table_inner">
        <h2>Exchange Details</h2>
          <div className="table-responsive">
              <table className="table">
                  <thead>
                  <tr>
                      <th>FROM</th>
                      <th>TO</th>
                      <th>EXCHANGE PRICE</th>
                      <th>YOUR BEST RATE</th>
                  </tr>
                  </thead>
                  <tbody>
                  {rates.map((item: any, index: any) => {
                    console.log("itemid",item.id)
                    var val = calculateRate(item.currencyFrom, item.currencyTO)
                    return(
                    <tr key={index}>
                      <td><img src={item.currencyFromFlag} style={ { width:`33px`, height: `22px` } }/>{item.currencyFrom}</td>
                      <td><img src={item.currencyToFlag} style={ { width:`33px`, height: `22px` } }/>{item.currencyTO}</td>
                      <td>1 {item.currencyFrom}={val} {item.currencyTO}</td>
                      <td>1 {item.currencyFrom}=<span>{item.exchangeRate} {item.currencyTO}</span></td>
                      </tr> )
                    })}							  
                  </tbody>
              </table>
          </div>        
      </div>
    )
  }

  const MerchantTable = () => {   
    return(
      <div className="table_inner">
        <h2>Merchant Details</h2>
          <div className="table-responsive">
              <table className="table">
                  <thead>
                  <tr>
                      <th>EMAIL</th>
                      <th>NAME</th>
                      <th>PHONE NUMBER</th>
                      <th>ADDRESS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{merchantdetail && merchantdetail.email}</td>
                    <td>{merchantdetail && merchantdetail.firstName} {merchantdetail && merchantdetail.lastName}</td>
                    <td>{merchantdetail && merchantdetail.phoneNumber}</td>
                    <td>{merchantdetail && merchantdetail.address}</td>
                  </tr>						  
                  </tbody>
              </table>
          </div>        
      </div>
    )
  }

  const pagination = () =>{
    let table = []
    table.push(<li><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(currentPage+1)}>Next</a></li>)
    for (let i = 0; i < total; i++) {
      console.log("i------------------->"+currentPage)
      // table.push(<span onClick={() => makeHttpRequestWithPage(i)} className="active">{i}</span>)
      table.push(<li className={ i == currentPage ? "active  block_cursor_event" :""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}  >{i+1}</a></li>)
    }
    table.push(<li><a  href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(currentPage-1)}>Previous</a></li>)
    return table
  }

const backurl =  () => {
    window.location.href = "/merchant/listing"
  }

    var val = window.sessionStorage.getItem('merchant_id')
    
    return (
        <Layout>  
        <section className="inner_sction">
          <section className="table_sction accunt_table">
            <div className="container">
                <button className="btn btn-danger" onClick={() => backurl()} >Back</button>
              <div className="table_sction_outer admin_index">
                {getData}
                <div className="table_inner">
                  <div className="table-responsive">
                  {isLoading == 0 && <div id="api_loader"><img src={loader_image} width="50" height="50" />Loading...</div> }
                  {merchantLoading == 1  &&  MerchantTable() }
                  {isLoading == 1 &&  UserTable() }
                  {isLoading == 2 &&   <h1>No Rates Found</h1> }
                  {isLoading == 3 &&   <h1>Some Error Occur</h1> }
                  </div>
                  <div className="pagination_sction">
                    <ul className="pagination">
                      {total > 0 ? pagination() : ""}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </Layout>
    )
}


export default connect()(Merchantrate);