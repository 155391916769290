import React, {useEffect, useState} from 'react';
import logo from '../../images/logo.png';
import user_image from '../../images/user_img_default.png';
import loader_image from '../../images/loading.gif';

import { Col,Row, Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Link } from "react-router-dom";
import axios from 'axios';
import { USER_MANAGEMENT, FRONT_BASE, EXCHANGE_MANAGEMENT, TRANSACTION_MANAGEMENT } from '../../config/config';
import $ from 'jquery'; 
import { adminService, userService } from '../../_services';
import Modal from "react-modal";

interface fieldInterface {
    input: any;
    type: string;
    label: string;
    id: string;
    accept: string;
    meta: {
        touched: boolean;
        error: string;
    };
}

const logout = () => { 
                      // localStorage.removeItem('user_type'); 
                      // localStorage.removeItem('access_token');
                      // localStorage.removeItem('user_role');
                      // localStorage.removeItem('user_id');
                      // localStorage.removeItem('lsid');
                      // localStorage.removeItem('tocurrency');
                      // localStorage.removeItem('fromcurrencyvalue');
                      // localStorage.removeItem('currencyTovalue');
                      // localStorage.removeItem('user_image');
                      // localStorage.removeItem('current_path');
                      // localStorage.removeItem('err');
                      // window.sessionStorage.removeItem('investment_id');
                      // window.sessionStorage.removeItem('investment_id_name');
                      // localStorage.clear();
                      // window.location.href = '/login'; 
                    }



const login = () => {
                      window.location.href = '/login';
                    }

const renderField = ({ input, id, accept,  type, meta: { touched, error } }: fieldInterface) => (
  <div className="avatar-edit">    
      <input {...input}  type={type} className="form-control" id={id} accept={accept} />
      <label htmlFor={id}></label>
    </div>
                                    
  )

  const renderField2 = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (    
    <div className={type=="hidden" ? "" : "form-group"}>
      <label>{label}</label>
        <input {...input} placeholder={label} type={type} className={type=="hidden" ? "" : "form-control"} required/>
        {touched && error && <span>{error}</span>}          
    </div>      
  )

  const select_by_cash = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (    
    <div className="form-group">
        <label>
          {label}
        </label>
          <select {...input} className="form-control"  id={input.name} >
          <option value="cash">Cash</option>
          </select>
       
    </div>     
  )

const Header = (props: any) => {
    var access_token = localStorage.getItem('access_token');
    const user_role = localStorage.getItem('user_role');
    const { error, handleSubmit, pristine, reset, submitting } = props
    const [image,setimage] = useState() as any;
    const [cover_pic,setcover_pic] = useState() as any;
    const [delete_msg,setdelete_msg] = useState() as any;
    const [currentpath,setcurrentpath] = useState();
    const [allcurrencies,setallcurrencies] = useState() as any;

    const [ errormsg, setErrormsg ] = useState() as any;
    const [ currencyFromId, setCurrencyFromId ] = useState();
    const [ topUpModal, setTopUpModal ] = useState(false);
    const [ topUpPasswwordModal, setTopUpPasswwordModal ] = useState(false);

    
    const [topUpForm, setTopUpForm] = useState({
      currencyId: "-1",
      amount: "",
      currencyError: "",
      amountError: "",
      isCurrencyValidate: false,
      isAmountValidate: false,
      password: "",
      passwordError: "",
      isPasswordValidate: false

    });
    useEffect(() => {
       getallcurrencies()
      localStorage.setItem('current_path',window.location.pathname)
      const img = localStorage.getItem('user_image');
      if((img && img!= null) || (image != null) || (image != "")) {
        console.log(img)
        setcover_pic(img);
        
      }else{
        setcover_pic(user_image);
      }
 
      }, []);

      const getallcurrencies = () => { 
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
          console.log("submit start")
          let config1 = {
            headers: {
                'Content-Type': 'application/json',
                'deviceType':'Android',
                'appVersion' : '1.0',
                'Authorization' : 'Bearer '+access_token
            }
          } 
          axios.get(`${TRANSACTION_MANAGEMENT}/currencies`, config1).then(function (responsedata) {
            localStorage.setItem('same_value',"false"); 
            setallcurrencies(responsedata.data.data.currencies)
          }).catch(function (error) {
            console.log(error)
            // if (error.response.status == 401 ){
            //   localStorage.clear();
            //   window.location.href = '/login'
            // }
            //userService.handleError(error);
            setallcurrencies([])
          })
        }
        else{
          window.location.href = '/login'
        }
      }

const delete_all_order = (values:any) => {    
  console.log("valuesssssssss")

  const access_token = localStorage.getItem('access_token');
  const user_id = localStorage.getItem('user_id');
  if (access_token && validatepasswordForm(values)) {
  let config1 = {
    headers: {
        'Accept': 'application/json',
        'deviceType':'Android',
        'appVersion' : '1.0',
        'Authorization' : 'Bearer '+access_token
    }
  }
  axios.post(`${EXCHANGE_MANAGEMENT}/delete`, {
    userId: user_id,
    password: values.password
  }, config1).then(function (response) {
    var data1 = response.data.data
    setdelete_msg('success')
    console.log(data1)
    window.location.href = '/order'
    setTimeout(() => {
      //window.location.reload();
      setdelete_msg('')
    }, 1000);
  }).catch(function (error) {
  console.log(error);
  if(error.response.status == 404){
    localStorage.setItem('same_value',"true"); 
    setErrormsg("already") 
  }
  else{
    localStorage.setItem('same_value',"true"); 

    setErrormsg("pass_failed") 
  }     
  setTimeout(() => {
    setErrormsg('')
  }, 2000);
    setdelete_msg('error')
  })
}

}


const validatepasswordForm= (data:any) => {
  let formIsValid = true;
  let errors :any = [];
 
  if(!data.password)
  {
    formIsValid = false;
    localStorage.setItem('same_value',"true"); 
    setErrormsg("error_field")

    setTimeout(() => {
      setErrormsg('')
    }, 2000);
    throw new SubmissionError({ email: 'Please enter valid email-ID.', _error: 'Register failed!' })
  }
  else{
    formIsValid = true;
  }

  return formIsValid;
}

    function _handleSubmit(e:any, val:string) {
      e.preventDefault();
      console.log(val)
      // TODO: do something with -> this.state.file
      console.log('handle uploading-', image);
      const access_token = localStorage.getItem('access_token');
      const user_id = localStorage.getItem('user_id');
      let config1 = {
        headers: {
            'Accept': 'application/json',
            'deviceType':'Android',
            'appVersion' : '1.0',
            'Authorization' : 'Bearer '+access_token
        }
      }
      const formData = new FormData();

      formData.append('file',image)

      axios.post(`${USER_MANAGEMENT}/users/${user_id}/updateImage`, formData, config1).then(function (response) {
        var data1 = response.data.data
        console.log(response.data)
        
        $("#wait").css("display", "none");
        let image_url =  "https://api.e-fx.asia/EFXUserManagement/"+data1.user.image;
        localStorage.setItem('user_image',image_url);
        if(val == "home"){
        window.location.href = `${FRONT_BASE}`;
        }
        else{
          window.location.href = `${FRONT_BASE}/profile`;
        }
      }).catch(function (error) {
        $("#wait").css("display", "none");
        console.log(error);
      })
    }
  
    function _handleImageChange(e:any) {
      e.preventDefault();
  
      let reader = new FileReader();
      let file = e.target.files[0];
      setimage(file)
      reader.onloadend = () => {
        // this.setState({
        //   file: file,
        //   imagePreviewUrl: reader.result
        // });
        console.log("onloadend")

        console.log(reader.result)
      }
  
      reader.readAsDataURL(file)
    }

    const submittopup = (values:any) => {
            
      const access_token = localStorage.getItem('access_token');
      const user_id = localStorage.getItem('user_id');
     
      if (validateTopUpPassword()) {
        console.log("submit start")
        let config1 = {
          headers: {
              'Content-Type': 'application/json',
              'deviceType':'Android',
              'appVersion' : '1.0',
              'Authorization' : 'Bearer '+access_token
          }
        }
        console.log("submit start end")
        axios.post(`${TRANSACTION_MANAGEMENT}/${user_id}/addCurrencyMerchant`, {
          amount: topUpForm.amount,
          currencyId: topUpForm.currencyId,
          password: topUpForm.password
        }, config1).then(function (response) {
          window.location.reload()
            
        }).catch(function (error) {
          console.log(error)
          if(error.response.status == 404){
            localStorage.setItem('same_value',"true");
            setErrormsg("already")
          }
          else{
            localStorage.setItem('same_value',"true");
            setErrormsg("pass_failed")
          }
          setTimeout(() => {
            setErrormsg('')
          }, 2000);
        })
      }
    }
    const validateForm= (data:any) => {
      let formIsValid = true;
      let errors :any = [];
     
      if(!data.amount || !data.currencyId)
      {
        formIsValid = false;
        localStorage.setItem('same_value',"true"); 
        setErrormsg("error_field")

        setTimeout(() => {
          setErrormsg('')
        }, 2000);
        throw new SubmissionError({ email: 'Please enter valid email-ID.', _error: 'Register failed!' })
      }
      else{
        formIsValid = true;
      }

      return formIsValid;
    }

    const renderSelectField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
      <div className="form-group">
          <label>
            {label}
            </label>
              <select {...input} className="form-control" id={input.name}>

                <option>Select Currency</option>
                {
                  allcurrencies && allcurrencies.map((item: any, index: any) => { 
                    return (<option key={index} value={item.id}>{item.name}</option>)
                  })
                }
              </select> 
            
      </div>
    )
    const setCurrencyData = (event:any) => {
      let index = event.target.selectedIndex;
      let optionElement = event.target.childNodes[index];
      let optionElementId = optionElement.getAttribute('id');
      setTopUpForm({...topUpForm, currencyId: optionElementId, currencyError: (optionElementId != -1 ? "" : "Please Select Currency"),
       isCurrencyValidate: (optionElementId != -1 ? true : false)});
    }

    const setcurrencyFromId = (event:any) => {
      
      var index = event.nativeEvent.target.selectedIndex;
      console.log(event.nativeEvent.target[index].text)
      // setCurrencyFromtext(event.nativeEvent.target.selectedIndex)
      console.log("event.nativeEvent.target[index].text",event.nativeEvent.target[index].text);
      setCurrencyFromId(event.nativeEvent.target[index].text)
    }

    function openTopUpModal() {
      setTopUpForm({...topUpForm,
        currencyId: "-1",
        amount: "",
        currencyError: "",
        amountError: "",
        isCurrencyValidate: false,
        isAmountValidate: false,
        password: "",
        passwordError: "",
        isPasswordValidate: false
  
      });
      setTopUpModal(true);
    }


    const customStyles1 = {
      overlay : {
       display: "block" ,
       paddingRight: "15px",
       background: "#00000054",
      },
     
      content:{
        position: "fixed",
        top: "24px",
        right: "588px",
        bottom: "",
        left: "617px",
      }
    }; 
  const validateTopUpFormCase = (type: any, value: any) => {
    switch(type) {
    case 'amount':{
      if (value == "" || value == null) {
        setTopUpForm({ ...topUpForm, amount: "", amountError: "Please Enter Amount", isAmountValidate: false });
      }
      else{
        if (!/^\d+(\.\d+)?$/.test(value) || /^[0.]+$/.test(value) ) {
          setTopUpForm({ ...topUpForm, amount: value, amountError: "Please Enter Valid Amount",  isAmountValidate: false });
        } 
        else{
            setTopUpForm({ ...topUpForm, amount: value, amountError: "",  isAmountValidate: true });
        }
      }
      break;
    }

    case 'currency_id':{
      if (value == "-1" || value == null) {
        setTopUpForm({...topUpForm, currencyError: "Please Select Currency", isCurrencyValidate: false})
      }else {
        setTopUpForm({...topUpForm, currencyError: "", isCurrencyValidate: true})
      }
      break;
      }
      case 'password':{
      if (value == "" || value == null) {
        setTopUpForm({ ...topUpForm, password: "", passwordError: "Please Enter Password", isPasswordValidate: false  });
      }else {
        setTopUpForm({ ...topUpForm, password: value, passwordError: "", isPasswordValidate: true });
      }
      break;
      }
    } 
  }

  function openTopUpPasswordMoal() {
    if (validateTopUpForm()){
      setTopUpPasswwordModal(true);
    }
  }

  const validateTopUpForm = () => {
    if (!topUpForm.isCurrencyValidate){
      validateTopUpFormCase('currency_id', topUpForm.currencyId);
      return false;
    }else{
      if(!topUpForm.isAmountValidate){
        validateTopUpFormCase('amount', topUpForm.amount);
        return false;
      }
      return true;
    }

  }

  function validateTopUpPassword() {
    if (!topUpForm.isPasswordValidate){
      validateTopUpFormCase('password', topUpForm.password);
      return false;
    }
      return true;
  }
    // var currentpathval = localStorage.getItem('current_path')
    var currentpathval = window.location.pathname
    console.log(currentpathval) 
    return (
      <div>
        <section className="header" id="myHeader">
            <nav className="navbar">
              <div className="container">
              <div className="header_postn">
                <div className="navbar-header">
                  <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>                        
                  </button>
                  <a className="navbar-brand" href="/"><img src={logo} alt="logo" /></a>
                </div>
                <div className="collapse navbar-collapse" id="myNavbar">
                    <ul className="nav navbar-nav">
                        {!access_token && <li  ><a href="/"><i className="fa fa-tachometer" aria-hidden="true"></i> Home</a></li> }
                        {access_token && user_role == "ADMIN" && <li className={currentpathval == '/' ? "active" : ""}><a href="/"><i className="fa fa-tachometer" aria-hidden="true"></i>Dashboard</a></li>}
                        {!access_token && <li ><a href="#"><i className="fa fa-bar-chart" aria-hidden="true"></i> Merchants</a></li>}
                        { access_token && user_role == "ADMIN" && <li className={currentpathval == '/merchant/listing' ? "active" : ""}><a href="/merchant/listing"><i className="fa fa-user-o" aria-hidden="true"></i> Merchants</a></li>}
                        { access_token && user_role == "ADMIN" && <li  className={currentpathval == '/transactions' ? "active" : ""}><a href="/transactions"><i className="fa fa-money" aria-hidden="true"></i> Transactions</a></li>}
                        {!access_token && <li ><a href="#"><i className="fa fa-bar-chart" aria-hidden="true"></i> Transactions</a></li>}
                        {access_token && (user_role == "MERCHANT" || user_role == "BRANCH" ) && <li className={currentpathval == '/' ? "active" : ""}><a href="/merchant/home"><i className="fa fa-tachometer" aria-hidden="true"></i> Dashboard</a></li>}
                        {/* {access_token && user_role == "MERCHANT" && <li className={currentpathval == '/' ? "active" : ""}><a href="#"><i className="fa fa-money" aria-hidden="true"></i> Wallets</a></li>} */}
                        {access_token && (user_role == "MERCHANT" || user_role == "BRANCH" ) && <li className={currentpathval == '/merchant/transaction' ? "active" : ""}><a href="/merchant/transaction"><i className="fa fa-money" aria-hidden="true"></i> Transactions</a></li>}
                        {access_token && (user_role == "MERCHANT" || user_role == "BRANCH" ) && <li className={currentpathval == '/merchant/exchange' ? "active" : ""}><a href="/merchant/exchange"><i className="fa fa-exchange" aria-hidden="true"></i> Rates</a></li>}


                        <li className={currentpathval == '/merchant/wallet' ? "active" : ""}><a href="/merchant/wallet"><i className="fa fa-usd" aria-hidden="true"></i> Inventory</a></li>
                        <li className={currentpathval == '/merchant/notification' ? "active" : ""}><a href="/merchant/notification"><i className="fa fa-bell-o" aria-hidden="true"></i> Notifications</a></li>
                        <li className={currentpathval == '/merchant/activities' ? "active" : ""}><a href="/merchant/activities"><i className="fa fa-bell-o" aria-hidden="true"></i> Activities</a></li>

                        {/* {access_token && user_role == "MERCHANT" && <li className={currentpathval == '/merchant/wallet' ? "active" : ""}><a href="/merchant/wallet"><i className="fa fa-money" aria-hidden="true"></i> Wallet</a></li>} */}
                        {user_role == "ADMIN" && <li className={currentpathval == '/account' ? "active" : ""}><a href="/account"><i className="fa fa-user-o" aria-hidden="true"></i> Accounts</a></li>}
                        {!access_token && <li ><a href="#"><i className="fa fa-bar-chart" aria-hidden="true"></i> Accounts</a></li>}
                        {access_token && user_role == "ADMIN" && <li className={currentpathval == '/wallet' ? "active" : ""}><a href="/wallet"><i className="fa fa-usd" aria-hidden="true"></i> Wallet</a></li>} 
                        {access_token && user_role == "ADMIN" && <li className={currentpathval == '/investment' || currentpathval == '/profit-loss' || currentpathval == '/completed-investment' ? "active" : ""}><a href="/investment"><i className="fa fa-bar-chart" aria-hidden="true"></i> Investment</a></li>}
                        {access_token && user_role == "ADMIN" && <li className={currentpathval == '/order' ? "active" : ""}><a href="/order"><i className="fa fa-user-o" aria-hidden="true"></i>Orders</a></li>}               
                    </ul>   
                </div>
                 <div className="profile_optn">
                <ul>
                  {access_token ?
                  
                  <li className="dropdown">
                     <a className="dropdown-toggle" data-toggle="dropdown" href="#" >{user_image && cover_pic ? <img className="header_logo" src={cover_pic == "null" ? user_image  : cover_pic}/> : "Logo  "}<p>Menu<span className="caret"></span></p></a>
                    <ul className="dropdown-menu">

                    {/* {(user_role == "ADMIN" || user_role == "BRANCH" ) && <li><a href='/profile' ><i className="fa fa-user" aria-hidden="true"></i>&nbsp;Profile</a></li>} */}


                  {/* {user_role == "ADMIN" && <li><a data-toggle="modal" data-target="#editprofilepic"><i className="fa fa-user" aria-hidden="true"></i>&nbsp;Profile</a></li>}                      */}
                   <li><a href="/change_password"><i className="fa fa-lock" aria-hidden="true"></i>&nbsp;Change Password</a></li>
                  {user_role == "ADMIN" && <li><a href='/currency-pairing' ><i className="fa fa-sign-out" aria-hidden="true"></i>&nbsp;Currency Pairing</a></li>}
                  {(user_role == "MERCHANT" || user_role == "BRANCH" ) && <li><a href='/profile' ><i className="fa fa-user" aria-hidden="true"></i>&nbsp;Profile</a></li>}
                  {/* {(user_role == "MERCHANT" || user_role == "BRANCH" ) && <li><a className="change_cursor_to_pointer" onClick={openTopUpModal}><i className="fa fa-address-card" aria-hidden="true"></i>&nbsp;Top Up</a></li>} */}

                  <li><a href='javascript:void(0)' onClick={logout} id="logout-btn"><i className="fa fa-sign-out" aria-hidden="true"></i>&nbsp;Logout</a></li>
                  {user_role == "ADMIN" && <li><a data-toggle="modal" data-target="#pass_verifiedqueue"><i className="fa fa-user" aria-hidden="true"></i>&nbsp;Delete Queue Orders</a></li>}
                  { access_token && user_role == "ADMIN" && <li className={currentpathval == '/merchant/topup' ? "active" : ""}><a href="/merchant/topup"><i className="fa fa-user-o" aria-hidden="true"></i> Merchants Top Up</a></li>}
                  { access_token && user_role == "ADMIN" && <li className={currentpathval == '/admin/view-currencies' ? "active" : ""}><a href="/admin/view-currencies"><i className="fa fa-usd" aria-hidden="true"></i>View Currencies</a></li>}
                  
                </ul>
                  
                  </li> 
                  :
                    <li className="dropdown">
                     <a href='javascript:void(0)' onClick={login} className="dropdown-toggle" data-toggle="dropdown"><img src={user_image} alt="logoeeee" /><p>Login</p>
                    </a>
                    </li>
                  }  
                </ul>  
                </div>
                </div>              
                                
              </div>
        </nav>
        </section>
              {/* <div><h1>{delete_msg == 'success'? "Deleted All The Orders" : ""}</h1></div> */}
          <div className="modal fade" id="editprofilepic" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content custom_modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
              <h3 className="modal-title">Edit Profile Picture</h3>
            </div>
            <div className="modal-body">
              <div className="user_request">
              <form className="main_form" onSubmit={(e)=>_handleSubmit(e, "home")} >
                <div className="profl_chng text-center">                
                  <div className="avatar-upload">
                  <div className="avatar-edit">
                    <input type="file" id="imageUpload" onChange={(e)=>_handleImageChange(e)}/>
                    <label htmlFor="imageUpload"></label>
								  </div>
                    <div className="avatar-preview">
                      <div id="imagePreview" style={ { backgroundImage:`url(${user_image})` } }>
                      </div>
                    </div>
                  </div>
                  <h6>
                    Upload Profile Picture
                  </h6>
                </div>
                <div className="bottom_btn">
                  <button onClick={(e)=>_handleSubmit(e, "home")} id="upload_image_btn">Upload!</button> 
                  <div id="wait"><img src={loader_image} width="50" height="50" /></div>
                </div>
              </form>
            </div>
          </div>
        </div>          
      </div>
    </div>

    <Modal
        ariaHideApp={false}
        isOpen={topUpModal}
        // onRequestClose={closeModal}
        style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
      >
      <div className="modal-header">
        <button
          type="button"
          className="close"
          onClick={() => setTopUpModal(false)}
        >
          <i className="fa fa-times" aria-hidden="true"></i>
        </button>
        <h3 className="modal-title">Add Top-Up Wallet</h3>
      </div>
      <div className="modal-body">
        <div className="user_request">
          <form onSubmit={handleSubmit(submittopup)}>
            {errormsg == "error" && <h4 className="error">Please Select Different Value</h4> }
            {errormsg == "already" && <h4 className="error">Already Exists</h4>}

            <div className="form-group">
              <label>Currency:</label>
              <select className="form-control" onChange={(e) => setCurrencyData(e)}>
              <option id="-1">Select Currency</option>
              {
                allcurrencies && allcurrencies.map((item: any, index: any) => { 
                  return (<option key={index} id={item.id}>{item.name}</option>)
                })
              }
              </select>
              {topUpForm.currencyError != "" && (<span style={{ color: "red" }}>{topUpForm.currencyError}</span> )}
            </div>

            <div className="form-group" >
              <label>Amount:</label>
              <input  placeholder="Enter Amount" 
              value={topUpForm.amount} 
              type="text" 
              onChange={(e: any) => validateTopUpFormCase('amount', e.target.value)}
              className="form-control"
              />
              {topUpForm.amountError.length > 0 && ( <span style={{ color: "red" }}>{topUpForm.amountError}</span>)}
            </div>


            <div className="form-group">
              <label>Pay Using:</label>
              <select className="form-control">
              <option >Cash</option>
              </select>
            </div>
        
            <div className="bottom_btn"> 
              <a className="change_cursor_to_pointer" onClick={openTopUpPasswordMoal}>Submit</a>
              {/* <a href="#" data-toggle="modal" data-target="#pass_verifiedtopup" id="add_rate_btns" >Submit</a> */}

            </div>
          </form>
    
        </div>          
      </div>
    </Modal>

    <Modal
        ariaHideApp={false}
        isOpen={topUpPasswwordModal}
        style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
      >
      <div className="modal-header">
        <button
          type="button"
          className="close"
          onClick={() => setTopUpPasswwordModal(false)}
        >
          <i className="fa fa-times" aria-hidden="true"></i>
        </button>
        <h3 className="modal-title">Verify Your Password</h3>
      </div>
      <div className="modal-body">
        <div className="user_request">
          <form onSubmit={handleSubmit(submittopup)}>
            <div className="form-group ">
              <label>Password:</label> 
              <div className="input_box">
                <input 
                  type="password" 
                  className="form-control form-control-two" 
                  onChange={(e: any) => validateTopUpFormCase('password', e.target.value)}
                  value={topUpForm.password}
                  placeholder="Enter Password"  />              
              </div>
              {topUpForm.passwordError != "" && (<span style={{ color: "red" }}>{topUpForm.passwordError}</span>)}
              {errormsg == "error" && <h4 className="error">Please Select Different Value for currencies</h4> }
              {errormsg == "already" && <h4 className="error">Already Exists</h4>}
              {errormsg == "error_field" && <h4 className="error">Please Fill all the Fields.</h4>}
              {errormsg == "pass_failed" && <h4 className="error">Incorrect Password</h4>}
            </div>
            <div className="bottom_btn">                      
              <button id="add_pass_btn" type="submit" disabled={submitting}>Submit</button>                   
            </div>
          </form>
        </div>
      </div>
    </Modal>
        <div className="modal fade" id="pass_verifiedqueue" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content custom_modal">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
                <h3 className="modal-title">Verify Your Password</h3>
              </div>
              <div className="modal-body">
                <div className="user_request">
                  <form onSubmit={handleSubmit(delete_all_order)}>
                      {errormsg == "error" && <h4 className="error">Please Select Different Value for currencies</h4> }
                      {errormsg == "already" && <h4 className="error">Already Exists</h4>}
                      {errormsg == "error_field" && <h4 className="error">Please Fill all the Fields for TopUp</h4>}
                      {errormsg == "pass_failed" && <h4 className="error">Password Not Matched</h4>}
                      <Field
                        name="password"
                        type="password"
                        component={renderField2}
                        label="Enter Password"
                      />
                      <div className="bottom_btn">                      
                        <button id="add_queue_btn" type="submit" disabled={submitting}>Submit</button>                   
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
  </div>
    )
}

  
export default reduxForm({
    form: 'imageForm' // a unique identifier for this form
  })(Header)

