import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from 'react-bootstrap' 
import { Field, reduxForm, SubmissionError } from 'redux-form'
import axios from 'axios';
import { USER_MANAGEMENT, FRONT_BASE, EXCHANGE_MANAGEMENT } from '../../config/config';
import { Layout } from '../common/Layout';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import loader_image from '../../images/loading.gif';
import user_image from '../../images/user_img_default.png';
import { userService , adminService } from '../../_services';
import Modal from 'react-modal';
import { extend } from 'jquery';
import Moment from 'react-moment';
import * as moment from 'moment-timezone';
import {Timeformatter} from '../common/Timeformatter'
import jstz from 'jstz';
import {DataHandling , HandlePermission} from '../common/DataHandling'
import { AnyAaaaRecord, AnyARecord, AnyCnameRecord } from 'dns';
import {Amountformatter} from '../../components/common/AmountFormatter';
interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
}
const renderField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (    
    <div className={type=="hidden" ? "" : "form-group"}>
      <label>{label}</label>
        <input {...input} placeholder={label} type={type} className={type=="hidden" ? "" : "form-control"} required/>
        {touched && error && <span>{error}</span>}          
    </div>      
  )


const ExpiredTransactions = (props: any) => { 
  const { error, handleSubmit, pristine, reset, submitting } = props
    const [orders, setOrders ] = useState([]) as any;
    useEffect(() => {
      getData(currentPage,searchText);
    }, []);


    const [ currentPage, setCurrentPage ] = useState(0);
    const [ usersPerPage, setUsersPerPage ] = useState(20);
    const [ totalPage, setTotalPage ] = useState(1);
  const [rateCount, setRateCount] = useState(0);
  const [isLoader , setIsLoader] = useState(false)
  const [searchText, setSearchText] = useState("");
  const [isLoading, setisLoading] = useState(0);
  
    const [ customerDetail , setCustomerDetail] = useState({ "show" : false , "email" : "", "phoneNumber" : ""});
    const [ claim , setClaim ] = useState({ "show" : false , "id" : "", "document" : "", "error": "", "name": "" });
 //   const [ formVar, setFormVar ] = useState({"firstName": "", "lastName": "", "companyName": "", "companyAddress": "", "link": "" , "fullPhoneNumber": "" , "countryCode" : "" , "phoneNumber" : "" , "annualIncome" : "", "jobIndustry" : "" ,"jobTitle" : "" , "address" : "" , "workingHoursStart" : "09:30 AM" , "workingHoursEnd" : "10:30 PM","otp" : "" ,  "firstNameError" : "" , "lastNameError" : "" , "companyNameError" : "", "companyAddressError" : "", "linkError" : "", "fullPhoneNumberError" : ""  , "annualIncomeError" : "", "jobIndustryError":"","jobTitleError" : ""  , "addressError" : ""});

    const total = Math.ceil(totalPage/usersPerPage);

  const submit = (values: any) => {
    // setLoader(1)
    if (claim.document.length == 0) {
      setClaim({ ...claim, "error": "Please select a Document" });
    } else {
      const formData = new FormData();
      formData.append('file', claim.document)
      adminService.claim_money(claim.id, formData).then(function (response) {
        alert(response.data.message);
        setClaim({ ...claim, "show": false })
        getData(currentPage, searchText);
      }).catch(function (error) {
        console.log("error", error);
        // setLoaderstyle({display:"none"})
        userService.handleError(error);
      })
    }
  }

function closeModal(){
  setCustomerDetail({...customerDetail, "show": false   })
  setClaim({...claim, "show":false})
}


    function extendOrder(id:any){
      console.log("id---",id)
      adminService.extend_order(id,{}).then(function (response) {
        //console.log("---response",response.data);
        getData(currentPage,searchText);
      }).catch(function(error){
          // console.log("error",error);
          // setLoaderstyle({display:"none"})
          userService.handleError(error);
      })
    }

    function _handledocUpload(e:any) {
      e.preventDefault();
    
      let reader = new FileReader();
      let file = e.target.files[0];
      // setimage(file)
      setClaim({...claim ,  "document" : file, "name": e.target.files[0].name, "error": ""})
      reader.onloadend = () => {
      }
      reader.readAsDataURL(file)
    }

    function claimOrder(){
      if(claim.document){
        console.log(claim);

      }else{
        alert("Please attach document first")
      }

    }
  function searchExpiredTransaction(e: any) {
    setSearchText(e.target.value);
    getData(currentPage,e.target.value)
    }
    const getData = (pageNumber:any,txt:any) => {
      // expired_transactions
      console.log("pagenumber,tx",pageNumber,txt)
      setIsLoader(true)
      if (pageNumber < 0) {
        pageNumber = total - 1
      }
      if (pageNumber == total) {
        pageNumber = 0
      }
      adminService.expired_transactions(pageNumber,txt).then(function (response) {
        setIsLoader(false)
       // console.log("expired_transactions-----",response.data);
        // setBranchDetail(response.data.data)
        if (response.data.orderList.length > 0){
            setisLoading(1)
        }else{
            setisLoading(2)
        }
        setTotalPage(response.data.count)
        setOrders(response.data.orderList);
      }).catch(function(error){
          console.log("error",error.response);
          // alert(error)
          // setLoaderstyle({display:"none"})
           setisLoading(3)
          userService.handleError(error);
      })

    }

    const customStyles1 = {
      overlay : {
       display: "block" ,
       paddingRight: "15px",
       background: "#00000054"
      }
    }; 

    const makeHttpRequestWithPage = async (pageNumber:any) => {
      setOrders([]);
      if (pageNumber < 0){
        pageNumber = total-1
      }
      if (pageNumber == total){
        pageNumber = 0
      }
      getData(pageNumber,searchText)
      setCurrentPage(pageNumber)
    }
    const pagination = () =>{
      let table = []
      table.push(<li><a  href="javascript:void(0)" className={currentPage == 0 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage-1)}>Previous</a></li>)
      for (let i = 0; i < total; i++) {
        console.log("i------------------->"+currentPage)
        // table.push(<span onClick={() => makeHttpRequestWithPage(i)} className="active">{i}</span>)
        table.push(<li className={ i == currentPage ? "active  block_cursor_event" :""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}  >{i+1}</a></li>)
      }
      table.push(<li><a href="javascript:void(0)" className={currentPage == total-1 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage+1)}>Next</a></li>)
      return table
    }

  const UserTable = () => {     
      return (
              <table className="table">
                <thead>
                  <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>From Amount</th>               
                  <th>To Amount</th>
                  <th>Request Date</th>
                  <th>Expiry Date</th>
                  <th>Money Changer Name</th>
                  { HandlePermission("edit","expired_transactions") &&
                    <th>Action</th>
                  }
                  </tr>
                </thead>
                <tbody>
               {orders.length > 0 ? orders.map((item: any, index: any) => {
                console.log("ratecountval",item)
               
                 return(
                <tr key={index}>
                  <td>{(currentPage*20)+ (index+1)}</td>
                  <td>{item.userObj ? item.userObj.firstName : ""} {item.userObj ? item.userObj.lastName : ""}</td>
                  <td><Amountformatter amount={item.value}/> {item.currencyFrom}</td>
                  <td><Amountformatter amount={item.totalAmount}/> {item.currencyTo}</td>
                  <td><Timeformatter  timeget={item.createdOn}  /></td>
                  <td> <Timeformatter  timeget={item.expiresOn}  /></td>
                  <td>{item.merchantObj ? item.merchantObj.firstName : ""}</td>
                  { HandlePermission("edit","expired_transactions") &&
                 <td>

                 <div className="dropdown slect_profile">
                      <button className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">Select
                      <span className="caret"></span></button>
                      <ul className="dropdown-menu">
                      <li><a onClick={() => setCustomerDetail({...customerDetail, "show": true , "email" : item.userObj ? item.userObj.email : "" , "phoneNumber" : item.userObj ?  `${item.userObj.countryCode} ${item.userObj.phoneNumber}`  : ""  }) } >Contact Customer</a></li>
                      <li><a onClick={() => extendOrder(item.idMoneyChangerOrders) } >Extend</a></li>
                      <li><a onClick={() => setClaim({...claim ,  "show" : true , "id" : item.idMoneyChangerOrders, "name": "", "error": ""  }) }> Claimed Money </a></li>
                      
                      </ul>
                    </div>
                  </td>
                }
                </tr> )
                }): 
                  <div id="" className="loaderbox" style={{marginTop: '80px'}}><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div> 
                }
                </tbody>
           </table>      
        )

    }

    

    function viewRate(user_id:any){
      console.log("view rate")
      window.sessionStorage.setItem('merchant_id', user_id)
      window.location.href = `${FRONT_BASE}/merchant/rate`; 
    }
                    

    return (      
      <Layout>  
        <section className="inner_sction">
          <section className="table_sction accunt_table">
            <div className="container-fluid">
              <div className="table_sction_outer admin_index">

              <div className="top-sctin mbtm-0">
                  <h4>Expired Transactions</h4>
                    
                    <div className="form-group pull-right">
                          <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                          <input type="text" className="form-control" value={searchText} onChange={(e)=>searchExpiredTransaction(e)} placeholder="Search expired transaction by name"/>
                      </div>
                    <div className="clearfix"></div>
              </div>

                {getData}
                <div className="table_inner">
                  <div className="table-responsive">
                  {isLoading == 0 && <div id="api_loader"><img src={loader_image} width="50" height="50" />Loading...</div> }
                  {isLoading == 1 &&  UserTable() }
                  {isLoading == 2 &&   <h1>Record Not Found</h1> }
                  {isLoading == 3 &&   <h1>Some Error Occur</h1> }
                  </div>
                  <div className="pagination_sction">
                    <ul className="pagination">
                      {total > 0 ? pagination() : ""}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          { isLoader &&
                <div id="" className="loaderbox loginloader innerloader"><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div>
            }
        </section>

        <Modal
          ariaHideApp={false}
          isOpen={claim.show}
          onRequestClose={closeModal}
          // style={customStyles1}
          className={"modal-content custom_modal thakyou_pop"}
          contentLabel="Example Modal"
          overlayClassName="Overlay"
        >
          <div className="modal-header">
            <button
              type="button"
              className="close"
              onClick={() => setClaim({ ...claim, "show": false })}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
            <h3 className="modal-title">Add Claim Document</h3>
          </div>
          <div className="modal-body">
            <div className="user_request padding">
              {/* <form onSubmit={handleSubmit(submit)} >
                <div className="browse_box">
                  <label htmlFor="et_pb_contact_brand_file_request_0" className="et_pb_contact_form_label" style={{marginBottom: '0px'}}>Enter</label>
                  <input type="file" id="et_pb_contact_brand_file_request_0" className="file-upload" onChange={(e) => _handledocUpload(e)} />
                </div>
                <h5 className="claim_document_name">{documentName}</h5>
                <div className="bottom_btn">
                  <button type="submit">Upload!</button>
                </div>
              </form> */}

              <div className="input_form_box form_input_box input-upload">
                  <form autoComplete={"off"} onSubmit={handleSubmit((val: any) => submit(val))}>
                    <div className="input_content upload_content">
                      <input contentEditable={false} type="text" className="form-control" placeholder="Upload Claim Document" value={claim.name}/>
                      <div className="upload_file-btn-box">
                          <div className="upload-btn-wrapper">
                              <button className="btn" style={{fontSize: '19px'}}>Select</button>
                              <input type="file" name="myfile" onChange={(e)=> _handledocUpload(e)} />
                          </div>
                      </div>
                    </div>
                    {claim.error.length > 0 && ( <span style={{ color: "red" }}>{claim.error}</span>)}
                    <br/><br/>
                    <div className="bottom_btn">
                      <button type="submit">Upload!</button>
                    </div>
                  </form>
                </div>

            </div>
          </div>
        </Modal>

        <Modal
          ariaHideApp={false}
          isOpen={customerDetail.show}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          // style={customStyles1}
          className={"modal-content custom_modal thakyou_pop"}
          contentLabel="Example Modal"
          overlayClassName="Overlay"
        >
          <div className="modal-header">
            <button
              type="button"
              className="close"
              onClick={() => setCustomerDetail({ ...customerDetail, "show": false })}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
            <h3 className="modal-title">Customer</h3>
          </div>
          <div className="modal-body">
            <div className="user_request padding">
              <form>
                <div className="input_area">
                  <label>Email Address:</label>
                  <p>{customerDetail.email}</p>
                </div>

                <div className="input_area">
                  <label>Phone Number:</label>
                  <p>{customerDetail.phoneNumber}</p>
                </div>

                <div className="clearfix"></div>

                <div className="bottom_btn">
                  <button onClick={() => setCustomerDetail({ ...customerDetail, "show": false })} type="submit">OK</button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </Layout>
      
    )
}

export default reduxForm({
    form: 'searchForm', // a unique identifier for this form
    enableReinitialize : true
  })(ExpiredTransactions)