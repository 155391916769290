import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import store from '../../store';
import Count from '../Count';
import Merchant from '../Merchant';
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Layout } from '../common/Layout'
import $ from 'jquery';
import { Bar } from 'react-chartjs-2';
import { USER_MANAGEMENT, EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT } from '../../config/config';
import jstz from 'jstz';
import axios from 'axios';
import loader_image from '../../images/loading.gif';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import firebase from './../firebase/Firebase';
import { merchantService, userService } from '../../_services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import ReactTimeAgo from 'javascript-time-ago'
import TimeAgo from 'react-timeago'
import Modal from "react-modal";
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import QrReader from 'react-qr-reader'
import { Timeformatter } from '../../components/common/Timeformatter'
import CustomereKYCDetails from './CustomereKYCDetail'
import checked_gif from "../../images/checked.gif";
import { Amountformatter } from '../../components/common/AmountFormatter';
import { CollectionDateFormater } from '../common/CollectionDateFormater'
const hanldleClick = (type: string) => {
  store.dispatch({ type });
  console.log(store.getState().counter)
}

const Merchanthome = () => {
  //const countt = store.getState().counter 
  let popupstatus = localStorage.getItem('popupisopen');
  const [camerastatus, setCamerastatus] = useState('false');
  const [timezonename, setTimezonename] = useState('Asia/Calcutta');
  const [depositsCount, setDepositsCount] = useState();
  const [depositsselect, setDepositsselect] = useState("monthly");
  const [withdrawsCount, setWithdrawsCount] = useState();
  const [withdrawsslect, setWithdrawsslect] = useState("monthly");
  const [mapDeposits, setmapDeposits] = useState();
  const [mapWithdraws, setmapWithdraws] = useState();
  const [depositsdata, setdepositsdata] = useState() as any;
  const [withdrawdata, setwithdrawdata] = useState() as any;
  const [isLoading, setisLoading] = useState(0);

  const [istoggle, setistoggle] = useState(true);
  const [exchangeList, setExchangeList] = useState([]);
  const [merchantOnline, setMerchantOnline] = useState() as any;
  const [exchangeLoaded, setExchangeLoaded] = useState(false);
  const [selectedIndexSlider, setSelectedIndexSlider] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [toggelQR, setToggelOR] = useState(true);
  const [eKYCModal, setEKYCModal] = useState(false);
  const [transactionReceiptModal, setTransactionReceiptModal] = useState(false);
  const [transactionReceiptdata, setTransactionReceiptData] = useState() as any;
  const [iskycverified, setiskycverified] = useState(true);
  const [sentOtpResponseModal, setSentOtpResponseModal] = useState(false);
  const [orderCompleteModal, setOrderCompleteModal] = useState(false);
  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  const [customerEmail, setCustomerEmail] = useState('');
  const [isFormLoading, setIsFormLoading] = useState(false);
  // selectedIndexSlider
  let exchangeLoaded_var = false;

  const [qrVar, setQrVar] = useState({
    delay: 300,
    result: 'No result',
    words: [],
    advanced_words: [],
    scanstatus: false,
    status: 'false',
    scanpopup: false,
    isQRCode: true,
    manualCodeValue: '',
    manualCodeError: '',
  });



  // const notify = () => toast.success("Wow so easy !");
  function notify(msg: any, notify_type: any) {
    toast.success(msg);
  }
  var aa = 0;
  useEffect(() => {
    localStorage.setItem('customer_id', '');
    get_firebase_exchanges();
    getExchanges();
    console.log("merchant_online-------", localStorage.getItem("merchant_online"))
    if (localStorage.getItem("merchant_online") == "true") {
      setMerchantOnline(true)
    } else {
      setMerchantOnline(false)
    }

    $('#btnTest').on('click', function () {
      setCamerastatus('true')
      aa = 1;
    })
    $(".close").on("click", function () {
      setCamerastatus('false')
      aa = 2;
    });

    const timezone = jstz.determine();
    const tz = timezone.name()
    setTimezonename(tz)
    getData("montly", "");
  }, []);

  function get_firebase_exchanges() {
    const messages = firebase.database().ref("exchanges");

    messages.on("child_added", function (data, prevChildKey) {

      var newPlayer = data.val();

      console.log("newPlayer", newPlayer);
      let merchant_id = localStorage.getItem("user_id")
      console.log("merchant_id", merchant_id);
      if (merchant_id == newPlayer.merchantId) {
        console.log("For this merchant");
        console.log(exchangeLoaded_var);
        if (exchangeLoaded_var) {
          console.log("exchangeLoaded----");
          let msg = "New Exchange request from " + newPlayer.customerName
          notify(msg, "success");
        }
        console.log("after notify");
        getExchanges();
      } else {
        //     console.log("message----------------------------------------------------",messagesAry);
        //     message_ary.push(newPlayer)
        // setmessagesAry([
        //     ...message_ary
        //   ]);
      }
    });
  }


  const getData = (value: string, type: string) => {
    const user_id = localStorage.getItem('user_id');
    const access_token = localStorage.getItem('access_token');
    console.log("depositsselect------------->" + depositsselect)
    if (access_token) {
      console.log("submit start")
      let config1 = {
        headers: {
          'Content-Type': 'application/json',
          'deviceType': 'Android',
          'appVersion': '1.0',
          'Authorization': 'Bearer ' + access_token
        }
      }
      axios.post(`${TRANSACTION_MANAGEMENT}/merchant/getDashboardData`,
        {
          "deposits": type == "deposit" ? value : depositsselect,
          "userId": user_id,
          "withdraws": type == "withdraw" ? value : withdrawsslect,
          "timeZone": timezonename
        },
        config1).then(function (response) {
          var data1 = response.data.data
          setDepositsCount(data1.depositsCount)
          setWithdrawsCount(data1.withdrawsCount)
          setmapDeposits(data1.listDepositAmounts)
          setmapWithdraws(data1.setmapWithdraws)
          setdepositsdata(data1.listDepositTransactions)
          setwithdrawdata(data1.listWithdrawTransactions)
          setisLoading(1)
          console.log(data1)
        }).catch(function (error) {
          console.log(error);
        })
    }
  }

  function slice_ary(ary: any) {
    return ary.splice(0, 1);
  }

  function accept_reject(ty: any, item: any, index: any) {
    // notify("New Exchange","success");
    // console.log("exchang",exchangeLoaded);
    // return;

    setIsFormLoading(true);
    let merchant_id = localStorage.getItem("user_id")
    let params = {
      "merchantId": merchant_id,
      "orderId": item.idMoneyChangerOrders,
      "status": ty
    }
    console.log(item.idMoneyChangerOrders)


    merchantService.accept_reject_exchange(params)
      .then(function (response) {
        // setLoaderstyle({display:"none"})
        console.log("-----------------------------------------response accept_reject", response)
        setIsFormLoading(false);
        if (ty == "ACCEPTED") {
          console.log("changing in firebase");
          const exchange_list_firebase: any = firebase.database().ref("exchanges/" + item.idMoneyChangerOrders);
          console.log(exchange_list_firebase);
          console.log("response.data.expiresOn", response.data.expiresOn);
          exchange_list_firebase.ref.update({
            accepted: (ty == "ACCEPTED" ? true : false),
            expiresOn: response.data.expiresOn
          });
          console.log("firebase hit ends");
        }
        console.log("exchangeList", exchangeList);
        let exchangeList_ary = [...exchangeList]
        console.log("index", index);
        console.log("exchangeList_ary.length - 1", (exchangeList_ary.length - 1));
        notify(response.data.message, "success");
        if (index === (exchangeList_ary.length - 1)) {
          console.log("---- getExchanges")
          setExchangeList([])
          getExchanges()
        } else {
          console.log("slising index----", index);
          exchangeList_ary.splice(index, 1);
          setExchangeList(exchangeList_ary);
          let tmp_current_slide: any = (index - 1)
          if (tmp_current_slide < 0) {
            tmp_current_slide = 0
          }
          setCurrentSlide(tmp_current_slide);
        }
      }).catch(function (error) {
        setIsFormLoading(false);
        console.log("-----------error--------", error);
        // setLoaderstyle({display:"none"})
        userService.handleError(error);
      });


  }


  function sliderOnChanged(e: any) {
    console.log("sliderOnChangedddddd")
    console.log(e);
    console.log(e.page.index);
    if (e.page.index > -1) {
      setCurrentSlide(e.page.index);
    }

  }

  function getExchanges() {

    // setLoaderstyle({display:"block"})
    console.log("getExchanges-----");
    let merchant_id = localStorage.getItem("user_id")
    let params = {
      "merchantId": merchant_id
    }
    merchantService.get_exchange(params)
      .then(function (response) {
        // setLoaderstyle({display:"none"})
        // setCurrentSlide(0)


        console.log("-----------------------------------------response", response)
        setExchangeList(response.data.orderList)

        setExchangeLoaded(true);
        setTimeout(function () {
          exchangeLoaded_var = true;
        }, 5000);
        console.log("response.data.orderList.length", response.data.orderList.length);
        console.log("currentSlide", currentSlide);

        if (currentSlide > (response.data.orderList.length - 1)) {
          console.log("setting to 0 current slide-----")
          setCurrentSlide(0)
        }
        if (currentSlide < 0) {
          setCurrentSlide(-1)
          setCurrentSlide(0)
        }

        if (currentSlide == 0) {
          console.log("just for refresh");

          setCurrentSlide(-1)
          setCurrentSlide(0)
        }

        console.log("change exchamgeLoaded");

      }).catch(function (error) {
        console.log("-----------error--------", error);
        // setLoaderstyle({display:"none"})
        userService.handleError(error);
      });
  }

  var depositlabel: any

  var withdrawlabelabel: any = ['January', 'February', 'March']
  var depositdatamap: any
  if (depositsselect == "daily") {
    depositlabel = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    depositdatamap = mapDeposits
  } else if (depositsselect == "weekly") {
    depositlabel = ['week1', 'week2', 'week3', 'week4']

  }
  else {
    depositlabel = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    depositdatamap = mapDeposits
  }


  var witdrawlabel: any
  var depositdatamap: any
  if (withdrawsslect == "daily") {
    witdrawlabel = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    depositdatamap = mapDeposits
  } else if (withdrawsslect == "weekly") {
    witdrawlabel = ['week1', 'week2', 'week3', 'week4']

  }
  else {
    witdrawlabel = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    depositdatamap = mapDeposits
  }

  const data = {
    labels: depositlabel,
    datasets: [
      {
        label: 'Data',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: mapDeposits
      }
    ]
  };

  const chartOptions = {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  }

  const data2 = {
    labels: witdrawlabel,
    datasets: [
      {
        label: 'Data',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: withdrawdata
      }
    ]
  };

  const setPrimaryCurrency = (event: any) => {
    var dataval: string = event.target.value
    setWithdrawsslect(dataval)
    getData(dataval, "withdraw")
  }

  const setDepositselect = (event: any) => {
    var dataval: string = event.target.value
    setDepositsselect(dataval)
    getData(dataval, "deposit")
  }

  function handleTofuChange() {
    // setistoggle(!istoggle)
    console.log("aaaaaaaaa");
    setMerchantOnline(!merchantOnline)
    console.log("bbbbbbbbb")
    let merchant_id = localStorage.getItem("user_id")
    let params = {
      "merchantId": merchant_id,
      "merchantService": "MONEYEXCHANGEORDERS",
      "merchantServicestatus": (!merchantOnline)
    }
    console.log(params);
    merchantService.merchant_online_offline(params)
      .then(function (response) {
        // setLoaderstyle({display:"none"})
        console.log("-----------------------------------------response merchant_online_offline", response)
        let merchant_online = !merchantOnline
        localStorage.setItem("merchant_online", String(merchant_online))
        //  alert(response.data.message);
        //  getExchanges()
        //  setExchangeList(response.data.orderList)

      }).catch(function (error) {
        console.log("-----------error--------", error);
        // setLoaderstyle({display:"none"})
        // userService.handleError(error);
      });
  }

  function toggleCustomerEKYCDetailModal() {
    setTransactionReceiptModal(false);
    setEKYCModal(!eKYCModal)
  }

  const UserTable = () => {
    console.log(depositsdata)
    return (
      <div className="table_inner">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>TRANSACTION TYPE</th>
                <th>TRANSACTION STATUS</th>
                <th>TOTAL AMOUNT</th>
                <th>USER AMOUNT</th>
                <th>CURRENCY</th>
              </tr>
            </thead>
            <tbody>
              {depositsdata && depositsdata.map((item: any, index: any) => {
                console.log(item)
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.transactionType}</td>
                    <td> {item.transactionStatus}</td>
                    <td> {item.totalAmount}</td>
                    <td>{item.userAmount}</td>
                    <td><img src={item.currency.imagePath} width="30px" height="30px" />{item.currency ? item.currency.name : ""}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <p className="view_all text-center"><a href="/merchant/deposits">VIEW ALL DEPOSITS TRANSACTIONS</a></p>
      </div>
    )
  }

  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      background: "#00000054",
    },

    content: {
      position: "fixed",
      top: "24px",
      right: "588px",
      bottom: "",
      left: "550px",
      maxWidth: "850px"
    }
  };
  const customStyles2 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      background: "#00000054"
    },

    content: {
      position: "fixed",
      top: "24px",
      right: "588px",
      bottom: "",
      left: "617px"
    }
  };

  function toggleTransactionModal(data: any = '') {
    setCamerastatus('false');
    console.log("gggggggggggggggggggggg", data)
    let id: any = data == '' ? '' : data.user.id
    setCustomerEmail(id)
    setTransactionReceiptData(data);
    sleep(200).then(() => {
      setTransactionReceiptModal(!transactionReceiptModal)
    })

    // setCamerastatus('false');
  }

  function sentOtpToCustomer() {

    let qr_code: any = localStorage.getItem('qr_code');
    merchantService.merchant_exchange_scan(qr_code, '2')
      .then(function (response) {
        // localStorage.setItem('qr_code', qr_code);
        console.log("response---", response.data.orderId);
        const exchange_list_firebase: any = firebase.database().ref("exchanges/" + response.data.orderId);
        console.log(exchange_list_firebase);
        exchange_list_firebase.ref.update({
          scanned: true
        });
        setSentOtpResponseModal(true);
        sleep(3500).then(() => { setSentOtpResponseModal(false); });
        //toast.success("successfully sent");
        exchange_list_firebase.on("child_changed", function (snapshot: any) {
          var changedPost = snapshot.val();
          console.log("The updated post title is " + changedPost);
          if (changedPost == 'COMPLETED') {
            setEKYCModal(false);
            setOrderCompleteModal(true);

            sleep(6000).then(() => { setOrderCompleteModal(false); });
          }
        });
      }).catch(function (error) {
        console.log("-----------error--------", error);

        userService.handleError(error);
      });
  }

  const WithdrawTable = () => {
    console.log(depositsdata)
    return (
      <div className="table_inner">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>TRANSACTION TYPE</th>
                <th>TRANSACTION STATUS</th>
                <th>TOTAL AMOUNT</th>
                <th>USER AMOUNT</th>
                <th>CURRENCY</th>
              </tr>
            </thead>
            <tbody>
              {withdrawdata && withdrawdata.map((item: any, index: any) => {
                console.log(item)
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.transactionType}</td>
                    <td> {item.transactionStatus}</td>
                    <td> {item.totalAmount}</td>
                    <td>{item.userAmount}</td>
                    <td><img src={item.currency.imagePath} width="30px" height="30px" />{item.currency ? item.currency.name : ""}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <p className="view_all text-center"><a href="/merchant/withdraws">VIEW ALL WITHDRAWS TRANSACTIONS</a></p>
      </div>
    )
  }

  return (
    <Layout>
      {(merchantOnline == true || merchantOnline == false) &&

        <section className="accepting_order">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p>Are you accepting orders today?</p>
                <div className="accepting_toggle">

                  <label>
                    <span>Not Accepting</span>
                    <Toggle
                      defaultChecked={merchantOnline}
                      icons={false}
                      onChange={() => handleTofuChange()} />
                    <span>Accepting</span>
                  </label>

                </div>
              </div>
            </div>
          </div>

        </section>
      }

      <section className="inner_sction">
        <div className="table_sction admin_sction_area">
          <div className="container">
            <div className="dashboard_merchnt">
              <div className="exchange_request_box">
                {exchangeList && exchangeList.length > 0 &&
                  <div id="myCarousel" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators" style={{ zIndex: 9 }}>
                      {exchangeList.map((item, index) => {
                        return (
                          <li data-target="#myCarousel" data-slide-to={index} className={index == selectedIndexSlider ? "active" : ""}></li>
                        )
                      })}
                    </ol>
                    <div className="carousel-inner">
                      {exchangeList.map((item: any, index) => {
                        return (
                          <div className={index == selectedIndexSlider ? "item active" : "item"}>
                            <div className="exchange_request">
                              <p className='collection_date'>Collection Date :- {item.exchangeOn && <CollectionDateFormater timeget={item.exchangeOn} />}</p>
                              <p>{item.userObj ? item.userObj.firstName : ""} sent you a exchange request for {item.currencyFrom} <Amountformatter amount={item.value} /> into {item.currencyTo}.</p>
                              <div className="flag_box">
                                <div className="currency">
                                  <p><span><img className="exchange_flag_image" src={item.exchangeFromFlag} /></span>{item.currencyFrom}</p>
                                  <p className="amount"><Amountformatter amount={item.value} /> {item.currencyFrom}</p>
                                </div>

                                <div className="currency arrow">
                                  <img src={require("../../images/arrow_right.png")} />
                                </div>

                                <div className="currency">
                                  <p><span><img className="exchange_flag_image" src={item.exchangeToFlag} /></span>{item.currencyTo}</p>
                                  <p className="amount"><Amountformatter amount={item.totalAmount} /> {item.currencyTo}</p>
                                </div>
                              </div>

                              <p>Exchange Rate 1.00 {item.currencyFrom} = <Amountformatter amount={item.executionRate} /> {item.currencyTo}</p>
                              {item?.userObj?.flags && <div className='colors-area'>
                                <h6>Suspicious Flag :</h6>
                                <p className={item.userObj.flags == 1 ? 'yellow': item.userObj.flags == 2 ? "orange":"red"}>{item.userObj.flags}</p>
                              </div>}
                              <div className="right_btn">
                                <a onClick={() => accept_reject("ACCEPTED", item, index)} className="cursor_pointer" >Accept</a>
                                <a onClick={() => accept_reject("REJECTED", item, index)} className="cursor_pointer reject">Reject</a>
                              </div>
                              {/* <p className="req_time"> */}
                              <p style={{ color: '#929090', float: 'right' }}>
                                {/* Just Now */}
                                {/* <ReactTimeAgo date={item.createdOn} locale="en-US"/> */}
                                <TimeAgo date={item.createdOn} />
                              </p>


                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                }



                {/* <OwlCarousel
    className="owl-theme"
    loop
    margin={10}
    nav={false}
    items={1}
    startPosition={currentSlide}
    onChanged={sliderOnChanged}
    autoplay={true}
    smartSpeed={700}
    // info={sliderOnChanged}
>
{  exchangeList.map((item:any,index)=> {
                          return(
                            <div className={index == selectedIndexSlider ? "item active" : "item"}>
                              <div className="exchange_request">
                              <p className="req_time">
                                  <TimeAgo date={item.createdOn} live={false} />
                                  </p>


                                <p>{item.userObj ? item.userObj.firstName : ""} sent you a exchange request for {item.currencyFrom} {item.value} into {item.currencyTo}.</p>
                                <div className="flag_box">
                                  <div className="currency">
                          <p><span><img className="exchange_flag_image" src={item.exchangeFromFlag} /></span>{item.currencyFrom}</p>
                          <p className="amount">{item.value} {item.currencyFrom}</p>
                                  </div>

                                  <div className="currency arrow">
                                      <img src={require("../../images/arrow_right.png")} />
                                  </div>

                                  <div className="currency">
                          <p><span><img className="exchange_flag_image" src={item.exchangeToFlag} /></span>{item.currencyTo}</p>
                                      <p className="amount">{item.totalAmount} {item.currencyTo}</p>
                                  </div>
                                </div>

                                <p>Exchange Rate 1 {item.currencyFrom} = {item.executionRate} {item.currencyTo} </p>

                                <div className="right_btn">
                                    <a onClick={() => accept_reject("ACCEPTED",item,index)} className="cursor_pointer" >Accept</a>
                                    <a onClick={() => accept_reject("REJECTED",item,index)} className="cursor_pointer reject">Reject</a>
                                </div>

                                

                              </div>
                            </div>
                          )
                        })}
</OwlCarousel>  */}

                {exchangeList && exchangeList.length == 0 &&
                  <div className="text-center">
                    <img src={require("../../images/no_exchange.png")} />
                    <p>No exchange request received.</p>
                  </div>
                }

              </div>
            </div>

          </div>
        </div>
      </section>
      <div className="bar_code">
        <a data-toggle="modal" data-target="#scanbarcode" id="btnTest"><img src="../images/barcode.png" /></a>
      </div>

      <div className="modal fade" id="scanbarcode" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content custom_modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
              <h3 className="modal-title">
                Scan Barcode
              </h3>
            </div>
            {camerastatus == "true" && <Merchant onSubmitMessage={(data: any) => toggleTransactionModal(data)} />}
            {/* <div className="modal-body">
            <div className=" text-center">
              <div className="scan_box">                     
                  {camerastatus == "true" && qrScan()}
                <div className="scanline"></div>                  
              </div>
            </div>
            </div> */}
          </div>
        </div>
      </div>

      <ToastContainer />

      <Modal
        ariaHideApp={false}
        isOpen={eKYCModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop edit_profile"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => toggleCustomerEKYCDetailModal()}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Customer Details</h3>
        </div>
        <div className="modal-body">
          <div className="user_request">
            {/* for testing email is static.... */}
            <CustomereKYCDetails email={customerEmail} iskycverified={iskycverified} />
            {iskycverified && <div className="text-center" style={{ marginTop: '20px' }}>
              <div className="row">
                <div className="col-xs-6" style={{ float: 'left' }}>
                  <button className="qr_code_response_button" style={{ backgroundColor: "#f16a6e" }} onClick={() => toggleCustomerEKYCDetailModal()}>Not Now</button>
                </div>
                <div className="col-xs-6" style={{ float: 'right' }}>
                  <button className="qr_code_response_button" onClick={() => sentOtpToCustomer()}>Send OTP</button>
                </div>
              </div>
            </div>
            }
            <br />
            <br />
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={transactionReceiptModal}
        // style={customStyles2}
        className={"modal-content custom_modal thakyou_pop edit_profile"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setTransactionReceiptModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Transaction Receipt</h3>
        </div>
        {transactionReceiptdata &&
          <div className="modal-body">
            <div className="user_request text-center">
              <p>{(transactionReceiptdata as any).user ? (transactionReceiptdata as any).user.firstName : ""} sent you a exchange request for {(transactionReceiptdata as any).order.currencyFrom} <Amountformatter amount={(transactionReceiptdata as any).order.value} /> into {(transactionReceiptdata as any).order.currencyTo} </p>
              <p>On   <Timeformatter timeget={(transactionReceiptdata as any).order.createdOn} /></p>
              <br />
              <div className="flag_box">
                <div className="currency">
                  <p><span><img className="exchange_flag_image" src={(transactionReceiptdata as any).order.exchangeFromFlag} /></span>{(transactionReceiptdata as any).order.currencyFrom}</p>
                  <p className="amount"><Amountformatter amount={(transactionReceiptdata as any).order.value} /> {(transactionReceiptdata as any).order.currencyFrom}</p>
                </div>

                <div className="currency arrow">
                  <img src={require("../../images/arrow_right.png")} />
                </div>

                <div className="currency">
                  <p><span><img className="exchange_flag_image" src={(transactionReceiptdata as any).order.exchangeToFlag} /></span>{(transactionReceiptdata as any).order.currencyTo}</p>
                  <p className="amount"><Amountformatter amount={(transactionReceiptdata as any).order.totalAmount} /> {(transactionReceiptdata as any).order.currencyTo}</p>
                </div>
                <p>Exchange Rate 1.00{(transactionReceiptdata as any).order.currencyFrom} = <Amountformatter amount={(transactionReceiptdata as any).order.executionRate} /> {(transactionReceiptdata as any).order.currencyTo}</p>
                <p style={{ padding: '6px 16px', borderRadius: '5px', backgroundColor: '#ffd555' }}>Collection Date :-  {(transactionReceiptdata as any).order.exchangeOn && <CollectionDateFormater timeget={(transactionReceiptdata as any).order.exchangeOn} />}</p>
              </div>
              <br />
              <div className="text-center">
                <div className="row">
                  <div className="col-xs-6" style={{ float: 'right' }}>
                    {/* <button className="qr_code_response_button" onClick={() => toggleCustomerEKYCDetailModal()}>View eKYC</button> */}
                    <button className="qr_code_response_button" onClick={() => toggleCustomerEKYCDetailModal()}>View Details</button>
                  </div>
                  <div className="col-xs-6" style={{ float: 'left' }}>
                    <button className="qr_code_response_button" style={{ backgroundColor: "#f16a6e" }} onClick={() => toggleTransactionModal()}>Not Now</button>
                  </div>

                </div>
              </div>
            </div>

          </div>
        }
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={sentOtpResponseModal}
        // style={customStyles2}
        className={"modal-content custom_modal thakyou_pop edit_profile"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-body">
          <div className="user_request">
            <div className="text-center">
              <div className="row">
                <div className="text-center">
                  <img src={checked_gif} style={{ width: '80px', borderRadius: '40px' }}></img>
                  <h3>OTP Sent to Customer Successfully...</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={orderCompleteModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop edit_profile"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button type="button" className="close" onClick={() => setOrderCompleteModal(false)}><i className="fa fa-times" aria-hidden="true"></i></button>
        </div>
        <div className="modal-body">
          <div className="user_request">
            <div className="text-center">
              <div className="row">
                <div className="text-center">
                  <h1>Congratulation... <img src={checked_gif} style={{ width: '80px', borderRadius: '40px' }}></img></h1>
                  <h3>Order has been Successfully Completed.</h3>
                  <br /><br />
                  <button className="qr_code_response_button" style={{ backgroundColor: "#f16a6e" }} onClick={() => setOrderCompleteModal(false)}>Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {isFormLoading && (
        <div id="" className="loaderbox loginloader">
          <div>
            <img
              className="bounce-2"
              src={loader_image}
              width="50"
              height="50"
            />
            <p>Loading...</p>
          </div>
        </div>
      )}
    </Layout>

  )
}


export default connect()(Merchanthome);