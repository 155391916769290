import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import store from '../../store';
import Count from '../Count';
import Merchant from '../Merchant';
import { Container, Row, Col, Button } from 'react-bootstrap' 
import { Layout } from '../common/Layout'
import axios from 'axios';
import loader_image from '../../images/loading.gif';
import Moment from 'react-moment';
// import * as moment from 'moment';
import * as moment from 'moment-timezone';
import jstz from 'jstz';
import { adminService, userService } from '../../_services';

import { EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT} from '../../config/config';
import Transactions from '../Transactions';

const hanldleClick = (type: string) => {
  store.dispatch({type});
  console.log(store.getState().counter)
}

const Deposits = () => {
    const [ rates, setRates ] = useState([]);
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ usersPerPage, setUsersPerPage ] = useState(20);
    const [ totalPage, setTotalPage ] = useState(1);
    const [ isLoading, setisLoading ] = useState(0);

    const total = Math.round(totalPage/usersPerPage);

    useEffect(() => {
        getData();
      }, []);

    const getData = () => {                
        const user_id =  localStorage.getItem('user_id');              
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
          console.log('Bearer '+access_token)
          let config1 = {
            headers: {
                'Content-Type': 'application/json',
                'deviceType':'Android',
                'appVersion' : '1.0',
                'Authorization' : 'Bearer '+access_token
            }
          }
          axios.get(`${TRANSACTION_MANAGEMENT}/${user_id}/getTranctionListByMerchantForDep?page=0`, config1).then(function (response) {
            var data1 = response.data.data
            console.log(data1)
            setTotalPage(data1.count)
            if (data1.count > 0){
            setisLoading(1)
            }else{
            setisLoading(2)
            }
            setRates(data1.transactions)
          }).catch(function (error) {
            setisLoading(3)
          console.log(error);
          setRates([])
          userService.handleError(error);
        })
      }  
  }

  const makeHttpRequestWithPage = async (pageNumber:any) => {
    if (pageNumber < 0){
      pageNumber = total-1
    }
    if (pageNumber == total){
      pageNumber = 0
    }
    const access_token = localStorage.getItem('access_token');
    const user_id =  localStorage.getItem('user_id'); 
    const response = await fetch(`${TRANSACTION_MANAGEMENT}/${user_id}/getTranctionListByMerchantForDep?page=${pageNumber}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'deviceType':'Android',
        'appVersion' : '1.0',
        'Authorization' : 'Bearer '+access_token
    },
    });
    const data = await response.json();
    console.log("I am here")
    console.log(data)
    if (data.data.count > 0){
      setisLoading(1)
    }else{
      setisLoading(2)
    }
    setRates(data.data.transactions)
    setCurrentPage(pageNumber)
 
  }

  const pagination = () =>{
    let table = []
    table.push(<li><a  href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(currentPage-1)}>Previous</a></li>)
    for (let i = 0; i < total; i++) {
      // table.push(<span onClick={() => makeHttpRequestWithPage(i)} className="active">{i}</span>)
      table.push(<li className={ i == currentPage ? "active  block_cursor_event" :""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}  >{i+1}</a></li>)
    }  
    table.push(<li><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(currentPage+1)}>Next</a></li>)
    return table
  }


  const timezone = jstz.determine();
  const tz = timezone.name()

  const UserTable = () => {     
    return(
      <div className="table_inner">
          <div className="table-responsive">
                     <table className="table">
                <thead>
                  <tr>
                  <th>ID</th>
                  <th>PHONE NUMBER</th>
                  <th>NAME</th>    
                  <th>TRANSACTION TYPE</th>              
                  <th>AMOUNT</th>                  
                  <th>CREATED AT</th>
                  <th>STATUS</th>
                  <th>CURRENCY</th>
                  </tr>
                </thead>
                <tbody>
               { rates.map((item: any, index: any) => {    
                 const dateToFormat = item.createdAt;                       
                 var aa = moment.tz(item.createdAt, tz)              
                 return(
                 <tr key={item.id}>
                  <td>{ index+1 }</td>
                  <td>{ item.user.countryCode}-{item.user.phoneNumber }</td>
                  <td>{ item.user.firstName }</td>
                  <td>{item.transactionType == null ? item.transactionfor : item.transactionType == 'DEPOSITE' ? 'DEPOSIT' : item.transactionType }</td>
                 <td>{item.transactionType != null ? item.amount : <p>-{item.value}/ +{item.exchangedAmount}</p>} </td>
                  <td><Moment format="YYYY-MM-DD HH:mm"  withTitle>{aa}</Moment> </td>
                  <td>{ item.transactionStatus }</td>
                 <td>{ item.transactionType == null ?<p>{item.currencyFrom}/{item.currencyTo}</p>   : item.currency.name }</td>
                  </tr> )
                })}
                </tbody>
           </table>
          </div>        
      </div>
    )
  }


    var val = window.sessionStorage.getItem('merchant_id')
    
    return (
        <Layout>  
        <section className="inner_sction">
          <section className="table_sction accunt_table">
            <div className="container">
              <div className="table_sction_outer admin_index">
                {getData}
                <div className="table_inner">
                  <div className="table-responsive">
                    {isLoading == 0 && <div id="api_loader"><img src={loader_image} width="50" height="50" />Loading...</div> }
                    {isLoading == 1 &&  UserTable() }
                    {isLoading == 2 &&   <h1>Record Not Found</h1> }
                    {isLoading == 3 &&   <h1>Some Error Occur</h1> }
                  </div>
                  <div className="pagination_sction">
                    <ul className="pagination">
                      {total > 0 ? pagination() : ""}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </Layout>
    )
}


export default connect()(Deposits);