import React, { useEffect, useState } from "react";
import "./bootstrap.min.css";
import "./App.css";
import store from "./store";
import { Provider } from "react-redux";
import { Layout } from "./components/common/Layout";
import Home from "./components/Home";
import About from "./components/About";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Test from "./components/Test";
import Account from "./components/Account";
import Merchant from "./components/Merchant";
import Changepassword from "./components/users/Changepassword";
import Forgetpassword from "./components/users/Forgetpassword";
import Listing from "./components/merchant/Listing";
import Exchange from "./components/merchant/Exchange";
import Transaction from "./components/merchant/Transaction";
import Merchanthome from "./components/merchant/Merchanthome";
import Merchantrate from "./components/merchant/Merchantrate";
import Transactions from "./components/Transactions";
import Wallet from "./components/users/Wallet";
import MerchantWallet from "./components/merchant/wallet";
import Currencypairing from "./components/users/Currencypairing";
import Investment from "./components/admin/Investment";
import Profit from "./components/admin/Profit";
import Completedinvestment from "./components/admin/Completedinvestment";
import Merchanttopup from "./components/admin/Merchanttopup";
import Currency from "./components/admin/Currency";
import Editinvestment from "./components/admin/Editinvestment";
import Order from "./components/admin/Order";
import Profile from "./components/merchant/Profile";
import adminProfile from "./components/admin/Profile";

import Createprofile from "./components/merchant/Createprofile";
import Investmentdetail from "./components/admin/Investmentdetail";
import Deposits from "./components/merchant/deposits";
import Withdraws from "./components/merchant/withdraws";

import Cardbox from "./components/Cardbox";
import SignupMarchent from "./components/merchant/signup";
import CodeVerify from "./components/merchant/CodeVerify";
import Notification from "./components/merchant/notification";
import MerchantActivities from "./components/merchant/MerchantActivites";

import RequestDetails from "./components/admin/RequestDetails";
import PartnerProfile from "./components/admin/PartnerProfile";
import AccountDetails from "./components/admin/AccountDetails";
import ExpiredTransactions from "./components/admin/ExpiredTransactions";
import AcceptedRequests from "./components/admin/AcceptedRequests";
import SuspiciousTransactions from "./components/admin/SuspiciousTransactions";
import CustomerSupport from "./components/admin/CustomerSupport";
import RateInventory from "./components/admin/RateInventory";
import Branches from "./components/admin/Branches";
import Settings from "./components/admin/Settings";
import BranchDetails from "./components/admin/BranchDetails";
import Activites from "./components/admin/Activites";
import SubAdmin from "./components/admin/SubAdmin";
import AdminNotification from "./components/admin/AdminNotification";
import Countries from "./components/admin/Countries";
import TimeLimit from "./components/admin/TimeLimit";
import AmountLimit from "./components/admin/AmountLimit";
import Compliance from "./components/admin/Compliance";
import MarketingMaterial from "./components/admin/MarketingMaterial";
import KYCAdmin from "./components/admin/KYCAdmin";

import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
} from "react-router-dom";
import MerchantActivites from "./components/merchant/MerchantActivites";
import BannerImage from "./components/admin/BannerImage";

const NotFoundPage = () => (
  <div className="notFoundPage">
    <h1>404</h1>
    <h2>Page not found</h2>
  </div>
);

const App: React.FC = () => {
  var access_token = localStorage.getItem("access_token");
  const user_role = localStorage.getItem("user_role");

  useEffect(()=>{
    let current_path = window.location.pathname
    if (user_role =="KYCADMIN"){
      if (!(current_path.includes("AccountDetails") || current_path.includes("account"))){
        window.location.href ="/account"
      }
    }
  }, [])
  return (
    <Provider store={store}>
      <Router>
        {access_token ? (
          <Switch>
            <Route exact={true} path="/" component={Home} />
            <Route exact={true} path="/about" component={About} />

            <Route exact={true} path="/cardbox" component={Cardbox} />

            <Route
              exact={true}
              path="/signupmerchant"
              component={SignupMarchent}
            />

            {access_token && (user_role == "ADMIN" || user_role =="KYCADMIN") && (
              <Route exact={true} path="/account" component={Account} />
            )}
            <Route exact={true} path="/test" component={Test} />
            {!access_token && (
              <Route exact={true} path="/login" component={Login} />
            )}
            {!access_token && (
              <Route exact={true} path="/register" component={Signup} />
            )}
            <Route
              exact={true}
              path="/forgot_Settingspassword"
              component={Forgetpassword}
            />
            {access_token && (
              <Route
                exact={true}
                path="/change_password"
                component={Changepassword}
              />
            )}

            {/* MERCHANT */}
            {access_token &&
              (user_role == "MERCHANT" || user_role == "BRANCH") && (
                <Route exact={true} path="/merchant" component={Merchant} />
              )}
            {access_token &&
              (user_role == "MERCHANT" || user_role == "BRANCH") && (
                <Route
                  exact={true}
                  path="/merchant/home"
                  component={Merchanthome}
                />
              )}
            {access_token &&
              (user_role == "MERCHANT" || user_role == "BRANCH") && (
                <Route
                  exact={true}
                  path="/merchant/exchange"
                  component={Exchange}
                />
              )}
            {access_token &&
              (user_role == "MERCHANT" || user_role == "BRANCH") && (
                <Route
                  exact={true}
                  path="/merchant/transaction"
                  component={Transaction}
                />
              )}
            {access_token &&
              (user_role == "MERCHANT" || user_role == "BRANCH") && (
                <Route
                  exact={true}
                  path="/merchant/transaction"
                  component={Transaction}
                />
              )}
            {access_token &&
              (user_role == "MERCHANT" ||
                user_role == "BRANCH" ||
                user_role == "ADMIN") && (
                <Route exact={true} path="/profile" component={Profile} />
              )}
            {access_token &&
              (user_role == "MERCHANT" || user_role == "BRANCH") && (
                <Route
                  exact={true}
                  path="/merchant/wallet"
                  component={MerchantWallet}
                />
              )}
            {access_token &&
              (user_role == "MERCHANT" || user_role == "BRANCH") && (
                <Route
                  exact={true}
                  path="/merchant/deposits"
                  component={Deposits}
                />
              )}
            {access_token &&
              (user_role == "MERCHANT" || user_role == "BRANCH") && (
                <Route
                  exact={true}
                  path="/merchant/withdraws"
                  component={Withdraws}
                />
              )}
            <Route
              exact={true}
              path="/create-profile"
              component={Createprofile}
            />
            <Route exact={true} path="/codeverify" component={CodeVerify} />

            <Route
              exact={true}
              path="/merchant/notification"
              component={Notification}
            />
            <Route
              exact={true}
              path="/merchant/activities"
              component={MerchantActivites}
            />
            <Route
              exact={true}
              path="/admin/RequestDetails/:order_id"
              component={RequestDetails}
            />
            <Route
              exact={true}
              path="/admin/PartnerProfile/:id"
              component={PartnerProfile}
            />
            <Route
              exact={true}
              path="/admin/AccountDetails/:id"
              component={AccountDetails}
            />
              <Route
              exact={true}
              path="/admin/MarketingMaterial"
              component={MarketingMaterial}
            />
            <Route
              exact={true}
              path="/admin/ExpiredTransactions"
              component={ExpiredTransactions}
            />
            <Route
              exact={true}
              path="/admin/AcceptedRequests"
              component={AcceptedRequests}
            />
            <Route
              exact={true}
              path="/admin/SuspiciousTransactions"
              component={SuspiciousTransactions}
            />
            <Route
              exact={true}
              path="/admin/CustomerSupport"
              component={CustomerSupport}
            />
            <Route
              exact={true}
              path="/admin/RateInventory"
              component={RateInventory}
            />
            <Route exact={true} path="/admin/Branches" component={Branches} />
            <Route exact={true} path="/admin/Settings" component={Settings} />
            <Route
              exact={true}
              path="/admin/BranchDetails/:id"
              component={BranchDetails}
            />
            <Route exact={true} path="/admin/Activites" component={Activites} />
            <Route exact={true} path="/admin/SubAdmin" component={SubAdmin} />
            <Route exact={true} path="/admin/BannerImage" component={BannerImage } />
            <Route exact={true} path="/admin/KYCAdmin" component={KYCAdmin} />

            {/* ADMIN */}

            {access_token && user_role == "ADMIN" && (
              <Route exact={true} path="/profile" component={adminProfile} />
            )}

            {access_token && user_role == "ADMIN" && (
              <Route
                exact={true}
                path="/transactions"
                component={Transactions}
              />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route
                exact={true}
                path="/merchant/listing"
                component={Listing}
              />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route exact={true} path="/wallet" component={Wallet} />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route
                exact={true}
                path="/merchant/rate"
                component={Merchantrate}
              />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route
                exact={true}
                path="/currency-pairing"
                component={Currencypairing}
              />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route exact={true} path="/investment" component={Investment} />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route exact={true} path="/profit-loss" component={Profit} />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route
                exact={true}
                path="/completed-investment"
                component={Completedinvestment}
              />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route
                exact={true}
                path="/edit-investment"
                component={Editinvestment}
              />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route exact={true} path="/order" component={Order} />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route
                exact={true}
                path="/investment-detail"
                component={Investmentdetail}
              />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route
                exact={true}
                path="/merchant/topup"
                component={Merchanttopup}
              />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route
                exact={true}
                path="/admin/view-currencies"
                component={Currency}
              />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route
                exact={true}
                path="/admin/notification"
                component={AdminNotification}
              />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route
                exact={true}
                path="/admin/countries"
                component={Countries}
              />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route
                exact={true}
                path="/admin/time-limit"
                component={TimeLimit}
              />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route
                exact={true}
                path="/admin/amount-limit"
                component={AmountLimit}
              />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route
                exact={true}
                path="/admin/compliance"
                component={Compliance}
              />
            )}
            {access_token && user_role == "ADMIN" && (
              <Route exact={true} path="/profile" component={adminProfile} />
            )}
            <Route path="*" component={Login} />
          </Switch>
        ) : (
          <Switch>
            <Route exact={true} path="/login" component={Login} />
            <Route
              exact={true}
              path="/signupmerchant"
              component={SignupMarchent}
            />
            <Route
              exact={true}
              path="/forgot_Settingspassword"
              component={Forgetpassword}
            />
            <Route
              exact={true}
              path="/admin/AcceptedRequests"
              component={AcceptedRequests}
            />
            <Route path="*" component={Login} />
          </Switch>
        )}
      </Router>
    </Provider>
  );
};

export default App;
