import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Field, reduxForm, SubmissionError } from "redux-form";
// import {config} from '../config/config'

// import account_logo from '../images/account_logo.png';
import account_logo from "../../images/logo.png";
import axios from "axios";
import { userService } from "../../_services";
import $ from "jquery";
import loader_image from "../../images/loading.gif";
import Modal from "react-modal";
import close_image from "../../../assets/images/close.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import '../../alltype.d.ts';
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const Signupmerchant = (props: any) => {
  const [users, setUsers] = useState("string");
  const [api_error, setApiError] = useState("string");
  const [errormsg, setErrormsg] = useState("");
  const [fieldtype, setFieldType] = useState("password");
  const [fieldtype2, setFieldType2] = useState("password");
  const [image, setimage] = useState();
  const [loader, setLoader] = useState(0);
  const [passerror, setPasserror] = useState(0);

  const [signupModal, setsignupModal] = useState(false);

  const [userdetail, setUserdetail] = useState(false);
  const [inputTpye1, setInputTpye1] = useState('password');
  const [inputTpye2, setInputTpye2] = useState('password');
  const [isLoading, setIsLoading] = useState(false);




  const [formVar, setFormVar] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    emailError: "",
    passwordError: "",
    confirmPasswordError: "",
    countryId: 2,
    countryError: "",
  });

  const [formValidateVar, setFormvalidateVar] = useState({
    isEmailValidate: false,
    isPasswordValidate: false,
    isfirstNameValidate: false,
    isConfirmPasswordValidate: false,
    isLastNameValidate: false
  })


  useEffect(() => {
    getData();
  }, []);




  function Userdetailmodal() {
    console.log("Userdetailmodal");
    // localStorage.setItem("user", JSON.stringify(response.data.user))
    // setUserdetail(true)
    setIsLoading(false);
    setsignupModal(true);
  }

  function closeModal() {
    setsignupModal(false);
  }

  const submit = (values: any) => {
    setLoader(1);
    // setIsLoading(true)
    return sleep(100).then(() => {
      if (validateForm()) {
        setIsLoading(true)
        userService
          .register(formVar)
          .then(function (response) {
            console.log("register-----", response);
            localStorage.setItem(
              "user",
              JSON.stringify(response.data.data.user)
            );
            localStorage.setItem(
              "access_token",
              response.data.data.access_token
            );

            Userdetailmodal();
          })
          .catch(function (error) {
            setIsLoading(false)
            userService.handleError(error);
          });
      }
    });
  };

  function _handleImageChange(e: any) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    setimage(file);
    reader.onloadend = () => { };
    reader.readAsDataURL(file);
  }



  const validateForm = () => {
    if (!formValidateVar.isEmailValidate) {
      validateFormCase('email', formVar.email);
      return false;
    } else {
      if (!formValidateVar.isPasswordValidate) {
        validateFormCase('password', formVar.password);
        return false;
      } else {
        if (!formValidateVar.isConfirmPasswordValidate) {
          validateFormCase('confirmPassword', formVar.confirmPassword);
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      background: "#00000054",
    },

    content: {
      // position: "fixed",
      // top: "24px",
      // right: "588px",
      // bottom: "",
      // left: "617px"
    },
  };


  const validateFormCase = (type: any, value: any) => {
    switch (type) {
      case 'email': {
        setFormvalidateVar({ ...formValidateVar, isEmailValidate: false });
        if (value == "" || value == null) {
          setFormVar({ ...formVar, email: "", emailError: "Please Enter Email" });
        } else {
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(value)) {
            setFormVar({ ...formVar, email: value, emailError: "Please Enter Valid Email" });
          } else {
            setFormVar({ ...formVar, email: value, emailError: "" });
            setFormvalidateVar({ ...formValidateVar, isEmailValidate: true });
          }
        }
        break;
      }
      case 'password': {
        setFormvalidateVar({ ...formValidateVar, isPasswordValidate: false });
        if (value == "" || value == null) {
          setFormVar({ ...formVar, password: "", passwordError: "Please Enter Password" });
        } else {
          if (!/^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{9,}$/.test(value)) {
            setFormVar({ ...formVar, password: value, passwordError: "Password Contain Atleast 9 Characters, 1-Letter and 1-Number" });
          } else {
            setFormVar({ ...formVar, password: value, passwordError: "" });
            setFormvalidateVar({ ...formValidateVar, isPasswordValidate: true });
          }
          // var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{9,})");
          // var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{9,})");
          // if(strongRegex.test(value)) {
          //   setFormVar({ ...formVar, password: value, passwordError: "" });
          // } else if(mediumRegex.test(value)) {
          //   setFormVar({ ...formVar, password: value, passwordError: "" });
          // } else {
          //   setFormVar({ ...formVar, password: value, passwordError: "" });
          // }

        }
        break;
      }

      case 'confirm_password': {
        setFormvalidateVar({ ...formValidateVar, isConfirmPasswordValidate: false });
        if (value == "" || value == null) {
          setFormVar({ ...formVar, confirmPassword: "", confirmPasswordError: "Please Enter Your Confirm Password" });
        } else {
          if (value != formVar.password) {
            setFormVar({ ...formVar, confirmPassword: value, confirmPasswordError: "Password and Confirm Password doesn't match" });
          } else {
            setFormVar({ ...formVar, confirmPassword: value, confirmPasswordError: "" });
            setFormvalidateVar({ ...formValidateVar, isConfirmPasswordValidate: true });
          }
        }
        break;
      }
      case 'country': {
        if (value == -1) {
          setFormVar({ ...formVar, countryId: value, countryError: "Please select country" });
        } else {
          setFormVar({ ...formVar, countryId: value, countryError: "" });
        }
        break;
      }
    }
  }

  const getData = () => {
    localStorage.removeItem("err");
  };
  const is_error = localStorage.getItem("err");
  const { error, handleSubmit, pristine, reset, submitting } = props;
  return (
    <div>
      <section className="outer_section">
        <div className="container">
          <div className="col-md-8 col-md-offset-2">
            <div className="inner_section signup_form">
              <div className="logo_form text-center">
                <img src={account_logo} />
                <p>Welcome to eFX</p>
              </div>
              <div className="main_form row">
                <form onSubmit={handleSubmit(submit)}>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label>Email:</label>
                      <div className="input_box">
                        <input
                          className="form-control form-control-two"
                          value={formVar.email}
                          onChange={(e: any) => validateFormCase('email', e.target.value)}
                          placeholder="Enter Email"
                          maxLength={64}
                        ></input>
                        {formVar.emailError.length > 0 && (
                          <span style={{ color: "red" }}>{formVar.emailError}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 paddg_l0">
                      <div className="form-group ">
                        <label>Password:</label>
                        <div className="input_box">
                          <span className="eye hide_pass_signUp" >
                            <img src="images/eye.png" onClick={() => ((inputTpye1 === 'text') ? setInputTpye1('password') : setInputTpye1('text'))} />
                          </span>
                          <input
                            type={inputTpye1}
                            className="form-control form-control-two"
                            value={formVar.password}
                            onChange={(e: any) => validateFormCase('password', e.target.value)}
                            placeholder="Enter Password"
                            minLength={8}
                            maxLength={32}
                          ></input>
                          {formVar.passwordError.length > 0 && (
                            <span style={{ color: "red" }}>
                              {formVar.passwordError}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 paddg_r0">
                      <div className="form-group ">
                        <label>Confirm Password:</label>
                        <div className="input_box">
                          <span className="eye hide_pass_signUp">
                            <img src="images/eye.png" onClick={() => ((inputTpye2 === 'text') ? setInputTpye2('password') : setInputTpye2('text'))} />
                          </span>
                          <input
                            type={inputTpye2}
                            className="form-control form-control-two"
                            value={formVar.confirmPassword}
                            onChange={(e: any) => validateFormCase('confirm_password', e.target.value)}
                            placeholder="Re-Enter Password"
                          ></input>
                          {formVar.confirmPasswordError.length > 0 && (
                            <span style={{ color: "red" }}>
                              {formVar.confirmPasswordError}
                            </span>

                          )}
                        </div>
                      </div>


                    </div>
                    <div className="clear"></div>
                    <div style={{ color: 'grey', marginTop: '10px' }}><p>NOTE- Password Must Contain Atleast <span style={{ color: '#4097e4' }}>9 Characters</span>, Containing <span style={{ color: '#4097e4' }}>1-Letter</span> and <span style={{ color: '#4097e4' }}>1-Number</span>.</p></div>
                    <div className="bottom_btn signup_contnu">
                      <button>Continue</button>
                    </div>
                    <h4 className="text_terms text-center">
                      By clicking on Continue, You are agree with our{" "}
                      <a target='_blank' href="https://www.e-fx.my/terms-of-use">“Terms & Conditions”</a> and{" "}
                      <a target='_blank' href="https://www.e-fx.my/privacy-policy">“Privacy Policy”.</a>
                    </h4>
                  </div>
                </form>
                {isLoading && (
                  <div id="" className="loaderbox loginloader">
                    <div>
                      <img
                        className="bounce-2"
                        src={loader_image}
                        width="50"
                        height="50"
                      />
                      <p>Loading...</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="footer text-center">
        <p>© 2022, All Rights Reserved.</p>
      </section>
      <ToastContainer />
      <Modal
        ariaHideApp={false}
        isOpen={signupModal}
        //  onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      // className="reset_password"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setsignupModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Sign Up Confirmation</h3>
        </div>
        <div className="modal-body">
          <div className="thank_content text-center">
            <h1>Thank You</h1>
            <h3>Your sign up has been successfull.</h3>
            <div className="bottom_btn">
              <a href="/create-profile">Continue</a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(Signupmerchant);
