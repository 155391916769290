import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from 'react-bootstrap' 
import { Field, reduxForm, SubmissionError } from 'redux-form'
import axios from 'axios';
import { USER_MANAGEMENT, FRONT_BASE, EXCHANGE_MANAGEMENT } from '../../config/config';
import { Layout } from '../common/Layout';
import Merchantrate from './Merchantrate';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import loader_image from '../../images/loading.gif';
import user_image from '../../images/user_img_default.png';
import { adminService,userService } from '../../_services';
import {Timeformatter} from '../common/Timeformatter'
import {DataHandling} from '../common/DataHandling'
interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
}


const Listing = () => { 

    const [ users, setUsers ] = useState([]);
    useEffect(() => {
      getData(0,"");
    }, []);


    const [ currentPage, setCurrentPage ] = useState(0);
    const [ usersPerPage, setUsersPerPage ] = useState(20);
    const [ totalPage, setTotalPage ] = useState(1);
  const [rateCount, setRateCount] = useState(0);
  const [isLoader , setIsLoader] = useState(false)

  const [isLoading, setisLoading] = useState(0);
  const [searchText ,setSearchText] = useState("");
    

    const total = Math.ceil(totalPage/usersPerPage);

    const getData = (page:any,txt:any) => {
            adminService.getMerchants(page,txt)
            .then(function (response:any) {
              console.log("getData-------",response.data)
              var data1 = response.data.data
              console.log(data1)
              if (data1.count > 0){
                setisLoading(1)
                }else{
                setisLoading(2)
                }
              setTotalPage(data1.count)
              setUsers(data1.merchant)
            }).catch(function(error:any){
              console.log(error);
              setisLoading(3)
              setUsers([])
            });
    }


  function search_partners(e: any) {
    setCurrentPage(0)
      setSearchText(e.target.value);
        getData(0,e.target.value)
    }
    



    const makeHttpRequestWithPage = async (pageNumber:any) => {
      setUsers([]);
      if (pageNumber < 0){
        pageNumber = total-1
      }
      if (pageNumber == total){
        pageNumber = 0
      }
      getData(pageNumber,searchText)
      setCurrentPage(pageNumber)
    }

    const pagination = () =>{
      let table = []
      table.push(<li><a  href="javascript:void(0)" className={currentPage == 0 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage-1)}>Previous</a></li>)
      for (let i = 0; i < total; i++) {
        console.log("i------------------->"+currentPage)
        // table.push(<span onClick={() => makeHttpRequestWithPage(i)} className="active">{i}</span>)
        table.push(<li className={ i == currentPage ? "active block_cursor_event" :""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}  >{i+1}</a></li>)
      }
      table.push(<li><a href="javascript:void(0)" className={currentPage == total-1 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage+1)}>Next</a></li>)
      return table
    }

  const UserTable = () => {     
      return (
              <table className="table">
                <thead>
                  <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Company Name</th>
                  <th>COUNTRY</th>
                  <th>JOIN ON</th>
                  <th>STATUS</th>
                  <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
               {users.length > 0 ? users.map((item: any, index: any) => {
                 return (
                   
                <tr key={index}>
                  <td><a href={`/admin/PartnerProfile/${item.id}`}>
                      {  (currentPage*20)+ (index+1)}
                    </a>
                  </td>
                  {/* <td><img src={item.image == null ? user_image : "https://api.e-fx.asia/EFXUserManagement/"+item.image} width="50px" height="50px"/></td> */}
                  <td> <DataHandling  data={ item.firstName +" "+item.lastName  } /></td>
                  <td>{ item.email  }</td>
                  <td><DataHandling  data={item.companyName }  /></td>
                  <td>{ item.country ? item.country.name : '-' }</td>
                  <td><Timeformatter  timeget={item.createdAt}  /></td>
                  <td>  { item.status ? "Approved" : "Not-Approved" } </td>
                 {/* <td><button className="btn btn-secondary"  onClick={() => viewRate(item.id)}>view</button></td> */}
                 <td>
                    <div className="dropdown slect_profile">
                      <button className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">Select
                      <span className="caret"></span></button>
                      <ul className="dropdown-menu">
                      <li><a href={`/admin/PartnerProfile/${item.id}`}>View Profile</a></li>
                      {/* <li><a href="">Suspend</a></li> */}
                      
                      </ul>
                    </div>
                 </td>
                </tr> )
                }) : 
                  <div id="" className="loaderbox" style={{marginTop: '80px'}}><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div> 
                }
                </tbody>
           </table>      
        )

    }

    function viewRate(user_id:any){
      console.log("view rate")
      window.sessionStorage.setItem('merchant_id', user_id)
      window.location.href = `${FRONT_BASE}/merchant/rate`; 
    }
                    

    return (      
      <Layout>  
        <section className="inner_sction loader_parent">
          <section className="table_sction accunt_table">
            <div className="container-fluid">
              <div className="table_sction_outer admin_index">

              <div className="top-sctin mbtm-0">
                  <h4>Partners</h4>
                  <div className="form-group pull-right">
                        <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                        <input type="text" className="form-control" value={searchText } onChange={(e)=>search_partners(e)} placeholder="Search partner by name,email"/>
                    </div>
                  
              </div>
                <div className="table_inner">
                  <div className="table-responsive">
                  {isLoading == 0 && <div id="" className="loaderbox loginloader innerloader"><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div> }
                  {isLoading == 1 &&  UserTable() }
                  {isLoading == 2 &&   <h1>Record Not Found</h1> }
                  {isLoading == 3 &&   <h1>Some Error Occur</h1> }
                  </div>
                  <div className="pagination_sction">
                    <ul className="pagination">
                      {total > 0 ? pagination() : ""}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          { isLoader &&
                <div id="" className="loaderbox loginloader innerloader"><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div>
            }
        </section>
      </Layout>
    )
}

  export default reduxForm({
    form: 'searchForm' // a unique identifier for this form
  })(Listing)