import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from 'react-bootstrap' 
import { Field, reduxForm, SubmissionError } from 'redux-form'
import axios from 'axios';
import { TRANSACTION_MANAGEMENT, FRONT_BASE, USER_MANAGEMENT } from '../../config/config';
import { Layout } from '../common/Layout';
import { adminService , userService} from '../../_services';
import { Bar } from 'react-chartjs-2';

interface fieldInterface {
    input: any;
    label: string;
    type: string;
    option_data: any;
    meta: {
        touched: boolean;
        error: string;
    };
}



const Wallet = (props: any) => { 

  const [ wallets, setWallets ] = useState([]);
  const [ currencies, setCurrencies ] = useState([]);
  const [ checked, setChecked ] = useState();
  const [ periodselect, setPeriodselect ] = useState("monthly");
  const [ mapDeposits, setmapDeposits ] = useState();
  const [ exchangeGraphCurrency, setexchangeGraphCurrency ] = useState(1) as any;
  const [role , SetRole] = useState("");
  const [isLoading, setisLoading] = useState(0);
  const [graphLables, setGraphLables] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [countryId, setCountryId] = useState(1) as any;
  const [walletType, setWalletType] = useState('total');
  let user_country_id =  localStorage.getItem("admin_user_country_id") as any;

  const chartOptions =  {
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }]
    }
  }
var periodlabel:any;
var depositdatamap:any;
if (periodselect === "daily")
{
  periodlabel = graphLables
  // periodlabel = ['Sunday', 'Monday', 'Tuesday', 'Wednesday','Thursday','Friday','Saturday']
  depositdatamap = mapDeposits
}else if(periodselect === "weekly") {
  periodlabel = ['week1', 'week2', 'week3', 'week4']

}
else {
  periodlabel = graphLables
  // periodlabel = [ 'January', 'February' ,'March' ,'April','May' ,'June' ,'July' ,'August' ,'September' ,'October' ,'November' ,'December' ]
  depositdatamap = mapDeposits
}

  const data = {
    labels: periodlabel,
    datasets: [
      {
        label: 'Data',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: mapDeposits
      }
    ]
  };

  useEffect(() => {
    let user_role:any = localStorage.getItem("user_permissions") ? localStorage.getItem("user_permissions") : ""
    let tmp_country_id = user_role === 'GROUP_LEVEL' || user_role === 'GUEST' ? '1' : user_country_id
    getData("1","monthly", tmp_country_id, walletType);
    SetRole(user_role);
    setCountryId(tmp_country_id);
    getCurrency();
    getCountries();
  }, []);

  
  function getCountries() {
    adminService.countries_list().then(function (response) {
      setCountryList(response.data.data.countries)
    })
      .catch(function (error) {
        console.log("error get_countries-------------------");
      });
  }
 
  const setPeriodselectt = (event:any) => {
    var dataval:string = event.target.value
    setPeriodselect(dataval)
    getData(exchangeGraphCurrency,dataval, countryId, walletType)
  }

  const handleWalletType = (event:any) => {
    var dataval:string = event.target.value
    setWalletType(dataval);
    getData(exchangeGraphCurrency,periodselect, countryId, dataval);
  }

  function setCountryData(event: any) {
    var tmpCountryId: any = event.target.value;
    setCountryId(tmpCountryId);
    getData(exchangeGraphCurrency, periodselect, tmpCountryId, walletType)
  }

  
  const setexchangeGraphCurrencym = (event:any) => {
    var dataval:string = event.target.value
    setexchangeGraphCurrency(dataval)
    
    // console.log("monthhhh",depositsselect);
    console.log("currencyyyy",dataval);
    getData(dataval, periodselect, countryId, walletType)
  }

  function getCurrency(){
  adminService.get_currencies()
  .then(function (response:any) {
    console.log("currencies-----",response.data.data);
    // console.log("currencies-----",response.data.data.currencies);
    // console.log(response.data.data.data.currencies)
    setCurrencies(response.data.data.currencies)
  }).catch(function(error:any){
    console.log("-----------error--------",error);
    console.log(error)
    setCurrencies([])
  });
  }

  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
    const getData = (currency:any,per_ty:any, country_id:any, wallet_type:any) => {
      let params = {
        "currencyId": currency,
        "exchangeType": per_ty,
        "countryId": country_id,
        "walletType": wallet_type
      }
      console.log("params",params)
      adminService.collectedAmount(params).then(function (response) {
        var tmp_arry:any = [];
        setisLoading(1);
        response.data.data.listAmounts && response.data.data.listAmounts.map((amount:any) => {
          tmp_arry.push(amount.toFixed(2));
        });
        setmapDeposits(tmp_arry);
        if (per_ty === 'monthly'){
          let month_arry: any = [];
          let tmp_arry:any = [ 'January', 'February' ,'March' ,'April','May' ,'June' ,'July' ,'August' ,'September' ,'October' ,'November' ,'December' ];
          let tmp_date:any = new Date(response.data.data.benifits[0].endDate);
          if (tmp_date.getMonth() > 1){
            for(let i = tmp_date.getMonth(); i < 12; i++ ){
              month_arry.push(tmp_arry[i]);
            }
            for(let i = 0; i < tmp_date.getMonth(); i++ ){
              month_arry.push(tmp_arry[i]);
            }
          }
          setGraphLables(month_arry);
        }  
        if (per_ty === 'daily'){
          let week_days_arry: any = [];
          let tmp_arry:any = ['Sunday', 'Monday', 'Tuesday', 'Wednesday','Thursday','Friday','Saturday'];
          let tmp_date:any = new Date(response.data.data.benifits[0].endDate);
          
          if (tmp_date.getDay() > 0){
            for(let i = tmp_date.getDay(); i < 7; i++ ){
              week_days_arry.push(tmp_arry[i]);
            }
            for(let i = 0; i < tmp_date.getDay(); i++ ){
              week_days_arry.push(tmp_arry[i]);
            }
          }
          setGraphLables(week_days_arry);
        }
    }).catch(function(error){
      setisLoading(2);
        //userService.handleError(error);
    })

    }
  const UserTable = () => { 
      return (
        <div className="grade_listng bottm_grph">
        <div className="top-sctin">
          {/* <h4>Total Exchange</h4> */}
          <select value={walletType} className="form-control"  onChange={handleWalletType}>
            <option value="total">Total</option>
            <option value="commission">Commission</option>
          </select>
          <select value={periodselect} className="form-control"  onChange={setPeriodselectt}>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </select>

          {/* {role=="GROUP_LEVEL" &&  } */}
          <select value={exchangeGraphCurrency} className="form-control" onChange={setexchangeGraphCurrencym}>
          {currencies.map((item: any, index: any) => { 
            return (<option value={item.id}>{item.name}</option>)
          })}
          </select>
          {(role === 'GROUP_LEVEL' || role === 'GUEST') &&
            <select value={countryId} className="form-control" onChange={(e: any) => setCountryData(e)}>
              {countryList && countryList.length > 0 && countryList.map((item: any, index: any) => {
                return (<option value={item.id}>{item.name}</option>)
              })}
            </select>
                }
        </div>
        <Bar
          data={data}
          options={chartOptions}
        />
      </div>  
          )

    }



    const { error, handleSubmit, pristine, reset, submitting } = props
    return (      
      <Layout>  
        <section className="inner_sction">
          <section className="table_sction">
            <div className="container-fluid">
              <div className="wallt_listing">
              {isLoading == 1 &&  UserTable() }
              {isLoading == 2 &&   <div className="table_sction_outer admin_index loader_parent"> <h4>Wallet</h4> <h1>Some Error Occur</h1> </div> }
              </div>
            </div>
          </section>
          </section>
      </Layout>
    )
}

  export default reduxForm({
    form: 'searchForm' // a unique identifier for this form
  })(Wallet)