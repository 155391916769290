import React from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from 'react-bootstrap' 

const Home = () => { 
    return (
        <Container className="main-page about">
            <Row className="justify-content-md-center">
                <Col xs sm="3">
                <h3 className="page-title">About</h3>
                </Col>
            </Row>
            <Row>
                <div>This is about page</div>
            </Row>
        </Container>
    )
}


export default Home;