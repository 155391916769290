import { authHeader } from '../_helpers';
 import {config} from '../config/config'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isYieldExpression } from 'typescript';
export const userService = {
    register,
    handleError,
    get_kyc_status,
    create_profile,
    send_otp,
    getBasisImage,
    countries_list
};
var configheaders:any = {
        headers: authHeader()
    }

    function countries_list(){
        let url =  `${config.apiUrl}/EFXUserManagement/v1/api/countries`;
        return axios.get(url,configheaders)
    }

function get_kyc_status(email:any,api_key:any,sha_key:any){
    // let url =  `https://cors-anywhere.herokuapp.com/https://api.basisid.com/users/info/${email}/${api_key}/${sha_key}`;
    // let url =  `https://api.basisid.com/users/info/${email}/${api_key}/${sha_key}`;
    //let url =  `https://api.basisid.com/users/info/${email}/${api_key}/${sha_key}`;
    // admin/getKYCDetail/{email}
    let url = `${config.apiUrl}/EFXUserManagement/v1/api/admin/getKYCDetail/${email}`
    let config1 = {
        headers: {
            "Content-Type": "application/json",
            'deviceType':'Android',
            'appVersion' : '1.0'
        }
      }
      return axios.get(url,configheaders)
    
}
function register(params:any) {
    let url =  `${config.apiUrl}/EFXUserManagement/v1/api/merchantSignup`;
    let config1 = {
        headers: {
            "Content-Type": "application/json",
            'deviceType':'Android',
            'appVersion' : '1.0'
        }
      }
    return axios.post(url,params,config1)
}


function send_otp(params:any,id:any){
    let url =  `${config.apiUrl}/EFXUserManagement/v1/api/merchantSignupSentOtp/${id}`;
    console.log("configheaders",configheaders);
    return axios.post(url,params,configheaders)
}


function create_profile(params:any,id:any){
    let url =  `${config.apiUrl}/EFXUserManagement/v1/api/merchants/${id}/verifyOtp`;
    console.log("configheaders",configheaders);
    return axios.post(url,params,configheaders)
}

function getBasisImage(user_hash:any , access_token:any,image_path:any){
    console.log("user_hash",user_hash);
    console.log("access_token",access_token);
    console.log("image_path",image_path);
    let url =  `https://api.basisid.com/mobcontent/${user_hash}/${access_token}/${image_path}`;
    console.log("url",url);
    return url;
}


function handleError(error:any){
    if(error.response && error.response.status==401 ){
        alert("Your Account is Deactivated by Admin. Please Contact with Admin for Activate.");
        console.log("redirectionnnn");
        localStorage.clear();
        setTimeout(function () {
            window.location.href = '/login'
        }, 1000);
    }else{
        console.log("error",error);
        if(error.name == 'NetworkError'){
            alert('Please check you network');
        }else if('response' in error){
                toast.error(error.response.data.message);
            alert(error.response.data.message);
        }
         else{
            //  alert("Something went wrong")
         }
    }
}


