import React, { useEffect, useState } from 'react';
import { Layout } from '../common/Layout';
import loader_image from '../../images/loading.gif';
import { adminService, userService } from '../../_services';
import { Timeformatter } from '../../components/common/Timeformatter'
import { Amountformatter } from '../../components/common/AmountFormatter'

const Compliance = () => {
    const [users, setUsers] = useState([]);
    const [userskyc, setUsersKYC] = useState([]);
    const [isLoading, setisLoading] = useState(0);
    const [countryList, setCountryList] = useState([]) as any;
    const [occupation, setOccupation] = useState([]) as any;
    const [periodSelected, setPeriodSelected] = useState("");
    const [searchTxt, SetSearchTxt] = useState("");
    const [role, SetRole] = useState("");
    const [selectedOccupation, SetselectedOccupation] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [usersPerPage, setUsersPerPage] = useState(20);
    const [totalPage, setTotalPage] = useState(1);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [countryId, setCountryId] = useState(1);
    const [complianceLogs, setComplianceLogs] = useState([]);

    const total = Math.ceil(totalPage / usersPerPage);
    let user_country_id = localStorage.getItem("admin_user_country_id") as any;
    let user_role: any = localStorage.getItem("user_permissions") ? localStorage.getItem("user_permissions") : ""

    useEffect(() => {
        let tmp_country_id = user_role === 'GROUP_LEVEL' || user_role === 'GUEST' ? 1 : user_country_id;
        getCountries();
        getData(0, tmp_country_id);
        setCountryId(tmp_country_id);
        // SetRole(user_role);
    }, []);


    const getData = (pageNumber:any, tmpCountryId:any) => {
        setisLoading(0);
        adminService. get_compliance_list(pageNumber, tmpCountryId).then(function (response) {
            console.log("transactions ----", response.data)
            if (response.data.data.count > 0) {
                setisLoading(1)
            } else {
                setisLoading(2)
            }
            setTotalPage(response.data.data.count)
            setComplianceLogs(response.data.data.complianceLogs)

        }).catch(function (error) {
            console.log(error);
            setisLoading(3)
            setUsers([])

        })
    }

    function getCountries() {
        adminService.countries_list().then(function (response) {
            setCountryList(response.data.data.countries)
        })
            .catch(function (error) {
                console.log("error get_countries-------------------");
            });
    }

    // const SetSearchTxtChange = (event: any) => {
    //     var dataval: string = event.target.value
    //     SetSearchTxt(dataval)
    //     setCurrentPage(0);
    //     getData(0, dataval, selectedCountry, periodSelected, selectedOccupation)
    // }

    function setCountryData(e: any) {
        var tmpCountryId: any = e.target.value;
        setCountryId(tmpCountryId);
        setCurrentPage(0);
        getData(0, tmpCountryId);
    }

    // const periodSelectedOnchange = (event: any) => {
    //     var dataval: string = event.target.value
    //     setPeriodSelected(dataval);
    //     setCurrentPage(0);
    //     getData(0, searchTxt, selectedCountry, dataval, selectedOccupation)
    // }

    // const setSelectedOccupationChange = (event: any) => {
    //     var dataval: string = event.target.value
    //     SetselectedOccupation(dataval);
    //     setCurrentPage(0);
    //     getData(0, searchTxt, selectedCountry, periodSelected, dataval)
    // }

    function markAsSuspicius(id:any){
        let params:any = { orderId: id}
        adminService.mark_as_suspicius(params).then(function (response) {
          //console.log("---response",response.data);
          getData(currentPage, countryId);
        }).catch(function(error){
            // console.log("error",error);
            // setLoaderstyle({display:"none"})
            userService.handleError(error);
        })
      }

    const makeHttpRequestWithPage = async (pageNumber: any) => {
        setUsers([]);
        if (pageNumber < 0) {
            pageNumber = total - 1
        }
        if (pageNumber == total) {
            pageNumber = 0
        }
        getData(pageNumber, countryId);
        setCurrentPage(pageNumber)
    }

    const pagination = () => {
        let table = []
        table.push(<li><a href="javascript:void(0)" className={currentPage == 0 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage - 1)}>Previous</a></li>)
        for (let i = 0; i < total; i++) {
            table.push(<li className={i == currentPage ? "active block_cursor_event" : ""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}  >{i + 1}</a></li>)
        }
        table.push(<li><a href="javascript:void(0)" className={currentPage == total - 1 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage + 1)}>Next</a></li>)
        return table
    }

    const UserTable = () => {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>PHONE NUMBER</th>
                        <th>MESSAGE</th>
                        <th>DATE & TIME</th>
                        <th>ACTION</th>
                        {/* <th>CREATED AT</th> */}
                        {/* <th>CURRENCY</th> */}

                    </tr>
                </thead>
                <tbody>
                    {complianceLogs.length > 0 && complianceLogs.map((item: any, index: any) => {
                        return (
                            <tr key={((currentPage * 20) + (item.id))}>
                                <td>{(currentPage * 20) + (index + 1)}</td>
                                <td>{item.user ? (item.user.firstName ? item.user.firstName : '') : ""} {item.user ? (item.user.lastName ? item.user.lastName : '') : ""}</td>
                                <td>{item.user ? item.user.countryCode : ""}-{item.user ? item.user.phoneNumber : ""}</td>
                                <td>{item.logMessage}</td>
                                <td><Timeformatter timeget={item.createdOn} /></td>
                                <td>  {item.moneyChangerOrder ?
                                        !item.moneyChangerOrder.isSuspicious ?
                                    <div className="dropdown slect_profile">
                                        <button className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">Select
                                    <span className="caret"></span></button>
                                        <ul className="dropdown-menu">
                                            <li><a onClick={() => markAsSuspicius(item.moneyChangerOrder.idMoneyChangerOrders)}>Mark as Suspicious</a></li>
                                        </ul>
                                    </div>
                                    :
                                    'Marked As Suspicious '
                                    :
                                    '      -'
                                }
                                </td>
                            </tr>)
                    })}
                </tbody>
            </table>
        )

    }
    return (
        <Layout>
            <section className="inner_sction">
                <section className="table_sction accunt_table">
                    <div className="container-fluid">
                        <div className="table_sction_outer admin_index">

                            <div className="top-sctin mbtm-0 big_select">
                                <h4>Compliance</h4>

                                {/* <select value={selectedOccupation} className="form-control long-select" onChange={setSelectedOccupationChange}>
                                    <option value="">Occupation</option>
                                    {occupation && occupation.length > 0 && occupation.map((item: any, index: any) => {
                                        return (
                                            <option value={item}>{item}</option>
                                        )

                                    })}

                                </select> */}
                                {/* <select className="form-control" value={periodSelected} onChange={periodSelectedOnchange} >
                                    <option value="">All</option>
                                    <option value="day">Day</option>
                                    <option value="week">Week</option>
                                    <option value="month">Month</option>
                                </select> */}

                                {(user_role === 'GROUP_LEVEL' || user_role === 'GUEST') &&
                                    <select value={countryId} className="form-control" onChange={(e: any) => setCountryData(e)}>
                                        {countryList && countryList.length > 0 && countryList.map((item: any, index: any) => {
                                            return (<option value={item.id}>{item.name}</option>)
                                        })}
                                    </select>
                                }
                                {/* <div className="form-group pull-right">
                                    <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                                    <input type="text" value={searchTxt} onChange={(e) => SetSearchTxtChange(e)} className="form-control" placeholder="Search with name" />
                                </div> */}

                                {/* <button className="pull-right" data-toggle="modal" data-target="#export_data">
                    <i className="fa fa-download" aria-hidden="true"></i> Export</button>  */}
                                <div className="clearfix"></div>

                            </div>
                            <div className="table_inner">
                                <div className="table-responsive">
                                    {isLoading == 0 && <div id="" className="loaderbox" style={{ marginTop: '50px' }}><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div>}
                                    {isLoading == 1 && UserTable()}
                                    {isLoading == 2 && <h1>Record Not Found</h1>}
                                    {isLoading == 3 && <h1>Some Error Occur</h1>}
                                    <div className="pagination_sction">
                                        <ul className="pagination">
                                            {total > 0 ? pagination() : ""}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </Layout>
    )
}

export default Compliance