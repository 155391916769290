import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from 'react-bootstrap' 
import { Field, reduxForm, SubmissionError } from 'redux-form'
import axios from 'axios';
import { USER_MANAGEMENT, FRONT_BASE, EXCHANGE_MANAGEMENT } from '../../config/config';
import { Layout } from '../common/Layout';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import loader_image from '../../images/loading.gif';
import user_image from '../../images/user_img_default.png';
import { adminService,userService } from '../../_services';
import Modal from 'react-modal';
import Moment from 'react-moment';
import * as moment from 'moment-timezone';
import jstz from 'jstz';
import { toast } from 'react-toastify';
interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
}

 

const CustomerSupport = (props:any) => { 
  let merchant_id = props.match.params.id; 
    const [ data, setData ] = useState([]);
    useEffect(() => {
      getData(0,searchText);
    }, []);


    const [ currentPage, setCurrentPage ] = useState(0);
    const [ usersPerPage, setUsersPerPage ] = useState(20);
    const [ totalPage, setTotalPage ] = useState(1);
    const [ rateCount, setRateCount ] = useState(0);
    const [ isLoading, setisLoading ] = useState(0);
  const [isuserStatus, setisStatus] = useState(3);
  const [isLoaders,setIsLoaders]=useState(false)
  const [searchText, setSearchText] = useState("")
  const [isLoader , setIsLoader] = useState(false)
  
    const [ modalData, setModalData ] = useState({ "show":false , "replyModal": false , "subject":"","message":"", "itemdata":{}});
    const [replyMessage , setReplyMessage] = useState("");

    const total = Math.ceil(totalPage/usersPerPage);
    const timezone = jstz.determine();
    const tz = timezone.name()

    const customStyles1 = {
      overlay : {
       display: "block" ,
       paddingRight: "15px",
       background: "#00000054"
      }
    }; 
     
  
  
  function searchSupport(e:any) {
    setSearchText(e.target.value)
    getData(0,e.target.value)
   }
  const getData = (currentPage: any, txt: any) => {
      setIsLoader(true)
      adminService.customerRequests(currentPage,txt)
        .then(function (response: any) {
        setIsLoader(false)
        console.log("response---------",response.data)

        let data1 = response.data.supportQueriesList;
        setData(response.data.supportQueriesList);
        if (data1.length > 0){
                  setisLoading(1)
                  }else{
                  setisLoading(2)
                  }
                  // let tmp_to:number = parseInt(response.data.customertRequestsCount);
                  // let total_page1:any = (tmp_to/10)
                  // let total_page2:any = (parseInt(total_page1) + 1)
                  // setTotalPage(parseInt(total_page2))
                  setCurrentPage(currentPage)
                  console.log("response.data.customertRequestsCount-------",response.data.customertRequestsCount)
                  setTotalPage(response.data.customertRequestsCount)

      }).catch(function(error:any){
        // console.log("-----------error--------",error);
          // setLoaderstyle({display:"none"})
          // userService.handleError(error);
      });
    }
    function submitReply(){
      console.log("submitReply", replyMessage);
      setIsLoaders(true)
     console.log("merchant_id",merchant_id)
      let params = {
        "id":(modalData.itemdata as any).id,
        "msg":replyMessage
      }
      adminService.send_reply(params,(modalData.itemdata as any).id)
        .then(function (response: any) {
        setIsLoaders(false)
          closeModal1() 
          toast.success(response.data.message);
          getData(currentPage,searchText)

      }).catch(function(error:any){
        console.log(error);
        userService.handleError(error);
      });

    }

    const makeHttpRequestWithPage = async (pageNumber:any) => {
      if (pageNumber < 0){
        pageNumber = total-1
      }
      if (pageNumber == total){
        pageNumber = 0
      }
      getData(pageNumber,searchText)
      setCurrentPage(pageNumber)

   
    }

    const pagination = () =>{
      let table = []
      table.push(<li><a  href="javascript:void(0)"  className={currentPage == 0 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage-1)}>Previous</a></li>)
      console.log("total----------.............................",total);
      for (let i = 0; i < total; i++) {
        table.push(<li className={ i == currentPage ? "active  block_cursor_event" :""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}  >{i+1}</a></li>)
      }
      table.push(<li><a href="javascript:void(0)"  className={currentPage == 0 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage+1)}>Next</a></li>)
      return table
    }
    function closeModal1(){
      console.log("-----------------------",modalData);
      setModalData({...modalData, "show": false ,"replyModal": false , "subject": "", "message": ""  })
    }

  const UserTable = () => {     

      return (
              <table className="table">
                <thead>
                  <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email Address</th>               
                  <th>Date & Time</th>
                  <th>Subject</th>
                  <th>Replied</th>
                  <th>Action</th>
                  </tr>
                </thead>
                <tbody>
               {data.map((item: any, index: any) => {
                console.log("ratecountval")
                const dateToFormat = item.createdAt;                       
                var aa = moment.tz(item.createdAt, tz)              
                 return(
                <tr key={index}>
                  <td>  { ((currentPage*10) + (index + 1)) }</td>
                  <td> <a href={`/admin/AccountDetails/${item.senderId}`}>{item.name}</a>   </td>
                   
                  <td>{item.email}</td>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm"  withTitle>{aa}</Moment>
                  </td>
                  <td>{  item.subject.length > 20 ? `${item.subject.substring(0,20)}...` : item.subject } </td>
                 <td>
                     {item.repliesList.length > 0 ? item.repliesList[0].msg : "-" }
                 {/* <b Replied */}
                 </td>
                  <td>
                    <div className="dropdown slect_profile">
                        <button className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">Select
                        <span className="caret"></span></button>
                        <ul className="dropdown-menu">
                            <li>
                              <a onClick={() => setModalData({...modalData, "show": true , "subject" : item.subject  , "message" : item.msg   }) } >View Message</a>
                              {item.repliesList.length == 0 ? 
                                <a onClick={() => { setReplyMessage(""); setModalData({ ...modalData, "replyModal": true, "itemdata": item }) }}>Reply Email</a>
                                :
                                null
                              }
                              </li>
                        </ul>
                    </div>
                  </td>
                </tr> )
                })}
                </tbody>
           </table>      
        )

    }

                    
    return (      
      <Layout>  
        <section className="inner_sction">
          <section className="table_sction accunt_table">
            <div className="container-fluid">
              <div className="table_sction_outer admin_index loader_parent">

              <div className="top-sctin mbtm-0">
                  <h4>Customer Support</h4>
                  <div className="form-group pull-right">
                    <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                    <input type="text" className="form-control" value={searchText} onChange={(e)=>searchSupport(e)} placeholder="Search customer support by name, email"/>
                  </div>
              </div>

                {getData}
                <div className="table_inner">
                  <div className="table-responsive">
                  {/* {isLoading == 0 && 
                    // <div id="api_loader">

                    //   <div id="" className="loaderbox">
                    //     <div>
                    //       <img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p>
                    //     </div>
                    //   </div>
                      
                    //   </div> 
                      } */}
                  {isLoading == 1 &&  UserTable() }
                  {isLoading == 2 &&   <h1>Record Not Found</h1> }
                  {isLoading == 3 &&   <h1>Some Error Occur</h1> }
                  </div>
                  <div className="pagination_sction">
                    <ul className="pagination">
                      {total > 0 ? pagination() : ""}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          { isLoader &&
                <div id="" className="loaderbox loginloader innerloader"><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div>
            }
        </section>
        <Modal
ariaHideApp={false}
 isOpen={modalData.show}
 // onAfterOpen={afterOpenModal}
 onRequestClose={closeModal1}
//  style={customStyles1}
 className={
   "modal-content custom_modal thakyou_pop"}
 contentLabel="Example Modal"
 overlayClassName="Overlay"
 // className="reset_password"
>
<div className="modal-header">
          <button type="button" className="close" onClick={closeModal1}><i className="fa fa-times" aria-hidden="true"></i></button>
          <h3 className="modal-title">Customer</h3>
        </div>
        <div className="modal-body">
          <div className="user_request padding">
            <form>
                <div className="input_area">
                    <label>Subject:</label>
                    <p>{modalData.subject}</p>
                </div>

                <div className="input_area">
                    <label>Message:</label>
                    <p>{modalData.message}</p>
                </div>

                <div className="clearfix"></div>

                <div className="bottom_btn">                      
                  <button onClick={() => setModalData({...modalData, "show": false   }) } type="submit">OK</button>
                </div>
            </form>
          </div>
        </div>
</Modal>


<Modal
ariaHideApp={false}
 isOpen={modalData.replyModal}
 onRequestClose={closeModal1}
//  style={customStyles1}
 className={
   "modal-content custom_modal thakyou_pop"}
 contentLabel="Example Modal"
 overlayClassName="Overlay"
>
          <div className="modal-header">
          { isLoaders &&
                <div id="" className="loaderbox loginloader innerloader"><div><img className="bounce-2" src={loader_image} width="50" height="50" /><p>Loading...</p></div></div>
            }
          <button type="button" className="close" onClick={closeModal1}><i className="fa fa-times" aria-hidden="true"></i></button>
          <h3 className="modal-title"> Reply To Customer</h3>
        </div>
          <div className="modal-body">
            {modalData &&
              <div className="">
                {/* <form> */}
              
                <div className="form-group">
                  <label>Email:</label>
                  
                  <input type="text" value={(modalData.itemdata as any).email} className="form-control" readOnly/>
                   
                </div>
                <div className="form-group">
                  <label>Message:</label>
                  <textarea value={replyMessage} onChange={(e) => setReplyMessage(e.target.value)} className="form-control">{replyMessage} </textarea>
                  
                  {/* <input type="text" value={replyMessage} onChange={(e) => setReplyMessage(e.target.value)} /> */}
                   
                </div>
                <div className="clearfix"></div>

                <div className="bottom_btn">
                  <button onClick={submitReply} type="submit">Reply</button>
                </div>
                {/* </form> */}
              </div>
            }
        </div>
</Modal>


      </Layout>
      
    )
}

export default (CustomerSupport)