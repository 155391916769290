import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Field, reduxForm, SubmissionError } from "redux-form";
// import {config} from '../config/config'
import { USER_MANAGEMENT, config } from "../config/config";
import { FRONT_BASE } from "../config/config";
// import account_logo from '../images/account_logo.png';
import account_logo from "../images/logo.png";
import axios from "axios";
import $ from "jquery";
import loader_image from "../images/loading.gif";
import Modal from "react-modal";
// import '../../alltype.d.ts';
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error },
}: fieldInterface) => (
  <div className={type == "password" ? "form-group pswd_sign" : "form-group"}>
    <label>{label}:</label>

    <input
      {...input}
      placeholder={label}
      type={type}
      className="form-control"
      id={type == "password" ? "password_input" : "email_input"}
    />
    {touched && error && <span>{error}</span>}
    {type == "password" && (
      <span className="eye hide_pass">
        <img src="images/eye.png" />
      </span>
    )}
  </div>
);

const renderCheckbox = ({ input, label, type }: any) => (
  <div className="group grup_chck">
    <label className="cb-container">
      <p>{label}</p> <input {...input} type={type} className="check" />
      <span className="checkmark"></span>
    </label>
  </div>
);

const Login = (props: any) => {
  const [users, setUsers] = useState("string");
  const [api_error, setApiError] = useState("string");
  const [errormsg, setErrormsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [profileReviewModal, setprofileReviewModal] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      background: "#00000054",
    },

    content: {
      position: "fixed",
      top: "24px",
      right: "588px",
      bottom: "",
      left: "617px",
    },
  };

  function closeModal() {
    setprofileReviewModal(false);
  }

  const submit = (values: any) => {
    return sleep(1000).then(() => {
      if (validateForm(values)) {
        console.log("submit start");
        setIsLoading(true);
        let config1 = {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            deviceType: "Android",
            appVersion: "1.0",
          },
        };
        console.log("submit start end");
        axios
          .post(
            `${USER_MANAGEMENT}/merchants/login`,
            {
              email: values.email,
              password: values.password,
            },
            config1
          )
          .then(function (response) {
            setIsLoading(false);
            if (response.data.data.role == 5){
              localStorage.setItem("user_type", "user");
                localStorage.setItem(
                  "access_token",
                  response.data.data.access_token
                );
                localStorage.setItem(
                  "user_role",
                  response.data.data.user.role.role
                );
                localStorage.setItem("user_id", response.data.data.user.id);
                localStorage.setItem("documentReport", response.data.data.user.documentReport);
                localStorage.setItem("watchlistStandardReport", response.data.data.watchlistStandardReport);
                localStorage.setItem("facialSimilarityPhotoReport", response.data.data.facialSimilarityPhotoReport);
                window.location.href = '/account'
            }
            else{
            if (response.data.data.redirectProfile == true) {
              localStorage.removeItem('user_role');
              localStorage.setItem("user_type", "user");
              localStorage.setItem(
                "access_token",
                response.data.data.access_token
              );
              localStorage.setItem("err", "bb");
              localStorage.setItem("user_id", response.data.data.user.id);
              localStorage.setItem("user", JSON.stringify(response.data.data.user))
              window.location.href = "/create-profile";
            }
            else {
              if (response.data.status == false) {
                alert(response.data.message);
              }
              else {
                localStorage.setItem("user_type", "user");
                localStorage.setItem(
                  "access_token",
                  response.data.data.access_token
                );
                localStorage.setItem(
                  "user_role",
                  response.data.data.user.role.role == "ROLE_ADMIN" ? "ADMIN" : response.data.data.user.role.role
                );
                localStorage.setItem("user_id", response.data.data.user.id);

                var pattern = new RegExp(
                  /https\:\/\/s3\-(\w+)\.amazonaws\.com\/(\w+)\/(\w+)\/(\w+)$/i
                );
                if (response.data.data.user.image) {
                  if (!pattern.test(response.data.data.user.image)) {
                    let image_url =
                      config.image_base_url +
                      response.data.data.user.image;
                    localStorage.setItem("user_image", image_url);
                  } else {
                    localStorage.setItem(
                      "user_image",
                      response.data.data.user.image
                    );
                  }
                } else {
                  localStorage.setItem(
                    "user_image",
                    response.data.data.user.image
                  );
                }

                localStorage.setItem("err", "bb");

                var local = localStorage.getItem("user_type");
                $("#loginwait").css("display", "none");
                const access_token = localStorage.getItem("access_token");
                const user_role = localStorage.getItem("user_role");
                if (user_role == "MERCHANT" || user_role == "BRANCH") {
                  console.log(
                    "mechant service",
                    response.data.data.merchantServices.status
                  );
                  localStorage.setItem(
                    "merchant_online",
                    response.data.data.merchantServices.status
                  );
                }
                // console.log("this is response access_token", response.data.data.access_token)
                if (user_role == "ADMIN") {
                  console.log("------", response.data.data.user.permision);
                  localStorage.setItem(
                    "user_permissions",
                    response.data.data.user.permision
                  );
                  localStorage.setItem("admin_user_country", '');
                  if (response.data.data.user.country != undefined) {
                    localStorage.setItem("admin_user_country", response.data.data.user.country.name);
                    localStorage.setItem("admin_user_country_id", response.data.data.user.country.id);
                  }
                  window.location.href = `${FRONT_BASE}`;
                } else {
                  if (response.data.data.user.mobileVerified) {
                    window.location.href = "/";
                  } else {
                    localStorage.removeItem('user_role');
                    localStorage.setItem("user", JSON.stringify(response.data.data.user))
                    window.location.href = "/create-profile";
                  }
                }
              }
            }
            }
          })
          .catch(function (error) {
            console.log("------ error---------------------------------------------------------------------gsvgvshsvhvshvhsvh--", error);
            setErrormsg(error.response.data.message);
            //localStorage.setItem('err', "aa");
            $("#loginwait").css("display", "none");
            console.log("response", error.response);
            console.log("status", error.response.status);
            alert(error.response.data.message);

            setIsLoading(false);
            if (error == "Error: Request failed with status code 406") {
              localStorage.setItem("err", "current_pwd_error");
              setUsers("current_pwd_error");
              setTimeout(() => {
                //window.location.reload();
                setUsers("");
              }, 2000);
            } else {
              localStorage.setItem("err", "aa");
              setUsers("network_error");
              setTimeout(() => {
                //window.location.reload();
                setUsers("");
              }, 2000);
            }
          });
      }
    });
  };

  const validateForm = (data: any) => {
    let fields = data;
    let errors: any = [];
    let formIsValid = true;
    if (!fields["email"]) {
      formIsValid = false;
      throw new SubmissionError({
        email: "Please enter your email-ID.",
        _error: "Register failed!",
      });
    }
    if (typeof fields["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        localStorage.removeItem("err");
        throw new SubmissionError({
          email: "Please enter valid email-ID.",
          _error: "Register failed!",
        });
      }
    }

    if (!fields["password"]) {
      formIsValid = false;
      localStorage.removeItem("err");
      // errors["password"] = "*Please enter your password.";
      throw new SubmissionError({
        password: "Please enter your password.",
        _error: "Register failed!",
      });
    }
    return formIsValid;
  };

  const getData = () => {
    localStorage.removeItem("err");
  };
  const is_error = localStorage.getItem("err");
  const { error, handleSubmit, pristine, reset, submitting } = props;
  return (
    <div>
      <section className="outer_section ">
        <div className="container">
          <div className="col-md-6 col-md-offset-3">
            <div className="inner_section">
              <div className="logo_form text-center">
                <a href="/">
                  <img src={account_logo}></img>
                </a>
                <p>Login in with your eFX Partner Account</p>
              </div>
              <div className="main_form loader_parent">
                {users == "network_error" && (
                  <h4 className="error">Internet Connection Error</h4>
                )}
                {users == "current_pwd_error" && (
                  <h4 className="error">{errormsg}</h4>
                )}
                <form onSubmit={handleSubmit(submit)}>
                  <Field
                    name="email"
                    type="text"
                    component={renderField}
                    label="Email"
                    placeholder="Enter Email Address"
                  />
                  <Field
                    name="password"
                    type="password"
                    component={renderField}
                    label="Password"
                    placeholder="Enter Password"
                  />
                  <Field
                    name="check"
                    type="checkbox"
                    component={renderCheckbox}
                    label="Keep me logged in"
                  />
                  {error && <strong>{error}</strong>}
                  <div className="bottom_btn">
                    <button type="submit" disabled={submitting} id="login_btn">
                      Login
                    </button>

                    {/* <div id="loginwait">
                      <img src={loader_image} width="50" height="50" />
                      </div>                    */}
                  </div>

                  {/* <button onClick={()=>setprofileReviewModal(true)}>Open Modal</button> */}
                  <div className="bottom_links">
                    <ul>
                      <li>
                        <a href="/forgot_Settingspassword">Forgot Password?</a>
                      </li>
                      <li></li>
                      <li>
                        <a href="/signupmerchant">Sign Up</a>
                      </li>
                    </ul>
                  </div>

                  {isLoading && (
                    <div id="" className="loaderbox loginloader">
                      <div>
                        <img
                          className="bounce-2"
                          src={loader_image}
                          width="50"
                          height="50"
                        />
                        <p>Loading...</p>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="footer text-center">
        <p>© 2022, All Rights Reserved.</p>
      </section>

      <Modal
        ariaHideApp={false}
        isOpen={profileReviewModal}
        //  onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        // style={customStyles1}
        className={"modal-content custom_modal thakyou_pop"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      // className="reset_password"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setprofileReviewModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Account Application Submitted</h3>
        </div>
        <div className="modal-body">
          <div className="thank_content under_review text-center">
            <img src={require("../images/review_loader.png")} />
            <h3>
              Your account application is under review. Our support staff will
              contact you for any further action. This may take up to 72 hours.
            </h3>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(Login);
