import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import store from '../../store';
import Count from '../Count';
import Merchant from '../Merchant';
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Field, reduxForm, SubmissionError, change } from 'redux-form'
import { Layout } from '../common/Layout'
import axios from 'axios';
import loader_image from '../../images/loading.gif';
import Moment from 'react-moment';
// import * as moment from 'moment';
import * as moment from 'moment-timezone';
import jstz from 'jstz';
import { config } from '../../config/config';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { merchantService, userService } from '../../_services';
import { EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT } from '../../config/config';
import Transactions from '../Transactions';
import { Amountformatter } from '../../components/common/AmountFormatter';
import { toast } from 'react-toastify';

// import { config } from 'process';

interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}


const hanldleClick = (type: string) => {
  store.dispatch({ type });
  console.log(store.getState().counter)
}

const Transaction = () => {
  const access_token = localStorage.getItem('access_token');
  const [exporttransaction, setExporttransaction] = useState(0)
  const [rates, setRates] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(20);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setisLoading] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [searchText, setSearchText] = useState("");
  const [orderStatus, setOrderStatus] = useState("ALL");
  const [dateRange, setDateRange] = useState([null, null]);
  const formatDate = (date: any) => moment(date).format('YYYY-MM-DD');
  const [user_id, setUserID] = useState(window.location.pathname.split("/")[3] ? window.location.pathname.split("/")[3] : localStorage.getItem("user_id"))
  const total = Math.ceil(totalPage / usersPerPage);
  const [selectedFilter, setSelectedFilter] = useState('')
  const pathname = window.location.pathname.split("/").pop()

  useEffect(() => {
    getData(user_id, currentPage, '', orderStatus, dateRange, exporttransaction);
  }, []);
  const getData = (user_id: any, pageNo: any, searchTxt: any, os: any, dateRange: any, exporttransaction: any) => {
    if (dateRange[0] && dateRange[1]) {
    }
    merchantService.get_exchange_completed(user_id, pageNo, searchTxt, os, dateRange, exporttransaction).then(function (response) {
      if (response?.data && exporttransaction == 1) {
        handleDownload()
        return;
      }
      var data1 = response.data.orderList;
      if (data1.length > 0) {
        setisLoading(1)
      } else {
        setisLoading(2)
      }
      setTotalPage(response.data.count)
      setRates(data1);
    }).catch(function (error) {
      console.log(error);
      setRates([])
      userService.handleError(error);
    })
  }

  const makeHttpRequestWithPage = async (pageNumber: any) => {
    setRates([]);
    if (pageNumber < 0) {
      pageNumber = total - 1
    }
    if (pageNumber == total) {
      pageNumber = 0
    }
    setisLoading(0);
    getData(user_id, pageNumber, '', orderStatus, dateRange, exporttransaction);
    setCurrentPage(pageNumber);
  }

  const pagination = () => {
    let table = []
    table.push(<li><a href="javascript:void(0)" className={currentPage == 0 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage - 1)}>Previous</a></li>)
    for (let i = 0; i < total; i++) {
      table.push(<li className={i == currentPage ? "active block_cursor_event" : ""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}  >{i + 1}</a></li>)
    }
    table.push(<li><a href="javascript:void(0)" className={currentPage == total - 1 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage + 1)}>Next</a></li>)
    return table
  }

  const timezone = jstz.determine();
  const tz = timezone.name()
  if (!dateRange[0] && dateRange[0] !== undefined) delete dateRange[0];
  if (!dateRange[1] && dateRange[1] !== undefined) delete dateRange[1];

  const fromDate = dateRange[0] !== undefined && dateRange[0] !== "undefined" && dateRange[0] !== null ? dateRange[0] : '';
  const toDate = dateRange[1] !== undefined && dateRange[1] !== "undefined" && dateRange[1] !== null ? dateRange[1] : '';
  const dateParams = (fromDate !== '' && toDate !== '') ? `&from=${fromDate}&to=${toDate}` : '';

  const apiEndpoint = `${config.apiUrl}/EfxExchange/v1/api/allCompletedRequestForMerchant/${user_id}?page=${0}&orderStatus=${orderStatus}${dateParams}&export=${1}`;

  const handleDownload = async () => {
    console.log("handled download")
    try {
      const response = await fetch(apiEndpoint, {
        method: 'GET',
        headers: {
          'deviceType': 'Android',
          'appVersion': '1.0',
          'Authorization': 'Bearer ' + access_token,
          'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
        },
      });

      const arrayBuffer = await response.arrayBuffer();
      const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = 'efx_transactions_report.xlsx';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      // setTimeout(() => {
      //   window.location.reload()
      // }, 10)
    } catch (error) {
      // console.error('Error:', error.message);
    }
  };


  const UserTable = () => {
    return (
      <div className="table_inner">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>PHONE NUMBER</th>
                <th>AMOUNT</th>
                <th>EXCHANGE BETWEEN CURRENCIES</th>
                <th>CREATED AT</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {rates && rates.map((item: any, index: any) => {
                const dateToFormat = item.createdAt;
                var aa = moment.tz(item.createdOn, tz)
                return (
                  <tr key={item.id}>
                    <td>{(currentPage * 20) + (index + 1)}</td>
                    <td>{item.userObj.firstName} {item.userObj.lastName ? item.userObj.lastName : ''}</td>
                    <td>{item.userObj.countryCode}-{item.userObj.phoneNumber}</td>
                    <td><Amountformatter amount={item.value} /></td>
                    <td>
                      <div className="flag_box" style={{ margin: '0px' }}>
                        <div className="currency">
                          <p><span><img src={item.exchangeFromFlag} style={{ width: '33px', height: '33px' }} /></span>{item.currencyFrom}( <Amountformatter amount={item.value} /> )</p>
                        </div>
                        <div className="currency arrow">
                          <img src={require("../../images/bold_arrow_right.png")} style={{ border: '0px solid #ddd', width: '15px', marginTop: '8px' }} />
                        </div>
                        <div className="currency">
                          <p><span><img className="exchange_flag_image" src={item.exchangeToFlag} style={{ width: `33px`, height: `33px` }} /></span>{item.currencyTo}( <Amountformatter amount={item.totalAmount} /> )</p>
                        </div>
                      </div>
                    </td>
                    <td><Moment format="YYYY-MM-DD, HH:mm" withTitle>{aa}</Moment> </td>
                    <td>{item.orderStatus}</td>
                  </tr>)
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  const renderField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className={type == "hidden" ? "" : "form-group"}>
      <label>{label}</label>
      <input {...input} placeholder={label} type={type} className={type == "hidden" ? "" : "form-control"} required />
      {touched && error && <span>{error}</span>}
    </div>
  )

  function search_account(e: any) {
    setSearchText(e.target.value);
    setCurrentPage(0)
    getData(user_id, 0, e.target.value, orderStatus, dateRange, exporttransaction)
  }

  function search_account_by_order_status(e: any) {
    setOrderStatus(e.target.value);
    setCurrentPage(0)
    getData(user_id, 0, searchText, e.target.value, dateRange, exporttransaction)
  }

  const handleDateChange = (date: Date | null, index: number) => {
    const formattedDate = formatDate(date);
    const newDateRange = [...dateRange];
    newDateRange[index] = formattedDate;
    setDateRange(newDateRange);
    // Check if both dates are available before making the API call
    if (newDateRange[0] && newDateRange[1]) {
      getData(user_id, 0, searchText, orderStatus, newDateRange, exporttransaction);
    }
  };

  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter);
    const today = moment();
    switch (filter) {
      case 'today':
        const todayDateRange = [
          formatDate(today.startOf('day').toDate()),
          formatDate(today.endOf('day').toDate())
        ];
        setDateRange(todayDateRange);
        getData(user_id, 0, searchText, orderStatus, todayDateRange, exporttransaction);
        break;
      case 'thisWeek':
        const thisWeekDateRange = [
          formatDate(today.startOf('week').toDate()),
          formatDate(new Date()),
        ];
        setDateRange(thisWeekDateRange);
        getData(user_id, 0, searchText, orderStatus, thisWeekDateRange, exporttransaction);
        break;
      case 'thisMonth':
        const thisMonthDateRange = [
          formatDate(today.startOf('month').toDate()),
          formatDate(new Date()),
        ];
        setDateRange(thisMonthDateRange);
        getData(user_id, 0, searchText, orderStatus, thisMonthDateRange, exporttransaction);
        break;
      default:
        setDateRange([null, null]);
        break;
    }
  };

  function ExportTransaction(e: any) {
    setExporttransaction(1)
    getData(user_id, 0, searchText, orderStatus, dateRange, 1);

  }

  console.log("dateRange", dateRange)

  var val = window.sessionStorage.getItem('merchant_id')

  return (
    <Layout>
      <section className="inner_sction">
        <section className="table_sction accunt_table">
          <div className="container">
            <div className="table_sction_outer admin_index">
              <div className="top-sctin mbtm-0">
                <h4>Transactions</h4>
                <select value={orderStatus} onChange={(e) => search_account_by_order_status(e)}>
                  <option value="ALL">ALL</option>
                  <option value="COMPLETED">COMPLETED</option>
                  <option value="REJECTED">REJECTED</option>
                </select>

                <div className="form-group pull-right">
                  <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                  <input type="text" className="form-control" placeholder="Search With Name, Phone" value={searchText} onChange={(e) => search_account(e)} />
                </div>
                <div>
                  <select onChange={(e) => handleFilterChange(e.target.value)}>
                    <option value="">Select Filter</option>
                    <option value="today">Today</option>
                    <option value="thisWeek">This Week</option>
                    <option value="thisMonth">This Month</option>
                    <option value="custom">Custom</option>
                  </select>

                  <div className="form-group pull-right">
                    <button type="submit" onClick={(e) => {
                      if (rates?.length > 0) {
                        ExportTransaction(e)
                      }
                      else {
                        toast.error("Transaction history is empty. No data to export.", {
                          toastId: 'success_draft_1',
                        })
                      }
                    }} className='export-btn'>Export</button>
                  </div>


                </div>
              </div>
              {/* {selectedFilter === 'custom' && (
                <div className='calender-main-area'>
                  <DatePicker
                    selected={dateRange[0] ? moment(dateRange[0]).toDate() : null}
                    onChange={(date: Date) => handleDateChange(date, 0)}
                    selectsStart
                    startDate={dateRange[0] ? moment(dateRange[0]).toDate() : null}
                    endDate={dateRange[1] ? moment(dateRange[1]).toDate() : null}
                    inline
                    showYearDropdown
                    showMonthDropdown
                    scrollableYearDropdown
                  />
                  <DatePicker
                    selected={dateRange[1] ? moment(dateRange[1]).toDate() : null}
                    onChange={(date: Date) => handleDateChange(date, 1)}
                    selectsEnd
                    startDate={dateRange[0] ? moment(dateRange[0]).toDate() : null}
                    endDate={dateRange[1] ? moment(dateRange[1]).toDate() : null}
                    inline
                    showYearDropdown
                    showMonthDropdown
                    scrollableYearDropdown
                  />
                </div>
              )} */}

              {selectedFilter === 'custom' && (
                <div className='calender-main-area calender-view'>
                  <label className='start-date'>Start Date:</label>
                  <input
                    type='date'
                    id='start-date'
                    className='form-control'
                    name='start-date'
                    value={dateRange[0] ? moment(dateRange[0]).format('YYYY-MM-DD') : ''}
                    onChange={(e:any) => handleDateChange(e.target.value, 0)}
                  />

                  <label className='end-date'>End Date:</label>
                  <input
                    type='date'
                    className='form-control'
                    id='end-date'
                    name='end-date'
                    value={dateRange[1] ? moment(dateRange[1]).format('YYYY-MM-DD') : ''}
                    onChange={(e:any) => handleDateChange(e.target.value, 1)}
                  />
                </div>
              )}




              <div className="table_inner">
                <div className="table-responsive">
                  {isLoading == 0 && <div id="api_loader"><img src={loader_image} width="50" height="50" />Loading...</div>}
                  {isLoading == 1 && UserTable()}
                  {isLoading == 2 && <h1>Record Not Found</h1>}
                  {isLoading == 3 && <h1>Some Error Occur</h1>}
                </div>
              </div>
            </div>
            <div className="pagination_sction" style={{ marginBottom: '100px' }}>
              <ul className="pagination">
                {total > 0 ? pagination() : ""}
              </ul>
            </div>
          </div>
        </section>
      </section>



      <div className="modal fade" id="export_data" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content custom_modal">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
              <h3 className="modal-title">Export Data</h3>
            </div>
            <div className="modal-body">
              <div className="user_request">
                <form>


                  <div className="form-group">
                    <label>From</label>
                    <DatePicker selected={startDate} onChange={(date: any) => setStartDate(date)} />
                  </div>

                  <div className="form-group">
                    <label>To</label>
                    <DatePicker selected={startDate} onChange={(date: any) => setStartDate(date)} />
                  </div>

                  <Field
                    name="user_password"
                    type="text"
                    component={renderField}
                    label="Transaction Status"
                  />



                  <div className="bottom_btn">
                    <button id="add_pass_btn" type="submit">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>


      </div>

    </Layout>
  )
}
// export default connect()(Transaction);

export default reduxForm({
  form: 'searchForm', // a unique identifier for this form
  enableReinitialize: true
})(Transaction)