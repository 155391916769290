import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
// import store from '../../store';
import { Field, reduxForm, SubmissionError, change } from 'redux-form'

import { Container, Row, Col, Button } from 'react-bootstrap' 
import { Layout } from '../common/Layout'
import $ from 'jquery'; 
import { INVESTMENT_MANAGEMENT,TRANSACTION_MANAGEMENT,FRONT_BASE } from '../../config/config';
import axios from 'axios';
import user_image from '../../images/user_img_default.png';
import loader_image from '../../images/loading.gif';


interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
  }
  


const Investment = (props: any) => {

    const [ investments, setInvestments] = useState([]);
    const [ currencies, setCurrencies ] = useState([]);
    const [ currencyToId, setCurrencyToId ] = useState();
    const [ modal, setModal ] = useState(false);
    const [ islocking, setislocking ] = useState("true");
    const [ investmentRollOverStatus, setinvestmentRollOverStatus ] = useState("USER_CHOICE");
    const [ isLoading, setisLoading ] = useState(0);

    const [ document, setdocument ] = useState() as any;
    useEffect(() => {
        getData();
        getCurrency();
    }, []);

    const getCurrency = () => { 
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
          console.log("submit start")
          let config1 = {
            headers: {
                'Content-Type': 'application/json',
                'deviceType':'Android',
                'appVersion' : '1.0',
                'Authorization' : 'Bearer '+access_token
            }
          } 
          axios.get(`${TRANSACTION_MANAGEMENT}/currencies`, config1).then(function (responsedata) { 
            setCurrencies(responsedata.data.data.currencies)
          }).catch(function (error) {
            console.log(error)
            setCurrencies([])
          })
        }

      }

    const getData = () => { 
        const access_token = localStorage.getItem('access_token');
        const user_id = localStorage.getItem('user_id');
        if (access_token) {
          console.log("submit start")
          let config1 = {
            headers: {
                'Content-Type': 'application/json',
                'deviceType':'Android',
                'appVersion' : '1.0',
                'Authorization' : 'Bearer '+access_token
            }
          } 
          axios.get(`${INVESTMENT_MANAGEMENT}/${user_id}/getInvestmentPlans`, config1).then(function (responsedata) { 
            console.log(responsedata.data.data)
            setInvestments(responsedata.data.data.plans)
            if (responsedata.data.data.plans.length > 0){
              setisLoading(1)
              }else{
              setisLoading(2)
              }
          }).catch(function (error) {
            console.log(error)
            setisLoading(3)
            setInvestments([])
          })
        }
      }

    function viewDetail(user_id:any,name:string){
      console.log("view rate")
      window.sessionStorage.setItem('investment_id', user_id)
      window.sessionStorage.setItem('investment_id_name', name)

      window.location.href = `${FRONT_BASE}/profit-loss`; 
    }

    function viewDetails(user_id:any){
      console.log("view rate")
      window.sessionStorage.setItem('investment_id', user_id)

      window.location.href = `${FRONT_BASE}/investment-detail`; 
    }

    function setInvestmentID(user_id:any,name:string){
      console.log("view rate")
      window.sessionStorage.setItem('investment_id', user_id)
      window.sessionStorage.setItem('investment_id_name', name)

      window.location.href = `${FRONT_BASE}/edit-investment`; 
    }
  
  const UserTable = () => { 
    return (	

        <div className="table-responsive">
            <table className="table">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>NAME</th>
                    <th>CURRENCY</th>
                    <th>MINIMUM INVESTMENT</th>
                    <th>TARGET RETURN</th>
                    <th>MANAGEMENT FEE</th>
                    <th>DESCRIPTION</th>
                    <th>TOTAL INVESTMENT</th>
                    <th>ACTION</th>
                </tr>
                </thead>
                <tbody>
                {investments.map((item: any, index: any) => {
                    return(
                    <tr key={index}>
                        <td>{item.id}</td> 
                        <td><a href="javascript:void(0)" onClick={() => viewDetails(item.id)} title={item.name}>{item.name.substr(0, 10)}</a></td>
                        <td>{item.currency }</td>
                        <td>{item.minimumInvestement}</td>
                        <td>{item.minimumWithdrawAmount}</td>
                        <td>{item.managementFee}</td>
                        <td title={item.description}>{item.description.substr(0, 10)}</td>
                        <td>{item.totalInvestment}</td>           
                        <td> 
                            <div className="dropdown slect_profile">
                                <button className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">Select
                                <span className="caret"></span></button>
                                <ul className="dropdown-menu">
                                <li><a href="javascript:void(0)" onClick={() => viewDetail(item.id, item.name)}>View Returns</a></li>
                                <li><a href="javascript:void(0)" onClick={() => setInvestmentID(item.id, item.name)}>Edit</a></li>
                                </ul>
                            </div>
                        </td>
                        <div className="modal fade" id="viewdetail"{...index} role="dialog"></div>
                    </tr>
                    
                    )
                    })}										  
                </tbody>
            </table>    
        </div>  
      )

  }

  $(document).ready(function(){

    setTimeout(function(){
    $('#currencyTo').on('change', function(this:any) {
      localStorage.setItem('currencyTo', this.value);
      var optionsText = this.options[this.selectedIndex].text;
      localStorage.setItem('currencyTovalue', optionsText);
      // $("#currencyToId").val(this.value)
    }); }, 1000);

    

  });

  const setcurrencyToId = (event:any) => {
    var index = event.nativeEvent.target.selectedIndex;
    setCurrencyToId(event.nativeEvent.target[index].text)
  }

  const [ errormsg, setErrormsg ] = useState() as any;
  const [ validerr, setValiderr ] = useState('');

  const closeModal = () => {
    setTimeout(function(){
      console.log("status----->",modal)
      if (modal == true)
      {
        //  $("#newinvestment").modal('hide');
        // $('#newinvestment').modal('toggle');
      }
   }, 1000);
  }

  const submit = (values:any) => {
      console.log(values)
      const access_token = localStorage.getItem('access_token');
      const user_id = localStorage.getItem('user_id');
      if (access_token && currencies[6]["id"]){
        let config1 = {
          headers: {
              'Content-Type': 'application/json',
              'deviceType':'Android',
              'appVersion' : '1.0',
              'Authorization' : 'Bearer '+access_token
          }
        }

        const formData = new FormData();

        formData.append('file',document)

     
        var currency_id = currencies[6]["id"]
        console.log(currencies[6]["id"])
        console.log(currencies[6])
        console.log(currency_id)
        axios.post(`${INVESTMENT_MANAGEMENT}/investmentPlan`, {
          name: values.name,
          description: values.description,
          managementFee: values.managementFee,
          lockingPeriod: values.lockingPeriod,
          isLocking: values.isLocking ? values.isLocking : islocking,
          minimumInvestement: values.minimumInvestement,
          maximumInvestement: values.maximumInvestement,
          liquidity: values.liquidity,
          minimumWithdrawAmount: values.minimumWithdrawAmount,
          investmentRollOverStatus: values.investmentRollOverStatus ? values.investmentRollOverStatus : investmentRollOverStatus ,
          currencyId: currency_id,
          currency: currencies[6]["name"],
          addedBy: user_id, 
          targetReturns: values.targetReturns,
          noticePeriodForCancellation: values.noticePeriodForCancellation,
          advantagesDetail: values.advantagesDetail,
        }, config1).then(function (response) {
          if (formData)
          {
            axios.post(`${INVESTMENT_MANAGEMENT}/${response.data.data.investmentPlan.id}/investmentPlanDoc`, formData, config1).then(function (response) {
            }).catch(function (error) {
              console.log("file upload failed")
            })
          }
  
                getData();
                UserTable();
                localStorage.setItem('same_value',"false");
                setErrormsg("")
                setModal(true)
                closeModal()
              
          }).catch(function (error) {
            setErrormsg("already") 
            setValiderr(error.response.data.message)    
            setTimeout(function(){
              setErrormsg('')
              setValiderr('')
            },3000)  
          })
        }
  }


  const renderField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (    
    <div className={type=="hidden" ? "" : "form-group"}>
      <label>{label == "Management Fee" ? label +" (%)" : label == "Lock Up Period" || label == "Redemptions"  || label == "Notice Period For Cancellation" ? label+" (days)" : label == "Target Returns" ? label +" (%)" : label }</label>
        <input {...input} placeholder={label} type={type} className={type=="hidden" ? "" : "form-control"} maxlength={label == "Advantages Detail" ? "50" : ""} required/>
        {touched && error && <span>{error}</span>}          
    </div>      
  )

  const renderDoubleField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (    
    <div className={type=="hidden" ? "" : "form-group"}>
      <label>{label} (%)</label>
        <input {...input} placeholder={label} type={type} className={type=="hidden" ? "" : "form-control"} step="0.01" required/>
        {touched && error && <span>{error}</span>}          
    </div>      
  )


  const renderTextareaField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className={type=="hidden" ? "" : "form-group"}>
        <label>{label}</label>
        <textarea {...input} className={type=="hidden" ? "" : "form-control"} />
        {touched && error && <span>{error}</span>}          
    </div>   
  )

  const renderSelectField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className="form-group">
      <label>
          {label}
            <select {...input} className="form-control"  value={ localStorage.getItem('currencyTo')} id={input.name} >
              <option>Select Currency</option>
              {
                currencies.map((item: any, index: any) => { 
                  return (<option value={item.id}>{item.name}</option>)
                })
              }
            </select>
          </label>
    </div>
  )


  const renderlockingfield = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className="form-group">
      <label>
          {label}
            <select {...input} className="form-control"  id={input.name} >
             <option value="true">true</option>
             <option value="false">false</option>
            </select>
          </label>
    </div>
  )

  const renderinvestmentRollOverStatus = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className="form-group">
      <label>
          {label}
            <select {...input} className="form-control"  id={input.name} >
             <option value="USER_CHOICE">Client’s Choice</option>
             <option value="YES">yes</option>
             <option value="NO">no</option>
            </select>
          </label>
    </div>
  )

  function _handleImageChange(e:any) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    setdocument(file)
    reader.onloadend = () => {
      // this.setState({
      //   file: file,
      //   imagePreviewUrl: reader.result
      // });
      console.log("onloadend")

      console.log(reader.result)
    }

    reader.readAsDataURL(file)
  }
  const { error, handleSubmit, pristine, reset, submitting } = props
    return (
    <Layout>
        <section className="inner_sction">
            <section className="table_sction">
                <div className="container">
                    <div className="table_sction_outer investment_sction">
                        <div className="top-sctin profile_new">
                            <h4>Investments</h4>
                            {/* <a className="btn btn btn-success complete-investmet" href="/completed-investment"> COMPLETED INVESTMENT </a> */}
                            <div className="btn_add_new">
                                <button className="btn add_new"  data-toggle="modal" data-target="#newinvestment"><i className="fa fa-plus" aria-hidden="true"></i>&nbsp; ADD</button>                                
                            </div>
                        </div>

                        {validerr != "" && <h4 className="error"> Form Not Submitted: {validerr}</h4>}
                        <div className="table_inner">
                        {isLoading == 0 && <div id="api_loader"><img src={loader_image} width="50" height="50" />Loading...</div> }
                        {isLoading == 1 &&  UserTable() }
                        {isLoading == 2 &&   <h1>Record Not Found</h1> }
                        </div>
                    </div>
                </div>
            </section>
        </section>    
        <div className="modal fade" id="newinvestment" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content custom_modal">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
                        <h3 className="modal-title">Add New Investment</h3>
                    </div>
                    <div className="modal-body">
                      {errormsg == "already" && <h4 className="error">Please Add all the fields</h4>}
                        <div className="user_request">
                        <form onSubmit={handleSubmit(submit)} id="investment_add">
                            <Field
                            name="name"
                            type="text"
                            component={renderField}
                            label="Title"
                            />
                            <Field
                            name="managementFee"
                            type="number"
                            component={renderField}
                            label="Management Fee"
                            />
                            <Field
                            name="lockingPeriod"
                            type="number"
                            component={renderField}
                            label="Lock Up Period"
                            />
                            <Field
                            name="minimumInvestement"
                            type="number"
                            component={renderField}
                            label="Minimum Investment"
                            />
                            <Field
                            name="maximumInvestement"
                            type="number"
                            component={renderField}
                            label="Maximum Investment"
                            />
                            <Field
                            name="liquidity"
                            type="number"
                            component={renderField}
                            label="Redemptions"
                            />
                            <Field
                            name="minimumWithdrawAmount"
                            type="number"
                            component={renderField}
                            label="Minimum Withdraw Amount"
                            />
                            <Field
                            name="isLocking"
                            type="select"
                            component={renderlockingfield}
                            label="Lock Up"
                            />  
                            <Field
                            name="investmentRollOverStatus"
                            type="select"
                            component={renderinvestmentRollOverStatus}
                            label="Roll Over"
                            />
                            <Field
                            name="targetReturns"
                            type="number"
                            component={renderDoubleField}
                            label="Target Returns"
                            />
                            <Field
                            name="noticePeriodForCancellation"
                            type="number"
                            component={renderField}
                            label="Notice Period For Cancellation"
                            />
                            <Field
                            name="advantagesDetail"
                            type="text"
                            component={renderField}
                            label="Advantages Detail"
                            />
                            {/* <Field
                            name="currencyTo"
                            type="select"
                            component={renderSelectField}
                            label="To"
                            onChange = {setcurrencyToId}
                            />  */}
                            <div className="form-group">
                              <label>Upload File</label>
                              <input type="file" id="imageUpload" onChange={(e)=>_handleImageChange(e)}/>
                            </div>
                            <Field
                            name="description"
                            type="textarea"
                            component={renderTextareaField}
                            label="Detailed Description"
                            /> 
                            <div className="bottom_btn"> 
                            <button id="add_investment_btn" type="submit" disabled={submitting}>Submit</button>                   
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    )
}

export default reduxForm({
    form: 'searchForm', // a unique identifier for this form
    enableReinitialize : true
  })(Investment)