import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from 'react-bootstrap' 
import { Field, reduxForm, SubmissionError } from 'redux-form'
import axios from 'axios';
import { EXCHANGE_MANAGEMENT, FRONT_BASE } from '../../config/config';
import { Layout } from '../common/Layout';
import Moment from 'react-moment';
import loader_image from '../../images/loading.gif';
import * as moment from 'moment-timezone';
import jstz from 'jstz';


interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
}


const Order = () => { 

    const [ users, setUsers ] = useState([]);
    const [ userskyc, setUsersKYC ] = useState([]);
    const [ isLoading, setisLoading ] = useState(0);

    // Default TimeZone
    const timezone = jstz.determine();
    const tz = timezone.name()

    useEffect(() => {
      getData();
    }, []);


    const getData = () => {                
          const access_token = localStorage.getItem('access_token');
          if (access_token) {
            console.log("submit start")
            let config1 = {
              headers: {
                  'Content-Type': 'application/json',
                  'deviceType':'Android',
                  'appVersion' : '1.0',
                  'Authorization' : 'Bearer '+access_token
              }
            }
            axios.get(`${EXCHANGE_MANAGEMENT}/getOrdersListByAdmin/0`, config1).then(function (response) {
              var data1 = response.data.data
              console.log(response.data)
              if (data1.count > 0){
                setisLoading(1)
              }else{
                setisLoading(2)
              }
              setTotalPage(data1.count)
              setUsers(data1.queuedOrders)
            }).catch(function (error) {
            console.log(error);
            setisLoading(3)
            setUsers([])

          })
        }  
    }


  const UserTable = () => { 
    
      return (
              <table className="table">
                <thead>
                  <tr>
                  <th>ID</th>
                  <th>ORDER TYPE</th>
                  <th>ORDER STATUS</th>    
                  <th>CURRENCY FROM</th>              
                  <th>CURRENCY TO</th>                  
                  <th>CREATED AT</th>
                  <th>EXCHANGE AT</th>
                  <th>EXCHANGED VALUE</th>
                  <th>AMOUNT</th>
                  <th>REMAINING VALUE</th>
                  <th>EXCHANGE NOW</th>
                  </tr>
                </thead>
                <tbody>
               {users.map((item: any, index: any) => {    
                 const dateToFormat = item.createdAt;    
                 var aa = moment.tz(item.createdAt, tz)      
                 var data_id = "#"+item.id  
                 console.log(data_id)
                 return(
                 <tr key={index}>
                  <td>{ index+1 }</td>
                  <td>{ item.orderStatus=="PARTIALLY_COMPLETED" ? <a href="#" data-toggle="modal" data-target={data_id} id="merchnt_profile" >{ item.orderType}</a> :  item.orderType }</td>
                  <td>{ item.orderStatus }</td>
                  <td>{item.currencyFrom }</td>
                  <td>{item.currencyTO }</td>
                  <td><Moment format="YYYY-MM-DD HH:mm"  withTitle>{aa}</Moment> </td>
                  <td>{ item.exchangeAt }</td>
                  <td>{ item.exchangedValue }</td>
                  <td>{ item.value }</td>
                  <td>{ item.remainingValue == null ? 0 : item.remainingValue  }</td>
                  <td>{ item.status == null ? "" :  item.status}</td>
                  <div className="modal fade" id={item.id} role="dialog">
                    <div className="modal-dialog">
                      <div className="modal-content custom_modal">
                        <div className="modal-header">
                          <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
                          <h3 className="modal-title">Partial Order</h3>
                        </div>
                        <div className="modal-body">
                          <div className="user_request">
                          <table className="table">
                            <thead>
                              <tr>
                              <th>ID</th>
                              <th>VALUE</th>
                              <th>EXCHANGED VALUE</th>  
                              <th>EXCHANGE AT</th>  
                              </tr>
                            </thead>
                            <tbody>
                          {
                            item.partiallyCompletedOrder.length > 0 ?
                            item.partiallyCompletedOrder.map((item1: any, index1: any) => {  
                             console.log("item1")
                            console.log(item1 )
                             return(
                              <tr key={index1}>
                                <td>{ index1+1 }</td>
                                <td>{ item1.value }</td>
                                <td>{ item1.exchangedValue }</td>
                                <td>{ item1.exchangeAt }</td>                              
                              </tr>
                             )
                            })
                          :
                           <tr><h4>No Record Found</h4></tr> 
                          }
                            </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </tr> 
                       )
                })}
                </tbody>
           </table>      
        )

    }

    const [ currentPage, setCurrentPage ] = useState(0);
    const [ usersPerPage, setUsersPerPage ] = useState(20);
    const [ totalPage, setTotalPage ] = useState(1);

    const total = Math.ceil(totalPage/usersPerPage);
    

    const makeHttpRequestWithPage = async (pageNumber:any) => {
      console.log("total",total)
      
      if (pageNumber < 0){
        pageNumber = total-1
      }
      if (pageNumber == total){
        pageNumber = 0
      }
      console.log("pageNumber",pageNumber)
      const access_token = localStorage.getItem('access_token');
      const response = await fetch(`${EXCHANGE_MANAGEMENT}/getOrdersListByAdmin/${pageNumber}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'deviceType':'Android',
          'appVersion' : '1.0',
          'Authorization' : 'Bearer '+access_token
      },
      });
  
      const data = await response.json();
      console.log(data)
      setUsers(data.data.queuedOrders)
      setCurrentPage(pageNumber)
   
    }
  
    const pagination = () =>{
      let table = []
      table.push(<li><a  href="javascript:void(0)" className={currentPage == 0 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage-1)}>Previous</a></li>)
      for (let i = 0; i < total; i++) {
        console.log("i------------------->"+currentPage)
        // table.push(<span onClick={() => makeHttpRequestWithPage(i)} className="active">{i}</span>)
        table.push(<li className={ i == currentPage ? "active  block_cursor_event" :""}><a href="javascript:void(0)" onClick={() => makeHttpRequestWithPage(i)}  >{i+1}</a></li>)
      }      
      table.push(<li><a href="javascript:void(0)" className={currentPage == total-1 ? "isDisabled" : ""} onClick={() => makeHttpRequestWithPage(currentPage+1)}>Next</a></li>)
      return table
    }
                    

    // const number_of_transactions = users.length
    return (      
      <Layout>  
        <section className="inner_sction">
          <section className="table_sction accunt_table">
            <div className="container-fluid">
              <div className="table_sction_outer admin_index">
                {getData}
                <div className="table_inner">
                  <div className="table-responsive">
                  <h4>Orders</h4>
                    {isLoading == 0 && <div id="api_loader"><img src={loader_image} width="50" height="50" />Loading...</div> }
                    {isLoading == 1 &&  UserTable() }
                    {isLoading == 2 &&   <h1>Record Not Found</h1> }
                    {/* {number_of_transactions != 0 ? UserTable() : <div className="table"><h1>NO Transactions Found</h1></div>} */}
                    <div className="pagination_sction">
                      <ul className="pagination">
                        {total > 0 ? pagination() : ""}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </Layout>
    )
}

  export default reduxForm({
    form: 'searchForm' // a unique identifier for this form
  })(Order)