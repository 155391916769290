import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import store from '../../store';
import Count from '../Count';
import Merchant from '../Merchant';
import { Container, Row, Col, Button } from 'react-bootstrap' 
import { Layout } from '../common/Layout'
import $ from 'jquery'; 
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { USER_MANAGEMENT,EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT} from '../../config/config';
import jstz from 'jstz';
import Moment from 'react-moment';
import * as moment from 'moment-timezone';
import { adminService,userService } from '../../_services';
import TimeAgo from 'react-timeago'
import {Timeformatter} from '../common/Timeformatter'
import {CollectionDateFormater} from '../common/CollectionDateFormater'
import loader_image from "../../images/loading.gif";
import {Amountformatter} from '../common/AmountFormatter'


// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

const Dashboard = () => {
    //const countt = store.getState().counter 
    let popupstatus = localStorage.getItem('popupisopen');
    const [ camerastatus, setCamerastatus ] = useState('false');
    const [ timezonename, setTimezonename ] = useState('Asia/Calcutta');
    const [ depositsCount, setDepositsCount ] = useState();
    const [ depositsselect, setDepositsselect ] = useState("monthly");
    const [ exchangeGraphCurrency, setexchangeGraphCurrency ] = useState(1) as any;
    const [ withdrawsCount, setWithdrawsCount ] = useState();
    const [ withdrawsslect, setWithdrawsslect ] = useState("monthly");
    const [ mapDeposits, setmapDeposits ] = useState();
    const [ mapWithdraws, setmapWithdraws ] = useState();
    const [ depositsdata, setdepositsdata ] = useState([]) as any;
    const [ withdrawdata, setwithdrawdata ] = useState([]) as any;
    const [ currencies, setCurrencies ] = useState([]);
    const [ defaultcurncy, setdefaultcurncy ] = useState(1) as any;
    const [ isLoading, setisLoading ] = useState(false);
    const [ merchantselect, setMerchantselect ] = useState("monthly");
    const [ userselect, setUsersselect ] = useState("monthly");
    const [exchangeList , setExchangeList] = useState([]);
    const [notifications , setNotifications] = useState([]);
    const [depositGraphlabel, setDepositGraphlabel] = useState([]);
  let user_permission = localStorage.getItem('user_permissions')
  useEffect(() => {
    getExchanges();  
    
    const timezone = jstz.determine();
      const tz = timezone.name();
      setTimezonename(tz);
    getDatavalue("monthly","");
  }, []);

  function getExchanges(){
  
    // setLoaderstyle({display:"block"})
    console.log("getExchanges-----");
   let merchant_id = localStorage.getItem("user_id")
   let params = {
     "merchantId" : merchant_id
   }  
   adminService.get_exchange()
    .then(function (response:any) {
        // setLoaderstyle({display:"none"})
        console.log("-----------------------------------------response",response)
        setExchangeList(response.data.orderList)
  
    }).catch(function(error:any){
      console.log("-----------error--------",error);
        // setLoaderstyle({display:"none"})
        // userService.handleError(error);
    });
  }

  const getDatavalue = (value:string, type:string,defaultcurncyget:any="not_get") => {
      setisLoading(true);
      if(defaultcurncyget == "not_get"){
        defaultcurncyget = defaultcurncy
      }
      const user_id =  localStorage.getItem('user_id');
      let params = {
        "deposits": type == "deposits" ? value : depositsselect,
        "withdraws": type == "withdraws" ? value : withdrawsslect,
        "timeZone":timezonename,
        "currencyId" :defaultcurncyget,
        'userTime': type == "userTime" ? value : userselect,
        'merchantTime': type == "merchantTime" ? value : merchantselect,
        'exchangeType': type == "deposits" ? value : depositsselect
      }
      adminService.getExchangeDashboardData(params)
      .then(function (response:any) {
          // setLoaderstyle({display:"none"})
          console.log("-----------------------------------------response",response)
          var data1 = response.data.data
          // console.log("dashboard data----",data1)

          adminService.get_currencies()
          .then(function (response:any) {
            console.log("currencies-----",response.data.data);
            // console.log("currencies-----",response.data.data.currencies);
            // console.log(response.data.data.data.currencies)
            setCurrencies(response.data.data.currencies)
          }).catch(function(error:any){
            console.log("-----------error--------",error);
            console.log(error)
            setCurrencies([])
          });
          setisLoading(false);
          setDepositsCount(data1.merchantCount);
          setWithdrawsCount(data1.usersCount);
          if (type == "deposits" ? value : depositsselect === 'monthly'){
            let month_arry: any = [];
            let tmp_arry:any = [ 'January', 'February' ,'March' ,'April','May' ,'June' ,'July' ,'August' ,'September' ,'October' ,'November' ,'December' ];
            let tmp_date:any = new Date(data1.transactions[0].endDate);
            if (tmp_date.getMonth() > 1){
              for(let i = tmp_date.getMonth(); i < 12; i++ ){
                month_arry.push(tmp_arry[i]);
              }
              for(let i = 0; i < tmp_date.getMonth(); i++ ){
                month_arry.push(tmp_arry[i]);
              }
            }
            setDepositGraphlabel(month_arry);
          }
          if (type == "deposits" ? value : depositsselect === 'daily'){
            let week_days_arry: any = [];
            let tmp_arry:any = ['Sunday', 'Monday', 'Tuesday', 'Wednesday','Thursday','Friday','Saturday'];
            let tmp_date:any = new Date(data1.transactions[0].endDate);
            
            if (tmp_date.getDay() > 0){
              for(let i = tmp_date.getDay(); i < 7; i++ ){
                week_days_arry.push(tmp_arry[i]);
              }
              for(let i = 0; i < tmp_date.getDay(); i++ ){
                week_days_arry.push(tmp_arry[i]);
              }
            }
            setDepositGraphlabel(week_days_arry);
          }
         console.log("--------------------------------------------------------------data1.listTransactionsAmounts",data1.listTransactionsAmounts)
          setmapDeposits(data1.listTransactionsAmounts)
          setmapWithdraws(data1.listWithdrawAmounts)
          setdepositsdata(data1.returnList)
          setNotifications(response.data.notifications)
          //setdepositsdata(data1.listTransactions)
          setwithdrawdata(data1.listWithdrawAmounts)
          console.log(data1)


      }).catch(function(error:any){
        setisLoading(false);
        console.log("-----------error--------",error);
          // setLoaderstyle({display:"none"})
          // userService.handleError(error);
      });

}
    
    var aa = 0;


var depositlabel:any

var depositdatamap:any
if (depositsselect == "daily")
{
  depositlabel = depositGraphlabel
  // depositlabel = ['Sunday', 'Monday', 'Tuesday', 'Wednesday','Thursday','Friday','Saturday']
  depositdatamap = mapDeposits
}else if(depositsselect == "weekly") {
  depositlabel = ['week1', 'week2', 'week3', 'week4']

}
else {
  depositlabel = depositGraphlabel
  // depositlabel = [ 'January', 'February' ,'March' ,'April','May' ,'June' ,'July' ,'August' ,'September' ,'October' ,'November' ,'December' ]
  depositdatamap = mapDeposits
}



var witdrawlabel:any
var depositdatamap:any
if (withdrawsslect == "daily")
{
  witdrawlabel = ['Sunday', 'Monday', 'Tuesday', 'Wednesday','Thursday','Friday','Saturday']
  depositdatamap = mapDeposits
}else if(withdrawsslect == "weekly") {
  witdrawlabel = ['week1', 'week2', 'week3', 'week4']

}
else {
  witdrawlabel = [ 'January', 'February' ,'March' ,'April','May' ,'June' ,'July' ,'August' ,'September' ,'October' ,'November' ,'December' ]
  depositdatamap = mapDeposits
}



    const setWithdraws = (event:any) => {
      var dataval:string = event.target.value
      setWithdrawsslect(dataval)
       getDatavalue(dataval, "withdraws")
    }

    const setDepositselect = (event:any) => {
      var dataval:string = event.target.value
      setDepositsselect(dataval)
       getDatavalue(dataval, "deposits")
    }

    const setexchangeGraphCurrencym = (event:any) => {
      var dataval:string = event.target.value
      setexchangeGraphCurrency(dataval)
      setdefaultcurncy(dataval)
      console.log("monthhhh",depositsselect);
      console.log("currencyyyy",dataval);
      getDatavalue(depositsselect, "",dataval)
    }

    const setmerchantselect = (event:any) => {
      var dataval:string = event.target.value
      setMerchantselect(dataval)
       getDatavalue(dataval, "merchantTime")
    }

    const setuserselect = (event:any) => {
      var dataval:string = event.target.value
      setUsersselect(dataval)
       getDatavalue(dataval, "userTime")
    }


  const timezone = jstz.determine();
  const tz = timezone.name()

    const UserTable = () => {   
      return(
        <div className="table_inner">
            <div className="table-responsive">
               
                <table className="table">
                    <thead>
                      <tr>
                      <th>ID</th>
                      {user_permission === 'GROUP_LEVEL' && <th>Country</th>}
                      <th>Message</th>
                      <th>created</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                    {notifications && notifications.map((item: any, index: any) => {
                     return( 
                     <tr key={item.id}>
                      <td>{ index+1 }</td>
                      {user_permission === 'GROUP_LEVEL' && <td>{ item.country }</td> }
                      <td>{ item.message }</td>
                      <td><Timeformatter  timeget={item.createdAt}  /></td>
                      </tr> 
                      )
                    })}
                    </tbody>

                </table>
            </div>        
            <p className="view_all text-center"><a href="/admin/notification">VIEW ALL NOTIFICATIONS</a></p>
        </div>
      )
    }

    const WithdrawTable = () => {   
      console.log(depositsdata)
      return(
        <div className="table_inner">
            <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                    <th>ID</th>
                    <th>TRANSACTION TYPE</th>
                    <th>TRANSACTION STATUS</th>
                    <th>TOTAL AMOUNT</th>
                    <th>USER AMOUNT</th>
                    <th>CURRENCY</th>
                    </tr>
                  </thead>
                  <tbody>
                       {withdrawdata && withdrawdata.map((item: any, index: any) => {
                       console.log(item)
                      // return(
                      //   <tr key={index}>
                      //     <td>{index+1}</td>
                      //     <td>{item.transactionType}</td>
                      //     <td> {item.transactionStatus}</td>
                      //     <td> {item.totalAmount}</td>
                      //     <td>{item.userAmount}</td>
                      //     <td><img src={item.currency.imagePath} width="30px" height="30px"/>{item.currency ? item.currency.name : ""}</td>
                      //   </tr> 
                      // )
                    })}							 
                  </tbody>
                </table>
            </div>        
            <p className="view_all text-center"><a href="/merchant/transaction">VIEW ALL TRANSACTIONS</a></p>
        </div>
      )
    }


    const data = {
      labels: depositlabel,
      datasets: [
        {
          label: 'Data',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: mapDeposits
        }
      ]
    };

    const data2 = {
      labels: witdrawlabel,
      datasets: [
        {
          label: 'Data',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: withdrawdata
        }
      ]
    };

    const chartOptions =  {
      scales: {
          yAxes: [{
              ticks: {
                  beginAtZero: true
              }
          }]
      }
    }

    const setPrimaryCurrency = (event:any) => {
      var dataval = event.target.value
      console.log(dataval)
      setdefaultcurncy(dataval)
      getDatavalue("monthly","");

    }

    const currenciesselect = () => { 
      return(
      <div className="form-group">
      {currencies.length > 0 
      ?
        <label>
           Select Currency:
            <select value={defaultcurncy}  onChange={setPrimaryCurrency} className="form-control" id="currency_data">
              <option value="select_country">Select Currency</option>
              {currencies.map((item: any, index: any) => { 
          
               return (<option value={item.id}>{item.name}</option>)
              })}
            </select>
          </label>
      :
      <p>Loading..</p>
            }
    </div>
      )
    }

    return (
        <Layout>
        <section className="inner_sction">
            <div className="table_sction admin_sction_area">
              <div className="container-fluid">
                <div className="dashboard_merchnt">
                  <div className="exchange_request_box">
                  
                    { exchangeList && exchangeList.length == 0 &&
                      <div className="text-center">
                        <img src={require("../../images/no_exchange.png")} />
                        <p>No exchange request received.</p>
                      </div>
                    }

                  {/* <OwlCarousel
                      className="owl-theme"
                      loop
                      margin={10}
                      nav
                      items={1}
                  >
                      <div className="item"><h4>1</h4></div>
                      <div className="item"><h4>2</h4></div>
                      <div className="item"><h4>3</h4></div>
                      <div className="item"><h4>4</h4></div>
                      <div className="item"><h4>5</h4></div>
                      <div className="item"><h4>6</h4></div>
                      <div className="item"><h4>7</h4></div>
                      <div className="item"><h4>8</h4></div>
                      <div className="item"><h4>9</h4></div>
                      <div className="item"><h4>10</h4></div>
                      <div className="item"><h4>11</h4></div>
                      <div className="item"><h4>12</h4></div>
                  </OwlCarousel> */}

                    {exchangeList && exchangeList.length > 0 &&
                      <div id="myCarousel" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                          {  exchangeList.map((item,index)=> {
                              return (
                                <li data-target="#myCarousel" data-slide-to={index} className={index == 0 ? "active" : ""}></li>
                                )
                          })}
                        </ol>


                        <div className="carousel-inner">
                          {  exchangeList.map((item:any,index)=> {
                            // console.log("----item",item);
                              return(
                              <div className={index == 0 ? "item active" : "item"} >
                                <div className="exchange_request">
                                  {/* <p className="req_time">
                                    <TimeAgo date={item.createdOn}  />
                                  </p> */}
                                  <p className='collection_date'>Collection Date :-  {item.exchangeOn && <CollectionDateFormater  timeget={item.exchangeOn}  />}</p>
                                  <p>Request pending from { item.userObj ? item.userObj.firstName : ""} for {item.currencyFrom} <Amountformatter amount={item.value}/> to {item.currencyTo}.</p>
                                  <div className="flag_box">
                                    <div className="currency">
                                        <p><span>
                                        <img className="exchange_flag_image" src={item.exchangeFromFlag} />
                                          </span>{item.currencyFrom}</p>
                                        <p className="amount"><Amountformatter amount={item.value}/> {item.currencyFrom}</p>
                                    </div>

                                    <div className="currency arrow">
                                    <img src={require("../../images/arrow_right.png")} />
                                    </div>

                                    <div className="currency">
                                        <p><span>
                                        <img className="exchange_flag_image" src={item.exchangeToFlag} />
                                        </span>{item.currencyTo}</p>
                                        <p className="amount"><Amountformatter amount={item.totalAmount}/> {item.currencyTo}</p>
                                    </div>
                                  </div>

                                  <p>Exchange Rate 1.00 {item.currencyFrom} = <Amountformatter amount={item.executionRate}/> {item.currencyTo}</p>

                                  <div className="right_btn">
                                      <a href={'/admin/RequestDetails/' + item.idMoneyChangerOrders} >View Details</a>
                                  </div>
                                  <p style={{color: '#929090', float: 'right'}}>
                                    <TimeAgo date={item.createdOn}  />
                                  </p>
                                </div>
                              </div>
                              )
                          })}
                        </div> 
                      </div>
                    } 
                </div> 
                <div className="wallt_outer admin_grdant">
                    <div className="wallet_lft">
                        <div className="wllat_icon">
                            <img src="../images/user_icon.png"/>
                            <div>
                                <h1>{depositsCount}</h1>
                                <p>Total Partners</p>
                                <h6>(IN {merchantselect == "monthly" ? "MONTHLY" : merchantselect == "daily" ? "DAILY" : "WEEKLY"})</h6>
                                <div className="top-sctin">
                                  <select value={merchantselect} className="form-control merchant"   onChange={setmerchantselect}>
                                    <option value="daily">To Day</option>
                                    <option value="weekly">In Week</option>
                                    <option value="monthly">In Month</option>
                                  </select>
                                </div>
                            </div>
                            </div>
                        </div>
                    <div className="wallet_ryt">
                    <div className="wllat_icon">
                        <img src="../images/merchant_icon.png"/>
                        <div>
                            <h1>{withdrawsCount}</h1>
                            <p>Total Users</p>
                            <h6>(IN {userselect == "monthly" ? "MONTHLY" : userselect == "daily" ? "DAILY" : "WEEKLY"})</h6>
                            <div className="top-sctin">
                              <select value={userselect} className="form-control merchant"  onChange={setuserselect}>
                                <option value="daily">To Day</option>
                                <option value="weekly">In Week</option>
                                <option value="monthly">In Month</option>
                              </select>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                  
                <div className="graph_sction">
                    <div className="grade_listng bottm_grph">
                      <div className="top-sctin">
                        <h4>Total Exchange</h4>
                        <select value={depositsselect} className="form-control"  onChange={setDepositselect}>
                          <option value="daily">Daily</option>
                          <option value="weekly">Weekly</option>
                          <option value="monthly">Monthly</option>
                        </select>
                        <select value={exchangeGraphCurrency} className="form-control" onChange={setexchangeGraphCurrencym}>
                        {currencies.map((item: any, index: any) => { 
                          return (<option value={item.id}>{item.name}</option>)
                        })}
                        </select>
                      </div>
                      <Bar
                        data={data}
                        options={chartOptions}
                      />
                    </div>
                    <div className="table_sction_outer">
                      <div className="top-sctin">
                        <h4> Notifications</h4>
                      </div>

                      {notifications && notifications.length > 0 ? UserTable() : "No Record Found"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {isLoading && (
            <div id="" className="loaderbox loginloader">
              <div>
                <img
                  className="bounce-2"
                  src={loader_image}
                  width="50"
                  height="50"
                />
                <p>Loading...</p>
              </div>
            </div>
          )}
        </Layout>
        
    )
}


export default connect()(Dashboard);