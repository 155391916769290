import React, {useEffect, useState} from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col, Form, Button } from 'react-bootstrap' 
import { Field, reduxForm, SubmissionError } from 'redux-form'
// import {config} from '../config/config'
import { USER_MANAGEMENT } from '../config/config';
import { FRONT_BASE } from '../config/config';
// import account_logo from '../images/account_logo.png';
import account_logo from '../images/logo.png';
import axios from 'axios';
import $ from 'jquery'; 
import loader_image from '../images/loading.gif';
import Modal from 'react-modal';
import close_image from '../../assets/images/close.png'


// import '../../alltype.d.ts'; 
interface fieldInterface {
    input: any;
    label: string;
    type: string;
    meta: {
        touched: boolean;
        error: string;
    };
}

  

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))




  const Signup = (props: any) => {

    const [ users, setUsers ] = useState("string");
    const [ api_error, setApiError ] = useState("string");
    const [ errormsg, setErrormsg ] = useState("");
    const [ fieldtype, setFieldType ] = useState("password");
    const [ fieldtype2, setFieldType2 ] = useState("password");
    const [image,setimage] = useState();
    const [loader,setLoader] = useState(0);
    const [passerror,setPasserror] = useState(0);

    const [userdetail, setUserdetail] = useState(false);
    useEffect(() => {
      getData();
    }, []);

    const renderField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
      <div className={type=="password" ? "form-group pswd_sign" : "form-group"}>
          <label>{label}:</label>
          
            <input {...input} placeholder={label} type={type} className="form-control" id={type=="password" ? "password_input" : "email_input"}/>
            {touched && error && <span style={{color: "red"}}>{error}</span>}
            {type=="password" && <div className="eye hide_pass" onClick={(e)=> hidePassword(e)}><img id={label} className={type} src="images/eye.png"/></div>}
            {type=="text" && label=="Confirm Password" && <div className="eye hide_pass" onClick={(e)=> hidePassword(e)}><img id={label} src="images/eye.png" className={type}/></div>}
            {type=="text" && label=="Password"  && <div className="eye hide_pass" onClick={(e)=> hidePassword(e)}><img id={label} src="images/eye.png" className={type}/></div>}
          
        </div>
      )
    
    const renderCheckbox = ({ input, label, type }:any) => (
      <div className="group grup_chck">
        <label className="cb-container"><p>{label}</p>  <input {...input} type={type} className="check"/><span className="checkmark"></span></label>   
      </div>
    )

    function Userdetailmodal(){
      setUserdetail(true)
    }

    function closeModal(){
      setUserdetail(false)
    }

    

    const submit = (values:any) => {
      setLoader(1)
      return sleep(1000).then(() => {
      if (validateForm(values)) {
          console.log("submit start")
          let config1 = {
            headers: {
                'Content-Type': 'application/json',
                'deviceType':'Android',
                'appVersion' : '1.0'
            }
          }

          localStorage.setItem('access_token', "fdsgg");
          localStorage.setItem('is_profile_created', "0");
          localStorage.setItem('user_role',"MERCHANT");
          Userdetailmodal()

    //   axios.post(`${USER_MANAGEMENT}/merchants/login`, {
    //       email:values.email,
    //       password: values.password
        
    //   }, config1).then(function (response) {
    //     console.log(response.data.data)
    //     if (response.data.status==false){
    //       alert(response.data.message);
    //     } else{
    //       localStorage.setItem('user_type', "user");
    //       localStorage.setItem('access_token', response.data.data.access_token);
    //       localStorage.setItem('user_role', response.data.data.user.role.role);
    //       localStorage.setItem('user_id', response.data.data.user.id);
    //       localStorage.setItem('user_image', response.data.data.user.image);
    //       localStorage.setItem('err', "bb");
    //       var local =   localStorage.getItem('user_type');
    //       $("#loginwait").css("display", "none");
    //       const access_token = localStorage.getItem('access_token');
    //       const user_role = localStorage.getItem('user_role');

    //       // console.log("this is response access_token", response.data.data.access_token) 
    //       if (user_role == "ADMIN"){
    //         window.location.href = `${FRONT_BASE}`; 
    //       }
    //       else{
    //         window.location.href = `${FRONT_BASE}`; 
    //       }
    //     }
    //   }).catch(function (error) {
    //     console.log(error);
    //     setErrormsg(error.response.data.message)
    //     //localStorage.setItem('err', "aa");
    //     $("#loginwait").css("display", "none");
    //     if (error == "Error: Request failed with status code 406"){
    //       localStorage.setItem('err', "current_pwd_error");
    //       setUsers("current_pwd_error") 
    //       setTimeout(() => {
    //         //window.location.reload();
    //         setUsers('')
    //       }, 2000);
    //     }
    //     else{
    //       localStorage.setItem('err', "aa");
    //       setUsers("network_error")
    //       setTimeout(() => {
    //         //window.location.reload();
    //         setUsers('')
    //       }, 2000);
    //     }
    // })
  }
   
  })
}

function _handleImageChange(e:any) {
  e.preventDefault();

  let reader = new FileReader();
  let file = e.target.files[0];
  setimage(file)
  reader.onloadend = () => {
  }
  reader.readAsDataURL(file)
}

 
function hidePassword(e:any){
  if(e.target.id == "Confirm Password"){
    if(e.target.className=="password"){
      setFieldType2("text")
    }else{
    setFieldType2("password")
    }
  }else{
    if(e.target.className=="password"){
      setFieldType("text")
    }else{
      setFieldType("password")
    }
  }
}
 

const validateForm= (data:any) => {
       
  let fields = data;
  let errors :any = [];
  let formIsValid = true;
  console.log("ejfddfh")

 if (!fields["email"]) {
    formIsValid = false;
    setLoader(0)
    throw new SubmissionError({ email: 'Please enter your email-ID.', _error: 'Register failed!' })
    // errors["email"] = "*Please enter your email-ID.";
  }
  if (typeof fields["email"] !== "undefined") {
    //regular expression for email validation
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(fields["email"])) {
      formIsValid = false;
      localStorage.removeItem('err'); 
      setLoader(0)
      throw new SubmissionError({ email: 'Please enter valid email-ID.', _error: 'Register failed!' })
    }
  }
  
  if (!fields["password"]) {
    formIsValid = false;
    localStorage.removeItem('err'); 
    // errors["password"] = "*Please enter your password.";
    setLoader(0)
    throw new SubmissionError({ password: 'Please enter your password.', _error: 'Register failed!' })
  }

  if (!fields["confirmpassword"]) {
    formIsValid = false;
    localStorage.removeItem('err'); 
    // errors["password"] = "*Please enter your password.";
    setLoader(0)
    throw new SubmissionError({ confirmpassword: 'Please confirm your password.', _error: 'Register failed!' })
  }

  if (image == "" || image == undefined) {
    formIsValid = false;
    setLoader(0)
    alert("Please Upload your image")
  }

  if(fields["password"] !== fields["confirmpassword"]){
    formIsValid = false;
    setLoader(0)
    throw new SubmissionError({ confirmpassword: 'Password Not matched.', _error: 'Register failed!' })
    // setPasserrro(1)
  }

  
  return formIsValid;
}

const customStyles1 = {
  overlay : {
   display: "block" ,
   paddingRight: "15px",
   background: "#00000054"
  },
 
  content:{
    position: "fixed",
    top: "24px",
    right: "588px",
    bottom: "",
    left: "617px"
  }
}; 



    const getData = () => {    
    localStorage.removeItem('err'); 
    }  
    const is_error = localStorage.getItem('err');
    const { error, handleSubmit, pristine, reset, submitting } = props
    return (
    <div>
     <section className="outer_section">
     <div className="container">
		<div className="col-md-10 col-md-offset-1">
			<div className="inner_section signup_form">
				<div className="logo_form text-center">
					<img src={account_logo}/>
					<p>Welcome to eFX</p>
				</div>
				<div className="main_form row">
        <form onSubmit={handleSubmit(submit)}>
					<div className="col-lg-4 col-md-5">
						<div className="profl_chng text-center">
							<h4>Upload Profile Picture</h4>
							  <div className="avatar-upload">
								<div className="avatar-edit">
									<input type='file' id="imageUpload" accept=".png, .jpg, .jpeg"  onChange={(e)=>_handleImageChange(e)}/>
									<label ></label>
								</div>
								<div className="avatar-preview">
									<div id="imagePreview" style={{backgroundImage: "url(../images/bg_img.png)"}}>
									</div>
								</div>
							</div>
							<h6>
								* File format (jpg, jpeg,png)
								* Max size (1MB)<br/>
								* Best view pixels (800X800)
							</h6>
						</div>
					</div>
					<div className="col-lg-1 hidden-md">
					</div>
					<div className="col-md-7">
          <Field
                    name="email"
                    type="text"
                    component={renderField}
                    label="Email"
                  />
						<div className="col-md-6 paddg_l0">
            <Field
                    name="password"
                    type={fieldtype}
                    component={renderField}
                    label="Password"
                  />
						</div>
						<div className="col-md-6 paddg_r0">
            <Field
                    name="confirmpassword"
                    type={fieldtype2}
                    component={renderField}
                    label="Confirm Password"
                  />
						</div>
						<div className="clear"></div>
						<div className="bottom_btn signup_contnu">
            <button type="submit" disabled={submitting} id="login_btn">Login</button>
    {loader == 1 && <div id="loginwait"><img src={loader_image} width="50" height="50" /></div>  }
							{/* <a href="create_profile.html" data-toggle="modal" data-target="#myModal">Continue</a> */}
						</div>
						<h4 className="text_terms text-center">By clicking on Continue, You are agree with our <a href="">“Terms & Conditions”</a> and <a href="">“Privacy Policy”.</a></h4>
					</div>
          </form>
				</div>
			</div>
		</div>
	  </div>
      </section>
       <section className="footer text-center">
  <p>© 2022, All Rights Reserved.</p>
</section>

<Modal
ariaHideApp={false}
 isOpen={userdetail}
 // onAfterOpen={afterOpenModal}
 onRequestClose={closeModal}
//  style={customStyles1}
 className={
   "modal-content custom_modal"}
 contentLabel="Example Modal"
 overlayClassName="Overlay"
 // className="reset_password"
>
<div className="modal-header">
          <button type="button" className="close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true"></i></button>
          <h3 className="modal-title">Sign Up Confirmation</h3>
        </div>
        <div className="modal-body">
        	<div className="thank_content text-center">
	          <h1>Thank You</h1>
	          <h3>Your sign up has been successfull.</h3>
	          <div className="bottom_btn">
					<a href="/create-profile">Continue</a>
			   </div>
		   </div>
        </div>

</Modal>
</div>
       )
  }
  
  export default reduxForm({
    form: 'loginForm' // a unique identifier for this form
  })(Signup)