import React from 'react';
// import { connect } from "react-redux";
// import store from '../store';
import { Container, Row, Col } from 'react-bootstrap' 

const Cardbox = () => { 
    
    const WithdrawTable = () => {  
        return(
            <Row className="justify-content-md-center">
                <Col xs sm="3">
                <h3 className="page-title">About</h3>
                </Col>
            </Row>
        )
    }
    return (
        <Container className="main-page about">
                
           
            <Row>
                <div>This is about page</div>
            </Row>
            {WithdrawTable()}
        </Container>
    )
}


export default Cardbox;