import React, {useEffect, useState} from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Layout } from '../common/Layout';
import Moment from 'react-moment';
import * as moment from 'moment-timezone';
import jstz from 'jstz';

export const Timeformatter = (props: any) => {
  const { error, handleSubmit, pristine, reset, submitting } = props
    const timezone = jstz.determine();
    const tz = timezone.name()
    if(props.timeget){
      var moment_time = moment.tz(props.timeget, tz);
      // console.log("moment_time---",moment_time);
      return (
  
        <Moment format="YYYY-MM-DD, HH:mm"  withTitle>{moment_time}</Moment>
      )
    }else{
      return (
        <span>
          -
        </span>
      )

    }

}

