import React, { useEffect, useState } from 'react';
// import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError, change } from 'redux-form'

// import store from '../../store';
import Count from '../Count';
import Merchant from '../Merchant';
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Layout } from '../common/Layout'
import axios from 'axios';
import $ from 'jquery';
import user_image from '../../images/user_img_default.png';
import { USER_MANAGEMENT, EXCHANGE_MANAGEMENT, FRONT_BASE, TRANSACTION_MANAGEMENT, config } from '../../config/config';
import loader_image from '../../images/loading.gif';
import ReactTelephoneInput from 'react-telephone-input';
import { adminService, userService } from "../../_services";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Modal from "react-modal";

interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const Profile = (props: any) => {
  const [rates, setRates] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(20);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setisLoading] = useState(0);
  const [image, setimage] = useState() as any;
  const [cover_pic, setcover_pic] = useState() as any;
  const [profiledata, setProfiledata] = useState() as any;
  const [countryCode, setcountryCode] = useState() as any;
  const [defaultCountry, setDefaultCountry] = useState('us') as any;
  const [showMerchantEditModal, setShowMerchantEditModal] = useState(false) as any;
  const [showUpdateImageModal, setShowUpdateImageModal] = useState(false) as any;
  const [tempvar, setTempVar] = useState(0);
  const [websiteLink, setWebsiteLink] = useState('');
  let user_role = localStorage.getItem("user_role");

  const total = Math.round(totalPage / usersPerPage);

  useEffect(() => {
    // getData();
    setisLoading(1);
    getProfile();
    const img = localStorage.getItem('user_image');
    if ((img && img != null) || (image != null) || (image != "")) {
      console.log(img)
      setcover_pic(img);

    } else {
      setcover_pic(user_image);
    }
  }, []);


  const [formVar, setFormVar] = useState({
    firstName: "",
    lastName: "",
    businessName: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
    // phoneNumber: "",
    companyName: "",
    companyPhoneNumber: "",
    companyAddress: "",
    link: "",
    annualIncome: "",
    jobIndustry: "",
    jobTitle: "",
    firstNameError: "",
    lastNameError: "",
    businessNameError: "",
    emailError: "",
    countryCodeError: "",
    phoneNumberError: "",
    companyNameError: "",
    companyPhoneNumberError: "",
    companyAddressError: "",
    linkError: "",
    annualIncomeError: "",
    jobIndustryError: "",
    jobTitleError: ""
  });

  const getProfile = () => {
    const user_id = localStorage.getItem('user_id');
    adminService
      .get_merchant_profile(user_id)
      .then(function (response) {
        console.log("merchant profile-----", response.data);

        console.log("profile")
        console.log(response.data.data)
        setProfiledata(response.data.data.merchant)
        editProfileData(response.data.data.merchant)
        setisLoading(0);
        setWebsiteLink(getLink(response.data.data.merchant));
      })
      .catch(function (error) {
        // setisLoading(3);
        console.log(error);
        userService.handleError(error);
      });
  }

  const editProfileData = (data: any) => {
    setFormVar({
      ...formVar,
      firstName: data.firstName,
      lastName: data.lastName,
      businessName: data.businessName ? data.businessName : '',
      companyName: data.companyName,
      link: data.link,
      annualIncome: data.annualIncome,
      jobIndustry: data.jobIndustry,
      jobTitle: data.jobTitle,
      firstNameError: "",
      lastNameError: "",
      businessNameError: "",
      emailError: "",
      countryCodeError: "",
      phoneNumberError: "",
      companyNameError: "",
      companyPhoneNumberError: "",
      companyAddressError: "",
      linkError: "",
      annualIncomeError: "",
      jobIndustryError: "",
      jobTitleError: ""
    })
  }

  const validateForm = () => {

    let formIsValid = true;
    if (formVar.firstName == "" || formVar.firstName == null) {
      formIsValid = false;
      formVar.firstNameError = "Please Enter First Name";
    } else {
      if (!/^[A-Za-z ]+$/.test(formVar.firstName)) {
        formIsValid = false;
        formVar.firstNameError = "Allowed Only Character's Here";
      }
    }

    if (formVar.lastName == "" || formVar.lastName == null) {
      formIsValid = false;
      formVar.lastNameError = "Please Enter Last Name";
    } else {
      if (!/^[A-Za-z ]+$/.test(formVar.lastName)) {
        formIsValid = false;
        formVar.lastNameError = "Allowed Only Character's Here";
      }
    }
    // if (formVar.businessName == "" || formVar.businessName == null) {
    //   formIsValid = false;
    //   formVar.businessNameError = "* Please Enter Bussiness Name";
    // }else{
    //   if (!/^[A-Za-z ]+$/.test(formVar.businessName)) {
    //     formIsValid = false;
    //     formVar.businessNameError = "Allowed Only Character's Here";
    //   }
    // }

    if (formVar.companyName == "" || formVar.companyName == null) {
      formIsValid = false;

      formVar.companyNameError = "Please Enter Company Name";
    } else {
      if (!/^[A-Za-z ]+$/.test(formVar.companyName)) {
        formIsValid = false;
        formVar.companyNameError = "Allowed Only Character's Here";
      }
    }

    if (formVar.link == "" || formVar.link == null) {
      formIsValid = false;
      formVar.linkError = "Please Enter Website Link";
    } else {
      if (!/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(formVar.link)) {
        formIsValid = false;
        formVar.linkError = "Please Enter Valid Link";
      }
    }

    // if (formVar.annualIncome== "" || formVar.annualIncome == null) {
    //   formIsValid = false;
    //   formVar.annualIncomeError = "Please Enter Annual Income";
    // }else{
    //   if (!/^[0-9]+$/.test(formVar.annualIncome)) {
    //     formIsValid = false;
    //     formVar.annualIncomeError = "Allowed Only Digit's Here";
    //   }
    // }
    // if (formVar.jobIndustry == "" || formVar.jobIndustry == null) {
    //   formIsValid = false;
    //   formVar.jobIndustryError = "Please Enter Job Industry";
    // }else{
    //   if (!/^[A-Za-z ]+$/.test(formVar.jobIndustry)) {
    //     formIsValid = false;
    //     formVar.jobIndustryError = "Allowed Only Character's Here";
    //   }
    // }

    // if (formVar.jobTitle == "" || formVar.jobTitle == null) {
    //   formIsValid = false;
    //   formVar.jobTitleError = "Please Enter Job Title";
    // }else{
    //   if (!/^[A-Za-z ]+$/.test(formVar.jobTitle)) {
    //     formIsValid = false;
    //     formVar.jobTitleError = "Allowed Only Character's Here";
    //   }
    // }
    setTempVar(tempvar + 1)
    return formIsValid;
  };



  const getData = () => {
    const user_id = localStorage.getItem('user_id');
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      console.log("submit start")
      let config1 = {
        headers: {
          'Content-Type': 'application/json',
          'deviceType': 'Android',
          'appVersion': '1.0',
          'Authorization': 'Bearer ' + access_token
        }
      }
      axios.get(`${TRANSACTION_MANAGEMENT}/${user_id}/getTranctionListByMerchant?page=0`, config1).then(function (response) {
        var data1 = response.data.data
        console.log(data1)
        setTotalPage(data1.count)
        if (data1.count > 0) {
          setisLoading(1)
        } else {
          setisLoading(2)
        }
        setRates(data1.transactions)
      }).catch(function (error) {
        setisLoading(3)
        console.log(error);
        setRates([])
      })
    }
  }
  const submit = () => {
    if (validateForm()) {
      setShowMerchantEditModal(false)
      setisLoading(1);
      const user_id: any = localStorage.getItem('user_id');
      const access_token = localStorage.getItem('access_token');
      var params: any = {
        firstName: formVar.firstName,
        lastName: formVar.lastName,
        businessName: formVar.businessName,
        companyName: formVar.companyName,
        link: formVar.link,
        annualIncome: formVar.annualIncome,
        jobIndustry: formVar.jobIndustry,
        jobTitle: formVar.jobTitle
      }
      adminService.edit_merchant_profile(params, user_id).then(function (response) {
        getProfile();
        setisLoading(0);
      })
        .catch(function (error) {
          console.log(error);
          userService.handleError(error);
        });
    }
  }



  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      background: "#00000054",
    },

    content: {
      position: "fixed",
      top: "24px",
      right: "588px",
      bottom: "",
      left: "617px",
    }
  };

  const renderField = ({ input, label, type, meta: { touched, error } }: fieldInterface) => (
    <div className={type == "hidden" ? "" : "form-group"}>
      <label>{label}</label>
      <input {...input} placeholder={label} type={type} className={type == "hidden" ? "" : "form-control"} required />
      {touched && error && <span>{error}</span>}
    </div>
  )

  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  function _handleSubmit(e: any, value: string) {
    e.preventDefault();
    console.log(value);
    setisLoading(5);
    // TODO: do something with -> this.state.file
    console.log('handle uploading-', image);
    const access_token = localStorage.getItem('access_token');
    const user_id = localStorage.getItem('user_id');
    let config1 = {
      headers: {
        'Accept': 'application/json',
        'deviceType': 'Android',
        'appVersion': '1.0',
        'Authorization': 'Bearer ' + access_token
      }
    }
    const formData = new FormData();

    formData.append('file', image)

    axios.post(`${USER_MANAGEMENT}/users/${user_id}/updateImage`, formData, config1).then(function (response) {
      $("#wait").css("display", "none");
      var pattern = new RegExp(
        /https\:\/\/s3\-(\w+)\.amazonaws\.com\/(\w+)\/(\w+)\/(\w+)$/i
      );
      if (response.data.data.user.image) {
        if (!pattern.test(response.data.data.user.image)) {
          let image_url = config.image_base_url + response.data.data.user.image;
          localStorage.setItem("user_image", image_url);
        } else {
          localStorage.setItem("user_image", response.data.data.user.image);
        }
      } else {
        localStorage.setItem("user_image", response.data.data.user.image);
      }
      setShowUpdateImageModal(false);
      sleep(500).then(() => { window.location.reload(); });
      // window.location.href = "/profile"
      setisLoading(0);
    }).catch(function (error) {
      $("#wait").css("display", "none");
      setisLoading(0);
      console.log(error);
    })
  }

  function _handleImageChange_image(e: any) {
    console.log("_handleImageChangee");
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    setimage(file)
    reader.onloadend = () => {
      setcover_pic(reader.result)
      // this.setState({
      //   file: file,
      //   imagePreviewUrl: reader.result
      // });
      console.log("onloadend")

      console.log(reader.result)
    }

    reader.readAsDataURL(file)
  }

  function handleInputChange(telNumber: any, selectedCountry: any) {
    setFormVar({ ...formVar, countryCode: telNumber })
    setDefaultCountry(selectedCountry)
    // console.log('input changed. number: ', telNumber, 'selected country: ', selectedCountry);
    // setcountryCode(telNumber)
  }

  function changeNumber(phone: any) {
  }

  function openUpdateImageModal() {
    setShowUpdateImageModal(true)
  }

  function closeModal() {
    setShowMerchantEditModal(false)
  }

  const showData = (key: any) => {
    return (profiledata ? (profiledata[key] == null ? " - " : `${profiledata[key]} `) : " - ");
  }

  const getLink = (data: any) => {
    return data && data['link'] != null ? data['link'].startsWith("http://" || "https://") ? data['link'] : 'https://' + data['link'] : '#'
  }

  const { error, handleSubmit, pristine, reset, submitting } = props

  return (
    <Layout>
      <section className="inner_sction">
        <section className="table_sction">
          <div className="container">
            <div className="table_sction_outer">
              <div className="top-sctin profile_inner_sction">
                <h4>My Profile</h4>
              </div>
              <div className="profile_filled">
                <div className="profile_upd text-center">
                  {/* {"https://staging.e-fx.asia/"+cover_pic} */}
                  <a className="change_cursor_to_pointer" onClick={() => openUpdateImageModal()}><img className="profile_boarder" src=
                    {profiledata ? config.image_base_url + profiledata["image"] : ""}
                  /></a>
                  <a className="change_cursor_to_pointer" onClick={() => openUpdateImageModal()}>Update Profile Picture</a>
                  {/* {cover_pic == "null" ? user_image : cover_pic} */}
                </div>
                <div className="listng_user_info">
                  <ul>
                    <li>
                      <p>Name:</p>
                      <h5>{showData('firstName')} {profiledata ? profiledata["lastName"] : ""}</h5>
                    </li>
                    <li>
                      <p>Email Address:</p>
                      <h5>{showData('email')}</h5>
                    </li>
                    <li>
                      <p>Phone Number:</p>
                      <h5>{profiledata && profiledata["countryCode"] ? "+" + profiledata["countryCode"] : ""} {showData('phoneNumber')}</h5>
                    </li>
                    <li>
                      <p>Country:</p>
                      <h5>{profiledata ? (profiledata["country"] == null ? " - " : profiledata["country"]['name']) : " - "}</h5>
                    </li>
                    <li>
                      <p>Bank Name:</p>
                      <h5>{profiledata ? (profiledata["bankName"] == null ? " - " : profiledata["bankName"]) : " - "}</h5>
                    </li>
                    <li>
                      <p>Account Number:</p>
                      <h5>{profiledata ? (profiledata["accountNumber"] == null ? " - " : profiledata["accountNumber"]) : " - "}</h5>
                    </li>
                    <hr />
                    <p className="title">Company Information</p>
                    <li>
                      <p>Company Name:</p>
                      <h5>{showData('companyName')}</h5>
                    </li>
                    <li>
                      <p>Unit No, Floor:</p>
                      <h5>{showData('address')}</h5>
                    </li>
                    <li>
                      <p>Company Address:</p>
                      <h5>{showData('companyAddress')}</h5>
                    </li>
                    {user_role == 'MERCHANT' &&
                      <li>
                        <p>Website:</p>
                        <a href={websiteLink} target="_blank"><h5>{showData('link')}</h5></a>
                      </li>
                    }

                  </ul>
                  <div className="bottom_btn center">
                    <a className="change_cursor_to_pointer" style={{ maxWidth: '560px' }} onClick={setShowMerchantEditModal} >Edit Profile</a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </section>
      {isLoading == 1 && (
        <div id="" className="loaderbox loginloader">
          <div>
            <img
              className="bounce-2"
              src={loader_image}
              width="50"
              height="50"
            />
            <p>Loading...</p>
          </div>
        </div>
      )}
      <Modal
        ariaHideApp={false}
        isOpen={showMerchantEditModal}
        onRequestClose={closeModal}
        className={"modal-content custom_modal thakyou_pop edit_profile"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setShowMerchantEditModal(false)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Edit Profile</h3>
        </div>
        <div className="modal-body">
          <div className="user_request">
            <form onSubmit={handleSubmit(submit)}>
              <div className="form-group ">
                <label>First Name:</label>
                <div className="input_box">
                  <input
                    className="form-control form-control-two"
                    value={formVar.firstName}
                    onChange={(e: any) => setFormVar({ ...formVar, firstName: e.target.value, firstNameError: "" })}
                    placeholder="Enter First Name"
                    maxLength={30}
                  ></input>
                  {formVar.firstNameError.length > 0 && (
                    <span style={{ color: "red" }}>{formVar.firstNameError}</span>
                  )}
                </div>
              </div>

              <div className="form-group ">
                <label>Last Name:</label>
                <div className="input_box">
                  <input
                    className="form-control form-control-two"
                    value={formVar.lastName}
                    onChange={(e: any) => setFormVar({ ...formVar, lastName: e.target.value, lastNameError: "" })}
                    placeholder="Enter Your Last Name"
                    maxLength={30}
                  ></input>
                  {formVar.lastNameError.length > 0 && (
                    <span style={{ color: "red" }}>{formVar.lastNameError}</span>
                  )}
                </div>
              </div>

              <div className="form-group ">
                <label>Company Name:</label>
                <div className="input_box">
                  <input
                    className="form-control form-control-two"
                    value={formVar.companyName}
                    onChange={(e: any) => setFormVar({ ...formVar, companyName: e.target.value, companyNameError: "" })}
                    placeholder="Enter Company Name"
                    maxLength={30}
                  ></input>
                  {formVar.companyNameError.length > 0 && (
                    <span style={{ color: "red" }}>{formVar.companyNameError}</span>
                  )}
                </div>
              </div>

              <div className="form-group ">
                <label>Website Link:</label>
                <div className="input_box">
                  <input
                    className="form-control form-control-two"
                    value={formVar.link}
                    onChange={(e: any) => setFormVar({ ...formVar, link: e.target.value, linkError: "" })}
                    placeholder="Enter Website Link"
                  ></input>
                  {formVar.linkError.length > 0 && (
                    <span style={{ color: "red" }}>{formVar.linkError}</span>
                  )}
                </div>
              </div>

              <div className="bottom_btn signup_contnu">
                <button id="add_rate_btn" type="submit" disabled={submitting}>Submit</button>
              </div>

            </form>
          </div>
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={showUpdateImageModal}
        onRequestClose={closeModal}
        className={"modal-content custom_modal thakyou_pop edit_profile"}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="modal-header">
          <button type="button" className="close" onClick={() => setShowUpdateImageModal(false)}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          <h3 className="modal-title">Edit Profile Picture</h3>
        </div>
        <div className="modal-body">
          <div className="user_request">
            <form className="main_form" onSubmit={(e) => _handleSubmit(e, "profile")} >
              <div className="profl_chng text-center">
                <div className="avatar-upload">
                  <div className="avatar-edit">
                    <input type="file" id="imageUpload_1" onChange={(e) => _handleImageChange_image(e)} />
                    <label htmlFor="imageUpload_1"></label>
                  </div>
                  <div className="avatar-preview">
                    <div className="profile_boarder" id="imagePreview" style={{ backgroundImage: `url(${cover_pic})`, border: '1px solid #4097e4' }}>
                    </div>
                  </div>
                </div>
                <h6>
                  Upload Profile Picture
                </h6>
              </div>
              <div className="bottom_btn">
                <button onClick={(e) => _handleSubmit(e, "profile")} id="upload_image_btn">Upload!</button>
                <div id="wait"><img src={loader_image} width="50" height="50" /></div>
              </div>
            </form>
          </div>
        </div>
        {isLoading == 5 && (
          <div id="" className="loaderbox loginloader">
            <div>
              <img
                className="bounce-2"
                src={loader_image}
                width="50"
                height="50"
              />
              <p>Loading...</p>
            </div>
          </div>
        )}
      </Modal>
    </Layout>
  )
}

export default reduxForm({
  form: 'searchForm', // a unique identifier for this form
  enableReinitialize: true
})(Profile)
